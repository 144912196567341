import styled from "styled-components"

import { Back } from "Components/Atoms/Back/style"
import { HeaderInputContainer } from "Components/Molecules/HeaderInput/style"
import { PageTitle } from "Components/Atoms/PageTitle/style"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const TopBar = styled.div`
    align-items: center;
    background-color: ${({ hasGreyBackground }) => hasGreyBackground ? colors.QuorumGrey1 : colors.White};
    box-sizing: border-box;
    display: flex;
    gap: 4px;
    height: 60px;
    justify-content: left;
    padding: 10px ${rules.TopBarPaddingV2};

    ${Back} {
        width: 40px;
        padding: 12px;
        justify-content: center;
        border-radius: 50%;
        color: ${colors.gray9};
        transition: background-color .3s ease-in-out, color .3s ease-in-out;

        &:hover {
            color: ${colors.violet9};
            background-color: ${colors.violet1};
        }
    }

    ${PageTitle} {
        // necessary to allow truncating text in child elements
        min-width: 0;
        flex: 1;
        flex-shrink: 0;
    }

    ${HeaderInputContainer} {
        margin-right: 10px;
    }
`

export const OptionsSection = styled.div`
    display: flex;
    gap: 8px;
    margin-left: auto;
    overflow: visible;

    && > div {
        border: 0px;
        padding-right: 0px;
    }
`

export const TitleComponent = styled.div`
    // necessary to allow truncating text in title components
    ${({ shouldTruncateTitle }) => shouldTruncateTitle ? "min-width: 0;" : ""};
    flex: 1;

    * {
        margin: unset;
    }
`
