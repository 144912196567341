import React from "react"

import { Input } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/TextAndNumberField.styles"
import { CustomField } from "@/types/djangio"
import { useFormReturn } from "QuorumGrassroots/campaign-forms/hooks/useForm"
import { PromptAndResponseCampaignForm } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/PersonalizedMessages.helper"

const { TagType } = DjangIO.app.person.types

interface Props {
    question: CustomField
    form: useFormReturn<PromptAndResponseCampaignForm>
    disabled?: boolean
}

export const TextAndNumberField = ({ question, form, disabled = false }: Props) => {
    const handleChange = (e) => form.setFieldValue(question.slug, e.target.value, true)

    return (
        <Input
            value={form.values[question.slug]}
            name={question.slug}
            label={question.external_name}
            onChange={handleChange}
            type={question.tag_type === TagType.number.value ? "number" : "text"}
            required={question.is_required}
            error={form.errors[question.slug]}
            disabled={disabled}
        />
    )
}
