import styled from "styled-components"
import { constants } from "QuorumGrassroots/constants"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { getFontColorForBackgroundContrast } from "QuorumGrassroots/styled-components/helperFunctions"

export const PersonalStory = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.5rem;

    color: black;

    min-width: 420px;
    max-width: 654px;

    @media (max-width: ${constants.mobileWidth + 1}px) {
        min-width: 250px;
    }
`

export const TitleAndSubtitle = styled.div`
    width: 100%;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${constants.colors.gray[2]};
`

export const StyledButton = styleWithOrgDesign(styled.button<{ isSingleColumnLayout: boolean }>`
    min-width: 145px;
    border: 0;
    padding: 16px 64px;
    border-radius: 8px;
    background: black;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    font-size: 16px;
    font-weight: 500;

    ${(props) =>
        props.isSingleColumnLayout &&
        `
        margin: 0 auto;
        background: ${props.organizationDesign.primary_color};
        color: ${getFontColorForBackgroundContrast(props.organizationDesign.primary_color)};
    `}
`)
