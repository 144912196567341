import React from "react"
import PropTypes from "prop-types"
import { Button } from "react-bootstrap"
import classNames from "classnames"

import ReduxFormFieldWrapper from "app/static/frontend/forms/components/ReduxFormFieldWrapper"

import "app/static/stylus/FormStyles.styl"
import "../stylus/CheckBoxField.styl"

export const CheckBoxField = (props) => {
    const {
        // Redux Form props
        input: { onChange, value },
        meta: { error, touched, warning },
        // Checkbox Field props
        accessibilityId,
        additionalClassNames,
        buttonText,
        dataCy,
        disabled,
        displayErrorWithoutTouch,
        label,
        labelStyle,
        largeButton,
        style,
        tooltipText,
        className,
        organizationDesign,
    } = props

    const iconClassNames = classNames("fa", { "fa-check-square": value, "fa-square-o": !value })

    const onClick = () => onChange(!value)

    const iconStyle = organizationDesign ? { color: organizationDesign.primary_color } : {}

    return (
        <ReduxFormFieldWrapper
            additionalClassNames={additionalClassNames}
            className={"checkbox"}
            dataCy={dataCy}
            displayErrorWithoutTouch={displayErrorWithoutTouch}
            error={error}
            label={label}
            labelStyle={labelStyle}
            style={style}
            tooltipText={tooltipText}
            touched={touched}
            warning={warning}
            accessibilityId={accessibilityId}
        >
            {largeButton && (
                <Button
                    data-cy={dataCy}
                    bsSize="large"
                    onClick={onClick}
                    disabled={disabled}
                    className={className || ""}
                >
                    <i className={iconClassNames} style={iconStyle} />
                    {buttonText && <div className="form-checkbox-button-text">{buttonText}</div>}
                </Button>
            )}
            {!largeButton && (
                <div
                    className={classNames("small-button-wrapper " + className || "", {
                        "is-disabled": disabled,
                    })}
                    onClick={!disabled ? onClick : undefined}
                    onKeyDown={!disabled ? onClick : undefined}
                >
                    <i className={`small-button-icon ${iconClassNames}`} style={iconStyle} />
                    {buttonText && <span className="form-checkbox-icon-text">{buttonText}</span>}
                </div>
            )}
        </ReduxFormFieldWrapper>
    )
}

CheckBoxField.defaultProps = {
    displayErrorWithoutTouch: false,
    largeButton: true,
    disabled: false,
    shouldChangeColor: false,
}

CheckBoxField.propTypes = {
    // Redux Form props
    input: PropTypes.object,
    meta: PropTypes.object,

    // CheckboxField props
    accessibilityId: PropTypes.string,
    additionalClassNames: PropTypes.string,
    buttonText: PropTypes.string,
    dataCy: PropTypes.string,
    displayErrorWithoutTouch: PropTypes.bool,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
    style: PropTypes.object,
    tooltipText: PropTypes.string,
    largeButton: PropTypes.bool,
    disabled: PropTypes.bool,
    shouldChangeColor: PropTypes.bool,
    className: PropTypes.string,
    organizationDesign: PropTypes.object,
}

export default CheckBoxField
