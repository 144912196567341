import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import isEqual from "lodash.isequal"

import { Button, Col, Row } from "react-bootstrap"
import Tooltip from "components/Tooltip"
import Icon from "app/static/frontend/components/Icon"
import { SelectableItem, Checkbox, Icon as DSIcon, Label } from "quorumdesign"
import "components/stylus/GridToggle.styl"
import styled, { css } from "styled-components"
import * as constants from "app/static/frontend/design-constants"

const ToggleIndenter = styled.div`
    ${(props) =>
        props.isIndentedToggle &&
        css`
            margin-left: 30px;
        `}
`

const IconWrapper = styled.div`
    svg {
        font-size: 14px;
        margin-left: 4px;
    }
`

const ToggleButtonComponent = styled(Button)`
    &.btn-primary,
    &:active:focus {
        background-color: ${(props) =>
            props.organizationDesign ? props.organizationDesign.primary_color : constants.LightPurple};
    }
    &:hover,
    &:focus,
    &:active:focus,
    &.btn-primary {
        outline: 1px solid
            ${(props) => (props.organizationDesign ? props.organizationDesign.primary_color : constants.LightPurple)};
    }
`

export const GridToggle = ({
    accessibilityId,
    bsColWidth,
    gridClassName,
    multi,
    numbered,
    onChange,
    options,
    selectedStyle,
    tooltipPlacement,
    unselectedStyle,
    value,
    // additional props for Design System components
    errorMessage,
    hideIndividualErrorMessage,
    isIndentedToggle,
    label,
    readOnly,
    shouldRenderNewToggles,
    warningMessage,
    compactToggle,
    organizationDesign,
}) => {
    // Using `JSON.stringify` to compare values that are objects, while not affecting how integer or string values are compared
    const isSelected = (option) => {
        if (multi) {
            return value.includes(option.value)
        } else {
            if (option.value?.value !== undefined) {
                // sometimes we store a value that is the entire object,
                // so we can access other related data when we defined a customOnChange
                // for example, see QuorumGrassroots/widgets/DonationForm/components/index.js
                return isEqual(value, option.value.value)
            }

            return isEqual(value, option.value)
        }
    }

    const handleChange = (choice) => {
        if (multi) {
            if (value && value.includes(choice.value)) {
                // remove choice from array
                onChange(value.filter((val) => val !== choice.value))
            } else {
                // add choice to value or empty array if value is null
                onChange(value.concat(choice.value))
            }
        } else {
            // set the value
            onChange(choice.value, choice)
        }
    }

    const renderImageIcons = (option) => (
        <img
            alt={option.label}
            src={isSelected(option) ? option.active_icon_path : option.inactive_icon_path}
            className="grid-toggle-image-icon"
        />
    )

    const renderLabelAndIcons = (option) => [
        option.topLabel && (
            <div
                className={classNames("grid-toggle-option", {
                    "grid-toggle-label-with-icon": option.icon || option.iconElement,
                    "grid-toggle-with-option": option.disabled || multi,
                })}
                key={`${option.topLabel}-top-label`}
            >
                {option.topLabel}
            </div>
        ),
        option.icon ? (
            <Icon className="grid-toggle-icon" name={option.icon} key={`${option.label}-${option.icon}`} />
        ) : (
            option.iconElement
        ),
        <div
            className={classNames("grid-toggle-option", {
                "grid-toggle-label-with-icon": option.icon || option.iconElement,
                "grid-toggle-with-option": option.disabled || multi,
            })}
            key={`${option.label}-label`}
        >
            {option.label}
        </div>,
        // Icons - lock if disabled, checks if multi
        option.disabled || option.disabledOverride ? (
            <Icon name="lock" key={`${option.label}-lock`} />
        ) : (
            multi &&
            (value.includes(option.value) ? (
                numbered ? (
                    <div className="check-number">{value.findIndex((item) => item === option.value) + 1}</div>
                ) : (
                    <Icon name="check-square-o" key={`${option.label}-check`} />
                )
            ) : (
                <Icon name="square-o" key={`${option.label}-check`} />
            ))
        ),
    ]

    const renderOptionTooltip = (option) =>
        option.tip || option.info ? (
            <Tooltip label={option.tip || option.info}>
                <IconWrapper>
                    <DSIcon color={constants.DarkGrey} icon="info-circle" iconFamily="far" />
                </IconWrapper>
            </Tooltip>
        ) : undefined

    return shouldRenderNewToggles ? (
        options && (
            <ToggleIndenter isIndentedToggle={isIndentedToggle}>
                {label && (
                    <Label
                        title={label}
                        style={{
                            marginTop: "15px",
                        }}
                    />
                )}
                {options.map((option) =>
                    compactToggle ? (
                        <SelectableItem
                            dataCy={option.dataCy || `grid-choice-${option.label}`}
                            disabled={option.disabled}
                            errorMessage={errorMessage}
                            hideIndividualErrorMessage={hideIndividualErrorMessage}
                            icon={option.icon}
                            iconFamily={option.iconFamily}
                            isActive={isSelected(option)}
                            isSquare={false}
                            key={`tooltip-${option.value?.value || option.value}-${option.label}`}
                            label={option.label}
                            onClick={readOnly ? null : () => handleChange(option)}
                            size="medium"
                            suppressBackground
                            variation={multi ? "checkbox" : "radioCheckbox"}
                            warningMessage={warningMessage}
                        >
                            {renderOptionTooltip(option)}
                        </SelectableItem>
                    ) : (
                        <Checkbox
                            key={`tooltip-${option.value?.value || option.value}-${option.label}`}
                            disabled={option.disabled}
                            errorMessage={errorMessage}
                            hideIndividualErrorMessage={hideIndividualErrorMessage}
                            isChecked={isSelected(option)}
                            onChange={readOnly ? null : () => handleChange(option)}
                            text={option.label}
                            tooltipElement={renderOptionTooltip(option)}
                            variation={multi ? "checkbox" : "radioCheckbox"}
                            warningMessage={warningMessage}
                            dataCy={option.dataCy}
                        />
                    ),
                )}
            </ToggleIndenter>
        )
    ) : (
        <Row id={accessibilityId} className={multi ? "grid-toggle multi-grid-toggle" : "grid-toggle"}>
            {options &&
                options.map((option) => (
                    <Tooltip
                        label={option.tip || option.info || ""}
                        id={option.tip}
                        key={`tooltip-${option.value?.value || option.value}-${option.label}`}
                        placement={tooltipPlacement}
                    >
                        <Col md={option.optionWidth || bsColWidth} className="grid-toggle-button">
                            <ToggleButtonComponent
                                aria-disabled={option.disabled || readOnly}
                                variant={isSelected(option) ? "primary" : null}
                                data-cy={option.dataCy || `grid-toggle-${option.label}`}
                                onClick={readOnly ? null : () => handleChange(option)}
                                className={classNames("btn-block", "gridtoggle-button", gridClassName, {
                                    "btn-default-white": !isSelected(option),
                                    disabled: option.disabled,
                                    [option.gridToggleClassName]: option.gridToggleClassName,
                                })}
                                disabled={option.disabled || readOnly}
                                // Custom styling if styling is specificed and option is selected
                                style={isSelected(option) ? selectedStyle : unselectedStyle}
                                organizationDesign={organizationDesign}
                            >
                                {option.should_use_icon_path ? renderImageIcons(option) : renderLabelAndIcons(option)}
                            </ToggleButtonComponent>
                        </Col>
                    </Tooltip>
                ))}
        </Row>
    )
}

GridToggle.propTypes = {
    accessibilityId: PropTypes.string,

    bsColWidth: PropTypes.number,
    errorMessage: PropTypes.string,
    gridClassName: PropTypes.string,
    hideIndividualErrorMessage: PropTypes.bool,
    isIndentedToggle: PropTypes.bool,
    multi: PropTypes.bool,
    numbered: PropTypes.bool, // If multi and numbered, will number values by order in array
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            disabledOverride: PropTypes.bool, // if you'd like to use the lock icon without making the button unclickable
            tip: PropTypes.string,
            icon: PropTypes.string,
            iconElement: PropTypes.object, // if you'd like to give the full icon element instead of the fa name, use either icon or iconElement
            gridToggleClassName: PropTypes.string, // additional class name for the grid toggle button
        }),
    ),
    selectedStyle: PropTypes.object,
    shouldRenderNewToggles: PropTypes.bool,
    unselectedStyle: PropTypes.object,
    value: PropTypes.any,
    warningMessage: PropTypes.string,
    compactToggle: PropTypes.bool,
    organizationDesign: PropTypes.object,
}

GridToggle.defaultProps = {
    accessibilityId: "",
    bsColWidth: 4,
    numbered: false,
    compactToggle: false,
}
export default GridToggle
