import React, { useState } from "react"

import { TextAndNumberField } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/TextAndNumberField"
import { Heading } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/PersonalizedMessages.styles"
import {
    Container,
    IconContainer,
    PersonalStory,
    Subtitle,
    StyledButton,
} from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/QuestionsContainer.styles"
import { Campaign, CustomField } from "@/types/djangio"
import { useFormReturn } from "QuorumGrassroots/campaign-forms/hooks/useForm"
import { DatePickerField } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/DatePickerField"
import { SingleSelectionField } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/SingleSelectionField"
import { MultiSelectionField } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/MultiSelectionField"
import { BooleanField } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/BooleanField"
import {
    PromptAndResponseCampaignForm,
    validateFormValues,
} from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/PersonalizedMessages.helper"
import {
    Contents,
    IS_SINGLE_COLUMN_ENABLED,
} from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/PersonalizedMessages"
import { usePersonalizedCampaignGetCampaignMessages, useReworkMessageWithAI } from "QuorumGrassroots/services"

interface ExtraInputProps {
    disabled?: boolean
}

const { TagType } = DjangIO.app.person.types

const QUESTION_INPUT_MAP = {
    [TagType.string.value]: TextAndNumberField,
    [TagType.number.value]: TextAndNumberField,
    [TagType.date.value]: DatePickerField,
    [TagType.single_option_list.value]: SingleSelectionField,
    [TagType.multi_options_list.value]: MultiSelectionField,
    [TagType.boolean.value]: BooleanField,
}

const renderQuestion = (
    question: CustomField,
    form: useFormReturn<PromptAndResponseCampaignForm>,
    extraInputProps: ExtraInputProps = {},
) => {
    const QuestionComponent = QUESTION_INPUT_MAP[question.tag_type]
    if (!QuestionComponent) return <></>

    return <QuestionComponent data-cy="question-input" question={question} form={form} {...extraInputProps} />
}

interface Props {
    form: useFormReturn<PromptAndResponseCampaignForm>
    questions: CustomField[]
    handleChangeContent: (contentName: Contents) => void
    campaign: Campaign
}

export const QuestionsContainer = ({ form, questions, handleChangeContent, campaign }: Props) => {
    const [shouldGeneratePreview, setShouldGeneratePreview] = useState(false)

    const messagesQuery = usePersonalizedCampaignGetCampaignMessages(String(campaign.id), shouldGeneratePreview)
    const reworkQuery = useReworkMessageWithAI(
        messagesQuery.data,
        form.values,
        questions,
        campaign,
        Boolean(shouldGeneratePreview && messagesQuery.data),
    )

    const isLoading = (reworkQuery.isLoading || messagesQuery.isLoading) && shouldGeneratePreview

    const handleNavigateToNextPage = () => {
        const errors = validateFormValues(form.values, questions)
        if (Object.keys(errors).length > 0) {
            form.setFormErrors(errors)
            return
        }
        setShouldGeneratePreview(true)
    }

    if (reworkQuery.data && shouldGeneratePreview) {
        setShouldGeneratePreview(false)
        handleChangeContent(Contents.sendMessage)
    }

    return (
        <PersonalStory data-cy="question-form">
            {IS_SINGLE_COLUMN_ENABLED ? (
                <Heading>
                    <h3>Act now!</h3>
                    <h5>Fill out the form below to support us!</h5>
                </Heading>
            ) : (
                <>
                    <IconContainer>
                        <i className="fa fa-comment" />
                    </IconContainer>
                    <Subtitle>My personal story</Subtitle>
                </>
            )}
            <Container isSingleColumnLayout={IS_SINGLE_COLUMN_ENABLED}>
                {questions.map((question) => renderQuestion(question, form, { disabled: isLoading }))}
            </Container>
            <StyledButton
                isSingleColumnLayout={IS_SINGLE_COLUMN_ENABLED}
                className="send-button"
                data-cy="submit-questions"
                onClick={handleNavigateToNextPage}
                disabled={isLoading}
            >
                {isLoading ? (
                    <>
                        <i className={"fa fa-spinner fa-spin"} />
                        Creating message
                    </>
                ) : (
                    <>
                        Review info <i className={"fa fa-arrow-right"} />
                    </>
                )}
            </StyledButton>
        </PersonalStory>
    )
}
