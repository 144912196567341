import { fullWidthWidgetConnection } from "QuorumGrassroots/widgets/selectors"
import { createSelector, createStructuredSelector } from "reselect"
import { fromJS } from "immutable"

export const selectTransactionTotalsByYear = createSelector(() => {
    const totalsByYear = Userdata.total_amount_by_year
    if (totalsByYear) {
        return totalsByYear
    }
    // If there are no transactions at all, just show current year with 0.
    const currentYear = new Date().getFullYear()
    return [{ year: currentYear.toString(), value: 0 }]
})

export const selectLastTransactionAmount = createSelector(() => {
    return fromJS(Userdata.last_transaction_amount) || 0
})
export const selectLastTransactionDate = createSelector(() => {
    return fromJS(Userdata.last_transaction_date) || "Never"
})
export const selectLastTransactionMethod = createSelector(() => {
    if (Userdata.last_transaction_method === "Web") {
        return "Credit Card"
    }

    return fromJS(Userdata.last_transaction_method) || "None"
})

export const donationHistoryWidgetConnection = createStructuredSelector({
    lastTransactionAmount: selectLastTransactionAmount,
    lastTransactionDate: selectLastTransactionDate,
    lastTransactionMethod: selectLastTransactionMethod,
    transactionTotalsByYear: selectTransactionTotalsByYear,
    ...fullWidthWidgetConnection,
})
