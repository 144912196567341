import React, { useEffect, useState } from "react"
import { MentionsInput, Mention } from "react-mentions"
import { editablePlaceholderMap } from "QuorumGrassroots/helperFunctions"

import {
    StyledTextareaContainer,
    StyledLabel,
} from "QuorumGrassroots/framework/components/HighlightedTextarea/HighlightedTextarea.styles"
import { useDebouncedCallback } from "QuorumGrassroots/framework/hooks/useDebouncedCallback"
import { constants } from "QuorumGrassroots/constants"

const validPlaceholders = Object.values(editablePlaceholderMap)

export const HighlightedTextarea = ({ className, setWrongPlaceholder, ...props }) => {
    const [value, setValue] = useState(props.value ?? "")

    //The internal state and this useEffect are a workaround until we remove redux from the write a letter campaign.
    //See https://quorumanalytics.atlassian.net/browse/NEO-5616 for context
    useEffect(() => {
        if (props.value !== value) {
            setValue(props.value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value])

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value
        setValue(value)
        props.onChange(value)
        handleInvalidPlaceholders(value)
    }

    const handleInvalidPlaceholders = useDebouncedCallback((value: string) => {
        const placeholdersInText = value.match(/\{\{[^}]+\}\}/g)
        if (!placeholdersInText) {
            setWrongPlaceholder("")
            return
        }
        const invalidPlaceholder = placeholdersInText.find((placeholder) => !validPlaceholders.includes(placeholder))
        invalidPlaceholder ? setWrongPlaceholder(invalidPlaceholder) : setWrongPlaceholder("")
    }, 300)

    return (
        <>
            <StyledLabel>{props.label}</StyledLabel>
            <StyledTextareaContainer>
                <MentionsInput
                    {...props}
                    value={value}
                    onChange={handleChange}
                    className={`highlighted-textarea ${className}`}
                >
                    <Mention
                        markup="{{__id__}}"
                        displayTransform={(id, display) => `{{${display}}}`}
                        style={{
                            backgroundColor: constants.colors.gray[2],
                            borderRadius: "4px",
                            padding: "2px 0 2px 0",
                        }}
                    />
                </MentionsInput>
            </StyledTextareaContainer>
        </>
    )
}
