import * as Sentry from "@sentry/browser"

export const initSentry = ({ dsn, user }: { dsn: string; user?: Sentry.User }) => {
    Sentry.init({
        dsn,
        environment: window.ENVIRONMENT,
        release: window.CURRENT_VERSION,
        debug: window.ENVIRONMENT === "Development",
    })

    if (user) {
        Sentry.setUser(user)
    }

    // Remap the legacy SDK to the new one
    // @ts-expect-error We replaced Raven with Sentry, no point in typing dead code
    window.Raven = Sentry
}
