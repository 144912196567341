import React, { useState } from "react"
import { Stack, TextInput, Textarea, TextareaProps } from "@mantine/core"
import { withLock } from "QuorumGrassroots/styled-components/components/InputWithLock"
import { Campaign } from "@/types/djangio"
import { StyledSpacing } from "QuorumGrassroots/styled-components/components/StyledSpacing"
import { shouldShowOrganizationField } from "QuorumGrassroots/campaign-forms/helpers"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"
import { EditableMessagePreview } from "QuorumGrassroots/framework/components/EditableMessagePreview"

const { DraftOptions, CampaignType } = DjangIO.app.grassroots.campaign.types

const TextInputWithLock = withLock(TextInput)
const TextareaWithLock = withLock(Textarea)

type EditableTextAreaProps = TextareaProps & {
    isMultiAction?: boolean
    currentTargetedMessage?: object
    isVisualizationMode?: boolean
    setIsVisualizationMode?: (value: boolean) => void
    setWrongPlaceholder?: (value: string) => void
    wrongPlaceholder?: string
    firstSelectedTargetId?: number
    activeGroupId?: string
    updateFormattedMaMessage?: (groupId: number, targetedMaMessage: object) => void
    selectedTargetId?: number
    t: (text: string) => string
}
interface Props {
    campaign: Campaign
    queriedMessages: Record<string, Record<number, MultiActionSingleMessage>>
    selectedGroupId: number
    firstSelectedTargetId: number
    activeGroupId: string
    setQueriedMessages: React.Dispatch<
        React.SetStateAction<{
            [k: string]: object
        }>
    >
    t: (text: string) => string
    setWrongPlaceholder: (value: string) => void
    wrongPlaceholder: string
    isLoadingBody: boolean
}

const AdaptedEditableMessagePreview: React.FC<EditableTextAreaProps> = (props) => {
    return (
        <EditableMessagePreview
            input={{
                onChange: props.onChange,
                value: props.value,
                onBlur: props.onBlur,
            }}
            isQueryMode
            {...props}
        />
    )
}

export const QueriedMessageLayout = (props: Props) => {
    const isMessagePreviewEnabled = isFeatureEnabled("ff_ngg_message_preview")
    const [isVisualizationMode, setIsVisualizationMode] = useState(true)
    const messages = props.queriedMessages[props.campaign.campaign_type]
    const message = messages[props.selectedGroupId]
    const initialMessage = props.campaign.messages[props.selectedGroupId]
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        type: "subject" | "preBody" | "body" | "postBody" | "submitting_org_name",
    ) => {
        const preBody = type === "preBody" ? e.target.value : message.raw.preBody
        const postBody = type === "postBody" ? e.target.value : message.raw.postBody
        const subject = type === "subject" ? e.target.value : message.raw.subject
        const body = type === "body" ? e.target.value : message.raw.content
        const submitting_org_name = type === "submitting_org_name" ? e.target.value : message.submitting_org_name ?? ""
        props.setQueriedMessages((lastMessages) => {
            const group = lastMessages[props.campaign.campaign_type][props.selectedGroupId]
            const updatedGroup = {
                ...group,
                raw: {
                    ...group.raw,
                    content: body,
                    subject,
                    preBody,
                    postBody,
                },
                formatted: {
                    ...group.formatted,
                },
                ...(submitting_org_name && { submitting_org_name }),
            }
            return {
                ...lastMessages,
                [props.campaign.campaign_type]: {
                    ...lastMessages[props.campaign.campaign_type],
                    [props.selectedGroupId]: updatedGroup,
                },
            }
        })
        return
    }

    const updateFormattedMaMessage = (groupId, targetedMaMessage) => {
        props.setQueriedMessages((lastMessages) => {
            const group = lastMessages[props.campaign.campaign_type][props.selectedGroupId]
            const updatedGroup = {
                ...group,
                raw: targetedMaMessage.raw,
                formatted: targetedMaMessage.formatted,
            }
            return {
                ...lastMessages,
                [props.campaign.campaign_type]: {
                    ...lastMessages[props.campaign.campaign_type],
                    [groupId]: updatedGroup,
                },
            }
        })
    }

    const draftOption = message.advocate_editing_permission ?? props.campaign.draft_requirements
    const noEdit = draftOption === DraftOptions.cannot_edit.value
    const SubjectInput = noEdit ? TextInputWithLock : TextInput

    const campaignTypesAbleToPreview: number[] = [
        CampaignType.write_member.value,
        CampaignType.personalized_messages.value,
    ]

    const isCustomTarget = !!message?.is_custom
    const isCampaignTypeValidForMessagePreview = campaignTypesAbleToPreview.includes(props.campaign.campaign_type)

    const shouldShowMessagePreview = isMessagePreviewEnabled && !isCustomTarget && isCampaignTypeValidForMessagePreview

    const BodyInput =
        shouldShowMessagePreview && !noEdit ? AdaptedEditableMessagePreview : noEdit ? TextareaWithLock : Textarea

    return (
        <form>
            <Stack spacing="md">
                <SubjectInput
                    value={message.raw.subject}
                    label="Subject"
                    onChange={(e) => handleChange(e, "subject")}
                />

                {initialMessage.formatted.preBody && (
                    <TextareaWithLock
                        label="Message"
                        autosize
                        minRows={1}
                        value={message.formatted.preBody}
                        onChange={(e) => handleChange(e, "preBody")}
                    />
                )}
                <BodyInput
                    value={
                        shouldShowMessagePreview
                            ? isVisualizationMode
                                ? message.formatted.content
                                : message.raw.content
                            : message.raw.content
                    }
                    label={
                        (shouldShowMessagePreview && initialMessage.formatted?.preBody) || initialMessage.preBody
                            ? ""
                            : "Message"
                    }
                    minRows={3}
                    autosize
                    onChange={(e) => handleChange(e, "body")}
                    isEditEnabled={!noEdit}
                    disabled={shouldShowMessagePreview && isVisualizationMode}
                    t={props.t}
                    currentTargetedMessage={message}
                    isVisualizationMode={isVisualizationMode}
                    setIsVisualizationMode={setIsVisualizationMode}
                    setWrongPlaceholder={props.setWrongPlaceholder}
                    wrongPlaceholder={props.wrongPlaceholder}
                    selectedTargetId={props.firstSelectedTargetId ?? ""}
                    activeGroupId={props.selectedGroupId}
                    updateFormattedMaMessage={updateFormattedMaMessage}
                    isLoading={props.isLoadingBody}
                />
                {initialMessage.formatted.postBody && (
                    <TextareaWithLock
                        autosize
                        minRows={1}
                        value={message.formatted.postBody}
                        onChange={(e) => handleChange(e, "postBody")}
                    />
                )}

                {shouldShowOrganizationField(props.campaign) && (
                    <>
                        <StyledSpacing />
                        <TextInput
                            label={props.t("campaign.comment_on_regulation.organization")}
                            value={message.submitting_org_name}
                            onChange={(e) => handleChange(e, "submitting_org_name")}
                        ></TextInput>
                    </>
                )}
            </Stack>
        </form>
    )
}
