import { TextInput } from "@mantine/core"
import styled from "styled-components"

import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"

export const Input = styleWithOrgDesign(styled(TextInput)`
    width: 100%;

    font-size: 14px;
    color: var(--custom-font-color);
    border-color: var(--custom-border-color);

    & .mantine-TextInput-input {
        border-radius: 8px;
        background-color: white;
        color: black;

        min-height: 48px;
    }
`)
