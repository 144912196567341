import React from "react"
import PropTypes from "prop-types"

import { thankable } from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/ThankableWrapper"

import { resetSubmittedWidget } from "QuorumGrassroots/widgets/action-creators"
import { selectWidgetSubmitted } from "QuorumGrassroots/widgets/selectors"
import { selectCampaignThanksProps } from "QuorumGrassroots/widgets/Campaign/selectors"
import { StyledContrastText } from "QuorumGrassroots/styled-components/components/StyledContrastText"

import { CampaignWarning } from "QuorumGrassroots/widgets/Campaign/components/CampaignWarning"

export const WrappedCampaign = ({ t, ...props }) => {
    if (!props.hasLoaded || props.isLoadingContent || props.campaignListLoading) {
        return <StyledContrastText isCampaignPage>{t("loading")}</StyledContrastText>
    } else if (!props.canParticipate) {
        const text =
            props.hasAlreadyParticipated && props.campaignTargetsSupporter
                ? t("campaign.cannot_participate_again")
                : t("campaign.cannot_participate")

        return (
            <CampaignWarning
                className="cannot-participate-banner"
                text={text}
                dataCy="cannot-participate"
                t={t}
                isCampaignPage
            />
        )
    } else {
        const CampaignForm = props.campaignForm

        return (
            <div className="base-campaign-form-wrapper">
                <CampaignForm {...props} t={t} />
            </div>
        )
    }
}

WrappedCampaign.propTypes = {
    campaignForm: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    hasLoaded: PropTypes.bool.isRequired,
    isLoadingContent: PropTypes.bool.isRequired,
    canParticipate: PropTypes.bool.isRequired,
    campaignListLoading: PropTypes.bool.isRequired,
    hasAlreadyParticipated: PropTypes.bool.isRequired,
}

/**
 * This is the wrapper that all forms must have. This is responsible for
 * doing all the logic checks about whether or not we should show the form,
 * and also the thankable wrapper.
 *
 * This is the wrapper that controls what the user sees AFTER the user has
 * logged in.
 */
export const baseCampaignFormWrapper = (CampaignForm) => {
    const component = (props) => <WrappedCampaign {...props} campaignForm={CampaignForm} />

    return thankable({
        shouldThankSelector: selectWidgetSubmitted,
        thanksPropsSelector: selectCampaignThanksProps,
        onRerouteThank: resetSubmittedWidget,
    })(component)
}

/**
 * Since we are gradually moving campaigns out of redux, we are redirecting to the thank you page
 * manually on each campaign, so we don't need the thankable wrapper.
 */
export const baseCampaignFormWrapperWithoutThankable = (CampaignComponent) => {
    return (props) => <WrappedCampaign {...props} campaignForm={CampaignComponent} />
}
