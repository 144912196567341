import React, { useCallback } from "react"
import PropTypes from "prop-types"
import queryString from "query-string"
import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

import { LoadingSpinner } from "app/static/frontend/components/LoadingSpinner"

import { DistrictName, Title } from "QuorumGrassroots/widgets/DistrictList/components/style"

import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"

function DistrictList({ title, districtsPagePath, districtsList, location, loading }) {
    const ordinalSuffix = useCallback((number) => {
        const suffixes = {
            1: "st",
            2: "nd",
            3: "rd",
        }
        const lastDigit = number % 10
        const lastTwoDigits = number % 100

        if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
            return "th"
        } else {
            return suffixes[lastDigit] || "th"
        }
    }, [])

    const getDistrictNumberConsideringAtLargeDistricts = useCallback((districtNumber) => {
        const AT_LATGE_DISTRICTS_NUMBERS = [0, 98]

        if (AT_LATGE_DISTRICTS_NUMBERS.includes(districtNumber)) {
            return 1
        }

        return districtNumber
    }, [])

    const combineQueryParams = useCallback(
        (params) => {
            const currentParams = queryString.parse(location.search)
            const newParams = { ...currentParams, ...params }
            return queryString.stringify(newParams)
        },
        [location.search],
    )

    return (
        <WidgetStyleWrapper useWidgetStyling id="district-list-container">
            <Title id="district-list-title">{title || `Select a district`}</Title>
            {loading ? (
                <LoadingSpinner id="districts-list-loading" />
            ) : (
                <Row>
                    {districtsList.map((district) => {
                        const pathname = districtsPagePath || location.pathname
                        const districtNumber = getDistrictNumberConsideringAtLargeDistricts(district.number)
                        return (
                            <Col key={district.id} xs={6} sm={6} md={2} lg={1}>
                                <Link to={{ pathname: pathname, search: combineQueryParams({ cd: district.number }) }}>
                                    <DistrictName>{`${districtNumber}${ordinalSuffix(
                                        districtNumber,
                                    )} district`}</DistrictName>
                                </Link>
                            </Col>
                        )
                    })}
                </Row>
            )}
        </WidgetStyleWrapper>
    )
}

DistrictList.propTypes = {
    title: PropTypes.string,
    districtsList: PropTypes.array,
    location: PropTypes.object,
    loading: PropTypes.bool,
}

export default DistrictList
