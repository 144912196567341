import { Map, fromJS } from "immutable"
import queryString from "query-string"
import swal from "sweetalert"

import BACKENDERROR from "app/static/frontend/imports/backenderror"
import { swalConfigs, getBackendValidationSwalConfig } from "QuorumGrassroots/swalConfigs"
import { api } from "@/api"
import {
    actionTypes as frameworkActionTypes,
    loadFrameworkActionTypes,
    loginActionTypes,
} from "QuorumGrassroots/framework/action-types"

import { actionTypes, loadWidgetContentActionTypes } from "QuorumGrassroots/widgets/action-types"

import { sendAction, resourceGetList, resourceGetDetail } from "shared/djangio/action-creators"
import { createTagDict } from "shared/customFields/action-creators"

import { selectCustomFields } from "QuorumGrassroots/framework/selectors"
import { enableOneClickRegistration, updateSupporterPoints } from "QuorumGrassroots/framework/action-creators"
import { selectWidgetFormRegisteredFields } from "QuorumGrassroots/widgets/selectors"

import { redirectToUrl } from "QuorumGrassroots/widgets/helperFunctions"
import { getPathObject, runUserJavascript } from "QuorumGrassroots/helperFunctions"
import { ADDRESS_KEY } from "shared-web/forms/constants"
import { getNestedObjectKey } from "shared/imports/sharedHelperFunctions"

import {
    formatMessageGroupsToSubmitWriteMember,
    formatValuesToSubmitWriteMember,
    generateGrassrootsActionsWithImmutableValues,
    isWriteALetterCampaignWithOneClickRegistration,
} from "QuorumGrassroots/campaign-forms/helpers"
import { submitWriteForm } from "QuorumGrassroots/campaign-forms/action-creators"
import { generateWriteAMemberActions } from "QuorumGrassroots/campaign-forms/grassrootsActionGenerators"

const locks = {}

const { GrassrootsWidgetType } = DjangIO.app.grassroots.types

const { CampaignType } = DjangIO.app.grassroots.campaign.types

/*
 * Welcome to the Widget action creators! This will contain all action creators
 * for the widgets. There may come a time when the decision to keep all the
 * action creators in the same place may turn out to be a terrible idea.
 * The hope is that we don't need to make too many.
 *
 * Happy actioning!
 */

/**
 * Before registration or login, the page is set to 0 supporter points (window.supporter_points)
 * After registration or login success, need to update the page with the logged-in supporter's points
 *
 * @param  {object} response - HTTP response containing data, status, headers, etc
 * @param  {function} dispatch - dispatch function for Redux
 */
export const updateSupporterPointsOnSuccess = (response, dispatch) => {
    const supporterPoints = getNestedObjectKey(response, ["data", "supporterPoints"])

    if (supporterPoints) {
        dispatch(updateSupporterPoints(supporterPoints))
    }
}

/**
 * Given login values from LoginForm.js, call the grassroots_login
 * action in NewSupporterResource in order to try and log the user
 * in with this information.
 *
 * If there are any parameters passed in, pass them on to the login
 * function. Uses for fun things like texting login.
 *
 * @param  {object} loginValues immutable map of the LoginForm values.
 *
 * @return {promise} returns the promise generated by sendAction.
 */
export const submitLogin = (loginValues) => (dispatch, getState) => {
    const queryValues = queryString.parse(window.location.search)

    const kwargs = Object.assign({}, loginValues.toJS(), queryValues)

    return dispatch(
        sendAction(DjangIO.app.grassroots.models.NewSupporter, loginActionTypes, {
            action: "grassroots_login",
            method: "post",
            kwargs,
        }),
    )
        .catch((error) => BACKENDERROR(error, swalConfigs.loginError, undefined, false))
        .then((res) => {
            dispatch({ type: "CLEAR_USERDATA" })
            dispatch(reloadCampaignList())
        })
}

/**
 * Loads CampaignList data using the same strategy as preloadData
 * during supporter login, update or logout
 */
export const reloadCampaignList = () => (dispatch) => {
    const fieldEnum = DjangIO.app.grassroots.types.GrassrootsContextField.campaign_list
    const resource = DjangIO.modelAtPath(fieldEnum.model)

    const filters = {
        limit: 20,
        max_limit: 0,
        only_fields: fieldEnum.only_fields && !fieldEnum.resource && fieldEnum.only_fields,
        order_by: fieldEnum.order_by && fieldEnum.order_by.length && fieldEnum.order_by[0],
        ...fieldEnum.filters,
    }

    Object.keys(filters).forEach((key) => filters[key] == null && delete filters[key])

    dispatch(
        resourceGetList(resource, loadFrameworkActionTypes, filters, {
            fieldKey: fieldEnum.store_key,
            isDetail: fieldEnum.detail,
        }),
    )
}

export const submitLoginSuccess = (response, dispatch, props) => {
    // Update page with supporter's points after login
    updateSupporterPointsOnSuccess(response, dispatch)

    // If private action center and they specified a redirect url
    if (!props.publiclyAccessible && props.loginRedirectUrl) {
        const pathObject = getPathObject(props.loginRedirectUrl)

        // If the redirect url is an internal sign up page, add the current path as the next query
        if (
            pathObject &&
            pathObject.isInternal &&
            pathObject.url.includes(DjangIO.app.grassroots.types.GrassrootsWidgetType.sign_up.widget_url)
        ) {
            const nextQuery = `?next=${window.location.pathname}`

            redirectToUrl(props, props.loginRedirectUrl + nextQuery)
        } else {
            redirectToUrl(props, props.loginRedirectUrl)
        }
    }

    // Reload after login so window.Userdata is updated
    window.location.reload()
}

/**
 * Helper function that'll take the inputted value from user forms.
 * Used in Registration and Update Information forms.
 *
 * and format them the way we need.
 * @param  {Object} formValues form values
 * @param  {Object} state      store state
 * @return {Object}            the data that will be sent in the POST/PATCH
 */
export const formatUserInformationData = (formValues, state, registrationPageIds = []) => {
    const customFields = selectCustomFields(state).toJS()

    const tag_dict = createTagDict(customFields, formValues)

    // remove the custom fields from formValues, as they already
    // have been put into tag_dict!
    customFields.map((field) => delete formValues[field.slug])

    const addressFormKey = DjangIO.app.grassroots.types.GrassrootsRegistrationField.address.supporter_field

    const charity = formValues.charity && {
        charity_ein: formValues.charity.value,
        charity_name: formValues.charity.label,
        charity_address: formValues.charity.address,
    }

    // input_address can be either a string or a geocoded object, depending
    // on whether the data is received via the Google Maps Places Autocomplete
    // or user input. Here, we're changing input_address so that all the
    // geocoding stuff gets put into "address_dict" and "input_address" is
    // a happy little string.
    if (typeof formValues[addressFormKey] === "object") {
        formValues["address_dict"] = formValues[addressFormKey]
        formValues[addressFormKey] = formValues[addressFormKey][ADDRESS_KEY]
    }

    if (registrationPageIds && registrationPageIds.length) {
        // When registering, registrationPageIds should be an array of 1 registration page.
        formValues.registration_page_id = registrationPageIds[0]
    }

    return {
        ...formValues,
        tag_dict,
        charity,
    }
}

const submitOneClickRegistration = async (props, getState, data, dispatch) => {
    const officials = getState().widgets.toJS().officialsPreview
    const messageGroups = getState().form.toJS().campaignMessagePreviewForm.values
    const targetedOfficials = officials.filter((official) => official.targeted)

    const lockKey = `grassroots_register:${JSON.stringify(data)}`
    if (locks[lockKey]) {
        return
    }

    locks[lockKey] = true

    try {
        return await api
            .post(
                {
                    model: DjangIO.app.grassroots.models.NewSupporter,
                    action: "grassroots_register",
                },
                data,
            )
            .catch((error) => {
                const swalConfig = getBackendValidationSwalConfig({
                    error,
                    defaultConfig: swalConfigs.registrationError,
                    data,
                })
                BACKENDERROR(error, swalConfig, undefined, false)
            })
            .then(async (response) => {
                const data = await response.json()

                if (props.customAfterRegistrationJs) runUserJavascript(props.customAfterRegistrationJs)

                // if there are no officials, send user to home page after registering since there's no action to be sent
                if (officials.length === 0) {
                    window.location.assign(window.location.origin + "/")
                    return
                }

                dispatch({
                    type: frameworkActionTypes.UPDATE_USERDATA_SLICE,
                    updatedSlice: { ...data.userdata },
                })

                //since we don't validate the result of the action, we can just mark the widget as submitted and
                //completely bypass the campaign when it's one click registration, going straight to the thank you page
                dispatch(successfullySubmittedWidget(props.uniqueWidgetId))

                const parsedValues = formatValuesToSubmitWriteMember(targetedOfficials, messageGroups, props.campaign)
                const parsedMessageGroups = formatMessageGroupsToSubmitWriteMember(
                    targetedOfficials,
                    messageGroups,
                    props.campaign,
                )

                return submitWriteForm((messageValues, globalFormValues, props) =>
                    generateGrassrootsActionsWithImmutableValues(
                        generateWriteAMemberActions,
                        messageValues,
                        globalFormValues,
                        props,
                    ),
                )(fromJS(parsedValues), dispatch, {
                    campaign: props.campaign,
                    remainingMessageIds: [],
                    messageGroups: parsedMessageGroups,
                    reset: props.reset,
                })
            })
            .finally(() => {
                delete locks[lockKey]
            })
    } finally {
        delete locks[lockKey]
    }
}

export const submitRegistration = (values, props) => (dispatch, getState) => {
    const formValues = values.toJS()
    const data = formatUserInformationData(formValues, getState(), props.registrationPageIds)
    const officials = getState()?.widgets?.toJS()?.officialsPreview

    if (isWriteALetterCampaignWithOneClickRegistration(props.campaign, officials)) {
        submitOneClickRegistration(props, getState, data, dispatch)
        return
    }

    const oneClickEditAllowed = [CampaignType.write_member.value, CampaignType.comment_on_regulation.value].includes(
        props.campaign?.campaign_type,
    )

    const oneClickEditedMessages = oneClickEditAllowed
        ? getState()
              .form?.getIn(["campaignMessagePreviewForm"])
              ?.deleteIn(["values", "subject"])
              ?.deleteIn(["values", "message"])
        : null

    dispatch({
        //dispatching the form values to the framework slice, since the widget slice resets on registration.
        type: frameworkActionTypes.STORE_ONE_CLICK_EDITS,
        oneClickEditedMessages,
    })

    return dispatch(
        sendAction(DjangIO.app.grassroots.models.NewSupporter, loginActionTypes, {
            action: "grassroots_register",
            method: "post",
            kwargs: data,
        }),
    )
        .then((request) => {
            if (request.data && request.data["pac_userdata"] && request.data["pages"]) {
                /**
                 * This is needed to update the PAC user data and page access after register/login.
                 * Only necessary in case of context change without a page reload.
                 * */
                dispatch({
                    type: frameworkActionTypes.UPDATE_PAC_USERDATA_SLICE,
                    updatedSlice: { ...request.data["pac_userdata"] },
                })
                dispatch({
                    type: frameworkActionTypes.UPDATE_PAGES_SLICE,
                    updatedPages: request.data["pages"],
                })
            }
            if (props.customAfterRegistrationJs) {
                runUserJavascript(props.customAfterRegistrationJs)
            }

            dispatch(reloadCampaignList())
        })
        .catch((error) => {
            const swalConfig = getBackendValidationSwalConfig({
                error,
                defaultConfig: swalConfigs.registrationError,
                formValues,
            })
            BACKENDERROR(error, swalConfig, undefined, false)
        })
}

export const submitRegistrationSuccess = (response, dispatch, props) => {
    // Update page with supporter's points after registration
    updateSupporterPointsOnSuccess(response, dispatch)

    // If registration is completed on a campaign with one-click registration enabled,
    // dispatch enableOneClickRegistration to continue start one-click registration
    if (props.campaignIsOneClickRegistrationEnabled) {
        dispatch(enableOneClickRegistration(props.campaignId))
    }

    // Index into the 'search' field of the location prop. E.g. search = '?next=%2Funsubscribe%2F'
    const search = props.location && props.location.search

    // Set nextUrl to registration or
    // Instantiate everything after 'next=' from the search parameter as the 'nextUrl'
    // If 'next=' is not within the string, 'nextUrl' will be undefined
    const nextUrl = props.registrationRedirectUrl || (search && search.split("next=")[1])

    const pathName = (props.location && props.location.pathname) || document.location.pathname
    if (pathName.includes(GrassrootsWidgetType.campaign.widget_url)) {
        // If we're filling this out within a campaign, just return
        return
    } else if (nextUrl) {
        redirectToUrl(props, nextUrl)
    } else {
        return props.isThankable && dispatch(successfullySubmittedWidget(props.uniqueWidgetId))
    }
}

/**
 * Given errors from registration form, swal unique error messages
 * If there are multiple duplicate error messages, only show each unique error message
 *
 * @param  {object} errors  dict with key being the field name and value is the error message
 */
export const submitRegistrationFail = (errors) => {
    if (errors && Object.values(errors).length) {
        const errorMessages = Object.values(errors)
        const uniqueErrorMessages = [...new Set(errorMessages)]

        swal({
            icon: "error",
            text: uniqueErrorMessages.join("\n"),
        })
    }
}

/**
 * Given the information from UpdateInformation/index.js, call the grassroots_update_information
 * action in NewSupporterResource in order to update the user's information.
 * Upon success, set the slice to "submitted" so we can redirect to the thank you page.
 *
 * @param  {object} values  immutable map of the UpdateInformation values.
 *
 * @return {promise} returns the promise generated by sendAction.
 */
export const updateInformation = (values, props) => (dispatch, getState) => {
    const registeredFormFields = selectWidgetFormRegisteredFields(getState(), props)

    const supporterId = getState().framework.getIn(["userdata", "id"])

    const formValues = values
        .filter((_, key) => registeredFormFields.includes(key))
        .set("id", supporterId)
        .toJS()

    // for ConfirmationEmail sending reasons, pass in the registration page id
    // to the request/schema
    const data = formatUserInformationData(formValues, getState(), props.registrationPageIds)

    return dispatch(
        sendAction(DjangIO.app.grassroots.models.NewSupporter, loginActionTypes, {
            action: "grassroots_update_information",
            method: "patch",
            kwargs: data,
        }),
    )
        .then((response) => {
            if (props.isThankable) {
                dispatch(successfullySubmittedWidget(props.uniqueWidgetId))
            }

            if (props.campaignIsOneClickRegistrationEnabled) {
                dispatch(enableOneClickRegistration(props.campaignId))
            }
            // clear all the campaign messages, because they may be no longer relevant
            // now that the supporter had updated their address / custom fields / whatever.
            dispatch(
                resetWidgetSlices(DjangIO.app.grassroots.types.GrassrootsWidgetType.campaign.key, {
                    messages: Map(),
                    messagesLoaded: false,
                }),
            )
            dispatch(reloadCampaignList())
        })
        .catch((error) => {
            const swalConfig = getBackendValidationSwalConfig({
                error,
                defaultConfig: swalConfigs.updateInfoError,
                formValues,
            })
            BACKENDERROR(error, swalConfig, undefined, false)
        })
}

/**
 * Creates a slice of the store that is specifically for a widget.
 *
 * @param  {string} uniqueWidgetId  the key for the soon-to-be-initialized widget slice.
 *
 * @return {object} returns redux action.
 */
export const initializeWidgetSlice = (uniqueWidgetId) => ({
    type: actionTypes.INITIALIZE_WIDGET_SLICE,
    uniqueWidgetId,
})

/**
 * Reset parts of widget slices that match the widgetIdPattern. cleanSlate will
 * override the existing parts of the widget slices.
 *
 * @param  {string} widgetPatternId if the uniqueWidgetId contains this pattern, it should reset.
 * @param  {object} cleanSlate      the fields in the widget slice that should be reset, and their reset values.
 *
 * @return {object} returns redux action.
 */
export const resetWidgetSlices = (widgetIdPattern, cleanSlate) => ({
    type: actionTypes.RESET_WIDGET_SLICES,
    widgetIdPattern,
    cleanSlate,
})

/**
 * Destroy the widget slice, removing it from the widgets reducer.
 *
 * @param  {string} uniqueWidgetId  the key for the widget slice.
 *
 * @return {object} returns redux action.
 */
export const destroyWidgetSlice = (uniqueWidgetId) => ({
    type: actionTypes.DESTROY_WIDGET_SLICE,
    uniqueWidgetId,
})

/**
 * Destroy all the widget slices. Clear everything!
 *
 * @return {object} returns redux action.
 */
export const destroyAllWidgetSlices = () => ({
    type: actionTypes.DESTROY_ALL_WIDGET_SLICES,
})

/**
 * Loads the widget content given a varying set of parameters.
 * If the parameters come with an ID, this means we know exactly
 * what we're loading, so we do a typical get request.
 *
 * If the parameters do not come with an ID, we'll then check to see
 * if there is an action specified that will return a single object.
 *
 * Finally, if there is neither an action nor object, we're going to try
 * and find the object that corresponds to the filter parameters
 * specified. The filter parameters should be specific enough
 * to only return one object. If multiple objects are returned,
 * then we'll use the first one, and we'll be none the wiser.
 *
 * The filter parameter is used in cases that we have a url that has
 * a vanity slug, such as /campaign/save_the_whales/. In this situation,
 * save_the_whales actually corresponds to a campaign, but we can't
 * call '.get()' on it from TastyPie. So we use filters instead.
 *
 * @param  {Object} object describing the parameters of the get request
 *                 {number} id              the ID of the object, if we should be so lucky.
 *                 {object} filters         {[filterKey]: [filterValue]}, for querying.
 *                 {object} resource        the DjangIO object on which to call the GET request.
 *                 {string} uniqueWidgetId  the unique key for the widget slice.
 *
 * @return {promise} Promise returning the GET request.
 */
export const loadWidgetContent =
    ({ id, filter, resource, uniqueWidgetId, action, kwargs, detailRequest = false }) =>
    (dispatch, getState) => {
        if (id) {
            return dispatch(
                resourceGetDetail(
                    // First parameter is the resource manager. If resource.objects is undefined, 'resource' may be the manager
                    // ex: resource = {resource}.object.filter({ dehydrate_extra: ['XX'] })
                    resource.objects || resource,
                    loadWidgetContentActionTypes,
                    id,
                    { uniqueWidgetId },
                ),
            ).catch((error) => BACKENDERROR(error, swalConfigs.loadContentError, undefined, false))
        } else if (action) {
            return dispatch(
                sendAction(resource, loadWidgetContentActionTypes, {
                    action,
                    kwargs,
                    method: "get",
                    payload: { uniqueWidgetId },
                }),
            ).catch((error) => BACKENDERROR(error, swalConfigs.loadContentError, undefined, false))
        } else {
            return dispatch(
                resourceGetList(
                    // First parameter is the resource manager. If resource.objects is undefined, 'resource' may be the manager
                    // ex: resource = {resource}.object.filter({ dehydrate_extra: ['XX'] })
                    resource?.objects ?? resource,
                    loadWidgetContentActionTypes,
                    filter,
                    // If we are only expecting a single object from this list request, detailRequest = true
                    { uniqueWidgetId, detailRequest },
                ),
            ).catch((error) => BACKENDERROR(error, swalConfigs.loadContentError, undefined, false))
        }
    }

/**
 * Marks the widget as being successfully submitted, aka "widget.submitted = True"
 * in the widget slice.
 *
 * @param  {string} The unique key for the widget slice.
 */
export const successfullySubmittedWidget = (uniqueWidgetId) => ({
    type: actionTypes.SUCCESSFULLY_SUBMITTED_WIDGET,
    uniqueWidgetId,
})

/**
 * Marks the widget as "widget.submitted = False". This is so that
 * campaigns that are multiple submittable can mark themselves as
 * submitted over and over and over again. If you really want.
 *
 * @param  {string} The unique key for the widget slice.
 */
export const resetSubmittedWidget = (uniqueWidgetId) => ({
    type: actionTypes.RESET_SUBMITTED_WIDGET,
    uniqueWidgetId,
})

/**
 * After submission of widget, this slice of the widget store may be updated
 * with data related to the values submitted.
 * For example: For event widgets, we store how they RSVP'd
 * TODO: If gamification is enabled, store how many points they earned by submitting the widget
 *
 * @param  {object} data The data related to the submission of the widget
 */
export const setWidgetPostSubmitData = (uniqueWidgetId, data = {}) => ({
    type: actionTypes.SET_POST_SUBMIT_DATA,
    uniqueWidgetId,
    data,
})

/**
 * Marks the widget as "widget.videoCompletionRequired = True".
 * This is so Custom Call to Action campaigns with required videos can only be submitted if
 * videoCompletionRequired is set to true
 *
 * @param  {string} uniqueWidgetId unique key for the widget slice.
 * @return {object} returns redux action.
 */
export const completeRequiredVideo = (uniqueWidgetId) => ({
    type: actionTypes.COMPLETE_REQUIRED_VIDEO,
    uniqueWidgetId,
})
