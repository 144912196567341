import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import debounce from "debounce-promise"
import striptags from "striptags"
import ReactSelect from "react-select"
import { AsyncPaginate } from "react-select-async-paginate"

// helper
import { valueConverter } from "app/static/frontend/forms/WrappedFormFields/helperFunctions"

import Tooltip from "app/static/frontend/components/Tooltip"
import {
    DropdownIndicator,
    MultiValueRemove,
    Option,
} from "app/static/frontend/selects/components/Select/ReplacementComponents"
import { Icon } from "quorumdesign"

import * as S from "app/static/frontend/selects/components/Select/style"

export const Select = (props) => {
    const [isTowardsBottom, setIsTowardsBottom] = useState(false)
    const [inputValue, setInputValue] = useState("")

    const onInputChange = (newInput) => {
        setInputValue(newInput)
    }

    // render differently if it is a header
    const renderOption = (option) => {
        // if the parent wants to override this method
        if (props.renderOption) {
            // let them
            return props.renderOption(option)
        }
        // default implementation
        if (option.disabled) {
            return <strong style={{ color: "black" }}>{striptags(option[props.label])}</strong>
        }

        return option.isCurrentUser ? (
            <strong style={{ color: "black" }}>{`${striptags(option[props.label])} (You)`}</strong>
        ) : (
            <span>{striptags(option[props.label])}</span>
        )
    }

    const selectRef = useRef()

    useEffect(() => {
        /**
         * Takes selectRef and sets the 'isTowardsBottom' state to true if the
         * position is towards the lower third of the screen and false if not
         * @name getIsRefTowardsBottom
         * @function
         * @returns {void}
         */
        const getIsRefTowardsBottom = () => {
            if (selectRef.current) {
                const dropUpDisplayArea = window.innerHeight / 3
                const distToBottom = window.innerHeight - selectRef.current.getBoundingClientRect().top
                const towardsBottom = distToBottom < dropUpDisplayArea
                setIsTowardsBottom(towardsBottom)
            }
        }

        const debounceRef = debounce(getIsRefTowardsBottom, 400)

        window.addEventListener("wheel", debounceRef)
        // wheel does not work with mobile, so we need to add touchmove as well
        window.addEventListener("touchmove", debounceRef)

        return () => {
            window.removeEventListener("wheel", debounceRef)
            window.removeEventListener("touchmove", debounceRef)
        }
    }, [])

    const classNameArray = []

    const { defaultClassName } = props
    if (defaultClassName) {
        classNameArray.push(defaultClassName)
    }

    const { className } = props
    if (className) {
        classNameArray.push(className)
    }

    const classNamesJoined = classNameArray.length ? classNameArray.join(" ") : undefined

    const sharedProps = {
        hideSelectedOptions: false,
        closeMenuOnSelect: !props.multi,
        components: {
            MultiValueRemove,
            Option,
            DropdownIndicator,
            IndicatorSeparator: null,
        },
        className: classNamesJoined,
        classNamePrefix: props.defaultClassName,
        defaultOptions: true,
        escapeClearsValue: true,
        filterOption: props.filterOption,
        formatOptionLabel: (option) => renderOption(option),
        isClearable: props.clearable,
        isMulti: props.multi,
        onChange: (changedValue, { action }) => {
            // https://react-select.com/advanced#action-meta
            // https://github.com/JedWatson/react-select/blob/master/docs/pages/props/index.tsx#L72
            if (action === "clear" && props.onClear) {
                props.onClear()
            }

            // Determine if props.value is valid based on multi-select or single-select logic
            const hasValidValue = props.multi
                ? // For multi-select:
                  // Check if props.value is truthy, then check length (for arrays) or size (for immutable.js array)
                  props.value && (props.value.length || props.value.size)
                : // For single-select:
                  // Check if props.value is neither undefined nor null
                  props.value !== undefined && props.value !== null

            // Check if either hasValidValue or changedValue is truthy
            if (hasValidValue || changedValue) {
                // this is necessary due to a breaking change between react-select 2 and 3
                // "We rectify this in 3.0.0, on removal of all selected values in an isMulti Select,
                // the value passed to onChange is null and not []."
                const valueToPass = changedValue || (props.multi ? [] : {})

                // Call onChange with the determined value
                props.onChange(valueToPass)
            }
        },
        menuPlacement: props.allowExpandUp && isTowardsBottom ? "top" : "bottom",
        onMenuOpen: props.onMenuOpen,
        openMenuOnClick: true,
        placeholder: props.placeholder,
        styles: S.SelectStyle,
        shouldSuppressDropdownIndicator: props.shouldSuppressDropdownIndicator,
        hasError: Boolean(props.errorMessage),
    }

    // most of our component are async
    if (props.async) {
        const debouncedLoadOptions = debounce(props.loadOptions, props.customDebounce || 500)

        return (
            <>
                <div
                    className={classNames({
                        "quorum-reactselect-wrapper": true,
                        "is-searchify-select": !!props.advancedSearchifyConfig,
                        "is-design-system": props.isDesignSystem,
                    })}
                    data-cy={props.dataCy}
                    ref={selectRef}
                >
                    {props.advancedSearchifyConfig && !props.isDisabled && (
                        <DropdownIndicator advancedSearchifyConfig={props.advancedSearchifyConfig} />
                    )}
                    {props.selectLabel && (
                        <S.Label
                            id={`${props.selectLabel}-aria-label`}
                            htmlFor={props.selectLabel}
                            disabled={props.isDisabled}
                        >
                            {props.selectLabel}
                            {props.tooltip && (
                                <Tooltip label={props.tooltip} placement="top">
                                    <span>
                                        <Icon icon="info-circle" iconFamily="far" size="sm" />
                                    </span>
                                </Tooltip>
                            )}
                        </S.Label>
                    )}
                    <AsyncPaginate
                        {...props}
                        {...sharedProps}
                        isDisabled={props.isDisabled}
                        // force re-render if a specific value changes
                        // (i.e., if you have a permanentFilter QueryMethod with a variable parameter)
                        // https://github.com/JedWatson/react-select/issues/1581#issuecomment-408625770
                        key={props.customKey}
                        value={
                            props.value !== undefined &&
                            props.value !== null &&
                            props.options.length &&
                            valueConverter(props.options, props.multi, props.shouldHideExtraValues, props.value)
                        }
                        // Async
                        cacheOptions
                        inputValue={
                            // manually using the AsyncPaginateBase requires maintaining the state of the input;
                            // this allows us to open the dropdown menu by default with defaultMenuIsOpen and requiresBaseComponent
                            // https://github.com/vtaits/react-select-async-paginate/blob/master/packages/react-select-async-paginate/src/async-paginate-base.jsx
                            // https://github.com/vtaits/react-select-async-paginate/blob/master/packages/react-select-async-paginate/src/__stories__/Manual.jsx
                            props.requiresBaseComponent ? inputValue : undefined
                        }
                        // https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917
                        loadOptions={debouncedLoadOptions}
                        onInputChange={props.requiresBaseComponent && onInputChange}
                        ref={props.parentRef}
                    />
                </div>

                {(props.description || props.errorMessage) && (
                    <S.Description hasError={props.errorMessage}>
                        {props.errorMessage && <Icon icon="exclamation-triangle" iconFamily="far" size="xs" />}
                        {props.errorMessage || props.description}
                    </S.Description>
                )}
            </>
        )
    }

    const loadedOptions = props.loadOptions()
    return (
        <div data-cy={props.dataCy} ref={selectRef}>
            {props.selectLabel && (
                <S.Label id={`${props.id}-aria-label`} htmlFor={props.id} disabled={props.isDisabled}>
                    {props.selectLabel}
                    {props.showAsterisk && <span className="required">*</span>}
                </S.Label>
            )}
            <ReactSelect
                {...props}
                {...sharedProps}
                value={
                    props.value !== undefined &&
                    props.value !== null &&
                    loadedOptions.length &&
                    valueConverter(loadedOptions, props.multi, props.shouldHideExtraValues, props.value)
                }
                // Sync
                options={loadedOptions}
                ref={props.parentRef}
                menuShouldScrollIntoView={props.menuShouldScrollIntoView}
            />
            {(props.description || props.errorMessage) && (
                <S.Description hasError={props.errorMessage}>
                    {props.errorMessage && <Icon icon="exclamation-triangle" />}
                    {props.errorMessage || props.description}
                </S.Description>
            )}
        </div>
    )
}

Select.propTypes = {
    advancedSearchifyConfig: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    allowExpandUp: PropTypes.bool,
    async: PropTypes.bool,
    className: PropTypes.string,
    clearable: PropTypes.bool,
    dataCy: PropTypes.string,
    defaultClassName: PropTypes.string,
    description: PropTypes.string,
    errorMessage: PropTypes.string,
    filterOption: PropTypes.func,
    isDesignSystem: PropTypes.bool,
    isDisabled: PropTypes.bool,
    label: PropTypes.string,
    loadOptions: PropTypes.func.isRequired,
    menuShouldScrollIntoView: PropTypes.bool,
    multi: PropTypes.bool,
    onChange: PropTypes.func,
    onMenuOpen: PropTypes.func,
    options: PropTypes.array,
    pagination: PropTypes.bool,
    parentRef: PropTypes.object,
    placeholder: PropTypes.string,
    renderOption: PropTypes.func,
    requiresBaseComponent: PropTypes.bool,
    selectLabel: PropTypes.string,
    shouldHideExtraValues: PropTypes.bool,
    shouldSuppressDropdownIndicator: PropTypes.bool,
    tooltip: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
}

Select.defaultProps = {
    allowExpandUp: false,
    async: true,
    clearable: true,
    defaultClassName: "NewSelect",
    label: "label",
    menuShouldScrollIntoView: true,
    multi: false,
    pagination: true,
    placeholder: "Select...",
    // necessary if you need to set defaults for defaultMenuIsOpen, menuIsOpen, or inputValue props
    requiresBaseComponent: false,
    shouldHideExtraValues: false,
}

export default Select
