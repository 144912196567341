import React, { useState } from "react"
import { Loader, UnstyledButton } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"

import { Campaign } from "@/types/djangio"
import * as S from "QuorumGrassroots/campaign-forms/components/StoryForm/VideoPreview/VideoPreview.styles"
import { VideoModal } from "QuorumGrassroots/campaign-forms/components/StoryForm/VideoModal/VideoModal"
import { RecorderStatus, VideoPayload } from "QuorumGrassroots/framework/hooks/useRecorder/useRecorder"
import { Icon } from "@/components/Icon"

interface VideoPreviewProps {
    resetRecording: () => void
    campaign: Campaign
    t: (text: string) => string
    video: VideoPayload
    recorderStatus: RecorderStatus
}

export const VideoPreview = (props: VideoPreviewProps) => {
    const [isLoadingThumbnail, setIsLoadingThumbnail] = useState(true)
    const [isVideoModelOpen, videoModelActions] = useDisclosure()

    const isThumbLoaded = props.recorderStatus === RecorderStatus.PROCESSED

    const handlePlay = () => videoModelActions.open()

    return (
        <S.StyledVideoPreviewContainer data-cy="video-preview">
            {isThumbLoaded ? (
                <S.StyledVideoPreview>
                    {isLoadingThumbnail ? (
                        <Loader type="Oval" color="gray" />
                    ) : (
                        <>
                            <S.PlayButton type="button" onClick={handlePlay}>
                                <i className="fa fa-play play-icon" aria-hidden="true" />
                            </S.PlayButton>
                        </>
                    )}
                    <S.StyledThumbnail
                        src={props.video.thumbnail}
                        alt="Video thumbnail"
                        onLoad={() => setIsLoadingThumbnail(false)}
                    />
                </S.StyledVideoPreview>
            ) : (
                <S.StyledLoadingContainer>
                    <S.StyledIconContainer>
                        <i className="fa fa-video-camera" aria-hidden="true" />
                    </S.StyledIconContainer>
                    <S.StyledVideoDescription>
                        {props.t("campaign.grassroots_story.processing_video")}
                    </S.StyledVideoDescription>
                    <S.StyledSubtitle>{props.t("campaign.grassroots_story.submit_anyway")}</S.StyledSubtitle>
                </S.StyledLoadingContainer>
            )}

            <VideoModal isOpened={isVideoModelOpen} onClose={videoModelActions.close} videoURL={props.video.videoUrl} />
        </S.StyledVideoPreviewContainer>
    )
}
