import React from "react"
import PropTypes from "prop-types"

import * as colors from "Components/colors"
import * as S from "Components/Compounds/ListItem/style"

import Anchor from "Components/Atoms/Anchor"
import CircleImage from "Components/Molecules/CircleImage"
import Icon from "Components/Atoms/Icon"

import { fa4to5 } from "utils/fontAwesomeHelpers"

export class ListItem extends React.PureComponent {
    constructor(props) {
        super(props)

        this._isMounted = false
    }

    async componentDidMount() {
        this._isMounted = true
        await new Promise(r => setTimeout(r, 50))

        if (this._isMounted) {
            this.props.measure()
        }
    }

    // react does not unmount a PureComponent
    // even if you invoke it in a conditional in the parent
    // i.e.,
    // <ListWidget>
    //     {
    //         props.condition
    //             ? <StyledListItem empty />
    //             : <StyledListItem datum={props.datum} />
    //     }
    // </ListWidget>
    // does not remount StyledListItem even when ListWidget re-renders and condition changes
    async componentDidUpdate(prevProps) {
        if (prevProps.empty !== this.props.empty) {
            // we need to only fire the CellMeasurer measure() for each ListItem after it renders its content.
            // because we use reactify(), which wraps embedded Anchor elements in <object>,
            // the this.props.datum.firstLine jsx finishes dynamically rendering after the ListItem componentDidUpdate fires,
            // so we need to wait until the render event finishes

            // "Using setTimeout(() => {}, 0) is the most practically accurate,
            // as it fires at the end of the active browser event queue without any more delay.
            // In other words, your code is queued right after the render operation (and all other operations happening at the time)."
            // https://stackoverflow.com/a/21043017/6201291

            // while this should theoretically work with a timeout of 0,
            // we add an extra 50ms just to be safe and ensure that the <object> has finished rendering all of its children
            await new Promise(r => setTimeout(r, 50))

            if (this._isMounted) {
                this.props.measure()
            }
        }
    }

    componentWillUnmount() {
        // avoid memory leaks
        // and prevent "Error: Unable to find node on an unmounted component"
        // https://stackoverflow.com/a/52061655/6201291
        this._isMounted = false
    }

    render() {
        if (!this.props.shouldRender) {
            return <div style={{ height: 0 }}></div>
        }

        const hasSegue = (
            this.props.datum.href &&
            this.props.datum.segue
        )

        if (this.props.empty) {
            return (
                <S.ListItem
                    empty
                    hasSegue={hasSegue}
                    icon={this.props.datum.icon}
                    data-auto-cy="CompoundListItem"
                >
                    {
                        this.props.datum.icon &&
                            <Icon
                                icon={this.props.datum.icon}
                                color={colors.violet5}
                                iconFamily={this.props.datum.iconFamily || "far"}
                                style={{
                                    fontSize: "20px",
                                    margin: "0 8px 0 12px",
                                }}
                            />
                    }
                    <S.Content>
                        <S.FirstLine empty />
                        <S.SecondLine empty />
                    </S.Content>
                </S.ListItem>
            )
        }

        const fa4to5Translation = (
            (
                this.props.datum.fa4Icon &&
                fa4to5[this.props.datum.fa4Icon]
            ) || {}
        )
        const icon = (
            // if we have a fa4 icon that can be translated to fa5, use that
            fa4to5Translation.icon ||
            // if we have a fa4 icon that cannot be translated, we know it is a fa5 icon
            this.props.datum.fa4Icon ||
            // if we have an fa5 icon, use that
            this.props.datum.icon
        )

        return (
            <Anchor
                data-auto-cy="CompoundListItem"
                href={
                    (
                        !this.props.editing &&
                        this.props.datum.href
                    )
                        ? `${this.props.datum.href}`
                        : undefined
                }
                onClick={
                    (!this.props.editing && hasSegue)
                        ? () => this.props.datum.segue()
                        : undefined
                }
                target={
                    this.props.isExternal &&
                    "_blank"
                }
            >
                <S.ListItem
                    // needed to disable onClick and remove hover state
                    editing={this.props.editing}
                    hasSegue={hasSegue}
                    icon={icon}
                    iconText={this.props.datum.iconText}
                >
                    {
                        this.props.datum.imageUrl &&
                            <CircleImage
                                alt="List Item Icon"
                                // needed for ListItem Icon border color
                                circleColor={this.props.datum.circleColor}
                                diameter="36px"
                                imgUrl={this.props.datum.imageUrl}
                            />
                    }
                    {
                        this.props.datum.fa4Icon &&
                            <S.IconComponent
                                color={
                                    this.props.datum.iconColor
                                        ? colors[this.props.datum.iconColor]
                                        : colors.violet5
                                }
                            >
                                <i className={`fa fa-${this.props.datum.fa4Icon}`} />
                            </S.IconComponent>
                    }
                    {
                        (!this.props.datum.imageUrl && !this.props.datum.fa4Icon && icon) &&
                            <Icon
                                color={
                                    this.props.datum.iconColor
                                        ? colors[this.props.datum.iconColor]
                                        : colors.violet5
                                }
                                icon={icon}
                                iconFamily={fa4to5Translation.family || this.props.datum.iconFamily || "far"}
                                style={{
                                    fontSize: "20px",
                                    margin: "0 8px 0 12px",
                                }}
                            />
                    }
                    {
                        this.props.datum.iconText &&
                            <S.IconText>
                                {this.props.datum.iconText}
                            </S.IconText>
                    }
                    <S.Content>
                        {
                            this.props.hasMultipleFirstLines
                                ? this.props.datum.firstLine.map((line, i) => (
                                    <S.FirstLine
                                        title={this.props.datum.firstLineRaw[i]}
                                    >
                                        {line}
                                    </S.FirstLine>
                                ))
                                : (
                                    <S.FirstLine
                                        title={this.props.datum.firstLineRaw}
                                    >
                                        {this.props.datum.firstLine}
                                    </S.FirstLine>
                                )
                        }
                        {
                            this.props.datum.secondLineItems &&  (
                                <S.SecondLine>
                                    {
                                        this.props.datum.secondLineItems.map(([key, value]) => (
                                            <S.SecondLineSpan
                                                key={key}
                                            >
                                                <S.SecondLineKey>{key}</S.SecondLineKey>: {value}
                                            </S.SecondLineSpan>
                                        ))
                                    }
                                </S.SecondLine>
                            )
                        }
                    </S.Content>
                </S.ListItem>
            </Anchor>
        )
    }
}

ListItem.defaultProps = { shouldRender: true, }

ListItem.propTypes = {
    datum: PropTypes.object,
    editing: PropTypes.bool,
    empty: PropTypes.bool,
    isExternal: PropTypes.bool,
    measure: PropTypes.func,
}

export default ListItem
