import axios from "axios"
import moment from "moment"
import { capitalizeFirstLetter } from "shared/imports/sharedHelperFunctions"
import Model from "shared/djangio/Model"

// CONSTANTS
export const MODEL_NAME_RECEIPT = "Receipt"
export const MODEL_NAME_CANDIDATES_SUGGESTER = "CandidatesSuggester"
export const MODEL_NAME_CONTRIBUTOR_SUGGESTER = "ContributorSuggester"
export const MODEL_NAME_COMMITTEES_SUGGESTER = "CommitteesSuggester"
export const MODEL_NAME_INDIVIDUALS_SUGGESTER = "IndividualsSuggester"
export const MODEL_NAME_DISBURSEMENT = "Disbursement"
export const MODEL_NAME_SUPPORTER = "Supporter"
export const MODEL_NAME_SAFED_SEARCH = "SafedSearch"
export const MODEL_NAME_POLITICAL_COMMITTEE = "PoliticalCommittee"
export const MODEL_NAME_OFFICIAL = "NewPerson"
export const MODEL_NAME_VISUALIZATION = "Visualization"
export const MODEL_WIDGET_ENGINE = "WidgetEngine"
export const MODEL_NAME_BULK_EMAIL = "BulkEmail"

export const RECEIPT_ENDPOINT = "/api/campaign_finance/receipts"
export const DISBURSEMENT_ENDPOINT = "/api/campaign_finance/disbursements"
export const SUGGESTER_COMMITTEES_ENDPOINT = "/api/campaign_finance/super_committees/light_suggest"
export const SUGGESTER_CANDIDATES_ENDPOINT = "/api/campaign_finance/super_candidates/suggest"
export const SUGGESTER_INDIVIDUALS_ENDPOINT = "/api/campaign_finance/super_individuals/suggest"

export const CHARTS_AG = "/api/campaign_finance/receipts/charts_aggregator"

const STAKEHOLDERS_RECEIPT_ENDPOINT = "/api/campaign_finance/stakeholder_mgmt/stakeholders/{token}/receipts/lookup"

const RELATIONSHIP_KEYS_OFFICIAL = [
    "involving_member",
    "source_members",
    "about_person",
    "about_official",
    "mentions_officials",
    "political_committee_candidate",
    "transaction_person",
    "has_members_or",
    "sponsored_by_or",
    "cosponsored_by_or",
    "sponsored_or_cosponsored_by_or",
    "yea_voters_or",
    "not_cosponsored_by_or",
    "members",
    "represented_by",
]
const RELATIONSHIP_KEYS_SUPPORTER = ["involving_supporter", "about_supporter", "transaction_supporter"]
const RELATIONSHIP_KEYS_POLITICAL_COMMITTEES = ["transaction_political_committee"]

export function getStakeholdersReceiptEndpoint(token) {
    return STAKEHOLDERS_RECEIPT_ENDPOINT.replace("{token}", token)
}

// HELPERS
export function shouldUsePowerSearch(params, endpoint) {
    const isCampaignFinanceEndpoint =
        endpoint.includes("campaign_finance") &&
        !endpoint.includes("receipts/light_lookup") &&
        !endpoint.includes("disbursements/light_lookup")
    const isPowerSearch = "disburser_query" in params || "candidate_query" in params
    const hasCurrentSearchifySelection = "current_searchify_selection" in params
    const hasIdInParams = "id__in" in params
    const isSearchifyRequest = params.is_searchify_request
    const isModalPowerSearch =
        isCampaignFinanceEndpoint && (hasCurrentSearchifySelection || hasIdInParams || isSearchifyRequest)

    return {
        isPowerSearch,
        isModalPowerSearch,
        hasCurrentSearchifySelection,
        hasIdInParams,
    }
}

function removeDuplicates(data, key = "fec_committee_id") {
    const uniqueObjects = []
    const seenNames = new Set()

    for (const obj of data.objects) {
        if (!seenNames.has(obj[key])) {
            uniqueObjects.push(obj)
            seenNames.add(obj[key])
        }
    }

    return uniqueObjects
}

export function shouldUseCampaignFinanceEndpoint(params, endpoint) {
    const isCampaignFinanceEndpoint = endpoint.includes("campaign_finance")

    return (
        isCampaignFinanceEndpoint &&
        ("individuals_query" in params ||
            "committeeOfficials" in params ||
            "recipientCommitteesChart" in params ||
            "receiptsTrendsChart" in params ||
            "recipientCommitteePartyChart" in params ||
            "committeeContacts" in params ||
            params.map)
    )
}

export function isStrictTokenRequest(params) {
    return (
        params &&
        ("associated_with_officials_disbursements" in params ||
            "associated_with_officials_receipts" in params ||
            "disbursement_political_committee" in params ||
            "receipts_political_committee" in params)
    )
}

export function initMoneyballObject() {
    return {
        models: {},
        types: {},
    }
}

export const handlePowerSearchRequest = async (params, hasCurrentSearchifySelection, hasIdInParams, endpoint) => {
    const page_size = parseInt(params["limit"] || 20)
    const offset = parseInt(params["offset"] || 0)
    const page = offset / page_size + 1

    if (
        !params?.advanced_search &&
        _unwrapPowersearchTokenField(params.searchify_filters)?.querysetList?.[0]?.advanced_search
    ) {
        params.advanced_search = _unwrapPowersearchTokenField(
            params.searchify_filters,
        )?.querysetList?.[0]?.advanced_search
    }

    let data = {
        query: "",
        page_size: 20,
        page,
        empty_query: true,
        sort: true,
    }

    if (endpoint.includes("super_candidates")) {
        if (hasCurrentSearchifySelection) {
            data.ids = _unwrapPowersearchTokenField(params.current_searchify_selection).addedIds
        }
        if (hasIdInParams) {
            data.ids = params.id__in
        }
        if ("advanced_search" in params) {
            data.query = params.advanced_search
            data.empty_query = !params.advanced_search
        }
        if ("candidate_query" in params) {
            data.query = params.candidate_query
            data.empty_query = !params.candidate_query
        }
        delete data.sort
        return axios.post(SUGGESTER_CANDIDATES_ENDPOINT, { ...data })
    } else if (endpoint.includes("super_individuals/suggest")) {
        delete data.sort
        delete data.empty_query
        data.ids = params.id__in
        data.column_name = params.column_name

        return axios.post(SUGGESTER_INDIVIDUALS_ENDPOINT, { ...data })
    } else if (endpoint.includes("super_committees/light_suggest")) {
        if (hasCurrentSearchifySelection) {
            data.ids = _unwrapPowersearchTokenField(params.current_searchify_selection).addedIds
        }

        if (hasIdInParams) {
            data.ids = params.id__in
        }

        if ("advanced_search" in params) {
            data.query = params.advanced_search
            data.empty_query = !params.advanced_search
        }

        if ("disburser_query" in params) {
            data.query = params.disburser_query
            data.empty_query = !params.disburser_query
        }

        delete data.sort
        return axios.post(SUGGESTER_COMMITTEES_ENDPOINT, { ...data })
    }
}

export function getMoneyballModels(models) {
    const baseParams = {
        fields: [],
        default_filters: {},
        default_exclude_filters: {},
    }

    models.Visualization = new Model({
        ...baseParams,
        endpoint: CHARTS_AG,
        __name__: MODEL_NAME_VISUALIZATION,
        data_model: "app.moneyball.models.Visualization",
        external_products: { isMoneyball: true },
    })

    models.CandidatesSuggester = new Model({
        ...baseParams,
        endpoint: SUGGESTER_CANDIDATES_ENDPOINT,
        __name__: MODEL_NAME_CANDIDATES_SUGGESTER,
        data_model: "app.moneyball.models.CandidatesSuggester",
        external_products: { isMoneyball: true },
    })

    models.ContributorSuggester = new Model({
        ...baseParams,
        endpoint: SUGGESTER_COMMITTEES_ENDPOINT,
        __name__: MODEL_NAME_CONTRIBUTOR_SUGGESTER,
        data_model: "app.moneyball.models.ContributorSuggester",
        external_products: { isMoneyball: true },
    })

    models.CommitteesSuggester = new Model({
        ...baseParams,
        endpoint: SUGGESTER_COMMITTEES_ENDPOINT,
        __name__: MODEL_NAME_COMMITTEES_SUGGESTER,
        data_model: "app.moneyball.models.CommitteesSuggester",
        external_products: { isMoneyball: true },
    })

    models.IndividualsSuggester = new Model({
        ...baseParams,
        endpoint: SUGGESTER_INDIVIDUALS_ENDPOINT,
        __name__: MODEL_NAME_INDIVIDUALS_SUGGESTER,
        data_model: "app.moneyball.models.IndividualsSuggester",
        external_products: { isMoneyball: true },
    })

    models.Receipt = new Model({
        ...baseParams,
        endpoint: `${RECEIPT_ENDPOINT}/light_lookup`,
        __name__: MODEL_NAME_RECEIPT,
        data_model: "app.moneyball.models.Receipt",
        external_products: { isMoneyball: true },
    })

    models.Disbursement = new Model({
        ...baseParams,
        endpoint: `${DISBURSEMENT_ENDPOINT}/light_lookup`,
        __name__: MODEL_NAME_DISBURSEMENT,
        data_model: "app.moneyball.models.Disbursement",
        external_products: { isMoneyball: true },
    })
}

/**
 * flattenKey is used to de-nest api response body
 *
 * Example:
 * { super_committee_donor: {id: 1, name: 'Josh'} }
 *
 * Becomes:
 * {
 *     super_committee_donor_id: 1,
 *     super_committee_donor_name: 'Josh'
 * }
 */
function flattenObjectKey(key, data) {
    const selectedObject = data[key]

    if (selectedObject) {
        return Object.keys(selectedObject).reduce(
            // eslint-disable-next-line
            (acc, value) => ((acc[`${key}_${value}`] = selectedObject[value]), acc),
            {},
        )
    }
}

/**
 * Cleans date input before sending to API
 *
 * The input date can have different forms depending on the source:
 * DD-MM-YYYY, YYYY-MM-DD, YYYY-MM-DD:TIME, or a Moment Instance
 *
 * Returns a date in YYYY-MM-DD string format for API.
 * @param dateString
 * @returns {string|null}
 */
export function cleanDateInput(dateString) {
    const dateFormat = "YYYY-MM-DD"

    // null or undefined
    if (null == dateString) {
        return undefined
    }

    return moment(dateString).format(dateFormat)
}

// DATA TRANSFORMERS
function parseMeta({ pagination }, model) {
    const pageSize = parseInt(pagination.page_size)
    const pageNumber = parseInt(pagination.page_number)
    const offset = pageNumber * pageSize
    const previousOffset = offset - pageSize
    const endpoints = {
        [MODEL_NAME_RECEIPT]: RECEIPT_ENDPOINT,
        [MODEL_NAME_DISBURSEMENT]: DISBURSEMENT_ENDPOINT,
        [MODEL_NAME_CANDIDATES_SUGGESTER]: SUGGESTER_CANDIDATES_ENDPOINT,
        [MODEL_NAME_COMMITTEES_SUGGESTER]: SUGGESTER_COMMITTEES_ENDPOINT,
        [MODEL_NAME_INDIVIDUALS_SUGGESTER]: SUGGESTER_INDIVIDUALS_ENDPOINT,
    }
    const currentEndpoint = endpoints[model.__name__]

    return {
        limit: pageSize,
        model: model.__name__,
        next: `${currentEndpoint}/lookup/?decode_enums=false&limit=${pageSize}&offset=${offset}`,
        previous: `${currentEndpoint}/lookup/?decode_enums=false&limit=${pageSize}&offset=${previousOffset}`,
        offset,
        total_count: pagination.total_count,
        watermark: "pg",
    }
}

function mapReceiptResponse(response) {
    return response.items.map((data) => {
        return {
            ...data.receipt,
            ...flattenObjectKey("super_individual_donor", data),
            ...flattenObjectKey("super_committee_donor", data),
            ...flattenObjectKey("super_committee_recipient", data),
            ...flattenObjectKey("super_corporation_donor", data),
            ...flattenObjectKey("super_committee_conduit", data),
            title: data.receipt?.donor_name || data.name,
            recipient_id: data.receipt?.id || data.id,
            recipient_title: data.receipt?.donor_name || data.name,
            amount: data.receipt?.contribution_amount,
            date: data.receipt?.contribution_date,
        }
    })
}

function mapDisbursementResponse(response) {
    return response.items.map((data) => {
        const updated_data = {
            ...data.disbursement,
            ...flattenObjectKey("recipient", data),
            ...flattenObjectKey("spender", data),
        }

        return {
            ...updated_data,
            ...flattenObjectKey("recipient_super_individual", updated_data),
            ...flattenObjectKey("recipient_super_committee", updated_data),
            ...flattenObjectKey("recipient_super_vendor", updated_data),
            recipient_name:
                data.recipient.super_committee?.name ||
                data.recipient.super_individual?.name ||
                data.recipient.super_vendor?.name,
            id: data.spender.id,
            title: data.disbursement.spender_name,
            disbursement_id: data.disbursement.id,
            disburser_name: data.spender.name,
            date: data.disbursement.disbursement_date,
            amount: data.disbursement.disbursement_amount,
            recipient_id:
                data.recipient.super_committee?.id ||
                data.recipient.super_individual?.id ||
                data.recipient.super_vendor?.id,
            recipient_state_code:
                data.recipient.super_committee?.state_code ||
                data.recipient.super_individual?.state_code ||
                data.recipient.super_vendor?.state_code,
            recipient_title:
                data.recipient.super_committee?.name ||
                data.recipient.super_individual?.name ||
                data.recipient.super_vendor?.name,
        }
    })
}

function mapSuggesterResponse(response, params) {
    const deleteIds = _unwrapPowersearchTokenField(params.searchify_filters)?.deletedIds || []
    const advancedSearch = _unwrapPowersearchTokenField(params.searchify_filters)?.querysetList?.[0]?.advanced_search
    const hasIdenticalQuery =
        advancedSearch &&
        advancedSearch !== undefined &&
        advancedSearch !== "" &&
        advancedSearch === params?.advanced_search

    if (hasIdenticalQuery) {
        return response.items.map((data) => {
            return {
                ...data,
                title: data.name,
                recipient_id: data.id,
                recipient_title: data.name,
                is_searchify_selected: !deleteIds.includes(data.id),
            }
        })
    } else {
        return response.items.map((data) => {
            return {
                ...data,
                title: data.name,
                recipient_id: data.id,
                recipient_title: data.name,
            }
        })
    }
}

function mapIndividualSuggesterResponse(response) {
    return response.items.map((data) => {
        return {
            title: data.value,
            id: data.value,
        }
    })
}

async function mapVisualizationOfficialsResponse(response) {
    const allTokens = response.result.map((data) => data.token)
    const sliceTokens = allTokens.slice(0, 100)
    const newData = await DjangIO.app.person.models.Person.objects
        .filter({ most_recent_role__fec_id__in: sliceTokens })
        .limit(100)
        .GET()

    return newData.objects
        .map((newDataObject) => {
            const data = response.result.find((data) => data.token === newDataObject.most_recent_role__fec_id)

            if (!data) {
                return null
            }

            const id = data.id
            const long_display = newDataObject.name
            const short_display = newDataObject.name_and_title

            return {
                id,
                long_display,
                short_display,
                value: data.value,
            }
        })
        .filter((item) => item !== null)
        .sort((a, b) => b.value - a.value)
}

async function mapVisualizationCommitteesResponse(response) {
    const allTokens = response.result.map((data) => data.token)
    const sliceTokens = allTokens.slice(0, 100)
    const newData = await DjangIO.app.pac.models.political_committee.PoliticalCommittee.objects
        .filter({ fec_committee_id__in: sliceTokens })
        .limit(100)
        .GET()

    const dataWithoutDuplicates = removeDuplicates(newData, "fec_committee_id")
    return dataWithoutDuplicates
        .map((newDataObject) => {
            const data = response.result.find((data) => data.token === newDataObject.fec_committee_id)

            if (!data) {
                return null
            }

            const id = newDataObject.id
            const long_display = newDataObject.name
            let short_display = newDataObject.name
            if (short_display.length > 15) {
                short_display = short_display.substring(0, 15) + "..."
            }
            return {
                id,
                long_display,
                short_display,
                value: data.value,
            }
        })
        .filter((item) => item !== null)
        .sort((a, b) => b.value - a.value)
}

async function mapVisualizationReceiptsTrendsResponse(response) {
    return response.result
}

async function mapVisualizationReceiptsMapResponse(response) {
    const regions = DjangIO.app.models.Region.items()
        .filter((item) => item.is_state_region)
        .map((region) => ({
            id: region.value,
            value: region.abbrev.toUpperCase(),
            label: region.region_name,
        }))

    return regions
        .map((data) => {
            const results = response.result.find((obj) => obj.state === data.value)

            const id = data.id
            const long_display = data.label

            return {
                id,
                long_display,
                value: results?.value || 0,
            }
        })
        .filter((item) => item.value !== 0)
}

export function mapVisualizationReceiptsPartyResponse(response) {
    return response.result
        .map((data) => {
            return {
                id: data.party,
                long_display: capitalizeFirstLetter(data.party),
                short_display: capitalizeFirstLetter(data.party),
                value: data.value,
            }
        })
        .sort((a, b) => b.value - a.value)
}

const mapVisualizationReceiptsContactsResponse = async (response) => {
    const allTokens = response.result.map((data) => data.token)
    const sliceTokens = allTokens.slice(0, 100)
    const newData = await DjangIO.app.grassroots.models.Supporter.objects
        .filter({ id__in: sliceTokens })
        .limit(100)
        .GET()

    const dataWithoutDuplicates = removeDuplicates(newData, "id")
    return dataWithoutDuplicates
        .map((newDataObject) => {
            const data = response.result.find((data) => data.token === newDataObject.id)

            if (!data) {
                return null
            }

            const id = data.id
            const long_display = newDataObject.name
            let short_display = newDataObject.name
            return {
                id,
                long_display,
                short_display,
                value: data.sum,
            }
        })
        .filter((item) => item !== null)
        .sort((a, b) => b.value - a.value)
}

export const getReceiptsFilters = (data, params) => {
    if (params.filters) {
        if (params.filters.receipt_supporter) {
            // Cleaning incompatible keys
            delete data.query
            delete data.source_types

            if (data.sort_column && data.sort_direction) {
                data.sort_column = `contribution_${data.sort_column}`
            }

            // adding custom keys
            data.custom_endpoint_url = getStakeholdersReceiptEndpoint(params.filters.receipt_supporter)
            data.source = "quorum"
        }

        if (params.filters.receipts_political_committee) {
            const political_committee_ids = []
            political_committee_ids.push(params.filters.receipts_political_committee)
            if (isStrictTokenRequest(params)) {
                data.recipient_committee_tokens = political_committee_ids
            } else {
                data.recipient_committee_tokens = political_committee_ids
                data.donor_committee_tokens = political_committee_ids
                data.token_condition_is_or = true
            }
        }

        if (params.filters.recipientTypes) {
            if (Array.isArray(params.filters.recipientTypes)) {
                data.recipient_committee_types = params.filters.recipientTypes
            } else if (typeof params.filters.recipientTypes === "string") {
                data.recipient_committee_types = params.filters.recipientTypes.split(",")
            }
        }

        if (params.filters.recipientParties) {
            if (Array.isArray(params.filters.recipientParties)) {
                data.recipient_committee_parties = params.filters.recipientParties
            } else if (typeof params.filters.recipientParties === "string") {
                data.recipient_committee_parties = params.filters.recipientParties.split(",")
            }
        }

        if (params.filters.recipientStateCodes) {
            if (Array.isArray(params.filters.recipientStateCodes)) {
                data.recipient_state_codes = params.filters.recipientStateCodes
            } else if (typeof params.filters.recipientStateCodes === "string") {
                data.recipient_state_codes = params.filters.recipientStateCodes.split(",")
            }
        }

        if (params.filters.receipts_candidate_name) {
            if (params.filters.receipts_candidate_name.addedIds.length > 0) {
                data.recipient_candidate_ids = params.filters.receipts_candidate_name.addedIds
            }
            if (params.filters.receipts_candidate_name?.querysetList?.[0]?.advanced_search) {
                data.recipient_candidate_query =
                    params.filters.receipts_candidate_name?.querysetList?.[0]?.advanced_search
            }
            if (params.filters.receipts_candidate_name.deletedIds.length > 0) {
                data.recipient_candidate_excluded_ids = params.filters.receipts_candidate_name.deletedIds
            }
        }

        if (params.filters.contributorTypes) {
            if (params.filters.contributorTypes.includes("candidate")) {
                const newContributorTypes = params.filters.contributorTypes
                    .toString()
                    .replace("candidate", ["house", "senate", "presidential"])

                if (Array.isArray(newContributorTypes)) {
                    data.donor_committee_types = newContributorTypes
                } else if (typeof newContributorTypes === "string") {
                    data.donor_committee_types = newContributorTypes.split(",")
                }
            } else {
                if (Array.isArray(params.filters.contributorTypes)) {
                    data.donor_committee_types = params.filters.contributorTypes
                } else if (typeof params.filters.contributorTypes === "string") {
                    data.donor_committee_types = params.filters.contributorTypes.split(",")
                }
            }
        }

        if (params.filters.electionsType) {
            if (Array.isArray(params.filters.electionsType)) {
                data.election_types = params.filters.electionsType
            } else if (typeof params.filters.electionsType === "string") {
                data.election_types = params.filters.electionsType.split(",")
            }
        }

        if (params.filters.dateStart || params.filters.dateEnd) {
            data.contribution_date = {
                start: cleanDateInput(params.filters.dateStart),
                end: cleanDateInput(params.filters.dateEnd),
            }
        }

        if ("amountMin" in params.filters || "amountMax" in params.filters) {
            if (params.filters.receipt_supporter) {
                data.contribution_amount = {
                    start: params.filters.amountMin,
                    end: params.filters.amountMax,
                }
            } else {
                data.contributed_amount = {
                    start: params.filters.amountMin,
                    end: params.filters.amountMax,
                }
            }
        }

        if (params?.filters?.political_committee_receipts_cf) {
            data.political_committee_receipts_cf = params.filters.political_committee_receipts_cf
        }

        if (params?.filters?.political_committee_disbursements_cf) {
            data.political_committee_disbursements_cf = params.filters.political_committee_disbursements_cf
        }

        // integrate advanced search query from lists filter
        if (params.filters.advanced_search && typeof params.filters.advanced_search == "string") {
            if (data.query) {
                data.query = data.query + " " + params.filters.advanced_search
            } else {
                data.query = params.filters.advanced_search
            }
        }

        // search widgets data by additional query
        if (params.filters.additional_filters && params.filters.additional_filters.advanced_search) {
            if (data.query) {
                data.query = data.query + " " + params.filters.additional_filters.advanced_search
            } else {
                data.query = params.filters.additional_filters.advanced_search
            }
        }

        if ("fec_ids" in params.filters) {
            if (isStrictTokenRequest(params)) {
                data.recipient_candidate_tokens = params.filters.fec_ids ? params.filters.fec_ids : [""]
                data.recipient_pac_committee_tokens = params.filters.fec_committee_ids
                    ? params.filters.fec_committee_ids
                    : [""]
            } else {
                data.recipient_candidate_tokens = params.filters.fec_ids ? params.filters.fec_ids : [""]
                data.donor_candidate_tokens = params.filters.fec_ids ? params.filters.fec_ids : [""]
                data.token_condition_is_or = true
            }
        }

        if (params.filters.receipts_state_codes) {
            if (Array.isArray(params.filters.receipts_state_codes)) {
                data.address_state_codes = params.filters.receipts_state_codes
            } else if (typeof params.filters.receipts_state_codes === "string") {
                data.address_state_codes = params.filters.receipts_state_codes.split(",")
            }
        }
        if (params.filters.receipts_contributor_cities) {
            if (params.filters.receipts_contributor_cities.addedIds.length > 0) {
                data.address_cities = params.filters.receipts_contributor_cities.addedIds
            }
        }
        if (params.filters.receipts_contributor_zip_codes) {
            data.address_zip_codes = params.filters.receipts_contributor_zip_codes.addedIds.map((id) => id.toString())
        }
        if (params.filters.receipts_contributor_employers) {
            if (params.filters.receipts_contributor_employers.addedIds.length > 0) {
                data.employers = params.filters.receipts_contributor_employers.addedIds
            }
        }
        if (params.filters.receipts_contributor_occupations) {
            if (params.filters.receipts_contributor_occupations.addedIds.length > 0) {
                data.occupations = params.filters.receipts_contributor_occupations.addedIds
            }
        }
        delete params.filters
    }
    if (params.receipt_supporter) {
        // Cleaning incompatible keys
        delete data.query
        delete data.source_types

        if (data.sort_column && data.sort_direction) {
            data.sort_column = `contribution_${data.sort_column}`
        }

        // adding custom keys
        data.custom_endpoint_url = getStakeholdersReceiptEndpoint(params.receipt_supporter)
        data.source = "quorum"
    }

    if (params.receipts_political_committee) {
        const political_committee_ids = []
        political_committee_ids.push(params.receipts_political_committee)
        if (isStrictTokenRequest(params)) {
            data.recipient_committee_tokens = political_committee_ids
        } else {
            data.recipient_committee_tokens = political_committee_ids
            data.donor_committee_tokens = political_committee_ids
            data.token_condition_is_or = true
        }
    }

    if (params.recipientTypes) {
        data.recipient_committee_types = params.recipientTypes.split(",")
    }

    if (params.recipientParties) {
        data.recipient_committee_parties = params.recipientParties.split(",")
    }

    if (params.recipientStateCodes) {
        data.recipient_state_codes = params.recipientStateCodes.split(",")
    }

    if (params.receipts_candidate_name) {
        if (_unwrapPowersearchTokenField(params.receipts_candidate_name).addedIds.length > 0) {
            data.recipient_candidate_ids = _unwrapPowersearchTokenField(params.receipts_candidate_name).addedIds
        }
        if (_unwrapPowersearchTokenField(params.receipts_candidate_name)?.querysetList?.[0]?.advanced_search) {
            data.recipient_candidate_query = _unwrapPowersearchTokenField(
                params.receipts_candidate_name,
            )?.querysetList?.[0]?.advanced_search
        }
        if (_unwrapPowersearchTokenField(params.receipts_candidate_name).deletedIds.length > 0) {
            data.recipient_candidate_excluded_ids = _unwrapPowersearchTokenField(
                params.receipts_candidate_name,
            ).deletedIds
        }
    }

    if (params.contributorTypes) {
        if (params.contributorTypes.includes("candidate")) {
            const newContributorTypes = params.contributorTypes.replace("candidate", [
                "house",
                "senate",
                "presidential",
            ])

            data.donor_committee_types = newContributorTypes.split(",")
        } else {
            data.donor_committee_types = params.contributorTypes.split(",")
        }
    }

    if (params.electionsType) {
        data.election_types = params.electionsType.split(",")
    }

    if (params.dateStart || params.dateEnd) {
        data.contribution_date = {
            start: cleanDateInput(params.dateStart),
            end: cleanDateInput(params.dateEnd),
        }
    }

    if ("amountMin" in params || "amountMax" in params) {
        if (params.receipt_supporter) {
            data.contribution_amount = {
                start: params.amountMin,
                end: params.amountMax,
            }
        } else {
            data.contributed_amount = {
                start: params.amountMin,
                end: params.amountMax,
            }
        }
    }

    if ("fec_ids" in params) {
        if (isStrictTokenRequest(params)) {
            data.recipient_candidate_tokens = params.fec_ids ? params.fec_ids : [""]
            data.recipient_pac_committee_tokens = params.fec_committee_ids ? params.fec_committee_ids : [""]
        } else {
            data.recipient_candidate_tokens = params.fec_ids ? params.fec_ids : [""]
            data.donor_candidate_tokens = params.fec_ids ? params.fec_ids : [""]
            data.token_condition_is_or = true
        }
    }

    if (params.receipts_state_codes) {
        data.address_state_codes = params.receipts_state_codes.split(",")
    }
    if (params.receipts_contributor_cities) {
        if (_unwrapPowersearchTokenField(params.receipts_contributor_cities).addedIds.length > 0) {
            data.address_cities = _unwrapPowersearchTokenField(params.receipts_contributor_cities).addedIds
        }
    }
    if (params.receipts_contributor_zip_codes) {
        if (_unwrapPowersearchTokenField(params.receipts_contributor_zip_codes).addedIds.length > 0) {
            data.address_zip_codes = _unwrapPowersearchTokenField(params.receipts_contributor_zip_codes).addedIds
        }
    }
    if (params.receipts_contributor_employers) {
        if (_unwrapPowersearchTokenField(params.receipts_contributor_employers).addedIds.length > 0) {
            data.employers = _unwrapPowersearchTokenField(params.receipts_contributor_employers).addedIds
        }
    }
    if (params.receipts_contributor_occupations) {
        if (_unwrapPowersearchTokenField(params.receipts_contributor_occupations).addedIds.length > 0) {
            data.occupations = _unwrapPowersearchTokenField(params.receipts_contributor_occupations).addedIds
        }
    }

    return data
}

export const getReceiptsCandidatesFilters = (data, params) => {
    // Cleaning incompatible keys
    delete data.source_types

    // adding custom keys
    data.custom_endpoint_url = getStakeholdersReceiptEndpoint(params.receipt_supporter)
    data.source = "quorum"

    if (data.sort_column && data.sort_direction) {
        data.sort_column = `contribution_${data.sort_column}`
    }

    if (params.receipts_political_committee) {
        const political_committee_ids = []
        political_committee_ids.push(params.receipts_political_committee)
        if (isStrictTokenRequest(params)) {
            data.recipient_committee_tokens = political_committee_ids
        } else {
            data.recipient_committee_tokens = political_committee_ids
            data.donor_committee_tokens = political_committee_ids
            data.token_condition_is_or = true
        }
    }

    if (params.recipientTypes) {
        data.recipient_committee_types = params.recipientTypes.split(",")
    }

    if (params.receipts_candidate_name) {
        data.recipient_candidate_ids = _unwrapPowersearchTokenField(params.receipts_candidate_name).addedIds
        if (_unwrapPowersearchTokenField(params.receipts_candidate_name)?.querysetList?.[0]?.advanced_search) {
            data.recipient_candidate_query = _unwrapPowersearchTokenField(
                params.receipts_candidate_name,
            )?.querysetList?.[0]?.advanced_search
        }
        if (_unwrapPowersearchTokenField(params.receipts_candidate_name).deletedIds.length > 0) {
            data.recipient_candidate_excluded_ids = _unwrapPowersearchTokenField(
                params.receipts_candidate_name,
            ).deletedIds
        }
    }

    if (params.contributorTypes) {
        if (params.contributorTypes.includes("candidate")) {
            const newContributorTypes = params.contributorTypes.replace("candidate", [
                "house",
                "senate",
                "presidential",
            ])

            data.donor_committee_types = newContributorTypes.split(",")
        } else {
            data.donor_committee_types = params.contributorTypes.split(",")
        }
    }

    if (params.electionsType) {
        data.election_types = params.electionsType.split(",")
    }

    if (params.dateStart || params.dateEnd) {
        data.contribution_date = {
            start: cleanDateInput(params.dateStart),
            end: cleanDateInput(params.dateEnd),
        }
    }

    if (params.amountMin || params.amountMax) {
        data.contribution_amount = {
            start: params.amountMin,
            end: params.amountMax,
        }
    }

    if ("fec_ids" in params) {
        if (isStrictTokenRequest(params)) {
            data.recipient_candidate_tokens = params.fec_ids ? params.fec_ids : [""]
        } else {
            data.recipient_candidate_tokens = params.fec_ids ? params.fec_ids : [""]
            data.donor_candidate_tokens = params.fec_ids ? params.fec_ids : [""]
            data.token_condition_is_or = true
        }
    }

    return data
}

export const getSupporterHasMoneyallParams = (params) => {
    const allowedFilters = [
        "receipts_candidate_name",
        "campaign_finance_has_contributed",
        "receipts_campaign_finance_amount_min",
        "receipts_campaign_finance_amount_max",
        "receipts_campaign_finance_date_start",
        "receipts_campaign_finance_date_end",
        "campaign_finance_legislator_receivers",
        "receipts_contributor_committee_name",
        "amountMin",
        "amountMax",
        "dateStart",
        "dateEnd",
    ]

    return Object.keys(params).some((i) => {
        return allowedFilters.includes(i)
    })
}

export const getPoliticalCommitteesHasMoneyballParams = (params) => {
    const allowedFilters = [
        "receipts_campaign_finance_has_contributed",
        "disbursements_campaign_finance_has_contributed",
        "receipts_campaign_finance_amount_min",
        "receipts_campaign_finance_amount_max",
        "receipts_campaign_finance_date_start",
        "receipts_campaign_finance_date_end",
        "disbursements_campaign_finance_amountMin",
        "disbursements_campaign_finance_amountMax",
        "disbursements_campaign_finance_dateStart",
        "disbursements_campaign_finance_dateEnd",
        "disbursements_contributor_committee_name",
        "receipts_contributor_committee_name",
        "receipts_campaign_finance_legislator_receivers",
        "disbursements_campaign_finance_legislator_receivers",
        "political_committee_receipts_cf",
    ]

    return Object.keys(params).some((i) => {
        return allowedFilters.includes(i)
    })
}

export const getOfficialsHasMoneyballParams = (params) => {
    const allowedFilters = [
        "receipts_campaign_finance_has_contributed",
        "receipts_campaign_finance_amount_min",
        "receipts_campaign_finance_amount_max",
        "receipts_campaign_finance_date_start",
        "receipts_contributor_committee_name",
        "receipts_campaign_finance_date_end",
        "receipts_campaign_finance_legislator_receivers",
    ]

    return Object.keys(params).some((i) => {
        return allowedFilters.includes(i)
    })
}

export const _unwrapPowersearchTokenField = (field) => {
    try {
        return typeof field === "object" ? field : JSON.parse(field)
    } catch (e) {
        // skip
    }
    return field
}

export const getSupporterLookupFilters = (data, params) => {
    if ("campaign_finance_has_contributed" in params) {
        data.has_contributed = params.campaign_finance_has_contributed
    }

    if (params.dateStart || params.dateEnd) {
        data.contribution_date = {
            start: cleanDateInput(params.dateStart),
            end: cleanDateInput(params.dateEnd),
        }
    }

    if (params.amountMin || params.amountMax) {
        data.contribution_amount = {
            start: params.amountMin,
            end: params.amountMax,
        }
    }

    if (params.receipts_contributor_committee_name) {
        if (_unwrapPowersearchTokenField(params.receipts_contributor_committee_name).addedIds.length > 0) {
            data.receipts_contributor_committee_name = params.receipts_contributor_committee_name
        }
    }

    if (params.campaign_finance_legislator_receivers) {
        if (_unwrapPowersearchTokenField(params.campaign_finance_legislator_receivers).addedIds.length > 0) {
            data.super_candidate_recipient_raw_ids = _unwrapPowersearchTokenField(
                params.campaign_finance_legislator_receivers,
            ).addedIds
        }
        if (
            _unwrapPowersearchTokenField(params.campaign_finance_legislator_receivers)?.querysetList?.[0]
                ?.advanced_search
        ) {
            data.super_candidate_recipient_raw_query = _unwrapPowersearchTokenField(
                params.campaign_finance_legislator_receivers,
            )?.querysetList?.[0]?.advanced_search
        }
        if (_unwrapPowersearchTokenField(params.campaign_finance_legislator_receivers).deletedIds.length > 0) {
            data.super_candidate_recipient_excluded_raw_ids = _unwrapPowersearchTokenField(
                params.campaign_finance_legislator_receivers,
            ).deletedIds
        }
    }

    return data
}

export const getPoliticalCommitteeLookupFilters = (data, params) => {
    data.receipts_filters = {}
    data.disbursements_filters = {}

    if ("receipts_campaign_finance_has_contributed" in params) {
        data.receipts_filters.has_contributed = params.receipts_campaign_finance_has_contributed
    }

    if ("disbursements_campaign_finance_has_contributed" in params) {
        data.disbursements_filters.has_contributed = params.disbursements_campaign_finance_has_contributed
    }

    if (params.receipts_campaign_finance_legislator_receivers) {
        if (_unwrapPowersearchTokenField(params.receipts_campaign_finance_legislator_receivers).addedIds.length > 0) {
            data.receipts_filters.receipts_campaign_finance_legislator_receivers_raw_ids = _unwrapPowersearchTokenField(
                params.receipts_campaign_finance_legislator_receivers,
            ).addedIds
        }
        if (
            _unwrapPowersearchTokenField(params.receipts_campaign_finance_legislator_receivers)?.querysetList?.[0]
                ?.advanced_search
        ) {
            data.receipts_filters.receipts_campaign_finance_legislator_receivers_raw_query =
                _unwrapPowersearchTokenField(
                    params.receipts_campaign_finance_legislator_receivers,
                )?.querysetList?.[0]?.advanced_search
        }
    }

    if (params.receipts_contributor_committee_name) {
        if (_unwrapPowersearchTokenField(params.receipts_contributor_committee_name).addedIds.length > 0) {
            data.receipts_contributor_committee_name = params.receipts_contributor_committee_name
        }
    }

    if (params.disbursements_contributor_committee_name) {
        if (_unwrapPowersearchTokenField(params.disbursements_contributor_committee_name).addedIds.length > 0) {
            data.disbursements_contributor_committee_name = params.disbursements_contributor_committee_name
        }
    }

    if (params.disbursements_campaign_finance_legislator_receivers) {
        if (
            _unwrapPowersearchTokenField(params.disbursements_campaign_finance_legislator_receivers).addedIds.length > 0
        ) {
            data.disbursements_filters.disbursements_campaign_finance_legislator_receivers_raw_ids =
                _unwrapPowersearchTokenField(params.disbursements_campaign_finance_legislator_receivers).addedIds
        }
        if (
            _unwrapPowersearchTokenField(params.disbursements_campaign_finance_legislator_receivers)?.querysetList?.[0]
                ?.advanced_search
        ) {
            data.disbursements_filters.disbursements_campaign_finance_legislator_receivers_raw_query =
                _unwrapPowersearchTokenField(
                    params.disbursements_campaign_finance_legislator_receivers,
                )?.querysetList?.[0]?.advanced_search
        }
        if (
            _unwrapPowersearchTokenField(params.disbursements_campaign_finance_legislator_receivers).deletedIds.length >
            0
        ) {
            data.disbursements_filters.disbursements_campaign_finance_legislator_receivers_raw_excluded_ids =
                _unwrapPowersearchTokenField(params.disbursements_campaign_finance_legislator_receivers).deletedIds
        }
    }

    if (params.receipts_campaign_finance_date_start || params.receipts_campaign_finance_date_end) {
        data.receipts_filters.contribution_date = {
            start: cleanDateInput(params.receipts_campaign_finance_date_start),
            end: cleanDateInput(params.receipts_campaign_finance_date_end),
        }
    }

    if (params.disbursements_campaign_finance_dateStart || params.disbursements_campaign_finance_dateEnd) {
        data.disbursements_filters.contribution_date = {
            start: cleanDateInput(params.disbursements_campaign_finance_dateStart),
            end: cleanDateInput(params.disbursements_campaign_finance_dateEnd),
        }
    }

    if (params.receipts_campaign_finance_amount_min || params.receipts_campaign_finance_amount_max) {
        data.receipts_filters.contribution_amount = {
            start: params.receipts_campaign_finance_amount_min,
            end: params.receipts_campaign_finance_amount_max,
        }
    }

    if (params.disbursements_campaign_finance_amountMin || params.disbursements_campaign_finance_amountMax) {
        data.disbursements_filters.contribution_amount = {
            start: params.disbursements_campaign_finance_amountMin,
            end: params.disbursements_campaign_finance_amountMax,
        }
    }
    return data
}

export const getOfficialsLookupFilters = (data, params) => {
    data.receipts_filters = {}

    if ("receipts_campaign_finance_has_contributed" in params) {
        data.receipts_filters.has_contributed = params.receipts_campaign_finance_has_contributed
    }

    if (params.receipts_campaign_finance_legislator_receivers) {
        if (_unwrapPowersearchTokenField(params.receipts_campaign_finance_legislator_receivers).addedIds.length > 0) {
            data.receipts_filters.receipts_campaign_finance_legislator_receivers_raw_ids = _unwrapPowersearchTokenField(
                params.receipts_campaign_finance_legislator_receivers,
            ).addedIds
        }
        if (
            _unwrapPowersearchTokenField(params.receipts_campaign_finance_legislator_receivers)?.querysetList?.[0]
                ?.advanced_search
        ) {
            data.receipts_filters.receipts_campaign_finance_legislator_receivers_raw_query =
                _unwrapPowersearchTokenField(
                    params.receipts_campaign_finance_legislator_receivers,
                )?.querysetList?.[0]?.advanced_search
        }
    }

    if (params.receipts_campaign_finance_date_start || params.receipts_campaign_finance_date_end) {
        data.receipts_filters.contribution_date = {
            start: cleanDateInput(params.receipts_campaign_finance_date_start),
            end: cleanDateInput(params.receipts_campaign_finance_date_end),
        }
    }

    if (params.receipts_contributor_committee_name) {
        if (_unwrapPowersearchTokenField(params.receipts_contributor_committee_name).addedIds.length > 0) {
            data.receipts_contributor_committee_name = params.receipts_contributor_committee_name
        }
    }

    if (params.receipts_campaign_finance_amount_min || params.receipts_campaign_finance_amount_max) {
        data.receipts_filters.contribution_amount = {
            start: params.receipts_campaign_finance_amount_min,
            end: params.receipts_campaign_finance_amount_max,
        }
    }

    return data
}

export const getDisbursementFilters = (data, params) => {
    if (params.filters) {
        if (params.filters.disbursement_political_committee) {
            const political_committee_ids = []
            political_committee_ids.push(params.filters.disbursement_political_committee)
            if (isStrictTokenRequest(params)) {
                data.spender_committee_tokens = political_committee_ids
            } else {
                data.spender_committee_tokens = political_committee_ids
                data.recipient_committee_tokens = political_committee_ids
                data.token_condition_is_or = true
            }
        }
        if (params.filters.disburserTypes) {
            if (Array.isArray(params.filters.disburserTypes)) {
                data.spender_committee_types = params.filters.disburserTypes
            } else if (typeof params.filters.disburserTypes === "string") {
                data.spender_committee_types = params.filters.disburserTypes.split(",")
            }
        }
        if (params.filters.recipientTypes) {
            if (Array.isArray(params.filters.recipientTypes)) {
                data.recipient_committee_types = params.filters.recipientTypes
            } else if (typeof params.filters.recipientTypes === "string") {
                data.recipient_committee_types = params.filters.recipientTypes.split(",")
            }
        }

        if (params.filters.dateStart || params.filters.dateEnd) {
            data.disbursement_date = {
                start: cleanDateInput(params.filters.dateStart),
                end: cleanDateInput(params.filters.dateEnd),
            }
        }

        if ("amountMin" in params.filters || "amountMax" in params.filters) {
            data.disbursement_amount = {
                start: params.filters.amountMin,
                end: params.filters.amountMax,
            }
        }

        if ("fec_id" in params.filters) {
            if (isStrictTokenRequest(params)) {
                data.spender_candidate_tokens = params.filters.fec_ids ? params.filters.fec_ids : [""]
                data.spender_pac_committee_tokens = params.filters.fec_committee_ids
                    ? params.filters.fec_committee_ids
                    : [""]
            } else {
                data.spender_candidate_tokens = params.filters.fec_ids ? params.filters.fec_ids : [""]
                data.recipient_candidate_tokens = params.filters.fec_ids ? params.filters.fec_ids : [""]
                data.token_condition_is_or = true
            }
        }

        if (params?.filters?.political_committee_receipts_cf) {
            data.political_committee_receipts_cf = params.filters.political_committee_receipts_cf
        }

        if (params?.filters?.political_committee_disbursements_cf) {
            data.political_committee_disbursements_cf = params.filters.political_committee_disbursements_cf
        }

        // integrate advanced search query from lists filter
        if (params.filters.advanced_search && typeof params.filters.advanced_search == "string") {
            if (data.query) {
                data.query = data.query + " " + params.filters.advanced_search
            } else {
                data.query = params.filters.advanced_search
            }
        }

        // search widgets data by additional query
        if (params.filters.additional_filters && params.filters.additional_filters.advanced_search) {
            if (data.query) {
                data.query = data.query + " " + params.filters.additional_filters.advanced_search
            } else {
                data.query = params.filters.additional_filters.advanced_search
            }
        }

        delete params.filters
    }
    if (params.disbursement_political_committee) {
        const political_committee_ids = []
        political_committee_ids.push(params.disbursement_political_committee)
        if (isStrictTokenRequest(params)) {
            data.spender_committee_tokens = political_committee_ids
        } else {
            data.spender_committee_tokens = political_committee_ids
            data.recipient_committee_tokens = political_committee_ids
            data.token_condition_is_or = true
        }
    }

    if (params.disburserTypes) {
        data.spender_committee_types = params.disburserTypes.split(",")
    }
    if (params.recipientTypes) {
        data.recipient_committee_types = params.recipientTypes.split(",")
    }

    if (params.dateStart || params.dateEnd) {
        data.disbursement_date = {
            start: cleanDateInput(params.dateStart),
            end: cleanDateInput(params.dateEnd),
        }
    }

    if (params.amountMin || params.amountMax) {
        data.disbursement_amount = {
            start: params.amountMin,
            end: params.amountMax,
        }
    }

    if ("fec_ids" in params) {
        if (isStrictTokenRequest(params)) {
            data.spender_candidate_tokens = params.fec_ids ? params.fec_ids : [""]
            data.spender_pac_committee_tokens = params.fec_committee_ids ? params.fec_committee_ids : [""]
        } else {
            data.spender_candidate_tokens = params.fec_ids ? params.fec_ids : [""]
            data.recipient_candidate_tokens = params.fec_ids ? params.fec_ids : [""]
            data.token_condition_is_or = true
        }
    }

    return data
}

export const getIndividualsFilters = (data, params) => {
    const query = params.individuals_query || ""
    data.query = query
    data.empty_query = !query
    data.column_name = params.column_name

    return data
}

export const getReceiptsVisualizationsFilter = (data, params) => {
    delete data.page
    delete data.page_size
    delete data.query

    if (params.committeeOfficials) {
        data.agg_name = "officials"
    } else if (params.recipientCommitteesChart) {
        data.agg_name = "committees"
    } else if (params.receiptsTrendsChart) {
        data.agg_name = params.interval ? `trends_${params.interval + "s"}` : "trends_months"
    } else if (params.recipientCommitteePartyChart) {
        data.agg_name = "parties"
    } else if (params.committeeContacts) {
        data.agg_name = "contacts"
        data.company_token = Userdata.organization_id
    } else if (params.map) {
        data.agg_name = "states"
    }

    if (params.receipts_political_committee) {
        const political_committee_ids = []
        political_committee_ids.push(params.receipts_political_committee)
        if (isStrictTokenRequest(params)) {
            data.recipient_committee_tokens = political_committee_ids
        } else {
            data.recipient_committee_tokens = political_committee_ids
            data.donor_committee_tokens = political_committee_ids
            data.token_condition_is_or = true
        }
    }

    if (params.political_committee_receipts_cf) {
        data.political_committee_receipts_cf = params.political_committee_receipts_cf
    }

    if ("fec_ids" in params) {
        if (isStrictTokenRequest(params)) {
            data.recipient_candidate_tokens = params.fec_ids ? params.fec_ids : [""]
        } else {
            data.recipient_candidate_tokens = params.fec_ids ? params.fec_ids : [""]
            data.donor_candidate_tokens = params.fec_ids ? params.fec_ids : [""]
            data.token_condition_is_or = true
        }
    }

    if (params.recipientTypes) {
        data.recipient_committee_types = params.recipientTypes.split(",")
    }

    if (params.recipientStateCodes) {
        data.recipient_state_codes = params.recipientStateCodes.split(",")
    }

    if (params.recipientParties) {
        data.recipient_committee_parties = params.recipientParties.split(",")
    }

    if (params.receipts_candidate_name) {
        if (_unwrapPowersearchTokenField(params.receipts_candidate_name).addedIds.length > 0) {
            data.recipient_candidate_ids = _unwrapPowersearchTokenField(params.receipts_candidate_name).addedIds
        }
        if (_unwrapPowersearchTokenField(params.receipts_candidate_name)?.querysetList?.[0]?.advanced_search) {
            data.recipient_candidate_query = _unwrapPowersearchTokenField(
                params.receipts_candidate_name,
            )?.querysetList?.[0]?.advanced_search
        }
        if (_unwrapPowersearchTokenField(params.receipts_candidate_name).deletedIds.length > 0) {
            data.recipient_candidate_excluded_ids = _unwrapPowersearchTokenField(
                params.receipts_candidate_name,
            ).deletedIds
        }
    }

    if (params.contributorTypes) {
        if (params.contributorTypes.includes("candidate")) {
            const newContributorTypes = params.contributorTypes.replace("candidate", [
                "house",
                "senate",
                "presidential",
            ])

            data.donor_committee_types = newContributorTypes.split(",")
        } else {
            data.donor_committee_types = params.contributorTypes.split(",")
        }
    }

    if (params.electionsType) {
        data.election_types = params.electionsType.split(",")
    }

    if (params.dateStart || params.dateEnd) {
        data.contribution_date = {
            start: cleanDateInput(params.dateStart),
            end: cleanDateInput(params.dateEnd),
        }
    }

    if (params.amountMin || params.amountMax) {
        if (params.receipt_supporter) {
            data.contribution_amount = {
                start: params.amountMin,
                end: params.amountMax,
            }
        } else {
            data.contributed_amount = {
                start: params.amountMin,
                end: params.amountMax,
            }
        }
    }

    if (params.receipts_state_codes) {
        data.address_state_codes = params.receipts_state_codes.split(",")
    }
    if (params.receipts_contributor_cities) {
        if (_unwrapPowersearchTokenField(params.receipts_contributor_cities).addedIds.length > 0) {
            data.address_cities = _unwrapPowersearchTokenField(params.receipts_contributor_cities).addedIds
        }
    }
    if (params.receipts_contributor_zip_codes) {
        if (_unwrapPowersearchTokenField(params.receipts_contributor_zip_codes).addedIds.length > 0) {
            data.address_zip_codes = _unwrapPowersearchTokenField(params.receipts_contributor_zip_codes).addedIds
        }
    }
    if (params.receipts_contributor_employers) {
        if (_unwrapPowersearchTokenField(params.receipts_contributor_employers).addedIds.length > 0) {
            data.employers = _unwrapPowersearchTokenField(params.receipts_contributor_employers).addedIds
        }
    }
    if (params.receipts_contributor_occupations) {
        if (_unwrapPowersearchTokenField(params.receipts_contributor_occupations).addedIds.length > 0) {
            data.occupations = _unwrapPowersearchTokenField(params.receipts_contributor_occupations).addedIds
        }
    }

    return data
}

export function isMoneyballRelationshipRequest(params) {
    const param_keys = Object.keys(params)

    return [
        ...RELATIONSHIP_KEYS_OFFICIAL,
        ...RELATIONSHIP_KEYS_SUPPORTER,
        ...RELATIONSHIP_KEYS_POLITICAL_COMMITTEES,
    ].some((key) => param_keys.includes(key))
}

export function _transformQuerysetList(key, querysetList) {
    if (RELATIONSHIP_KEYS_SUPPORTER.includes(key)) {
        if (getSupporterHasMoneyallParams(querysetList)) {
            return {
                ...querysetList,
                moneyball_params: getSupporterLookupFilters({}, querysetList),
            }
        }
    }

    if (RELATIONSHIP_KEYS_OFFICIAL.includes(key)) {
        if (getOfficialsHasMoneyballParams(querysetList)) {
            return {
                ...querysetList,
                moneyball_params: getOfficialsLookupFilters({}, querysetList),
            }
        }
    }

    if (RELATIONSHIP_KEYS_POLITICAL_COMMITTEES.includes(key)) {
        if (getPoliticalCommitteesHasMoneyballParams(querysetList)) {
            return {
                ...querysetList,
                moneyball_params: getPoliticalCommitteeLookupFilters({}, querysetList),
            }
        }
    }

    return querysetList
}

export function transformMoneyballRelationshipRequest(params) {
    // find keys
    const param_keys = Object.keys(params)
    const found_keys = [
        ...RELATIONSHIP_KEYS_OFFICIAL,
        ...RELATIONSHIP_KEYS_SUPPORTER,
        ...RELATIONSHIP_KEYS_POLITICAL_COMMITTEES,
    ].filter((key) => param_keys.includes(key))

    for (const key of found_keys) {
        try {
            let params_object = JSON.parse(params[key])
            const querysetList = params_object.querysetList[0]

            params_object.querysetList[0] = _transformQuerysetList(key, querysetList)
            params[key] = JSON.stringify(params_object)
        } catch (e) {
            // just skip to the next key
        }
    }

    return params
}

export function transformMoneyballRequest(params, model) {
    const page_size = parseInt(params["limit"] || params["page_size"] || 20)
    const offset = parseInt(params["offset"] || 0)
    const page = offset / page_size + 1
    const query = params["advanced_search"] || ""
    let sort_direction
    let sort_column = params["order_by"]

    if (sort_column) {
        if (sort_column.startsWith("-")) {
            sort_column = sort_column.slice(1)
            sort_direction = "asc"
        } else {
            sort_direction = "desc"
        }
    }

    const data = {
        page_size,
        page,
        sort_column,
        sort_direction,
        query,
    }

    // handle relationship params
    if (isMoneyballRelationshipRequest(params)) {
        params = transformMoneyballRelationshipRequest(params)
    }

    if (model.__name__ === MODEL_NAME_SUPPORTER) {
        if (getSupporterHasMoneyallParams(params)) {
            return { ...params, moneyball_params: getSupporterLookupFilters({}, params) }
        }

        return params
    }

    if (model.__name__ === MODEL_NAME_POLITICAL_COMMITTEE) {
        if (getPoliticalCommitteesHasMoneyballParams(params)) {
            return { ...params, moneyball_params: getPoliticalCommitteeLookupFilters({}, params) }
        }

        return params
    }

    if (model.__name__ === MODEL_NAME_OFFICIAL) {
        if (getOfficialsHasMoneyballParams(params)) {
            return { ...params, moneyball_params: getOfficialsLookupFilters({}, params) }
        }

        return params
    }

    if (model.__name__ === MODEL_NAME_RECEIPT) {
        let receiptsFilters = []
        if (params.receipt_supporter) {
            receiptsFilters = getReceiptsCandidatesFilters(data, params)
        } else {
            receiptsFilters = getReceiptsFilters(data, params)
        }

        return { ...params, moneyball_params: receiptsFilters }
    }

    if (model.__name__ === MODEL_NAME_DISBURSEMENT) {
        const disbursmentFilters = getDisbursementFilters(data, params)

        return { ...params, moneyball_params: disbursmentFilters }
    }

    if (model.__name__ === MODEL_NAME_INDIVIDUALS_SUGGESTER) {
        return getIndividualsFilters(data, params)
    }

    if (model.__name__ === MODEL_NAME_VISUALIZATION) {
        return getReceiptsVisualizationsFilter(data, params)
    }

    return { ...params, moneyball_params: { ...data } }
}

/**
 * Used in /sheet/ to inject moneyball_params field into the SafedSearch filters to trigger the
 * moneyball_params querymethod of the respective models.
 * @param params
 * @param model
 * @returns {*}
 */
export function injectMoneyballSafedSearchParams(params) {
    // The model is always SafedSearch, but the data_type varies depending on the sheet
    if (params.data_type === DjangIO.app.models.QuorumDataType.supporter.value) {
        // No real way to make this prettier, this is the format, unfortunately.
        const filters = params.filters[0].for_searchify_filters.filters[0]
        // Check if there are any Supporter params in filters.
        if (getSupporterHasMoneyallParams(filters)) {
            params.filters[0].for_searchify_filters.filters[0] = {
                ...filters,
                moneyball_params: getSupporterLookupFilters({}, filters),
            }
        }
        return params
    }

    if (params.data_type === DjangIO.app.models.QuorumDataType.person.value) {
        const filters = params.filters[0].for_searchify_filters.filters[0]
        if (getOfficialsHasMoneyballParams(filters)) {
            params.filters[0].for_searchify_filters.filters[0] = {
                ...filters,
                moneyball_params: getOfficialsLookupFilters({}, filters),
            }
        }
        return params
    }

    if (params.data_type === DjangIO.app.models.QuorumDataType.political_committee.value) {
        const filters = params.filters[0].for_searchify_filters.filters[0]
        if (getPoliticalCommitteesHasMoneyballParams(filters)) {
            params.filters[0].for_searchify_filters.filters[0] = {
                ...filters,
                moneyball_params: getPoliticalCommitteeLookupFilters({}, filters),
            }
        }
        return params
    }

    return params
}

/**
 * Used in /dashboard/<id>/ to inject moneyball_params field into the WidgetEngine filters to trigger the
 * moneyball_params querymethod of the respective models.
 * @param params
 * @param model
 * @returns {*}
 */
export function injectMoneyballWidgetEngineParams(params) {
    if (!params.data_sources) {
        return params
    }

    for (let source of params.data_sources) {
        let filters
        // The model is always SafedSearch, but the data_type varies depending on the sheet
        if (source.safed_search.data_type === DjangIO.app.models.QuorumDataType.supporter.value) {
            // No real way to make this prettier, this is the format, unfortunately.
            filters = source.safed_search.filters[0]
            // Check if there are any Supporter params in filters.
            if (getSupporterHasMoneyallParams(filters)) {
                source.safed_search.filters[0] = {
                    ...filters,
                    moneyball_params: getSupporterLookupFilters({}, filters),
                }
            }
            continue
        }

        if (source.safed_search.data_type === DjangIO.app.models.QuorumDataType.person.value) {
            filters = source.safed_search.filters[0]
            if (getOfficialsHasMoneyballParams(filters)) {
                source.safed_search.filters[0] = {
                    ...filters,
                    moneyball_params: getOfficialsLookupFilters({}, filters),
                }
            }
            continue
        }

        if (source.safed_search.data_type === DjangIO.app.models.QuorumDataType.political_committee.value) {
            filters = source.safed_search.filters[0]
            if (getPoliticalCommitteesHasMoneyballParams(filters)) {
                source.safed_search.filters[0] = {
                    ...filters,
                    moneyball_params: getPoliticalCommitteeLookupFilters({}, filters),
                }
            }
            continue
        }

        if (source.safed_search.data_type === DjangIO.app.models.QuorumDataType.receipt.value) {
            filters = source.safed_search.filters[0]
            const data = {}
            if (source.additional_filters) {
                filters.additional_filters = source.additional_filters
            }
            if (filters.advanced_search) {
                data.query = filters.advanced_search
            }
            source.safed_search.filters[0] = {
                ...filters,
                moneyball_params: getReceiptsFilters(data, { filters: filters }),
            }
            continue
        }

        if (source.safed_search.data_type === DjangIO.app.models.QuorumDataType.disbursement.value) {
            filters = source.safed_search.filters[0]
            const data = {}

            if (source.additional_filters) {
                filters.additional_filters = source.additional_filters
            }
            if (filters.advanced_search) {
                data.query = filters.advanced_search
            }
            source.safed_search.filters[0] = {
                ...filters,
                moneyball_params: getDisbursementFilters(data, { filters: filters }),
            }
            continue
        }
    }

    return params
}

export function injectMoneyballBulkEmailParams(params) {
    // check if params are even object
    if ("object" !== typeof params) {
        return params
    }

    // check if there are params
    if (!params || (params && Object.keys(params).length === 0)) {
        return params
    }

    // check if there is data
    if (!params?.selection_json?.data || params?.selection_json?.data.length === 0) {
        return params
    }

    params?.selection_json?.data.forEach((data) => {
        // check if there are advSearchParams
        if (!data?.advSearchParams || data?.advSearchParams.length === 0) {
            return
        }

        let filters = data?.advSearchParams[0]
        const dataType = data?.quorumDataType

        // just skip if something is not right
        if (!filters || !dataType) {
            return
        }

        // The model is always SafedSearch, but the data_type varies depending on the sheet
        if (dataType === DjangIO.app.models.QuorumDataType.supporter.value) {
            // Check if there are any Supporter params in filters.
            if (getSupporterHasMoneyallParams(filters)) {
                data.advSearchParams[0] = {
                    ...filters,
                    moneyball_params: getSupporterLookupFilters({}, filters),
                }
            }
        }

        if (dataType === DjangIO.app.models.QuorumDataType.person.value) {
            if (getOfficialsHasMoneyballParams(filters)) {
                data.advSearchParams[0] = {
                    ...filters,
                    moneyball_params: getOfficialsLookupFilters({}, filters),
                }
            }
        }

        if (dataType === DjangIO.app.models.QuorumDataType.political_committee.value) {
            if (getPoliticalCommitteesHasMoneyballParams(filters)) {
                data.advSearchParams[0] = {
                    ...filters,
                    moneyball_params: getPoliticalCommitteeLookupFilters({}, filters),
                }
            }
        }
    })

    return params
}

export function transformMoneyballResponse(response, params, model) {
    if (params.count_only) {
        return response?.pagination?.total_count ?? 0
    }

    const moneyballModelTransformers = {
        [MODEL_NAME_RECEIPT]: mapReceiptResponse,
        [MODEL_NAME_DISBURSEMENT]: mapDisbursementResponse,
        [MODEL_NAME_CANDIDATES_SUGGESTER]: mapSuggesterResponse,
        [MODEL_NAME_COMMITTEES_SUGGESTER]: mapSuggesterResponse,
        [MODEL_NAME_CONTRIBUTOR_SUGGESTER]: mapSuggesterResponse,
        [MODEL_NAME_INDIVIDUALS_SUGGESTER]: mapIndividualSuggesterResponse,
    }

    if (model.__name__ === MODEL_NAME_VISUALIZATION) {
        if (params.committeeOfficials) {
            return mapVisualizationOfficialsResponse(response)
        } else if (params.recipientCommitteesChart) {
            return mapVisualizationCommitteesResponse(response)
        } else if (params.receiptsTrendsChart) {
            return mapVisualizationReceiptsTrendsResponse(response)
        } else if (params.recipientCommitteePartyChart) {
            return mapVisualizationReceiptsPartyResponse(response)
        } else if (params.committeeContacts) {
            return mapVisualizationReceiptsContactsResponse(response)
        } else if (params.map) {
            return mapVisualizationReceiptsMapResponse(response)
        }
    }

    return {
        meta: parseMeta(response, model),
        objects: moneyballModelTransformers[model.__name__](response, params) || [],
    }
}
