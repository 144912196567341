import styled from "styled-components"

import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { constants } from "QuorumGrassroots/constants"

export const Container = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
`

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 0.5rem;
`

export const Button = styled.button<{ isSelected: boolean }>`
    padding: 0.5rem 2rem;
    border: 0;
    border-radius: 24px;

    background-color: ${(props) => (props.isSelected ? "black" : constants.colors.gray[2])};
    color: ${(props) => (props.isSelected ? "white" : constants.colors.gray[9])};

    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
`

export const Label = styleWithOrgDesign(styled.label`
    font-size: 14px;
    font-weight: 500;
    color: var(--custom-font-color);
    font-family: inherit;
`)

export const Error = styled.div`
    color: ${constants.errorTextColor};
`
