import React, { useState } from "react"
import PropTypes from "prop-types"
import { Field, FormSection } from "redux-form/immutable"
import { connect } from "react-redux"

import {
    InputCarouselWrapper,
    CarouselPagination,
} from "QuorumGrassroots/styled-components/components/InputCarouselWrapper"
import { requiredFieldValidation } from "QuorumGrassroots/helperFunctions"

import { writeFormSectionSelector } from "QuorumGrassroots/campaign-forms/selectors/form-section-selectors"
import { viewNextBody } from "QuorumGrassroots/campaign-forms/action-creators"
import { InputWithLock, withLock } from "QuorumGrassroots/styled-components/components/InputWithLock"
import { InputField } from "app/static/frontend/forms/components/InputField"
import { EditableMessagePreview } from "QuorumGrassroots/framework/components/EditableMessagePreview"

const { DraftOptions } = DjangIO.app.grassroots.campaign.types

const LockedMessagePreview = withLock(EditableMessagePreview)

// FormSection is awesome and allows us to get a slice of the form that is
// its own object!!!
export const WriteFormSection = ({ t, ...props }) => {
    const [isVisualizationMode, setIsVisualizationMode] = useState(true)

    const noEdit = props.draftOption === DraftOptions.cannot_edit.value
    const partialEdit = props.draftOption === DraftOptions.partial_edit.value

    const InputBodyComponent =
        props.inputBodyHasEditAndVisualizationMode && !noEdit
            ? EditableMessagePreview
            : props.inputBodyHasEditAndVisualizationMode && (noEdit || props.submitting)
              ? LockedMessagePreview
              : noEdit || props.submitting
                ? InputWithLock
                : InputField

    const bodyInputIsDisabled =
        props.inputBodyHasEditAndVisualizationMode && !noEdit
            ? noEdit || props.submitting || isVisualizationMode
            : noEdit || props.submitting

    const getNextMessage = () =>
        props.viewNextBody({
            uniqueWidgetId: props.uniqueWidgetId,
            currentId: props.activeId,
            formName: props.formName,
            increment: 1,
        })

    const getPrevMessage = () =>
        props.viewNextBody({
            uniqueWidgetId: props.uniqueWidgetId,
            currentId: props.activeId,
            formName: props.formName,
            increment: -1,
        })

    const renderCarousel = () => (
        <InputCarouselWrapper>
            <CarouselPagination
                key="prev"
                onClick={getPrevMessage}
                disabled={!props.hasPreviousMessage || props.submitting}
                data-cy="carousel-prev"
            >
                {"<"}
            </CarouselPagination>
            <Field
                component={InputField}
                name="body"
                label={props.hasPreBody ? null : t("campaign.write.body")}
                validate={requiredFieldValidation}
                disabled={noEdit || props.submitting}
                style={{ flexGrow: 1 }}
                placeholder={t("campaign.write.body_placeholder")}
                isTextarea
                dataCy="body"
            />
            <CarouselPagination
                key="next"
                onClick={getNextMessage}
                disabled={!props.hasNextMessage || props.submitting}
                data-cy="carousel-next"
            >
                {">"}
            </CarouselPagination>
        </InputCarouselWrapper>
    )

    return (
        <FormSection name={props.activeId}>
            {props.hasDescription && (
                <Field
                    component={InputWithLock}
                    name="description"
                    label={t("campaign.write.description")}
                    disabled
                    isTextarea
                    dataCy="description"
                />
            )}
            {!props.writeSectionConfigs.noSubject && (
                <Field
                    component={noEdit || props.submitting ? InputWithLock : InputField}
                    name="subject"
                    label={t("campaign.write.subject")}
                    validate={requiredFieldValidation}
                    disabled={noEdit || props.submitting}
                    placeholder={t("campaign.write.subject")}
                    dataCy="subject"
                />
            )}
            {props.hasPreBody && (
                <Field
                    component={InputLockWithCustomValue}
                    customValue={props.currentTargetedMessage?.formatted?.fixed_pre_message}
                    name="prebody"
                    label={props.hasPreBody ? t("campaign.write.body") : null}
                    validate={requiredFieldValidation}
                    disabled
                    isTextarea
                    minRows={1}
                    dataCy="prebody"
                />
            )}
            {!props.writeSectionConfigs.noBody &&
                (props.hasMultipleMessages ? (
                    renderCarousel()
                ) : (
                    <Field
                        component={InputBodyComponent}
                        name="body"
                        label={props.hasPreBody ? null : t("campaign.write.body")}
                        validate={partialEdit ? undefined : requiredFieldValidation}
                        isEditEnabled={!noEdit}
                        disabled={bodyInputIsDisabled}
                        placeholder={t("campaign.write.body_placeholder")}
                        isTextarea
                        dataCy="body"
                        isVisualizationMode={isVisualizationMode}
                        setIsVisualizationMode={setIsVisualizationMode}
                        maxLength={props.bodyMaxLength}
                        currentTargetedMessage={props.currentTargetedMessage}
                        activeGroupId={props.activeGroupId}
                        selectedTargetId={props.selectedTargetId}
                        isLoading={props.isLoadingBody}
                        t={t}
                        setWrongPlaceholder={props.setWrongPlaceholder}
                        wrongPlaceholder={props.wrongPlaceholder}
                    />
                ))}
            {props.hasPostBody && (
                <Field
                    component={InputLockWithCustomValue}
                    customValue={props.currentTargetedMessage?.formatted?.fixed_post_message}
                    name="postbody"
                    validate={requiredFieldValidation}
                    disabled
                    isTextarea
                    minRows={1}
                    dataCy="postbody"
                />
            )}
        </FormSection>
    )
}

const InputLockWithCustomValue = ({ customValue, ...props }) => {
    const inputWithCustomValue = {
        ...props.input,
        value: customValue || props.input.value,
    }

    return <InputWithLock {...props} input={inputWithCustomValue} />
}

WriteFormSection.propTypes = {
    activeId: PropTypes.string.isRequired,
    bodyMaxLength: PropTypes.number,
    formName: PropTypes.string.isRequired,
    uniqueWidgetId: PropTypes.string.isRequired,
    writeSectionConfigs: PropTypes.object.isRequired,
    hasDescription: PropTypes.bool,
    hasMultipleMessages: PropTypes.bool,
    hasPreBody: PropTypes.bool,
    hasPostBody: PropTypes.bool,
    draftOption: PropTypes.number.isRequired,
    viewNextBody: PropTypes.func.isRequired,
    hasPreviousMessage: PropTypes.bool,
    hasNextMessage: PropTypes.bool,
    submitting: PropTypes.bool.isRequired,
    setWrongPlaceholder: PropTypes.func,
    wrongPlaceholder: PropTypes.string,
}

WriteFormSection.defaultProps = {
    writeSectionConfigs: {},
}

export default connect(writeFormSectionSelector, { viewNextBody })(WriteFormSection)
