import React from "react"

import { Input } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/DatePickerField.styles"
import { CustomField } from "@/types/djangio"
import { useFormReturn } from "QuorumGrassroots/campaign-forms/hooks/useForm"
import { PromptAndResponseCampaignForm } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/PersonalizedMessages.helper"

interface Props {
    question: CustomField
    form: useFormReturn<PromptAndResponseCampaignForm>
    disabled?: boolean
}

export const DatePickerField = ({ question, form, disabled = false }: Props) => {
    const handleChange = (value: Date | null) => {
        if (value) form.setFieldValue(question.slug, value.toLocaleDateString("en-CA"), true)
        else form.setFieldValue(question.slug, "", true)
    }

    const parseLocalDate = (dateString: string) => {
        const [year, month, day] = dateString.split("-").map(Number)
        return new Date(year, month - 1, day) // Month is zero-indexed
    }

    const value = form.values[question.slug] ? parseLocalDate(form.values[question.slug] as string) : null

    return (
        <Input
            value={value}
            name={question.slug}
            label={question.external_name}
            onChange={handleChange}
            required={question.is_required}
            error={form.errors[question.slug]}
            popoverProps={{ withinPortal: true }}
            disabled={disabled}
            clearable
        />
    )
}
