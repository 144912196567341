import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Row, Col } from "react-bootstrap"
import { Field } from "redux-form/immutable"

import { selectFormFields } from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/selectors"

import CustomFieldFormSection from "app/static/frontend/custom-fields/containers/CustomFieldFormSection"
import {
    optionalFieldValidationWithLength,
    phoneNumberFormatWarning,
    requiredFieldValidation,
    requiredFieldValidationWithLength,
    requiredFieldPhoneValidation,
} from "QuorumGrassroots/helperFunctions"

import { AddressField } from "shared-web/forms/fields/AddressField"
import { InputField } from "app/static/frontend/forms/components/InputField"
import { PhoneField } from "shared-web/forms/fields/PhoneField"
import { EmailField } from "shared-web/forms/fields/EmailField"

import ToggleField from "shared-web/forms/fields/ToggleField"

import { StyledContainer } from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/style"
import { StyledContrastText } from "QuorumGrassroots/styled-components/components/StyledContrastText"
import { AddressFieldWithOfficials } from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/AddressFieldWithOfficials"
import { EmailFieldWithOfficials } from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/EmailFieldWithOfficials"
import { FieldWithPlaceholderResolving } from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/FieldWithPlaceholderResolving"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"

const { CampaignType } = DjangIO.app.grassroots.campaign.types

export const UserInfoFormSection = ({ t, ...props }) => {
    const renderFormField = (fieldObj) => {
        const formField = DjangIO.app.grassroots.types.GrassrootsRegistrationField.by_value(
            fieldObj.registration_field_type,
        )

        // First check for a custom label specified in props, otherwise default to label in i18n
        const formFieldLabel = (fieldObj.props && fieldObj.props.label) || t(formField.i18n_label_key)

        const optionalField = props.optionalSimpleFields[formField.value]

        // Some fields, like the texting opt-in field, can disallow the supporter
        // from making a choice; this is used for toggle fields
        const allowChoices = fieldObj.props && fieldObj.props.allow_choices

        // If the field is not explicitly set to be optional, set validation to 'null'
        // Otherwise, if 'require all fields' is true, set validation to 'required'
        const validation = !optionalField && props.requireAllFields ? requiredFieldValidation : null

        //currently the frontend validation only checks for required fields, so if it's null it means the field isn't required
        const showAsterisk = !!validation

        // For phone number fields, we want to show a warning about number format and compatibility
        // with texting, but only if there is also a texting field on the form
        const warning =
            formField.input_type === DjangIO.app.grassroots.types.FormInputType.phone_number.value &&
            props.includesTextingOptIn
                ? phoneNumberFormatWarning
                : null

        const readOnlyFields = props.readOnlyFields && props.readOnlyFields.includes(formField.value)

        const hasCustomRecipients = props.campaign?._extra.message_groups.some(
            (message) => message.custom_email_targets?.length > 0,
        )
        const shouldShowOfficialsByAddress =
            isFeatureEnabled("ff_ngg_address_lookup") &&
            props.campaign?.campaign_type === CampaignType.write_member.value &&
            props.campaign?.is_one_click_registration_enabled &&
            !hasCustomRecipients

        const AddressFieldComponent = shouldShowOfficialsByAddress ? AddressFieldWithOfficials : AddressField
        const EmailFieldComponent = shouldShowOfficialsByAddress ? EmailFieldWithOfficials : EmailField
        const GenericFieldComponent = shouldShowOfficialsByAddress ? FieldWithPlaceholderResolving : Field

        switch (formField.input_type) {
            case DjangIO.app.grassroots.types.FormInputType.address.value:
                return (
                    <AddressFieldComponent
                        name={formField.supporter_field}
                        dataCy={formField.supporter_field}
                        label={formFieldLabel}
                        placeholder={props.allowPartialAddress ? "" : t(formField.i18n_placeholder_key)}
                        displayErrorWithoutTouch={false}
                        validate={validation}
                        showAsterisk={showAsterisk}
                        shouldGeocode={isFeatureEnabled("ff_ngg_places_service_autocomplete_geocode")}
                        accessibilityId={t(formField.i18n_label_key)}
                        isCampaignPage={props.isCampaignPage}
                        campaign={props.campaign}
                        disabled={readOnlyFields}
                        tooltipText={readOnlyFields ? props.tooltipText : null}
                        uniqueWidgetId={props.uniqueWidgetId}
                    />
                )
            case DjangIO.app.grassroots.types.FormInputType.phone_number.value:
                return (
                    <PhoneField
                        accessibilityId={t(formField.i18n_label_key)}
                        dataCy={formField.supporter_field}
                        displayErrorWithoutTouch={false}
                        label={formFieldLabel}
                        name={formField.supporter_field}
                        placeholder={t(formField.i18n_placeholder_key)}
                        validate={!optionalField && props.requireAllFields ? requiredFieldPhoneValidation : null}
                        warn={warning}
                        countryCodeEditable={true}
                        defaultCountry={"us"}
                        showAsterisk={showAsterisk}
                        shouldCleanInput
                        isCampaignPage={props.isCampaignPage}
                        disabled={readOnlyFields}
                        tooltipText={readOnlyFields ? props.tooltipText : null}
                    />
                )
            case DjangIO.app.grassroots.types.FormInputType.toggle.value:
                return (
                    <ToggleField
                        allowChoices={allowChoices}
                        key={formField.supporter_field}
                        name={formField.supporter_field}
                        dataCy={formField.supporter_field}
                        label={t(formField.i18n_label_key)}
                        choices={formField.defaults.toggleChoices.map((choice) => ({
                            label: t(choice.i18n_label_key),
                            value: choice.value,
                        }))}
                        validate={validation}
                        displayErrorWithoutTouch={false}
                        showAsterisk={showAsterisk}
                        accessibilityId={t(formField.i18n_label_key)}
                        disabled={readOnlyFields}
                        tooltipText={readOnlyFields ? props.tooltipText : null}
                    />
                )
            case DjangIO.app.grassroots.types.FormInputType.email.value:
                return (
                    <EmailFieldComponent
                        name={formField.supporter_field}
                        dataCy={formField.supporter_field}
                        label={formFieldLabel}
                        placeholder={t(formField.i18n_placeholder_key)}
                        displayErrorWithoutTouch={false}
                        warn={warning}
                        validate={
                            !optionalField && props.requireAllFields
                                ? requiredFieldValidationWithLength
                                : optionalFieldValidationWithLength
                        }
                        accessibilityId={t(formField.i18n_label_key)}
                        showAsterisk={showAsterisk}
                        isCampaignPage={props.isCampaignPage}
                        campaign={props.campaign}
                        disabled={readOnlyFields}
                        tooltipText={readOnlyFields ? props.tooltipText : null}
                        uniqueWidgetId={props.uniqueWidgetId}
                    />
                )
            default:
                return (
                    <GenericFieldComponent
                        name={formField.supporter_field}
                        dataCy={formField.supporter_field}
                        label={formFieldLabel}
                        component={InputField}
                        placeholder={t(formField.i18n_placeholder_key)}
                        displayErrorWithoutTouch={false}
                        warn={warning}
                        validate={
                            !optionalField && props.requireAllFields
                                ? requiredFieldValidationWithLength
                                : optionalFieldValidationWithLength
                        }
                        accessibilityId={t(formField.i18n_label_key)}
                        showAsterisk={showAsterisk}
                        isCampaignPage={props.isCampaignPage}
                        campaign={props.campaign}
                        disabled={readOnlyFields}
                        tooltipText={readOnlyFields ? props.tooltipText : null}
                    />
                )
        }
    }

    const renderAllFieldObjects = () => {
        const allFields = []

        let doubleSimpleField = []
        if (props.allSimpleAndCustomFieldsWidth) {
            props.allSimpleAndCustomFieldsWidth.forEach((fieldObj) => {
                const slug = Object.keys(fieldObj.field)[0]
                const mappedFields = props.customFields.find((customField) => customField.slug === slug)
                const isReadOnlyCustomFields = props.readOnlyFields

                if (fieldObj.field && !fieldObj.isSimple) {
                    allFields.push(
                        <CustomFieldFormSection
                            allFieldsMandatory={props.requireAllFields}
                            customFieldNameDict={fieldObj.field}
                            externalFacing
                            form={props.form}
                            optionalFields={props.customFieldOptionalFields}
                            isCampaignPage={props.isCampaignPage}
                            requiredValues={props.customFieldRequiredValues}
                            selectLabel={t("form.select_placeholder")}
                            toggleChoices={[
                                { label: t("form.toggle_true"), value: true },
                                { label: t("form.toggle_false"), value: false },
                            ]}
                            disabled={props.readOnlyFields && props.readOnlyFields.includes(mappedFields.id)}
                            toolTipTextActionCenterSettings={
                                props.readOnlyFields && props.readOnlyFields.includes(mappedFields.id)
                                    ? props.tooltipText
                                    : null
                            }
                            isReadOnlyCustomFields={isReadOnlyCustomFields}
                        />,
                    )
                }

                if (fieldObj.field && fieldObj.isSimple) {
                    if (fieldObj.md === 12) {
                        allFields.push(
                            <Row>
                                <Col key={fieldObj.field.order} md={fieldObj.md} sm={12}>
                                    {renderFormField(fieldObj.field)}
                                </Col>
                            </Row>,
                        )
                    } else if (fieldObj.md === 6) {
                        doubleSimpleField.push(
                            <Col key={fieldObj.field.order} md={fieldObj.md} sm={12}>
                                {renderFormField(fieldObj.field)}
                            </Col>,
                        )
                    }
                    if (doubleSimpleField.length === 2) {
                        allFields.push(<Row>{doubleSimpleField}</Row>)
                        doubleSimpleField = []
                    }
                }
            })
        }
        return allFields
    }

    return (
        <StyledContainer>
            {props.registrationPagePreText && (
                <StyledContrastText
                    as="div"
                    isCampaignPage={props.isCampaignPage}
                    className="registration-pre-text"
                    dangerouslySetInnerHTML={{ __html: props.registrationPagePreText }}
                />
            )}
            {renderAllFieldObjects()}
            {props.registrationPagePostText && (
                <StyledContrastText
                    as="div"
                    isCampaignPage={props.isCampaignPage}
                    className="registration-post-text"
                    dangerouslySetInnerHTML={{ __html: props.registrationPagePostText }}
                />
            )}
        </StyledContainer>
    )
}

UserInfoFormSection.propTypes = {
    form: PropTypes.string.isRequired,
    // user provided props, if using connected component
    registrationPageIds: PropTypes.arrayOf(PropTypes.number),
    additionalSimpleFields: PropTypes.arrayOf(PropTypes.number),
    showOnlyFilledCustomFields: PropTypes.bool,
    showOnlyUnfilledFields: PropTypes.bool,
    // derived props, found in UserInfoFormSection/selectors
    allSimpleAndCustomFieldsWidth: PropTypes.arrayOf(PropTypes.object).isRequired,
    customFieldOptionalFields: PropTypes.object,
    customFieldRequiredValues: PropTypes.object,
    optionalSimpleFields: PropTypes.object,
    requireAllFields: PropTypes.object,
    registrationPagePreText: PropTypes.string,
    defaultRegistrationPage: PropTypes.object,
    uniqueWidgetId: PropTypes.string,
    campaign: PropTypes.object,
    t: PropTypes.func.isRequired,
}

UserInfoFormSection.defaultProps = {
    requireAllFields: true,
}

export default connect(selectFormFields)(UserInfoFormSection)
