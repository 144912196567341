import React from "react"
import { Col } from "react-bootstrap"
import styled from "styled-components"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { styleInMobileScreenSize, styleInDesktopScreenSize } from "QuorumGrassroots/styled-components/helperFunctions"
import {
    overrideCampaignWrapperStyle,
    overrideCampaignDescriptionStyle,
} from "QuorumGrassroots/widgets/styled-components/helperFunctions"
import { constants } from "QuorumGrassroots/constants"

export const StyledCampaignWrapper = styleWithOrgDesign(styled.div`
    ${(props) => overrideCampaignWrapperStyle(props)}
    ${(props) =>
        `
        padding: 24px 9px; /* 9px to work with the 15px deeply nested in bootstrap.css */
        @media(min-width: 992px) {
            padding: 40px;
            display: flex;
            gap: 24px;
            flex-direction: ${
                props.layout === DjangIO.app.grassroots.campaign.types.CampaignPageLayout.two_rows.value
                    ? "column"
                    : "row"
            };
        }
        border-radius: 8px;
        max-width: 1280px;
        margin: auto;
    `}
    ${(props) => props.isStandalone && styleInMobileScreenSize(`padding: 24px 9px;`)}

    ${(props) =>
        props.layout === DjangIO.app.grassroots.campaign.types.CampaignPageLayout.two_columns.value &&
        `
        min-height: calc(100dvh - 15rem);
    `}

    ${(props) =>
        props.isSingleColumnLayout &&
        `
        display: flex;
        justify-content: center;
        background: transparent;

        padding: 0 !important;

        @media (max-width: ${constants.mobileWidth + 1}px) {
            margin-top: 24px;
        }

        & .control-label {
            color: black !important;
        }

        & .update-info-text-alert {
            color: ${props.organizationDesign.black ?? "black"};
            background-color: ${props.organizationDesign.light_grey};
            border-color: ${props.organizationDesign.lighter_primary};
        }
    `}
`)

export const StyledCampaignColumn = styled(Col)`
    padding-left: 0px;
    padding-right: 0px;
`

export const DescriptionFadeOutOverlay = styled.div`
    ${(props) => {
        const backgroundColor =
            props.backgroundStyle === DjangIO.app.grassroots.enums.ActionCenterBackgroundStyle.color.value &&
            props.campaignBackgroundColor
                ? props.campaignBackgroundColor
                : "#ffffff"
        return styleInMobileScreenSize(`
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: ${props.previewHeight};
            background: linear-gradient(to bottom, transparent, ${backgroundColor});
        `)
    }}
`

export const StyledDescription = styleWithOrgDesign(styled.div`
    ${(props) =>
        props.shouldThank &&
        styleInMobileScreenSize(`
        display: none;
    `)}
    ${(props) => overrideCampaignDescriptionStyle(props)}

    ${(props) =>
        props.isCampaignPage &&
        props.activateNGGTheme &&
        `
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 145%;
        img {
            max-width: 100%;
        }
    }

    `}
        ${(props) =>
        props.isCampaignPage &&
        props.activateNGGTheme &&
        !props.showFullDescriptionOnMobile &&
        styleInMobileScreenSize(`
            max-height: ${props.previewHeight};
            overflow: hidden;
        `)}
`)

const StyledDescriptionWrapper = styled.div`
    position: relative;
`

export const StyledCampaignDescription = (props) => {
    const previewHeight = "72px"
    return props.isCampaignPage && props.activateNGGTheme ? (
        <StyledDescriptionWrapper>
            <StyledDescription {...props} previewHeight={previewHeight} />
            {!props.showFullDescriptionOnMobile && (
                <DescriptionFadeOutOverlay
                    className="description-overlay"
                    previewHeight={previewHeight}
                    backgroundStyle={props.backgroundStyle}
                    campaignBackgroundColor={props.campaignBackgroundColor}
                />
            )}
        </StyledDescriptionWrapper>
    ) : (
        <StyledDescription {...props} />
    )
}

export const StyledRegulationsFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
`

export const StyledShowMoreButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    color: ${(props) =>
        props.backgroundStyle === DjangIO.app.grassroots.enums.ActionCenterBackgroundStyle.color.value
            ? "inherit"
            : constants.freshDarkGrey};
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    padding: 0;
    width: 100%;
    margin: auto;
    cursor: pointer;
    ${styleInDesktopScreenSize(`
        display: none;
    `)}
    i {
        padding: 0 5px 0 5px;
        font-size: 12px;
        color: ${(props) =>
            props.backgroundStyle === DjangIO.app.grassroots.enums.ActionCenterBackgroundStyle.color.value
                ? "inherit"
                : constants.freshDarkGrey};
    }
`
