import { Nav, Navbar, NavDropdown } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import styled from "styled-components"

import { shadeColor } from "app/static/frontend/imports/desktopHelperFunctions"
import { generateMediaUrl } from "shared/imports/sharedHelperFunctions"
import { constants } from "QuorumGrassroots/constants"
import {
    getBackgroundColorForWhiteHeader,
    getHeaderBackgroundColorRuleMinusSemiColon,
    styleHeaderImageOrColor,
    styleWithOrgDesignHelper,
} from "QuorumGrassroots/styled-components/helperFunctions"

const backgroundColorTransition = `
    transition: background-color 0.2s ease-out;
    -o-transition: background-color .2s ease-out;
    -ms-transition: background-color .2s ease-out;
    -moz-transition: background-color .2s ease-out;
    -webkit-transition: background-color .2s ease-out;
`

const fontSize = "18px"

const borderRadius = "6px"

const StyledNavComponent = styled(Nav)`
    list-style-type: none;
    @media (min-width: ${constants.mobileWidth + 1}px) {
        display: flex;
        align-items: center;
    }

    @media (max-width: ${constants.mobileWidth}px) {
        border-bottom: 1px solid ${(props) => props.organizationDesign.light_grey};
        float: none;
        margin: 0px;
    }
`
export const StyledNav = styleWithOrgDesignHelper(StyledNavComponent)

const openDropdownMenuStyling = `
    display: block;
    left: unset;
    right: 0px;

    @media (min-width: ${constants.mobileWidth + 1}px) {
        width: fit-content;
        min-width: 170px;

        a:first-child {
            border-top-left-radius: ${borderRadius};
            border-top-right-radius: ${borderRadius};
        }

        a:last-child {
            border-bottom-left-radius: ${borderRadius};
            border-bottom-right-radius: ${borderRadius};
        }

        padding-top: 4px;
        background-color: transparent;
        border: none;
    }
`

const StyledNavDropdownComponent = styled(NavDropdown)`
    margin: 23px 5px;
    border-radius: ${borderRadius};
    font-size: ${fontSize};
    display: flex;

    .dropdown-toggle {
        ${backgroundColorTransition}
        border-radius: 6px;
        color: white !important;
        padding: 10px 15px;
        white-space: nowrap;
        background-color: transparent !important;
        position: relative;
        display: block;
        line-height: 20px;

        &:hover,
        &:active,
        &:focus {
            color: ${(props) => props.organizationDesign.light_grey} !important;
            background-color: ${(props) => props.organizationDesign.secondary_color} !important;
            text-decoration: none;

            @media (max-width: ${constants.mobileWidth}px) {
                background-color: ${(props) => props.organizationDesign.lighter_primary} !important;
            }
        }

        @media (max-width: ${constants.mobileWidth}px) {
            ${(props) => `${getHeaderBackgroundColorRuleMinusSemiColon(props)} !important;`}
            padding-left: 25px;
            border-radius: 0px;

            ${(props) => getBackgroundColorForWhiteHeader(props)}
        }

        ${(props) => getBackgroundColorForWhiteHeader(props)}
    }

    // Clicking dropdown menu will open menu items
    &.open .dropdown-menu {
        ${openDropdownMenuStyling}
    }

    // On desktop screen size, hovering will open dropdown menu
    &:hover .dropdown-menu {
        @media (min-width: ${constants.mobileWidth + 1}px) {
            ${openDropdownMenuStyling}
        }
    }

    & .dropdown-menu {
        padding: 0px;
        border-radius: ${borderRadius};
    }

    &.more-nav-dropdown {
        display: inherit;
    }

    @media (max-width: ${constants.mobileWidth}px) {
        margin: 0px;
        float: none !important;
        position: relative;
        display: block;
    }
`
export const StyledNavDropdown = styleWithOrgDesignHelper(StyledNavDropdownComponent)

export const StyledNavbar = styled(Navbar)`
    margin-bottom: 0px;
    background: transparent;
    border: none;
    width: 100%;
    z-index: 1000;

    .container {
        display: flex;
        padding: 0px;
        width: 100%;
        ${(props) =>
            props.logoOnly &&
            `
            justify-content: center;
            padding: 0px;
        `}

        @media (max-width: ${constants.mobileWidth}px) {
            flex-direction: column;
        }
    }

    ${(props) =>
        props.isCampaignPage &&
        props.logoOnly &&
        `
        min-height: auto;
    `}
`

const linkStyling = (props) => `
    ${backgroundColorTransition}
    margin: 20px 5px;
    border-radius: ${borderRadius};
    font-size: ${fontSize};
    color: white;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    ${props.login ? `background-color: ${props.organizationDesign.secondary_color};` : ""}

    :hover, :focus {
        color: ${props.organizationDesign.light_grey};
        text-decoration: none;

        @media (min-width: ${constants.mobileWidth + 1}px) {
            background-color: ${
                props.login
                    ? shadeColor(props.organizationDesign.secondary_color, 10)
                    : props.organizationDesign.secondary_color
            };
        }
    }


    @media (max-width: ${constants.mobileWidth}px) {
        ${
            props.login
                ? `
                display: flex;
                justify-content: center;
                margin: 10px 40px 10px 25px;

                &:hover, &:focus, &:active {
                    background-color: ${shadeColor(props.organizationDesign.secondary_color, 10)};
                }
            `
                : `
                margin: 0px;
                padding: 10px 10px 10px 25px;

                &:hover, &:focus, &:active {
                    background-color: ${props.organizationDesign.lighter_primary};
                }
            `
        }
    }

    ${getBackgroundColorForWhiteHeader(props)}
`

const StyledNavLinkComponent = styled(NavLink)(linkStyling)
export const StyledNavLink = styleWithOrgDesignHelper(StyledNavLinkComponent)

export const StyledATag = styleWithOrgDesignHelper(styled.a(linkStyling))

const navbarLinkStyling = (props) => `
    ${backgroundColorTransition}
    display: block;
    color: white;
    background-color: ${props.organizationDesign.secondary_color};
    padding: 5px 10px;
    font-size: 15px;

    &:hover, &:active, &:focus {
        color: ${props.organizationDesign.light_grey};
        text-decoration: none;
        background-color: ${shadeColor(props.organizationDesign.secondary_color, 20)};
    }

    @media (max-width: ${constants.mobileWidth}px) {
        background-color: ${props.organizationDesign.primary_color};
        padding: 7px 25px 7px 35px;

        &:hover, &:active, &:focus {
            background-color: ${props.organizationDesign.lighter_primary};
        }
    }
`

const StyledNavbarNavLinkComponent = styled(NavLink)(navbarLinkStyling)
export const StyledNavbarNavLink = styleWithOrgDesignHelper(StyledNavbarNavLinkComponent)

export const StyledNavbarATag = styleWithOrgDesignHelper(styled.a(navbarLinkStyling))

export const StyledLogoWrapper = styleWithOrgDesignHelper(styled.div`
    width: ${(props) => props.maxLogoWidth}px;
    ${(props) =>
        props.logoOnly &&
        props.isCampaignPage &&
        `
        max-width: -webkit-fill-available;
    `}

    @media (min-width: ${constants.mobileWidth + 1}px) {
        max-width: ${(props) => props.maxLogoWidth}px;
        ${(props) =>
            props.logoOnly &&
            props.isCampaignPage &&
            `
            max-width: -webkit-fill-available;
        `}
    }

    @media (max-width: ${constants.mobileWidth}px) {
        width: 100%;
        ${(props) => styleHeaderImageOrColor(props)}
        background-size: cover;
        background-repeat: no-repeat;
        background-position-y: center;
        background-blend-mode: multiply;
        ${(props) =>
            props.isCampaignPage &&
            props.isTransparent &&
            `
                height: 112px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-image: url(${props.campaignBackgroundImage});
                background-size: cover;
        `}

        ${(props) =>
            props.isCampaignPage &&
            props.actionCenterSettings.navigation_bar_style_type ===
                DjangIO.app.grassroots.enums.ActionCenterNavigationBarBackgroundStyle.transparent.value &&
            `
                background-image: url(${props.campaignBackgroundImage});
                background-size: cover;
        `}
    }
`)

const getLogoBackgroundImage = (props) =>
    props.isCampaignPage &&
    props.campaignLayoutType === DjangIO.app.grassroots.campaign.types.CampaignLayoutType.standalone.value &&
    props.logoOverrideUrl
        ? generateMediaUrl(props.logoOverrideUrl)
        : generateMediaUrl(props.organizationDesign.white_logo)

export const StyledLogo = styleWithOrgDesignHelper(styled.div`
    background-image: ${(props) => `url("${getLogoBackgroundImage(props)}")`};
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: center;
    display: inline-block;

    @media (min-width: ${constants.mobileWidth + 1}px) {
        height: 70px;
        width: 150px;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-right: 15px;
        ${(props) => props.logoOnly && "margin: 0px;"}
        ${(props) => props.logoOnly && "background-position: center;"}
        ${(props) =>
            props.logoOnly &&
            props.isCampaignPage &&
            `
            width: -webkit-fill-available;
        `}
    }

    @media (max-width: ${constants.mobileWidth}px) {
        width: 50%;
        height: 35px;
        margin: 5px 20px;
        ${(props) =>
            props.isCampaignPage &&
            props.isTransparent &&
            props.logoOnly &&
            `
                margin: 0;
                background-position: center;
        `}
    }
`)

export const StyledNavbarToggleComponent = styled(Navbar.Toggle)`
    @media (min-width: ${constants.mobileWidth}px) {
        display: none;
    }

    @media (max-width: ${constants.mobileWidth}px) {
        display: inline-block;
        border: 1px solid white;

        // '&&' is to give this style preference over bootstrap style
        && .icon-bar {
            background-color: #ddd;
            height: 2px;
            width: 22px;
        }

        &&:hover,
        &&:active,
        &&:focus {
            background-color: transparent;

            border-color: white;

            .icon-bar {
                background-color: white;
            }
        }

        ${(props) => getBackgroundColorForWhiteHeader(props)}
    }
`
export const StyledNavbarToggle = styleWithOrgDesignHelper(StyledNavbarToggleComponent)

// '&&' would call the class name twice (Ex: class name is "iYOrGc", rule would evaluate to ".iYOrGc.iYOrGc")
// This is give these style rules precedence over other styling (such as bootstrap styles)
// TODO: Figure out why wrapping this styled component in 'styleWithOrgDesign' breaks functionality of navbar toggle
export const StyledNavbarCollapse = styled(Navbar.Collapse)`
    && {
        @media (min-width: ${constants.mobileWidth + 1}px) {
            display: flex !important;
            ${
                // Assuming the max logo width is 300px,
                // For any screen width smaller than 1200px (maxLogoWidth * 4), set the navbar width to 75% of screen width
                // If screen width greater than 1200px, set navbar width to screen width minus logo width (300px)
                (props) =>
                    window.innerWidth <= props.maxLogoWidth * 4
                        ? "width: 75%;"
                        : `width: calc(100vw - ${props.maxLogoWidth}px);`
            }
            justify-content: flex-end;
            padding-right: 0px;
        }

        @media (max-width: ${constants.mobileWidth}px) {
            width: 100%;
            padding-right: 0px;
            border-top: 1px solid #ecf0f1;
        }
    }

    // If screen is mobile size and user has not clicked toggle, do not show navigation options
    &.collapse:not(.in, .show) {
        @media (max-width: ${constants.mobileWidth}px) {
            display: none !important;
        }
    }

    &.collapsing {
        -webkit-transition-duration: 0s;
        -o-transition-duration: 0s;
        transition-duration: 0s;
    }

    &.collapse {
        -webkit-transition-duration: 0s;
        -o-transition-duration: 0s;
        transition-duration: 0s;
    }
`

export const StyledNavLinkWrapper = styled.div`
    @media (min-width: ${constants.mobileWidth + 1}px) {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
`

export const StyledNavInvisible = styled(StyledNav)`
    visibility: hidden;
    position: absolute;
`

export const StyledMoreDropDown = styleWithOrgDesignHelper(styled(StyledNavDropdown)`
    margin: 0px;
    float: none !important;

    .dropdown-toggle {
        display: block;
        padding-left: 10px;
        font-size: 15px;
        border-radius: 0px !important;
        background-color: ${(props) => props.organizationDesign.secondary_color} !important;
    }

    .dropdown-menu {
        padding: 0px !important;
        margin: 0px;
        display: contents !important;
    }
`)

export const StyledMoreNavbarATag = styled(StyledNavbarATag)`
    ${(props) => (props.dropdown ? "padding-left: 20px;" : "")}
    border-radius: 0px !important;
`

export const StyledMoreNavbarNavLink = styled(StyledNavbarNavLink)`
    ${(props) => (props.dropdown ? "padding-left: 20px;" : "")}
    border-radius: 0px !important;
`
