import { Campaign, CustomField } from "@/types/djangio"

export type PromptAndResponseCampaignForm = Record<string, string | string[] | boolean>

const { TagType } = DjangIO.app.person.types

const findDefaultValueForSingleSelection = (question: CustomField) => {
    const defaultValue = question?.default_string_value ?? ""
    return Object.keys(question.options_new).find((key) => question.options_new[key].value === defaultValue)
}

const findDefaultValueForMultiSelection = (question: CustomField) => {
    const defaultValues = question?.default_multi_value ?? []
    return Object.keys(question.options_new).filter((key) => defaultValues.includes(question.options_new[key].value))
}

export const getQuestionDefaultValue = (question: CustomField) => {
    switch (question.tag_type) {
        case TagType.number.value:
            return question.default_string_value
        case TagType.string.value:
            return question.default_value
        case TagType.date.value:
            return question.default_string_value
        case TagType.boolean.value:
            return question.default_boolean_value
        case TagType.single_option_list.value:
            return findDefaultValueForSingleSelection(question)
        case TagType.multi_options_list.value:
            return findDefaultValueForMultiSelection(question)
        default:
            return ""
    }
}

export const getFormDefaultValues = (campaign: Campaign) => {
    if (!campaign?._extra?.personalized_campaign_pages?.[0]) return {}

    return campaign._extra.personalized_campaign_pages[0].reduce((acc, question) => {
        acc[question.slug] = getQuestionDefaultValue(question)
        return acc
    }, {})
}

export const validateFormValues = (values: PromptAndResponseCampaignForm, questions: CustomField[]) => {
    const errors = {}

    questions.forEach((question) => {
        if (question.is_required && !valueIsFilled(values[question.slug], question.tag_type)) {
            errors[question.slug] = "This field is required"
        }
    })

    return errors
}

const valueIsFilled = (value, tag_type) => {
    switch (tag_type) {
        case TagType.boolean.value:
            return value !== null
        case TagType.multi_options_list.value:
            return value.length > 0
        default:
            return !!value
    }
}
