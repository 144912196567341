import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"
import styled from "styled-components"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import {
    styleWithOrgDesignHelper,
    getFontColorForBackgroundContrast,
} from "QuorumGrassroots/styled-components/helperFunctions"
import { shadeColor } from "app/static/frontend/imports/desktopHelperFunctions"

export const errorButtonStyling = (props) => `
    font-size: 22px;
    padding: 15px 20px;
    border-radius: 4px;
    background-color: ${props.organizationDesign.secondary_color};
    color: white !important;
    width: 100%;
    border: 1px solid ${props.organizationDesign.secondary_color} !important;
`

const StyledErrorButtonComponent = styled(Button)`
    ${(props) => errorButtonStyling(props)}

    &:hover, &:active, &:focus {
        background-color: ${(props) => shadeColor(props.organizationDesign.secondary_color, -10)} !important;
        color: white !important;
    }
`
export const StyledErrorButton = styleWithOrgDesignHelper(StyledErrorButtonComponent)

export const StyledPaddedContent = styled.div`
    padding: 10px 15px;
`

export const socialButtonBackgroundColor = (props) => {
    let backgroundColor
    let hoverColor

    const { SocialMediaType } = DjangIO.app.grassroots.enums

    switch (props.platform) {
        case SocialMediaType.facebook.value:
            backgroundColor = "#3b5998"
            hoverColor = "#293e6a"
            break
        case SocialMediaType.twitter.value:
            backgroundColor = "#14171a"
            hoverColor = "#aab8c2"
            break
        case SocialMediaType.linkedin.value:
            backgroundColor = "#0177b5"
            hoverColor = "#01537f"
            break
        case SocialMediaType.pinterest.value:
            backgroundColor = "#d8121d"
            hoverColor = "#970d14"
            break
        default:
            break
    }

    return `
        background-color: ${backgroundColor};
        border: 1px solid ${backgroundColor} !important;

        &:hover, &:active, &:focus {
            background-color: ${hoverColor} !important;
            outline: none;
        }
    `
}

export const socialButtonIconColor = (props) => {
    let color

    const { SocialMediaType } = DjangIO.app.grassroots.enums

    switch (props.platform) {
        case SocialMediaType.facebook.value:
            color = "#3b5998"
            break
        case SocialMediaType.twitter.value:
            color = "#4099ff"
            break
        case SocialMediaType.linkedin.value:
            color = "#0177b5"
            break
        default:
            break
    }

    return `
        color: ${color};
    `
}

export const StyledSocialButton = styled(Button)`
    ${(props) => socialButtonBackgroundColor(props)}
    font-size: 17px;
    color: white !important;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 10px;

    &:hover,
    &:active,
    &:focus {
        color: white !important;
    }

    &:nth-child(odd) {
        padding-right: 10px;
    }

    &:nth-child(even) {
        padding-left: 10px;
    }

    i {
        width: 30px;
    }
`

export const StyledLargeBoldText = styleWithOrgDesign(styled.div`
    align-text: center;
    font-size: 24px;
    font-weight: 700;
    color: ${(props) => getFontColorForBackgroundContrast(props.organizationDesign.primary_color)};
    display: flex;
    align-items: center;
`)

export const StyledSubheaderText = styleWithOrgDesign(styled.div`
    align-text: center;
    font-size: 18px;
    font-weight: 700;
    color: ${(props) => getFontColorForBackgroundContrast(props.organizationDesign.primary_color)};
    display: flex;
    align-items: center;
    margin-top: 15px;
`)

export const FlexRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justifyContent || "flex-start"};
    margin: ${(props) => props.margin || "0px"};
`

export const SpaceBetweenFlexRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin: ${(props) => props.margin || "0px"};
`

export const StyledIcon = styleWithOrgDesign(styled.i`
    font-size: ${(props) => props.fontSize || "24px"};
    font-weight: ${(props) => props.fontWeight || "normal"};
    color: ${(props) => props.color || props.organizationDesign.primary_color};
    padding: ${(props) => props.padding || "0px"};
    margin: ${(props) => props.margin || "0px"};

    ${(props) =>
        props.hoverable &&
        `
        &:hover {
            opacity: 0.6;
            cursor: pointer;
        }`}

    &.fa-check-square-o {
        margin-right: -3px;
    }
`)

export const StyledBox = styleWithOrgDesign(styled.div`
    display: flex;
    align-items: center;

    border-radius: 4px;
    border: 1px solid ${(props) => props.organizationDesign.primary_color};
    background-color: ${(props) => props.backgroundColor || props.organizationDesign.light_grey};

    margin: ${(props) => props.margin || "0px"};
    min-height: ${(props) => props.minHeight || "0px"};
    height: ${(props) => props.height || "auto"};
    padding: ${(props) => props.padding || "0px"};

    &.disabled {
        opacity: 0.4;
        cursor: ${(props) => (props.hover ? "pointer" : "default")};
    }

    &.hoverable :hover {
        background-color: ${(props) => shadeColor(props.organizationDesign.light_grey, -10)};
    }
`)

export const LineClampText = styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => props.lineClamp || 2};
    -webkit-box-orient: vertical;
    max-width: ${(props) => props.maxWidth || "none"};
`

const linkStyling = `
    color: white;
    &:hover {
        text-decoration: none;
        color: white;
    }
`

export const StyledLink = styled(Link)`
    ${linkStyling}
`

export const StyledAnchor = styled.a`
    ${linkStyling}
`

const cardLinkStyling = `
    color: inherit;
    display: flex;
    width: 100%;
    height: 100%;
    &:hover {
        text-decoration: none;
        color: inherit;
        background-color: rgba(0, 0, 0, 0.1);
    }
`

export const StyledCardLink = styled(Link)`
    ${cardLinkStyling}
    ${(props) => (props.isSingle ? "flex-direction: row;" : "flex-direction: column")}
`

export const StyledCardAnchor = styled.a`
    ${cardLinkStyling}
    ${(props) => (props.isSingle ? "flex-direction: row;" : "flex-direction: column")}
`
