import React from "react"

import { CustomField } from "@/types/djangio"
import { useFormReturn } from "QuorumGrassroots/campaign-forms/hooks/useForm"
import { PromptAndResponseCampaignForm } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/PersonalizedMessages.helper"
import { mountOptions } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/SingleSelectionField"
import { MultiSelect } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/MultiSelectionField.styles"

interface Props {
    question: CustomField
    form: useFormReturn<PromptAndResponseCampaignForm>
    disabled?: boolean
}

export const MultiSelectionField = ({ question, form, disabled = false }: Props) => {
    const options = mountOptions(question)

    return (
        <MultiSelect
            label={question.external_name}
            value={form.values[question.slug]}
            onChange={(value) => form.setFieldValue(question.slug, value, true)}
            required={question.is_required}
            error={form.errors[question.slug]}
            data={options}
            disabled={disabled}
            clearable
        />
    )
}
