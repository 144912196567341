import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { Col } from "react-bootstrap"
import styled from "styled-components"

import GridToggleSelectField from "app/static/frontend/forms/components/GridToggleSelectField"
import { constants } from "QuorumGrassroots/constants"

const NewThemeStyledPeopleSelectorWrapper = `
    .quorum-obj-selector {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-height: 44px;

        background-color: white;

        width: 100%;
        max-width: 100%;
    }

    .quorum-obj-selector .quorum-selected-objects {
        width: auto;

        padding-top: 0;
        padding-bottom: 0;

        max-height: unset !important;
        min-height: unset !important;
    }

    .quorum-obj-selector .quorum-selected-objects .quorum-tag {
        background-color: #f1f3f5;

        border-radius: 4px;
        border: none;

        margin: 4px;
        padding: 0;
    }

    .quorum-obj-selector .quorum-selected-objects .quorum-tag .quorum-tag-button {
        color: #495057;
        font-size: 14px;

        display: flex;
        align-items: center;
        gap: 8px;
        padding: 6px 10px;
    }

    .quorum-obj-selector .quorum-selected-objects .quorum-tag .quorum-tag-button .tag {
        position: initial;
        transform: none;
    }

    .quorum-obj-selector .quorum-selected-objects .quorum-tag .quorum-tag-button .tag .remove-tag {
        display: none;
    }

    .quorum-obj-selector .quorum-selected-objects .quorum-tag .quorum-tag-button .tag .remove-tag-new-theme {
        display: initial;

        font-size: 20px;
        font-weight: 500;

        line-height: 0px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .quorum-obj-selector .quorum-selected-objects .quorum-tag .quorum-tag-button .quorum-tag-text-container {
        display: flex;

        .quorum-tag-text {
            margin-right: 0;
        }
    }

    .quorum-obj-selector .quorum-typeahead-div {
        width: 100%;
        position: relative;
    }
    .quorum-obj-selector .quorum-typeahead-div .quorum-tag-obj-search {
        padding-left: 8px;
        width: 100%;

        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top: none;
    }

    .quorum-obj-selector .quorum-typeahead-div .quorum-typeahead-results {
        width: 100%;
    }

    .quorum-obj-selector .quorum-typeahead-div .quorum-typeahead-results .list-group .list-group-item {
        color: inherit !important;
        font-size: 14px;

        border: none;
        border-top: none;
        border-bottom: none;
    }

    .quorum-obj-selector .quorum-typeahead-div .quorum-typeahead-results .list-group .group-label > i {
        font-style: normal;
    }

    .quorum-obj-selector .quorum-typeahead-div .quorum-typeahead-results .list-group .list-group-item:hover {
        background-color: #ebeafd !important;
    }
`

export const StyledPeopleSelectorWrapper = styleWithOrgDesign(styled.div`
    .quorum-obj-selector {
        border: 1px ${constants.inputFieldBorderColor} solid;

        .quorum-selected-objects {
            background-color: white;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;

            .purple-tag-1 a {
                color: ${(props) => props.organizationDesign.primary_color};
            }
        }

        .quorum-tag-obj-search {
            background-color: white;
        }

        .quorum-typeahead-div {
            position: relative;
        }

        .quorum-typeahead-div .quorum-typeahead-results {
            width: 100%;
        }

        .quorum-typeahead-div .quorum-typeahead-results .list-group .list-group-item {
            color: ${(props) => props.organizationDesign.primary_color};

            :not(.group-label):hover {
                background-color: ${(props) => props.organizationDesign.primary_color};
            }
        }
    }

    .quorum-obj-selector .quorum-selected-objects .quorum-tag .quorum-tag-button .tag .remove-tag-new-theme {
        display: none;
    }

    ${(props) => props.activateNewNGGTheme && NewThemeStyledPeopleSelectorWrapper}
`)

export const StyledDateTimeCol = styled(Col)`
    .form-control {
        font-size: 16px;
        height: 44px;
        border: thin solid ${constants.inputFieldBorderColor};
        position: relative;
        bottom: 2px;
    }

    .Select__control {
        border: thin solid ${constants.inputFieldBorderColor} !important;
    }

    .rdtPicker {
        min-width: 100%;
    }
`

export const StyledGridToggleSelectField = styleWithOrgDesign(styled(GridToggleSelectField)`
    .grid-toggle .gridtoggle-button {
        height: 38px;
        outline: none;
        border: thin solid ${constants.inputFieldBorderColor};

        &:active:focus {
            color: white;
            background-color: ${(props) => props.organizationDesign.primary_color};
        }
    }

    .grid-toggle-icon {
        padding-left: 0px !important;
    }

    .grid-toggle-label-with-icon {
        margin-left: 20px;
    }
`)
