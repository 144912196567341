import React from "react"
import { Field } from "redux-form/immutable"

import PageTemplate from "QuorumGrassroots/framework/components/Foundations/PageTemplate"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"

import InputFieldComponent from "app/static/frontend/forms/components/InputField"
import { requiredFieldValidation } from "QuorumGrassroots/helperFunctions"

// needs logic for if the key is expired
//

export const ResetPasswordPage = ({ t, ...props }) => {
    return (
        <PageTemplate className="reset-password-page" title={t("registration.password.reset.label")}>
            <form onSubmit={props.handleSubmit}>
                <Field
                    name="password"
                    dataCy="password"
                    label={t("registration.password.label")}
                    component={InputFieldComponent}
                    placeholder={t("registration.password.label")}
                    displayErrorWithoutTouch={false}
                    validate={requiredFieldValidation}
                    type="password"
                />
                <Field
                    name="passwordConfirm"
                    dataCy="passwordConfirm"
                    label={t("registration.password.reenter_label")}
                    component={InputFieldComponent}
                    placeholder={t("registration.password.reenter_label")}
                    displayErrorWithoutTouch={false}
                    validate={requiredFieldValidation}
                    type="password"
                />
                <StyledButton onClick={props.handleSubmit} disabled={props.invalid || props.submitting} type="submit">
                    {props.submitting ? t("form.submitting") : t("form.submit_form")}
                </StyledButton>
            </form>
        </PageTemplate>
    )
}

export default ResetPasswordPage
