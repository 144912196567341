import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import {
    YoutubeIFrameWrapper,
    PetitionFormWrapper,
} from "QuorumGrassroots/campaign-forms/components/PetitionForm/style"
import { getVimeoLink, getYoutubeVideoLinkId } from "QuorumGrassroots/campaign-forms/components/PetitionForm/helper"
import { vimeoVideoIdRegex, youtubeVideoIdRegex } from "shared/imports/regex"
import { StyledContrastText } from "QuorumGrassroots/styled-components/components/StyledContrastText"

export const PetitionForm = (props) => {
    const { campaign } = props

    const videoLink = campaign ? campaign.required_video_link : ""

    const vimeoLink = getVimeoLink(videoLink)
    const youtubeLinkId = getYoutubeVideoLinkId(videoLink)

    const videoCompletionRequired = Boolean(
        videoLink &&
            // Check that the link is a valid Youtube or Vimeo link, as well as that the respective API is loaded
            // Common failure state are IE browsers. The Youtube player API is not compatible with IE
            ((window.Vimeo && vimeoVideoIdRegex.test(videoLink)) || (window.YT && youtubeVideoIdRegex.test(videoLink))),
    )

    const isSubmitButtonDisabled = videoCompletionRequired || props.isSubmitting

    useEffect(() => {
        if (vimeoLink && window.Vimeo) {
            initializeVimeoVideo()
        }

        if (youtubeLinkId && window.YT) {
            initializeYoutubeVIdeo()
        }

        if (props.shouldSubmitOneClickRegistration) {
            props.disableOneClickRegistration()
            submit()
        }
    })

    const initializeVimeoVideo = () => {
        const iframe = document.querySelector("#custom-vimeo-video")

        const vimeoplayer = new window.Vimeo.Player(iframe, { background: true })

        vimeoplayer.on("ended", () => {
            // When video ends, call action creator that advocate has finished video and can submit campaign
            props.completeRequiredVideo(props.uniqueWidgetId)
        })

        let curtime = 0

        vimeoplayer.on("timeupdate", (data) => {
            // Only update current time if the 'new/updated' time is less than a second ago.
            if (data.seconds < curtime + 1 && data.seconds > curtime) {
                curtime = data.seconds
            }
        })

        vimeoplayer.on("seeked", (data) => {
            // If advocate seeks forward, set the Vimeo player time back to their previous timestamp (to prevent skipping video)
            // If advocate seeks backward to replay video, allow the Vimeo player timestamp to be adjusted
            if (data.seconds > curtime) {
                vimeoplayer.setCurrentTime(curtime)
            }
        })
    }

    const initializeYoutubeVIdeo = () => {
        const onStateChange = (event) => {
            // Listen on Youtube player events. If video has ended, call action creator and advocate can submit campaign
            if (event.data === window.YT.PlayerState.ENDED) {
                props.completeRequiredVideo(props.uniqueWidgetId)
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const player = new window.YT.Player("custom-youtube-player", {
            height: "100%",
            width: "100%",
            videoId: youtubeLinkId,
            playerVars: {
                controls: 0,
                fs: 1,
                disablekb: 1,
                rel: 0,
            },
            events: { onStateChange },
        })
    }

    const submit = () =>
        props.postGrassrootsSupporterAction({
            uniqueWidgetId: props.uniqueWidgetId,
            campaign: props.campaign,
            shouldCompleteCampaign: true,
        })

    const getSubmitButtonProps = () => {
        const defaultProps = {
            onClick: submit,
            disabled: isSubmitButtonDisabled,
            "data-cy": "sign",
            isCampaignPage: true,
            activateNGGTheme: true,
        }
        if (!props.isMultiAction) return defaultProps
        return {
            ...defaultProps,
            disabled: props.isLoading,
            onClick: () => props.customSubmit(props.campaign),
        }
    }

    return (
        <PetitionFormWrapper>
            {youtubeLinkId && window.YT && (
                <YoutubeIFrameWrapper classname="youtube-iframe" id="custom-youtube-player" />
            )}
            {
                // If Youtube Player API is not loaded (most likely because of IE browser), render a normal youtube embed
                youtubeLinkId && !window.YT && (
                    <YoutubeIFrameWrapper classname="youtube-iframe" id="custom-youtube-player">
                        <iframe
                            width="100%"
                            height="100%"
                            src={`https://www.youtube.com/embed/${youtubeLinkId}`}
                            title="petition-form-youtube-video"
                        />
                    </YoutubeIFrameWrapper>
                )
            }
            {vimeoLink && (
                <iframe
                    title="custom-vimeo-video"
                    id="custom-vimeo-video"
                    src={vimeoLink}
                    width="100%"
                    height="360"
                    frameBorder="0"
                    allow="fullscreen"
                    allowFullscreen
                />
            )}
            <StyledContrastText isCampaignPage>{props.campaign && props.campaign.pos_message}</StyledContrastText>
            <StyledButton {...getSubmitButtonProps()}>
                {(props.campaign && props.campaign.custom_call_to_action_button_text) ||
                    props.t("campaign.petition.text")}
            </StyledButton>
        </PetitionFormWrapper>
    )
}

PetitionForm.propTypes = {
    campaign: PropTypes.object.isRequired,
    completeRequiredVideo: PropTypes.func.isRequired,
    disableOneClickRegistration: PropTypes.func.isRequired,
    postGrassrootsSupporterAction: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    uniqueWidgetId: PropTypes.string.isRequired,
}
