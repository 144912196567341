import isFeatureEnabled from "shared/featureflags/helperFunctions"

const convertRegionAbbrevToValue = (abbrev) => {
    // find the region that has the same abbreviation
    const regions = DjangIO.app.models.SupportedRegion.items().filter((item) => item.abbrev === abbrev)

    // if we found it, return the value of the first one
    if (regions.length) {
        return regions[0].value
    }
}

// currently has any of the given regions
export const checkHasRegion = (regions) =>
    regions.length === 0 ||
    regions.some((region) => Userdata.current_regions_values && Userdata.current_regions_values.includes(region))

// is allowed to be in any of the given regions
export const checkAllowedRegion = (regions) =>
    regions.length === 0 ||
    regions.some((region) => Userdata.allowed_regions_values && Userdata.allowed_regions_values.includes(region))

// is allowed to be in every one of thee given regions
export const checkAllowedEveryRegion = (regions) =>
    regions.length === 0 || regions.every((region) => checkAllowedRegion([region]))

// checks if user does not have any of the given regions given in their current_regions
// (i.e. returns true if Userdata.current_regions_values does NOT contain _any_ of the given regions)
export const checkDoesNotHaveAnyRegion = (regions) => regions.length === 0 || !checkHasRegion(regions)

// Checks if the user's current regions are fully contained by the `regions` given
export const checkHasOnlyRegionsWithin = (regions) =>
    Userdata.current_regions_values.length !== 0 &&
    Userdata.current_regions_values.every((region) => regions && regions.includes(region))

export const checkHasExactRegions = (regions) =>
    checkHasOnlyRegionsWithin(regions) && Userdata.current_regions_values.length === regions.length

// checks if any of the inputRegions are contained within the checkRegions array
export const checkRegionsWithinRegions = (inputRegions, checkRegions) =>
    inputRegions.some((region) => checkRegions.includes(region))

/**
 * Helper function to return an array of regions permissioned by the QuorumDataType's permission_enum field
 * If 'permission_enum' contains a permission value with an array of region values (e.g. qp_legislative_profiles), return that
 * If 'permission_enum' contains two different permissions, return an array containing all region values of both permissions
 * If 'permission_enum' only contains boolean permission values (e.g. qp_internal_relationship), return Userdata.allowed_regions_values
 * If 'permission_enum' is None or an array of only PermissionType.no_permissions_needed, return Userdata.allowed_regions_values
 *
 * @name regionsFromDataTypePermission
 * @function
 * @param {Selector} quorumDataTypeEnum - QuorumDataType enum object
 * @param {any} defaultVal
 * @returns {Array} Array of region values related to the permission enum, or allowed_regions_values
 */
export const regionsFromDataTypePermission = (quorumDataTypeEnum) => {
    const permissionEnums = quorumDataTypeEnum.permission_enum
    let booleanPermissionsCount = 0

    if (permissionEnums) {
        const allRegionValues = permissionEnums
            .map((permissionEnumValue) => {
                // For each permission enum, instantiate the label (e.g. 'qp_legislative_profiles')
                // Instantiate the permission value from Userdata.Permissions
                const permissionEnumLabel = DjangIO.app.models.PermissionType.by_value(permissionEnumValue).label
                const permissionsValue = Userdata.Permissions[permissionEnumLabel]

                // Check if permission enum value is 'No Permission Needed' or permissions value is a boolean
                if (
                    permissionEnumValue === DjangIO.app.models.PermissionType.no_permission_needed.value ||
                    typeof permissionsValue === "boolean"
                ) {
                    // increment boolean permission count and return empty regions array
                    booleanPermissionsCount += 1
                    return []
                } else {
                    // Return array of permissioned regions
                    return permissionsValue
                }
            })
            .reduce((acc, regionValues) => acc.concat(regionValues), [])

        // Check if all permission values are booleans instead of arrays of region values
        if (booleanPermissionsCount === permissionEnums.length) {
            // If all permissions were booleans, return 'allowed_region_values'
            return Userdata.allowed_regions_values
        } else {
            // Only return distinct values by changing array to a set and back to an array
            return [...new Set(allRegionValues)]
        }
    } else {
        // If QuorumDataType's permission_enum field is None, return 'allowed_regions_values'
        return Userdata.allowed_regions_values
    }
}

/**
 * This function takes a permissionEnum and an optional dictionary of arguments
 * The optional dictionary may contain:
 *   - a permissions object that should mirror Userdata.Permissions
 *   - an array of regions that can restrict the regions further than just by the
 *     currentRegions of a user. This might be the case if the item in question itself
 *     only applies to certain regions within the current regions
 *   - excludedRegions: an array of regions that the user's current regions must be fully
 *     contained by in order for checkPermission to return true (rather than `regions` which checks that
 *     user is currently in any region that is supported)
 * The function returns a boolean of whether or not a user is allowed to see a certain
 * thing on the page
 *
 * @param {Object} permissionEnum - Permission enum item
 * @param {Object} [options] - Options object
 * @param {Object} [options.permissions] - Array of Permission enum items
 * @param {number[]} [options.regions] - Array of Region enum items
 * @param {Boolean} [options.useCurrentRegions] - Boolean to use the User's current regions
 * @param {number[]} [options.excludedRegions] - Array of excluded Region enum items
 *
 * @deprecated Please use `hasAnyPermission` instead, it is almost an exact replacement with a less ambiguous name
 */
export const checkPermission = (
    permissionEnum,
    { permissions = Userdata.Permissions, regions = null, useCurrentRegions = false, excludedRegions = null } = {
        permissions: Userdata.Permissions,
        regions: null,
        useCurrentRegions: false,
        excludedRegions: null,
    },
) => {
    return checkPermissionEnum(permissionEnum, { permissions, regions, useCurrentRegions, excludedRegions })
}

/**
 * This function is intentionally NOT exported - use hasAnyPermission or hasAllPermissions instead
 */
const checkPermissionEnum = (
    permissionEnum,
    { permissions = Userdata.Permissions, regions = null, useCurrentRegions = false, excludedRegions = null } = {
        permissions: Userdata.Permissions,
        regions: null,
        useCurrentRegions: false,
        excludedRegions: null,
    },
) => {
    // check that user is not in an excluded region
    if (excludedRegions && useCurrentRegions && !checkDoesNotHaveAnyRegion(excludedRegions)) {
        return false
    } else if (excludedRegions && !useCurrentRegions && checkAllowedRegion(excludedRegions)) {
        return false
    }

    // SidebarItems have permission names by default, some don't need to be
    // restricted
    const permissionName = permissionEnum.label

    if (permissionName === DjangIO.app.models.PermissionType.no_permission_needed.label) {
        return true
    }

    // handle both objects and Maps passed in
    const permission =
        permissions.constructor === Object ? permissions[permissionName] : permissions.get(permissionName)

    if (typeof permission === "boolean") {
        return permission
    } else {
        let usedRegions = []

        const firstRegion = Userdata.current_regions[0]
        const firstRegionItem = DjangIO.app.models.SupportedRegion.by_value(convertRegionAbbrevToValue(firstRegion))
        // trying to see if current_regions is a major SupportedRegion group like "all", "eu", "states", etc.
        if (firstRegionItem && firstRegionItem.child_regions) {
            // if that's true, get the corresponding region codes
            usedRegions = firstRegionItem.child_regions
        } else {
            // otherwise get current_regions directly by looking up abbrevs
            usedRegions = Userdata.current_regions.map((region) => convertRegionAbbrevToValue(region))
        }

        if (regions && useCurrentRegions) {
            // find intersection of current regions and the regions passed in addition
            usedRegions = usedRegions.filter((currentRegion) => regions.includes(currentRegion))
        } else if (regions) {
            // just use regions passed in instead of current regions
            usedRegions = regions
        }

        // find intersection of current_regions and permission regions
        const intersection = usedRegions.filter((usedRegion) => permission && permission.includes(usedRegion))
        if (intersection.length) {
            return true
        }
        return false
    }
}

/**
 * @name permissionFilter
 * @function
 *
 * Determines wether a user has permissions to an enum item given enum and set of
 * permissions
 *
 * @param {Object} item - Enum item
 * @param {Object} [options] - Options object
 * @param {Object} [options.permissions] - Array of Permission enum items
 * @param {Array} [options.regions] - Array of Region enum items
 * @param {Boolean} [options.useCurrentRegions] - Boolean to use the User's current regions
 * @param {Array} [options.excludedRegions] - Array of excluded Region enum items
 * @returns {Boolean} Boolean of whether or not a user is allowed to see a certain thing on the page
 * @example
 * permissionFilter(item, { permissions, regions, useCurrentRegions, excludedRegions })
 *
 */
export const permissionFilter = (
    /** @type {any} */
    item,
    /** @type {{
        permissions?: Readonly<any>
        regions?: null | undefined | ReadonlyArray<any>
        useCurrentRegions?: boolean
        excludedRegions?: boolean
    }} */
    { permissions = Userdata.Permissions, regions = null, useCurrentRegions = false, excludedRegions = null } = {
        permissions: Userdata.Permissions,
        regions: null,
        useCurrentRegions: false,
        excludedRegions: null,
    },
) => {
    if (item.permission_enum) {
        // get the permissions that the item requires
        const neededPermissions = item.permission_enum.map((permissionEnum) =>
            DjangIO.app.models.PermissionType.by_value(permissionEnum),
        )

        // check if user has ANY of the needed permissions
        const hasPermissionForItem = neededPermissions.reduce(
            (acc, neededPermission) =>
                acc || checkPermission(neededPermission, { permissions, regions, useCurrentRegions, excludedRegions }),
            false,
        )
        return hasPermissionForItem
    }
    // some items do not require any permissions
    return true
}

/**
 * This function checks if the current user is one of the personas that are passed
 * in as an Enum array
 */
export const isPersona = (personaList) => {
    const isOneOfPersonas = personaList.reduce(
        (acc, personaEnum) => acc || personaEnum.value === Userdata.persona,
        false,
    )
    return isOneOfPersonas
}

/**
 * Check if the current User/Organization has ANY of the one or more given permissions
 * This function takes either a single permissionEnum or a list of permission enums
 * and an optional dictionary of arguments
 * The optional dictionary may contain:
 *   - a permissions object that should mirror Userdata.Permissions
 *   - an array of regions that can restrict the regions further than just by the
 *     currentRegions of a user. This might be the case if the item in question itself
 *     only applies to certain regions within the current regions
 *   - excludedRegions: an array of regions that the user's current regions must be fully
 *     contained by in order for checkPermission to return true (rather than `regions` which checks that
 *     user is currently in any region that is supported)
 * The function returns a boolean if the current User has ANY of the permissions
 *
 * @param {number|Object|[number]|[Object]} permissionValues - Permission item(s), either as PermissionType enums or numeric values
 * @param {Object} [options] - Options object
 * @param {Object} [options.permissions] - Array of Permission enum items
 * @param {number[]} [options.regions] - Array of Region enum items
 * @param {Boolean} [options.useCurrentRegions] - Boolean to use the User's current regions
 * @param {number[]} [options.excludedRegions] - Array of excluded Region enum items
 * @returns {Boolean} Boolean of whether or not a user is allowed to see a certain thing on the page
 */
export const hasAnyPermission = (
    permissionValues,
    {
        permissions = Userdata.Permissions ? Userdata.Permissions : window.permissions,
        regions = null,
        useCurrentRegions = false,
        excludedRegions = null,
    } = {
        permissions: Userdata.Permissions,
        regions: null,
        useCurrentRegions: false,
        excludedRegions: null,
    },
) => {
    const permissionArray = (Array.isArray(permissionValues) ? permissionValues : [permissionValues]).filter(Boolean)
    return (
        permissionArray.length === 0 ||
        permissionArray.some((permission) => {
            const permissionEnum =
                typeof permission === "number" ? DjangIO.app.models.PermissionType.by_value(permission) : permission
            return checkPermissionEnum(permissionEnum, { permissions, regions, useCurrentRegions, excludedRegions })
        })
    )
}

/**
 *
 * @deprecated Please use `hasAnyPermission` instead, it is an exact replacement with a less ambiguous name
 */
export const checkHasPermission = (permissions, opt_args = {}) =>
    permissions.length === 0 || permissions.some((permission) => checkPermissionEnum(permission, opt_args))

export const checkHasUserPermission = (permissions) => {
    return (
        permissions.length === 0 ||
        permissions.some(
            (permission) =>
                Userdata[permission] || Userdata.Permissions[permission] || Userdata.Permissions[permission.label],
        )
    )
}

/**
 * Check if the current User/Organization has ALL of the one or more given permissions
 * This function takes either a single permissionEnum/numeric value or a list of permission enums/numeric values
 * and an optional dictionary of arguments
 * The optional dictionary may contain:
 *   - a permissions object that should mirror Userdata.Permissions (key:value pairs with string keys and boolean or region list values)
 *   - an array of regions that can restrict the regions further than just by the
 *     currentRegions of a user. This might be the case if the item in question itself
 *     only applies to certain regions within the current regions
 *   - excludedRegions: an array of regions that the user's current regions must be fully
 *     contained by in order for checkPermission to return true (rather than `regions` which checks that
 *     user is currently in any region that is supported)
 * The function returns a boolean if the current User has ANY of the permissions
 *
 * @param {number|Object|[number]|[Object]} permissionValues - Permission item(s), either as PermissionType enums or numeric values
 * @param {Object} [options] - Options object
 * @param {Object} [options.permissions] - Array of Permission enum items
 * @param {number[]} [options.regions] - Array of Region enum items
 * @param {Boolean} [options.useCurrentRegions] - Boolean to use the User's current regions
 * @param {number[]} [options.excludedRegions] - Array of excluded Region enum items
 * @returns {Boolean} Boolean of whether or not a user is allowed to see a certain thing on the page
 */
export const hasAllPermissions = (
    permissionValues,
    { permissions = Userdata.Permissions, regions = null, useCurrentRegions = false, excludedRegions = null } = {
        permissions: Userdata.Permissions,
        regions: null,
        useCurrentRegions: false,
        excludedRegions: null,
    },
) => {
    const permissionArray = (Array.isArray(permissionValues) ? permissionValues : [permissionValues]).filter(Boolean)
    return (
        permissionArray.length === 0 ||
        permissionArray.every((permission) => {
            const permissionEnum =
                typeof permission === "number" ? DjangIO.app.models.PermissionType.by_value(permission) : permission
            return checkPermissionEnum(permissionEnum, { permissions, regions, useCurrentRegions, excludedRegions })
        })
    )
}

/**
 * @deprecated Please use `hasAllPermissions` instead, it is an exact replacement with a less ambiguous name
 * Check that ALL permissions are present
 */
export const checkHasAllPermissions = (permissions, opt_args = {}) =>
    permissions.every((permission) => checkPermissionEnum(permission, opt_args))

// This is special request for J&J who do not want 'Ethnicity' and 'Religion' to show up on official's profiles
// But it is expected that more organizations will request this in the future, so we should come up with a better solution
export const isNotAbleToViewSensitiveFields = () => Userdata.organization_id === 869

// This function is used to determine if the user should see the school board filter!
// There are three particular cases that we need to consider:
// 1. If the user has the school_board permission, but does not have any other local permissions, then only school boards filters should be shown.
// 2. If the user has the school_board permission and has other local permissions, then every filter should be shown.
// 3. If the user does not have the school_board permission, then the school board filter should not be shown.
export const checkIfShouldShowSchoolBoard = () => {
    const isExclusivelySchoolBoard =
        hasAnyPermission([DjangIO.app.models.PermissionType.school_boards]) &&
        !hasAnyPermission([
            DjangIO.app.models.PermissionType.local,
            DjangIO.app.models.PermissionType.local_agendas,
            DjangIO.app.models.PermissionType.states,
        ])
    // We also need to check the possibility of the user having other local permissions.
    // In this case, if he has the school_board permission as well, it should show up to him.
    const canSeeSchoolBoards = hasAnyPermission([DjangIO.app.models.PermissionType.school_boards])

    return {
        isExclusivelySchoolBoard,
        canSeeSchoolBoards,
    }
}
