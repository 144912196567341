import i18n from "i18next"
import { reactI18nextModule } from "react-i18next"
import { flattenObject } from "app/static/frontend/imports/desktopHelperFunctions"
import resources from "i18n_resource.json"

export const flattenByLanguage = (resource) =>
    Object.keys(resource).reduce((acc, language) => {
        acc[language] = flattenObject(resource[language])
        return acc
    }, {})

const myLanguage = window.language || DjangIO.app.document.types.Language.english.abbrev

i18n
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        resources,
        lng: myLanguage,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        // make it so that we can use colons in our strings
        nsSeparator: false,
    })

export default i18n
