/*
 * This is the component holds all of the selected tags
 */

import React from "react"
import PropTypes from "prop-types"
import { makeId, cleanUpHtml } from "imports/desktopHelperFunctions"
import QuorumObjectTag from "app/static/frontend/components/QuorumObjectTag"

const propTypes = {
    tagObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
    minHeight: PropTypes.number,
    maxHeight: PropTypes.number,
    onClickObj: PropTypes.func.isRequired,
    onObjDelete: PropTypes.func.isRequired,
    children: PropTypes.any,
}

const SelectedObjects = (props) => {
    // assign props
    const {
        onObjDelete,
        onClickObj,
        maxHeight,
        minHeight
    } = props
    // renders all of its children tags
    const tags = props.tagObjects.map((tagObject) => {
        let text = ""

        // normally, we want to clear all HTML here; however, there is one
        // particular case (the listadder component on the profile pages)
        // where we want to keep around b and br tags -- so, if a <br> is present
        // in the text, we don't want to do this
        if (tagObject.text) {
            text = tagObject.text.indexOf("<br />") < 0 ? cleanUpHtml(tagObject.text) : text = tagObject.text
        } else if (tagObject.name) {
            text = cleanUpHtml(tagObject.name)
        } else if (tagObject.email) {
            text = cleanUpHtml(tagObject.email)
        }

        const tagObj = { ...tagObject, text }

        return (
            <QuorumObjectTag
                key={tagObj.id || tagObj.listItemId || makeId(8)}
                objData={tagObj}
                onClickObj={onClickObj}
                onObjDelete={onObjDelete}
                locked={!!tagObj.locked}
                clickable={tagObject.clickable}
                additionalIcon={tagObject.additionalIcon}
            />
        )
    })

    // this is the size of the rows
    const style = {
        minHeight: `${minHeight}px`,
        maxHeight: `${maxHeight}px`,
    }

    return (
        <ul key="objects" className="quorum-selected-objects" style={style}>
            {tags}
            {props.children || null}
        </ul>
    )
}

SelectedObjects.propTypes = propTypes
export default SelectedObjects
