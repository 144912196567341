import React from "react"
import { Field } from "redux-form/immutable"

import PageTemplate from "QuorumGrassroots/framework/components/Foundations/PageTemplate"
import { StyledAlert } from "QuorumGrassroots/styled-components/components/StyledAlert"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"

import InputFieldComponent from "app/static/frontend/forms/components/InputField"
import { requiredFieldValidation } from "QuorumGrassroots/helperFunctions"

export const ForgotPasswordPage = ({ t, ...props }) => {
    return (
        <PageTemplate className="forgot-password-page" title={t("registration.password.forgot.label")}>
            <StyledAlert>{t("registration.password.forgot.description")}</StyledAlert>
            <form onSubmit={props.handleSubmit}>
                <Field
                    name="username"
                    dataCy="username"
                    component={InputFieldComponent}
                    placeholder={t("registration.username.placeholder")}
                    displayErrorWithoutTouch={false}
                    validate={requiredFieldValidation}
                />
                <StyledButton onClick={props.handleSubmit} disabled={props.invalid || props.submitting} type="submit">
                    {props.submitting ? t("form.submitting") : t("form.submit_form")}
                </StyledButton>
            </form>
        </PageTemplate>
    )
}

export default ForgotPasswordPage
