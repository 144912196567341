import React from "react"
import PropTypes from "prop-types"

import { Col } from "react-bootstrap"

import {
    StyledDataSectionRow,
    StyledDataSectionLabel,
    StyledDatumTitle,
    StyledDatumValue,
    StyledExternalLinkDatumValue,
} from "QuorumGrassroots/widgets/Official/components/DataSection/style.js"

const DataSection = ({ label, data, t }) => {
    const datumValue = (datum) => {
        const { SocialUserType } = DjangIO.app.social.models

        if (datum.socialMediaAccountType) {
            let href = ""
            let text = datum.value

            switch (datum.socialMediaAccountType) {
                case SocialUserType.twitter_user.value:
                    href = `https://twitter.com/${datum.value}`
                    text = `@${datum.value}`
                    break
                case SocialUserType.facebook_user.value:
                    href = `https://facebook.com/${datum.value}`
                    break
                case SocialUserType.instagram_user.value:
                    href = `https://instagram.com/${datum.value}`
                    break
                case SocialUserType.youtube_user.value:
                    // Handle case where value might be a full URL
                    text = t("official.social_media.youtube.text")
                    const { value, userId, url } = datum

                    if (url) {
                        href = url
                    } else if (userId) {
                        href = `https://www.youtube.com/channel/${userId}`
                    } else {
                        href = `https://www.youtube.com/user/${value}`
                    }

                    break
                case SocialUserType.medium_user.value:
                    href = `https://medium.com/@${datum.value}`
                    break
                default:
                    break
            }

            return (
                <StyledExternalLinkDatumValue href={href} target="_blank" rel="noopener noreferrer">
                    {text} <i className="fa fa-external-link" />
                </StyledExternalLinkDatumValue>
            )
        } else {
            return <span>{datum.value}</span>
        }
    }

    return (
        <StyledDataSectionRow className="data-section-row">
            <StyledDataSectionLabel md={12}>{label}</StyledDataSectionLabel>
            {data.map(
                (datum) =>
                    Boolean(datum.value) && (
                        <Col className="datum-col" md={datum.width || 3}>
                            <StyledDatumTitle>{datum.title}</StyledDatumTitle>
                            <StyledDatumValue>{datumValue(datum)}</StyledDatumValue>
                        </Col>
                    ),
            )}
        </StyledDataSectionRow>
    )
}

DataSection.propTypes = {
    label: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
}

export default DataSection
