import { useCallback, useState } from "react"

const isJSONObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
}

export const useForm = <T = object>(
    defaultValues?: T,
    validationCallback?: (values: T) => Record<keyof T, string>,
    enableReinitialize = false,
) => {
    const [currentDefault, setCurrentDefault] = useState<T>(defaultValues)
    const [values, setValues] = useState<T>(defaultValues)
    const [errors, setErrors] = useState<Record<keyof T, string>>({} as Record<keyof T, string>)

    const setFieldValue = <K extends keyof T>(name: K, value: T[K], validate = true) => {
        if (validate && validationCallback) {
            const newErrors = validationCallback({ ...values, [name]: value })
            setErrors((errors) => ({ ...errors, [name]: newErrors[name] }))
        }

        setValues((values) => ({ ...values, [name]: value }))
    }

    const setFormErrors = (errors: Record<keyof T, string>) => setErrors(errors)

    const reset = useCallback(() => {
        setValues(defaultValues)
        setErrors({} as Record<keyof T, string>)
    }, [defaultValues])

    const reInitialize = useCallback((values: T) => {
        setValues((currentValues) => ({ ...currentValues, ...values }))
    }, [])

    const hasDefaultValuesTheSame = isJSONObjectsEqual(currentDefault, defaultValues)
    const isUntouched = isJSONObjectsEqual(values, currentDefault)
    if (enableReinitialize && !hasDefaultValuesTheSame && isUntouched) {
        setCurrentDefault(defaultValues)
        reInitialize(defaultValues)
    }

    return {
        values,
        errors,
        setFieldValue,
        setFormErrors,
        reset,
        reInitialize,
    }
}

export type useFormReturn<T> = ReturnType<typeof useForm<T>>
