import styled from "styled-components"

import * as rules from "Components/rules"

export const ImageArea = styled.div`
    align-items: center;
    background-color: ${({fillCircle, fillColor, circleColor}) => {
        if (fillColor) {
            return `${fillColor}`
        }
        else if (fillCircle) {
            return `${circleColor}`
        }
        else {
            return `transparent`
        }
    }};
    color: ${({fontColor}) => fontColor};
    cursor: ${({cursor}) => cursor};
    display: flex;
    flex-shrink: 0;
    font-size: ${({fontSize}) => fontSize};
    justify-content: center;
    opacity: ${({opacity}) => opacity !== undefined ? opacity : "1"};
    overflow: hidden;
    transition: 0.05s;

    ${({boldText}) => boldText ? rules.MediumWeight : rules.RegularWeight};

    ${({
        circleColor,
        diameter,
        dynamicHover,
        hoverWidth,
        isV2,
    }) => isV2 ? `
        border: 1px solid ${circleColor};
        border-bottom-width: 5px;
        border-radius: .5rem;
        height: 6.875rem;
        width: 6.25rem;
    ` : `
        border: 2px solid ${circleColor};
        border-radius: ${dynamicHover ? "175px" : "50%"};
        height: ${diameter};
        width: ${diameter};

            &:after {
                content: ${diameter};
            }
            &:hover {
                width: ${hoverWidth};
            }
            &:hover:after {
                content: ${diameter};
            }
    `}
`

export const Img = styled.img`
    height: 100%;

    ${({ isV2 }) => isV2 ? `
        border: .125rem solid white;
        filter: brightness(1.08);
        object-fit: cover;
        width: 100%;
    ` : `
        width: auto;
    `}
`
