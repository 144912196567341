import "QuorumGrassroots/widgets/RegionList/components/style.styl"

import React from "react"
import PropTypes from "prop-types"

import { LoadingSpinner } from "app/static/frontend/components/LoadingSpinner"

import LoginUpdateOrRender from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/LoginUpdateOrRender"
import RegionCardView from "QuorumGrassroots/widgets/RegionList/components/RegionCardView"
import RegionListView from "QuorumGrassroots/widgets/RegionList/components/RegionListView"

export class RegionList extends React.Component {
    componentDidMount() {
        this.props.loadWidgetContent({
            resource: DjangIO.app.geography.models.State,
            action: "fetch_region_list_states",
            uniqueWidgetId: this.props.uniqueWidgetId,
            kwargs: this.props.selectRegionsValue
                ? { ids: this.props.selectRegionsList }
                : { abbrev: window.userdata.state_abbreviation },
        })
    }

    render() {
        if (this.props.loading) {
            return <LoadingSpinner id="region-list-loading" />
        }

        if (this.props.cardOrListDisplay) {
            return <RegionCardView regionList={this.props.regionList} regionPagePath={this.props.regionPagePath} />
        }
        return <RegionListView regionList={this.props.regionList} regionPagePath={this.props.regionPagePath} />
    }
}

RegionList.propTypes = {
    cardOrListDisplay: PropTypes.bool,
    regionList: PropTypes.array,
    selectRegionsList: PropTypes.array,
    selectRegionsValue: PropTypes.bool,
    loadWidgetContent: PropTypes.func,
    uniqueWidgetId: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    regionPagePath: PropTypes.string.isRequired,
}

function RegionListLoginWrapper(props) {
    return props.selectRegionsValue ? (
        <RegionList {...props} />
    ) : (
        <LoginUpdateOrRender
            {...props}
            registrationPageIds={props.registrationPageIds}
            additionalSimpleFields={[DjangIO.app.grassroots.types.GrassrootsRegistrationField.address.value]}
            useWidgetStyling={false}
            renderedComponent={RegionList}
            renderedProps={props}
            showOnlyUnfilledFields
        />
    )
}

RegionListLoginWrapper.propTypes = {
    registrationPageIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    selectRegionsValue: PropTypes.bool,
}

export default RegionListLoginWrapper
