import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import ReduxFormFieldWrapper from "app/static/frontend/forms/components/ReduxFormFieldWrapper"
import PeopleSelector from "app/static/frontend/containers/PeopleSelector"
import "app/static/stylus/FormStyles.styl"
import "app/static/frontend/forms/stylus/PeopleSelectorField.styl"

export const PeopleSelectorField = (props) => {
    const {
        officials, // Name of officials field, includes meta and input objects
        supporters, // Name of supporters field, includes meta and input objects
        pageId,
        className,
        label,
        tooltipText,
        displayErrorWithoutTouch,
        labelStyle,
        style,
        initialOfficialsValue,
    } = props

    const onChange = (newValue) => {
        const newOfficialsValue = newValue.filter((person) =>
            person.resource_uri.includes(DjangIO.app.person.models.Person.endpoint),
        )
        officials.input.onChange(newOfficialsValue)

        const supportersValue = newValue.filter((person) =>
            person.resource_uri.includes(DjangIO.app.grassroots.models.Supporter.endpoint),
        )
        supporters.input.onChange(supportersValue)
    }

    const officialsValue = officials.input.value
    const supportersValue = supporters.input.value

    const value = [
        // There seems to be an issue with Redux Form's Fields component not correctly passing initial values
        // So I created a selector and manually pass down initial officials value
        ...(officialsValue && officialsValue.toJS ? officialsValue.toJS() : initialOfficialsValue),
        ...(supportersValue && supportersValue.toJS ? supportersValue.toJS() : []),
    ]

    return (
        <ReduxFormFieldWrapper
            className={classNames("people-selector", className)}
            displayErrorWithoutTouch={displayErrorWithoutTouch}
            error={officials.meta.error || supporters.meta.error}
            label={label}
            labelStyle={labelStyle}
            style={style}
            tooltipText={tooltipText}
            touched={officials.meta.touched || supporters.meta.touched}
            warning={officials.meta.warning || supporters.meta.warning}
        >
            <PeopleSelector peopleUpdater={onChange} page_id={pageId} value={value} />
        </ReduxFormFieldWrapper>
    )
}

PeopleSelectorField.defaultProps = {
    className: "",
    displayErrorWithoutTouch: true,
    label: "",
    labelStyle: {},
    style: {},
    tooltipText: "",
    initialOfficialsValue: [],
}

PeopleSelectorField.propTypes = {
    className: PropTypes.string,
    displayErrorWithoutTouch: PropTypes.bool,
    initialOfficialsValue: PropTypes.array,
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
    meta: PropTypes.object.isRequired,
    officials: PropTypes.object.isRequired,
    pageId: PropTypes.string.isRequired,
    style: PropTypes.object,
    supporters: PropTypes.object.isRequired,
    tooltipText: PropTypes.string,
}

export default PeopleSelectorField
