import React from "react"
import { hasAnyPermission } from "shared/imports/permissionFunctions"
import { centsToCurrencyStr } from "shared/pac/helperFunctions"

const { TransactionMethodType } = DjangIO.app.ledger.types

const DonationTierLabel = ({ donationMethod, conditionalGivingLevelLabel, value, tierType, index, frequencyLabel }) => {
    const donationValue = tierType === "amount" ? centsToCurrencyStr(value) : value

    const isPACv2 = hasAnyPermission(DjangIO.app.models.PermissionType.pac_hybrid)

    if (!value) {
        return <span id={`donation-tier-label-${index}`}>Other</span>
    }

    if (!conditionalGivingLevelLabel) {
        return <span id={`donation-tier-value-${index}`}>{donationValue}</span>
    }

    return (
        <>
            <span id={`donation-tier-label-${index}`}>{conditionalGivingLevelLabel}</span>
            <span id={`donation-tier-hyphen-${index}`}> - </span>
            <span id={`donation-tier-value-${index}`}>
                {donationValue}
                {isPACv2 ? (
                    <span id={`donation-tier-frequency-${index}`}>
                        {donationMethod === TransactionMethodType.payroll.value ? ` / ${frequencyLabel}` : " "}
                    </span>
                ) : (
                    ""
                )}
            </span>
        </>
    )
}

export default DonationTierLabel
