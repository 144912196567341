import { fromJS } from "immutable"

import { formValueSelector } from "redux-form/immutable"
import { createSelector, createStructuredSelector } from "reselect"
import {
    selectDonationForms,
    selectImmutableActionCenterSettings,
    selectUserdata,
    selectOrganizationDesign,
    selectIsPacEligible,
    selectConditionalGivingLevel,
    selectCannotDonateMessage,
} from "QuorumGrassroots/framework/selectors"
import { createToJsSelector } from "shared/imports/sharedHelperFunctions"
import { constants } from "QuorumGrassroots/constants"
import { PAC_RESTRICTED_TO_ELIGIBLES_MESSAGE_DEFAULT } from "shared/grassroots/constants"

const { TransactionMethodType, PayrollFrequencyType } = DjangIO.app.ledger.types
const { PayrollDonationType } = DjangIO.app.grassroots.types

const makeFormValueSelector = (field) => (state) => formValueSelector(constants.donationFormReduxFormKey)(state, field)

export const selectDonationFormId = createSelector(
    (_, props) => props.match.params.donation_form_id || props.uniqueWidgetId.match(/\d+$/)[0],
    (donationFormId) => parseInt(donationFormId),
)

export const selectListDonationFormId = createSelector(selectDonationFormId, (donationFormId) => [donationFormId])

export const selectConditionalGivingLevelsEnabled = createSelector(
    selectImmutableActionCenterSettings,
    (actionCenter) => actionCenter.get("conditional_giving_enabled"),
)

const createDonationFormSelector = (key) =>
    createSelector(selectDonationFormId, selectDonationForms, (donationFormId, donationForms) => {
        const donationForm = donationForms.find((form) => donationFormId === form.get("id"))
        return donationForm && donationForm.get(key)
    })

export const selectAllowedDonationMethods = createDonationFormSelector("allowed_donation_methods")
export const selectAllowedDonationMethodsJS = createToJsSelector(selectAllowedDonationMethods, [])
export const selectAllowedPayrollDonationTypes = createDonationFormSelector("allowed_payroll_donation_types")
export const selectAllowedPayrollDonationTypesJS = createToJsSelector(selectAllowedPayrollDonationTypes, [])
export const selectPayrollPercentageLabel = createDonationFormSelector("payroll_percentage_label")
export const selectPayrollAmountLabel = createDonationFormSelector("payroll_amount_label")
export const selectDisclaimerText = createDonationFormSelector("disclaimer_text")
export const selectPostSubmissionActionType = createDonationFormSelector("post_submission_action_type")
export const selectThankYouText = createDonationFormSelector("thank_you_text")
export const selectDonationMethodPromptText = createDonationFormSelector("donation_method_prompt_text")
export const selectDonationAmountPayrollPromptText = createDonationFormSelector("donation_amount_payroll_prompt_text")
export const selectDonationAmountsPayroll = createDonationFormSelector("donation_amounts_payroll")
export const selectDonationAmountsCheck = createDonationFormSelector("donation_amounts_check")
export const selectDonationPercentagesPayroll = createDonationFormSelector("donation_percentages_payroll")
export const selectDonationPercentagesPayrollJS = createToJsSelector(selectDonationPercentagesPayroll, [])
export const selectPayrollFrequencyType = createDonationFormSelector("payroll_frequency_type")
export const selectEnabledPacMatch = createDonationFormSelector("enabled_pac_match")
export const selectPacMatchSelectLabel = createDonationFormSelector("pac_match_select_label")
export const selectPacMatchDescription = createDonationFormSelector("pac_match_description")
export const selectPacMatchThreshold = createDonationFormSelector("pac_match_threshold")
export const selectSubmitButtonText = createDonationFormSelector("submit_button_text")
export const selectCustomJavascript = createDonationFormSelector("custom_javascript")
export const selectCustomAfterRegistrationJavascript = createDonationFormSelector(
    "custom_after_registration_javascript",
)
export const selectCustomCSS = createDonationFormSelector("custom_css")
export const selectTermsAndConditionsSwitch = createDonationFormSelector("terms_and_conditions_switch")

const selectDonationAmount = makeFormValueSelector("donationAmount")
const selectDonationAmountPerYear = makeFormValueSelector("donationAmountPerYear")
const selectDonationAmountCustom = makeFormValueSelector("donationAmountCustom")
const selectDonationMethod = makeFormValueSelector("donationMethod")
const selectPayrollDonationType = makeFormValueSelector("payrollDonationType")

export const selectInitialCharity = createSelector(selectUserdata, (userdata) => userdata.pac_charity_ein)

export const selectDonationFormPacClassicSite = createSelector(
    selectDonationFormId,
    (grassrootsRegistrationPageId) =>
        window.Userdata?.donation_forms &&
        window.Userdata.donation_forms.find((df) => df.id === +grassrootsRegistrationPageId)?.pac_classic_site,
)

const selectIsPayrollByPercentageDonation = createSelector(
    selectDonationMethod,
    selectPayrollDonationType,
    (donationMethod, payrollDonationType) =>
        donationMethod === TransactionMethodType.payroll.value &&
        payrollDonationType === PayrollDonationType.percentage.value,
)

/**
 * Select the amount currently selected to be donated. This is different than donationAmount,
 * since this selector takes into account custom amounts. The output of this selector is *not*
 * relevant for percentage donations.
 */
const selectCurrentAmount = createSelector(
    selectDonationAmount,
    selectDonationAmountCustom,
    (donationAmount, donationAmountCustom) => (donationAmount ? donationAmount : donationAmountCustom),
)

/**
 * Select the enum object for the attached payroll_frequency_type, or return undefined if that value is not available
 *
 * @returns {Object | undefined}
 */
export const selectPayrollFrequencyTypeObj = createSelector(selectPayrollFrequencyType, (payrollFrequencyType) =>
    PayrollFrequencyType.by_value(payrollFrequencyType),
)

/**
 * Select the amounts for credit card donations, which is given in annual terms, and adjust them to per pay period
 * terms
 */
export const selectDonationAmountsPayrollPerPayPeriod = createSelector(
    selectDonationAmountsPayroll,
    selectPayrollFrequencyTypeObj,
    (amounts, payrollFrequencyType) => {
        if (!amounts || !payrollFrequencyType) return

        return amounts.map((amount) => {
            return amount.update("value", (value) =>
                fromJS({
                    originalValue: value,
                    value: Math.round(value / payrollFrequencyType.cycles_per_year),
                }),
            )
        })
    },
)

/**
 * Select amounts, adding an option at the end for amounts not specified in this list.
 */
export const selectAmounts = createSelector(
    selectDonationAmountsPayrollPerPayPeriod,
    selectDonationAmountsCheck,
    selectDonationMethod,
    (amountsPayroll, amountsCheck, method) => {
        let amounts = fromJS([])
        if (method === TransactionMethodType.payroll.value && amountsPayroll) {
            amounts = amountsPayroll
        } else if (method === TransactionMethodType.check.value && amountsCheck) {
            amounts = amountsCheck
        }

        return amounts
            .map((valueLabel) => valueLabel.set("dataCy", `grid-toggle-amount-${valueLabel.get("value")}`))
            .push(
                fromJS(
                    method === TransactionMethodType.payroll.value
                        ? {
                              value: {
                                  value: 0,
                                  originalValue: 0,
                              },
                              label: "Other",
                          }
                        : { value: 0 },
                ),
            )
    },
)
export const selectAmountsJS = createToJsSelector(selectAmounts)

export const selectAllowedDonationMethodChoices = createSelector(
    selectAllowedDonationMethodsJS,
    (allowedDonationMethods) =>
        allowedDonationMethods.map((donationMethod) => {
            const transactionMethod = TransactionMethodType.by_value(donationMethod)
            return {
                ...transactionMethod,
                label: transactionMethod.donation_form_display || transactionMethod.label,
            }
        }),
)

export const selectAllowedPayrollDonationTypesChoices = createSelector(
    selectAllowedPayrollDonationTypesJS,
    selectPayrollPercentageLabel,
    selectPayrollAmountLabel,
    (allowedPayrollDonationTypes, payrollPercentageLabel, payrollAmountLabel) => {
        return allowedPayrollDonationTypes.map((donationType) => {
            const payrollDonationType = PayrollDonationType.by_value(donationType)
            const label =
                donationType === PayrollDonationType.percentage.value ? payrollPercentageLabel : payrollAmountLabel

            return {
                ...payrollDonationType,
                label,
            }
        })
    },
)

const selectDonationAmountOther = createSelector(selectDonationAmount, (donationAmount) => donationAmount === 0)

const selectShouldDisplayCreditCardForm = createSelector(
    selectDonationMethod,
    (donationMethod) => donationMethod === TransactionMethodType.credit_card.value,
)

const selectShouldDisplayPayrollDonationTypesField = createSelector(
    selectDonationMethod,
    selectAllowedPayrollDonationTypesJS,
    (donationMethod, allowedPayrollDonationTypes) =>
        donationMethod === TransactionMethodType.payroll.value && allowedPayrollDonationTypes.length > 1,
)

export const selectInitialDonationMethod = createSelector(
    selectAllowedDonationMethodsJS,
    (allowedDonationMethods) => allowedDonationMethods && allowedDonationMethods[0],
)

export const selectInitialValues = createSelector(
    selectInitialDonationMethod,
    selectUserdata,
    (initialDonationMethod, userdata) => ({
        donationMethod: initialDonationMethod,
        agreeTermsConditions: false,
        ...userdata,
        ...userdata.tag_dict,
    }),
)

export const selectThanksMessage = createSelector(
    selectPostSubmissionActionType,
    selectThankYouText,
    (postSubmissionActionType, thankYouText) =>
        postSubmissionActionType ===
        DjangIO.app.grassroots.types.RegistrationPostSubmissionAction.thank_you_message.value
            ? thankYouText
            : "",
)

export const selectDonationFormThanksProps = createSelector(selectThanksMessage, (thanksMessage) => ({
    text: thanksMessage,
}))

export const selectDonateMethodPrompt = createSelector(
    selectDonationMethodPromptText,
    (donationMethodPromptText) => donationMethodPromptText || "How would you like to donate?",
)

export const selectDonateAmountPrompt = createSelector(
    selectDonationAmountPayrollPromptText,
    selectDonationMethod,
    (payrollPromptText, donationMethod) => {
        if (payrollPromptText && donationMethod === TransactionMethodType.payroll.value) {
            return payrollPromptText
        } else {
            return "How much would you like to give?"
        }
    },
)

/**
 * Select the amount to use in the supporter action. In the general case this will just be the option they selected,
 * however there's two special cases that we need to consider:
 *  1) The user wants to use a custom amount, whereas the actual amount is found as amountCustom
 *  2) The user wants to donate per pay period, in which case we need to convert the selected amount (which we store
 *     in annual terms) to amount per pay period
 */
export const selectAmountToSubmit = createSelector(
    selectDonationMethod,
    selectDonationAmount,
    selectDonationAmountCustom,
    selectPayrollFrequencyTypeObj,
    (method, selectedAmount, amountCustom, pft) => {
        if (!method) return

        let amount
        if (selectedAmount !== 0) {
            amount = selectedAmount
        } else if (selectedAmount === 0) {
            amount = amountCustom
        }

        return amount
    },
)

/**
 * Select if we should display the PAC Match field. PAC Match must be enabled
 * and we muse be above the threshold, or there isn't a threshold set. Threshold
 * is ignored for percentage donations.
 */
const calculateAnnualAmount = (amount, isPayroll, payrollFrequencyType) => {
    if (!amount) return 0
    if (isPayroll && payrollFrequencyType) {
        return Math.round(amount * payrollFrequencyType.cycles_per_year)
    }
    return amount
}

const getAmountForThreshold = (donationMethod, donationAmount, donationAmountPerYear) =>
    donationMethod === TransactionMethodType.payroll.value ? donationAmountPerYear : donationAmount

export const selectShouldDisplayPacMatchFields = createSelector(
    [
        selectEnabledPacMatch,
        selectPacMatchThreshold,
        selectDonationAmount,
        selectDonationAmountPerYear,
        selectDonationAmountCustom,
        selectDonationAmountOther,
        selectIsPayrollByPercentageDonation,
        selectPayrollFrequencyTypeObj,
        selectDonationMethod,
    ],
    (
        enabledPacMatch,
        threshold,
        currentDonationAmount,
        currentAmountPerYear,
        donationAmountCustom,
        donationAmountOther,
        isPayrollByPercentage,
        payrollFrequencyType,
        donationMethod,
    ) => {
        // Early returns for simple cases
        if (!enabledPacMatch) return false
        if (isPayrollByPercentage) return true
        if (!threshold) return true
        if (!currentDonationAmount && !currentAmountPerYear && !donationAmountCustom) return false

        const isPayroll = donationMethod === TransactionMethodType.payroll.value

        // Calculate the annual amount based on the donation type
        const annualAmount = donationAmountOther
            ? calculateAnnualAmount(donationAmountCustom, isPayroll, payrollFrequencyType)
            : getAmountForThreshold(donationMethod, currentDonationAmount, currentAmountPerYear)

        return annualAmount >= threshold
    },
)

/**
 * The message (which could contain HTML) that should be shown, if the user cannot access the donation form. This
 * message will only be displayed if a non-pac-eligible supporter, or a not logged in supporter tries to access
 * a donation form.
 */
export const selectCannotAccessMessage = createSelector(
    selectCannotDonateMessage, // We need window.Userdata; selectUserdata gives window.userdata
    (cannotDonateMessage) => cannotDonateMessage || PAC_RESTRICTED_TO_ELIGIBLES_MESSAGE_DEFAULT,
)

/**
 * return {boolean} Whether the check mailing instructions should be displayed
 */
export const selectShouldDisplayCheckInstructions = createSelector(
    selectDonationMethod,
    (donationMethod) => donationMethod === TransactionMethodType.check.value,
)

export const selectSiteUrl = createSelector(selectDonationFormPacClassicSite, (pacClassicSite) => {
    return pacClassicSite
})

export const donationFormWidgetConnection = createStructuredSelector({
    allowedDonationMethodChoices: selectAllowedDonationMethodChoices,
    allowedPayrollDonationTypesChoices: selectAllowedPayrollDonationTypesChoices,
    donateMethodPrompt: selectDonateMethodPrompt,
    donateAmountPrompt: selectDonateAmountPrompt,
    amounts: selectAmountsJS,
    donationPercentagesPayroll: selectDonationPercentagesPayrollJS,
    disclaimerText: selectDisclaimerText,
    donationAmountOther: selectDonationAmountOther,
    donationFormId: selectDonationFormId,
    donationMethod: selectDonationMethod,
    listDonationFormId: selectListDonationFormId,
    initialValues: selectInitialValues,
    isPayrollByPercentageDonation: selectIsPayrollByPercentageDonation,
    shouldDisplayCreditCardForm: selectShouldDisplayCreditCardForm,
    shouldDisplayPayrollDonationTypesField: selectShouldDisplayPayrollDonationTypesField,
    amountToSubmit: selectAmountToSubmit,
    payrollFrequencyType: selectPayrollFrequencyType,
    canAccess: selectIsPacEligible,
    cannotAccessMessage: selectCannotAccessMessage,
    conditionalGivingLevelsEnabled: selectConditionalGivingLevelsEnabled,
    userConditionalGivingLevel: selectConditionalGivingLevel,
    enabledPacMatch: selectEnabledPacMatch,
    pacMatchSelectLabel: selectPacMatchSelectLabel,
    pacMatchDescription: selectPacMatchDescription,
    pacMatchThreshold: selectPacMatchThreshold,
    payrollDonationType: selectPayrollDonationType,
    shouldDisplayPacMatchFields: selectShouldDisplayPacMatchFields,
    shouldDisplayCheckMailingInstructions: selectShouldDisplayCheckInstructions,
    submitButtonText: selectSubmitButtonText,
    customJavascript: selectCustomJavascript,
    customAfterRegistrationJavascript: selectCustomAfterRegistrationJavascript,
    customCSS: selectCustomCSS,
    termsAndConditionsSwitch: selectTermsAndConditionsSwitch,
    organizationDesign: selectOrganizationDesign,
    payrollFrequencyTypeObj: selectPayrollFrequencyTypeObj,
    siteUrl: selectSiteUrl,
    pacClassicSite: selectDonationFormPacClassicSite,
})
