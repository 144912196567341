import PropTypes from "prop-types"
import queryString from "query-string"
import React, { Component } from "react"
import { withNamespaces } from "react-i18next"
import { Navigate, Route, Routes } from "react-router-dom"

import { getBodyClassnameFromPathname, joinPaths, runUserJavascript } from "QuorumGrassroots/helperFunctions"

import Page404 from "QuorumGrassroots/framework/components/404/index"
import Page405 from "QuorumGrassroots/framework/components/405/index"
import Footer from "QuorumGrassroots/framework/components/Footer/index"
import {
    DisclaimerFooter,
    FooterWrapper,
    InvisibleFooterSpacing,
} from "QuorumGrassroots/framework/components/Footer/style"
import Header from "QuorumGrassroots/framework/components/Header/index"

import StyledApp, {
    StyledMainContainer,
    StyledMain,
    StyledRedirectButton,
    StyledRedirectMessage,
    StyledSkipToMainContentLink,
} from "QuorumGrassroots/framework/components/style"
import GamificationTierModal from "QuorumGrassroots/framework/containers/GamificationTierModal"
import Page from "QuorumGrassroots/framework/containers/Page"
import { createPageTitle } from "QuorumGrassroots/framework/selectors"

import FoundationalRouteMap from "QuorumGrassroots/framework/FoundationalRouteMap"

import {
    CookielessSafariOverlay,
    CookielessEmbeddedOverlay,
} from "QuorumGrassroots/framework/components/CookielessOverlay/index"
import { withRouter } from "QuorumGrassroots/withRouter"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"

export class App extends Component {
    static propTypes = {
        emailRelatedPerson: PropTypes.string,
        indexRoute: PropTypes.string,
        isPublic: PropTypes.bool,
        footerHtml: PropTypes.string,
        shouldUseFooterHtml: PropTypes.bool,
        userLoggedIn: PropTypes.bool,
        pages: PropTypes.arrayOf(PropTypes.object),
        widgetRouteTypes: PropTypes.arrayOf(PropTypes.object),
        hasHomePath: PropTypes.bool,
        t: PropTypes.func.isRequired,
        // action creators
        preloadData: PropTypes.func.isRequired,
        trackGrassrootsVisit: PropTypes.func.isRequired,
        // props only used for styling of campaign pages
        backgroundStyleType: PropTypes.number,
        backgroundImage: PropTypes.string,
        backgroundColor: PropTypes.string,
        shouldNotShowNavBar: PropTypes.bool,
    }

    componentDidMount() {
        if (this.props.isPublic || this.props.userLoggedIn) {
            this.props.preloadData()
        }

        if (
            this.props.location.pathname.includes(DjangIO.app.grassroots.types.GrassrootsWidgetType.campaign.widget_url)
        ) {
            this.trackGrassrootsVisit()
        }

        // Set body tag with initial pathname as class name
        // This is to allow organizations to specify custom CSS per page
        const actionCenterIdClass = `action-center-${this.props.actionCenterId}`
        document.body.className = `${getBodyClassnameFromPathname(this.props.location.pathname)} ${actionCenterIdClass}`

        if (this.props.customJS) {
            runUserJavascript(this.props.customJS)
        }
    }

    componentDidUpdate(prevProps) {
        // implement the visit tracker here. For now, all we're doing is
        // tracking if a user has logged in.
        const userJustLoggedIn = this.props.userLoggedIn && !prevProps.userLoggedIn
        const locationChanged = this.props.location !== prevProps.location
        const isCampaign = this.props.location.pathname.includes(
            DjangIO.app.grassroots.types.GrassrootsWidgetType.campaign.widget_url,
        )

        if ((userJustLoggedIn || locationChanged) && isCampaign) {
            this.trackGrassrootsVisit()
        }

        // As user navigates around grassroot, change classname to match pathname
        // This is to allow organizations to specify custom CSS per page
        if (prevProps.location.pathname !== this.props.location.pathname) {
            const actionCenterIdClass = `action-center-${this.props.actionCenterId}`
            document.body.className = `${getBodyClassnameFromPathname(
                this.props.location.pathname,
            )} ${actionCenterIdClass}`
        }
    }

    trackGrassrootsVisit = () => {
        if (this.props.userLoggedIn) {
            this.props.trackGrassrootsVisit(this.props.location.pathname)
        }
    }

    renderRoutes() {
        // foundational routes! These are things that are neither widgets nor pages,
        // mostly administrative things.
        const foundationalRoutes = this.props.foundationalRoutes.map((item) => {
            return <Route key={item.path} path={item.path} Component={FoundationalRouteMap[item.value]} />
        })

        // if the organization does not have grassroots, then it shouldn't have any access
        // to pages.
        const pageRoutes = this.props.hasGrassroots
            ? this.props.pages.map((page) => {
                  let PageRenderer
                  if (page.can_access || !isFeatureEnabled("ff_restricted_gr_pages")) {
                      PageRenderer = (props) => (
                          <Page
                              {...props}
                              widgets={page.widgets}
                              requiresLogin={page.requires_login}
                              widgetLayoutType={page.widget_layout_type}
                              customJs={page.custom_javascript}
                              customCss={page.custom_css}
                              pageTitle={createPageTitle(page.title)}
                              usePageRegion={page.use_page_region}
                          />
                      )

                      PageRenderer.displayName = "PageRenderer"
                  } else {
                      PageRenderer = () => <Page405 errorHtml={page.cannot_access_message} />
                      PageRenderer.displayName = "Page405Renderer"
                  }
                  const PageRendererWithRouter = withRouter(PageRenderer)
                  return <Route key={page.path} path={page.path} element={<PageRendererWithRouter />} />
              })
            : []

        const widgetRoutes = this.props.widgetRouteTypes.map((item) => {
            const path = item.widget_url_params ? joinPaths(item.widget_url, item.widget_url_params) : item.widget_url
            const PageWithRouter = withRouter(Page)

            return (
                <Route
                    key={item.widget_url}
                    path={`${path}*`}
                    element={
                        <PageWithRouter
                            widgets={item.widgets}
                            requiresLogin={item.requires_login}
                            isWidgetPage
                            pageTitle={createPageTitle(item.page_title)}
                            shouldNotShowNavBar={this.props.shouldNotShowNavBar}
                        />
                    }
                />
            )
        })

        const redirectRoutes = this.props.redirectPaths.map((path) => (
            <Route key={path} path={path} element={<Navigate replace to={this.props.indexRoute} />} />
        ))

        // if the index's path is not "/" (e.g. it's "/home" or something),
        // make sure that "/" redirects to "/home".
        const indexRoute = (!this.props.hasGrassroots || this.props.indexRoute !== "/") && (
            <Route key="index_route" index element={<Navigate replace to={this.props.indexRoute} />} />
        )

        const route404 = <Route path="*" key="404" element={<Page404 />} />

        // If Supporter has a related Person, then they are a Staffer or Official who signed up using their official email
        // And should not see any campaigns or log interactions.
        // We only include Events related widget routes and redirect all other routes to Events list widget
        if (this.props.emailRelatedPerson) {
            const routePersonToEvents = (
                <Route
                    path="*"
                    element={
                        <Navigate
                            replace
                            to={DjangIO.app.grassroots.types.GrassrootsWidgetType.event_list.widget_url}
                        />
                    }
                />
            )

            return (
                <Routes>
                    {[indexRoute, ...foundationalRoutes, ...widgetRoutes, routePersonToEvents, route404].filter(
                        (route) => Boolean(route),
                    )}
                </Routes>
            )
        }

        return (
            <Routes>
                {[
                    indexRoute,
                    ...foundationalRoutes,
                    ...pageRoutes,
                    ...widgetRoutes,
                    ...redirectRoutes,
                    route404,
                ].filter((route) => Boolean(route))}
            </Routes>
        )
    }

    renderOverlayIfCookieless = () => {
        if (this.props.cookielessSafari) {
            return <CookielessSafariOverlay />
        } else if (this.props.cookielessEmbedded) {
            return <CookielessEmbeddedOverlay />
        }
    }

    render() {
        const queryValues = queryString.parse(this.props.location.search)

        if (queryValues.frameless) {
            return (
                <div>
                    <StyledApp
                        className="app"
                        frameless
                        backgroundColor={this.props.backgroundColor}
                        backgroundImage={this.props.backgroundImage}
                        backgroundStyleType={this.props.backgroundStyleType}
                    >
                        {/* For acessbility purposes, add a link to direct screen readers to skip navigation items and go to main content of page */}
                        <StyledSkipToMainContentLink href="#main-content" className="sr-only main-content-link">
                            Skip to Main Content
                        </StyledSkipToMainContentLink>
                        <GamificationTierModal t={this.props.t} />
                        {this.renderOverlayIfCookieless()}
                        <main id="main-content" tabIndex="-1">
                            {this.renderRoutes()}
                        </main>
                    </StyledApp>
                </div>
            )
        }

        // When previewing events, we want to render a message stating we are viewing a preview page.
        // Also include a button to the live site. We parse a link to the live site here
        let eventRedirectLink = ""
        if (queryValues.preview) {
            // remove preview query from url and set result as eventRedirectLink
            delete queryValues.preview
            const query = queryString.stringify(queryValues)

            eventRedirectLink = `${window.location.origin}${window.location.pathname}?${query}`
        }

        return (
            <StyledApp
                className="app"
                backgroundColor={this.props.backgroundColor}
                backgroundImage={this.props.backgroundImage}
                backgroundStyleType={this.props.backgroundStyleType}
            >
                {/* For acessbility purposes, add a link to direct screen readers to skip navigation items and go to main content of page */}
                <StyledSkipToMainContentLink href="#main-content" className="sr-only main-content-link">
                    Skip to Main Content
                </StyledSkipToMainContentLink>
                <GamificationTierModal t={this.props.t} />
                {this.renderOverlayIfCookieless()}
                {queryValues.redirect && (
                    <StyledRedirectMessage className="redirect-banner">
                        {this.props.t("campaign.thanks.redirect_message")}
                    </StyledRedirectMessage>
                )}
                {Boolean(eventRedirectLink) && (
                    <StyledRedirectMessage className="event-preview-banner" fontSize={30}>
                        {this.props.t("event.preview.redirect.banner")}
                        <StyledRedirectButton href={eventRedirectLink}>
                            {this.props.t("event.preview.redirect.button_text")}
                        </StyledRedirectButton>
                    </StyledRedirectMessage>
                )}
                <StyledMainContainer className="main-container" isCampaignPage={this.props.isCampaignPage}>
                    <Header indexRoute={this.props.indexRoute} {...this.props} />
                    <StyledMain id="main-content" tabIndex="-1" isCampaignPage={this.props.isCampaignPage}>
                        {this.renderRoutes()}
                    </StyledMain>
                    {this.props.shouldUseFooterHtml && (
                        <InvisibleFooterSpacing
                            className="invisible-footer-spacing"
                            isCampaignPage={this.props.isCampaignPage}
                        >
                            {/* This is only here for spacing, so the custom footer/disclaimers don't overlay content */}
                            {this.props.disclaimerText && (
                                <DisclaimerFooter
                                    isCampaignPage={this.props.isCampaignPage}
                                    className="disclaimer-footer"
                                    dangerouslySetInnerHTML={{ __html: this.props.disclaimerText }}
                                />
                            )}
                            <div
                                className="custom-footer"
                                dangerouslySetInnerHTML={{ __html: this.props.footerHtml }}
                            />
                        </InvisibleFooterSpacing>
                    )}
                    <FooterWrapper isCampaignPage={this.props.isCampaignPage}>
                        {this.props.shouldUseFooterHtml ? (
                            <React.Fragment>
                                {this.props.disclaimerText && (
                                    <DisclaimerFooter
                                        dangerouslySetInnerHTML={{ __html: this.props.disclaimerText }}
                                        className="disclaimer-footer disclaimer-footer-visible"
                                    />
                                )}
                                <div
                                    className="custom-footer"
                                    dangerouslySetInnerHTML={{ __html: this.props.footerHtml }}
                                />
                            </React.Fragment>
                        ) : (
                            <Footer className="footer" />
                        )}
                    </FooterWrapper>
                </StyledMainContainer>
            </StyledApp>
        )
    }
}

export default withNamespaces()(withRouter(App))
