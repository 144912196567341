import React from "react"
import QuorumObjSelector from "components/QuorumObjSelector"
import PropTypes from "prop-types"
import { getInteractionSupportersActionTypes } from "QuorumGrassroots/campaign-forms/action-types"

export default class PeopleSelector extends React.Component {
    static initClass() {
        this.propTypes = {
            page_id: PropTypes.string.isRequired,
            peopleUpdater: PropTypes.func,
            sendAction: PropTypes.func.isRequired,
        }
    }

    constructor() {
        super(...arguments)
        this.handleSelectionChange = this.handleSelectionChange.bind(this)
        this.state = {
            // participants: [{id: Userdata.id, text: "You", typeAheadGroup: "Users"}]
            participants: this.props.value || [],
        }
    }

    componentDidMount() {
        return this.props.peopleUpdater(this.state.participants)
    }

    handleSelectionChange(args) {
        this.setState({
            participants: args.selection,
        })
        return this.props.peopleUpdater(args.selection)
    }

    render() {
        const typeAheadArgs = [
            {
                groupName: DjangIO.app.models.QuorumDataType.person.plural,
                queryFunction: (filterText, groupName, callback) => {
                    const supporterRequest = this.props.sendAction(
                        DjangIO.app.grassroots.models.NewSupporter,
                        getInteractionSupportersActionTypes,
                        {
                            action: "get_interaction_supporters",
                            method: "get",
                            kwargs: { search_phrase: filterText },
                        },
                    )
                    const personRequest = DjangIO.app.person.models.Person.objects
                        .limit(10)
                        .filter({ quick_search: filterText })
                        .GET()

                    return Promise.all([supporterRequest, personRequest]).then(
                        ([supporterResponse, personResponse]) => {
                            const supporterObjects =
                                supporterResponse.objects || (supporterResponse.data && supporterResponse.data.objects)
                            const personObjects =
                                personResponse.objects || (personResponse.data && personResponse.data.objects)

                            const supporterAndPersonObjects = supporterObjects.concat(personObjects)

                            return callback(groupName, supporterAndPersonObjects)
                        },
                    )
                },
            },
        ]

        return (
            <QuorumObjSelector
                id={this.props.page_id}
                objectArray={this.state.participants}
                stateKey="selection"
                rowLimits={{ min: 1, max: 3 }}
                typeAheadArgs={typeAheadArgs}
                debounceRate={1000}
                placeholder="Type to search for officials, staffers, or contacts..."
                handleSelectionChange={this.handleSelectionChange}
            />
        )
    }
}
PeopleSelector.initClass()
