import { UnstyledButton, CloseButton as MantineCloseButton, Popover as MantinePopover } from "@mantine/core"
import styled from "styled-components"
import { constants } from "QuorumGrassroots/constants"

export const StyledVideoPreviewContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const StyledLoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    min-height: 190px;
`

export const StyledIconContainer = styled.div`
    display: flex;
    width: var(--xxl, 32px);
    height: var(--xxl, 32px);
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: var(--xs, 8px);
    margin-bottom: var(--xs, 8px);

    border-radius: var(--xxl, 32px);
    background: ${constants.freshMediumGrey};

    i {
        color: white;
        font-size: var(--md, 16px);
    }
`

export const StyledVideoDescription = styled.p`
    color: ${constants.freshDarkGrey};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 150% */
`

export const StyledSubtitle = styled.p`
    color: ${constants.freshMediumGrey};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
`

export const StyledVideoPreview = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`

export const PlayButton = styled(UnstyledButton)`
    color: black;
    font-size: 18px;

    background: white;
    border-radius: 50%;

    width: 45px;
    height: 45px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
`

export const CloseButton = styled(MantineCloseButton)`
    position: absolute;
    color: white;
    top: 8px;
    right: 8px;
    border-radius: 32px;
    transition: color 0.25s ease-in-out;
    transition: background-color 0.25s ease-in-out;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.7));

    &:hover {
        color: ${constants.freshMediumGrey};
    }
`

export const StyledThumbnail = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`

export const Popover = styled(MantinePopover)`
    position: absolute;
    left: 99%;
    top: 98%;
`
export const PopoverDropdown = styled.div`
    padding: 1.25rem;
    gap: 1rem;
    color: ${constants.colors.gray[8]};
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const PopoverTitle = styled.div`
    color: ${constants.colors.gray[9]};
    font-size: 18px;
    font-weight: 500;
`
