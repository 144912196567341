import { TargetedMessage } from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/interfaces"
import { placeholders } from "QuorumGrassroots/constants"

// Maps the placeholders for their final values, so that we can replace them even if they are already replaced for provisional values like "Friend"
export function mapPlaceholders(rawText: string, formattedText: string): Record<string, string> {
    const placeholders = []
    const placeholderRegex = /{{[^}]+}}/g
    let match
    while ((match = placeholderRegex.exec(rawText)) !== null) {
        placeholders.push({
            placeholder: match[0],
            start: match.index,
            end: match.index + match[0].length,
        })
    }

    let pattern = ""
    let position = 0
    let groupIndex = 1
    const placeholderGroupMap: Record<string, number> = {}

    function escapeRegExp(string: string): string {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
    }

    for (const placeholder of placeholders) {
        const textBetween = rawText.substring(position, placeholder.start)
        pattern += escapeRegExp(textBetween)

        pattern += "(.*?)"

        placeholderGroupMap[placeholder.placeholder] = groupIndex
        groupIndex++

        position = placeholder.end
    }

    const remainingText = rawText.substring(position)
    pattern += escapeRegExp(remainingText)

    const regex = new RegExp("^" + pattern + "$", "s")

    const result = regex.exec(formattedText)

    const placeholderValues: Record<string, string> = {}

    for (const [placeholder, index] of Object.entries(placeholderGroupMap)) {
        placeholderValues[placeholder] = result ? result[index] || "" : ""
    }

    return placeholderValues
}

export function replacePlaceholdersToMappedValues(text: string, placeholderMapping: Record<string, string>): string {
    return Object.keys(placeholderMapping).reduce((acc, key) => {
        const re = new RegExp(key, "g")
        return acc.replace(re, placeholderMapping[key])
    }, text)
}

function generateSupporterPlaceholders(
    supporter: { firstname?: string; lastname?: string; address?: string; email?: string } = {},
    lastSupporterValues: { firstname?: string; lastname?: string; address?: string; email?: string },
) {
    const { firstname, lastname, address, email } = supporter
    const placeholderMap = {}
    if (firstname) placeholderMap[placeholders.CONTACT_FIRST_NAME] = firstname
    if (lastname) placeholderMap[placeholders.CONTACT_LAST_NAME] = lastname
    if (firstname && lastname) placeholderMap[placeholders.CONTACT_FULL_NAME] = `${firstname} ${lastname}`
    if (address) placeholderMap[placeholders.CONTACT_ADDRESS] = address
    if (email) placeholderMap[placeholders.CONTACT_EMAIL] = email

    // if the user erase the current value, to avoid be "undefined" we bring back the original placeholder
    // example: Firstname was John, user erase to empty, we want to bring back the placeholder {{contact_first_name}}
    // placeholdersMap["John"] = placeholders.CONTACT_FIRST_NAME (we will replace "John" with "{{contact_first_name}}")
    // otherwise, if the user change the current value, we will replace the old value with the new one
    // example: Firstname was John, user changes to Jake, we need to replace "John" with "Jake"
    // placeholdersMap["John"] = "Jake"
    if (lastSupporterValues?.firstname)
        placeholderMap[lastSupporterValues.firstname] = firstname ?? placeholders.CONTACT_FIRST_NAME
    if (lastSupporterValues?.lastname)
        placeholderMap[lastSupporterValues.lastname] = lastname ?? placeholders.CONTACT_LAST_NAME
    if (lastSupporterValues?.address)
        placeholderMap[lastSupporterValues.address] = address ?? placeholders.CONTACT_ADDRESS
    if (lastSupporterValues?.email) placeholderMap[lastSupporterValues.email] = email ?? placeholders.CONTACT_EMAIL
    return placeholderMap
}

export function replaceSupporterNamePlaceholders(
    rawText: string,
    formattedText: string,
    supporter: { firstname?: string; lastname?: string; address?: string; email?: string } = {},
    lastSupporterValues: { firstname?: string; lastname?: string; address?: string; email?: string },
): [string, string] {
    const legislatorAndOtherPlaceholdersMapped = mapPlaceholders(rawText, formattedText)
    const supporterValuesPlaceholderMapped = generateSupporterPlaceholders(supporter, lastSupporterValues)
    const newRawText = replacePlaceholdersToMappedValues(rawText, supporterValuesPlaceholderMapped)
    const supporterPlaceholdersMapped = {
        [placeholders.CONTACT_FIRST_NAME]: supporter.firstname || "Friend",
        [placeholders.CONTACT_LAST_NAME]: supporter.lastname || "Friend",
        [placeholders.CONTACT_ADDRESS]: supporter.address || "your address",
        [placeholders.CONTACT_EMAIL]: supporter.email || "your email",
    }
    const allPlaceholdersMapped = {
        ...legislatorAndOtherPlaceholdersMapped,
        ...supporterPlaceholdersMapped,
    }
    const formattedWithSupporterReplaced = replacePlaceholdersToMappedValues(newRawText, allPlaceholdersMapped)
    return [formattedWithSupporterReplaced, newRawText]
}

export function replaceSupporterPlaceholderOnTargetedMessages(
    targetedMessages: Record<string, TargetedMessage>,
    supporter: { firstname?: string; lastname?: string; address?: string; email?: string } = {},
    lastSupporterValues?: { firstname?: string; lastname?: string; address?: string; email?: string },
) {
    const newTargetedMessages = JSON.parse(JSON.stringify(targetedMessages)) as Record<string, TargetedMessage>
    Object.keys(newTargetedMessages).forEach((key) => {
        const [formattedContent, rawContent] = replaceSupporterNamePlaceholders(
            newTargetedMessages[key].raw.content,
            newTargetedMessages[key].formatted.content,
            supporter,
            lastSupporterValues,
        )
        const [formattedPreContent, rawPreContent] = replaceSupporterNamePlaceholders(
            newTargetedMessages[key].raw.fixed_pre_message,
            newTargetedMessages[key].formatted.fixed_pre_message,
            supporter,
            lastSupporterValues,
        )
        const [formattedPostContent, rawPostContent] = replaceSupporterNamePlaceholders(
            newTargetedMessages[key].raw.fixed_post_message,
            newTargetedMessages[key].formatted.fixed_post_message,
            supporter,
            lastSupporterValues,
        )
        newTargetedMessages[key] = {
            raw: {
                ...newTargetedMessages[key].raw,
                content: rawContent,
                fixed_pre_message: rawPreContent,
                fixed_post_message: rawPostContent,
            },
            formatted: {
                ...newTargetedMessages[key].formatted,
                content: formattedContent,
                fixed_pre_message: formattedPreContent,
                fixed_post_message: formattedPostContent,
            },
        }
    })
    return newTargetedMessages
}
