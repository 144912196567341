import { constants } from "QuorumGrassroots/constants"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { getFontColorForBackgroundContrast } from "QuorumGrassroots/styled-components/helperFunctions"

import styled from "styled-components"

export const Container = styleWithOrgDesign(styled.div<{ isSingleColumnLayout: boolean }>`
    width: 100%;
    height: 100%;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    ${(props) => `color: ${getFontColorForBackgroundContrast(props.organizationDesign.primary_color)};`}

    ${(props) =>
        props.isSingleColumnLayout &&
        `
        color: ${constants.colors.gray[8]};

        & .thank-you-text {
            color: black;
        }
    `}
`)

export const Heading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-bottom: 1.5rem;

    h3 {
        margin: 0;
        font-size: 24px;
        font-weight: 600;
    }

    h5 {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
    }
`
