import React from "react"
import axios from "axios"
// https://github.com/1904labs/dom-to-image-more
// https://github.com/tsayen/dom-to-image/issues/357
// https://github.com/tsayen/dom-to-image/pull/250
import domtoimage from "dom-to-image-more"
import moment from "moment-timezone"
import swal from "sweetalert"
import swalContents from "app/static/frontend/swalConfigs"
import { reverse } from "named-urls"

// components
import { SegueLink } from "app/static/frontend/middleware/segueMiddleware"

// helpers
import { param } from "shared/djangio/Manager"
import { isLoginUrl } from "shared/imports/regex"
import * as helperFunctions from "shared/imports/sharedHelperFunctions"
import { checkPermission } from "shared/imports/permissionFunctions"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"

/**
 * Opening a LiveChat Window
 */
export const openChatWindow = () => {
    // LEG-2714: Forethought AI is not working for Merck Organization. See ticket for more info!
    // COR-2300: removed Forethought access from WEX Inc.
    const orgsWithoutForethoughtAccess = [1550, 2979]

    if (!Userdata.isOnlyEUMode()) {
        try {
            if (!orgsWithoutForethoughtAccess.includes(window.Userdata.organization_id)) {
                window.Forethought("widget", "show")
                window.Forethought("widget", "open")
            } else {
                try {
                    window.zE("webWidget", "show")
                    window.zE("webWidget", "open")
                } catch {
                    window.zE("messenger", "show")
                    window.zE("messenger", "open")
                }
            }
        } catch (e) {
            console.error(e)
            // if there's an error opening the chat, show an error message
            swal({
                title: "Error opening Live Chat",
                icon: "error",
                text: `There was an error opening Live Chat. Your IT department
                    may be blocking our chat service! Please feel free to email your
                    CSM or support@quorum.us`,
            })
        }
    } else {
        window.open("mailto:support@quorumeu.com?subject=Quorum EU Support Request", "_self")
    }
}

/**
 * Check whether LiveChat is available
 */
export const chatIsAvailable = () => {
    const hasPermission = checkPermission(DjangIO.app.models.PermissionType.live_chat)

    // Between 9am and 6pm EST
    const isBusinessHours =
        moment().isoWeekday() <= 5 && // 1 = Monday, 7 = Sunday
        moment().isBetween(
            moment.tz("09:00:00", "HH:mm:ss", "America/New_York"),
            moment.tz("18:00:00", "HH:mm:ss", "America/New_York"),
            "second",
        )

    /* If the user has permission and it's business hours, check if agents are available.
     * Try a few times, because it isn't always ready on the first try.
     * This is a hack, but some users report the Chat option doesn't appear on initial load,
     * but pops in after the TopNav has re-rendered (navigated to another page).
     */
    if (hasPermission && isBusinessHours) {
        const checkAgentStatus = (attemptCount = 0) => {
            if (attemptCount >= 5) {
                return false
            }
            if (window.zE && window.zE("webWidget:get", "chat:department", "Support")?.status === "online") {
                return true
            } else {
                return checkAgentStatus(attemptCount + 1)
            }
        }

        return checkAgentStatus()
    }

    return false
}

export const focusOnLastItem = (className) => {
    // This function will focus on the last item on a page
    // For input fields, the user will be able to type without manually clicking the field.
    // Without this function, users would have to click an 'Add Option' button and then click the newest field before typing.
    // Now it is only one click
    // Can also map this function on the 'Enter' key.
    // Users can add many options without click by typing and hitting enter for the next field

    // Find all of the items on the page
    const items = document.getElementsByClassName(className)

    if (items && items.length) {
        // Focus on the latest option field added
        items[items.length - 1].focus()
    }
}

/**
 * Decodes a string with HTML entities
 * @param   {string} ex: "&lt;link href=&#39;https://fonts.googleapis.com/css?family=Montserrat&#39; rel=&#39;stylesheet&#39; type=&#39;text/css&#39;&gt;"
 *
 * @returns {string} ex: "<link href='https://fonts.googleapis.com/css?family=Montserrat' rel='stylesheet' type='text/css'>"
 */
export const decodeHtmlEntities = (inputString) => {
    const e = document.createElement("div")
    e.innerHTML = inputString
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue
}

/**
 * Handles a 205 Reset Content.
 * See app/api/classes.py for further documentation.
 * or https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/205.
 *
 * @param {Object} axiosResponse - an accepted HTTP response
 * @return {Object} The "data" parameter of the HTTP response
 */
export const handle205 = (axiosResponse) => {
    if (axiosResponse?.data?.status_code === 205) {
        window.location.href = axiosResponse.data.return_url
    }
    return axiosResponse?.data
}

/**
 * Determines whether a document or a bill text (really any object supporting
 * language_pdf_urls) is a multi-language PDF document. Used to determine how
 * a PDF viewer should be rendered.
 *
 * @param {Object} doc - A document or bill text.
 * @returns {boolean}
 */
export const isMultiLanguagePDF = (doc) => doc && doc.language_pdf_urls && Object.keys(doc.language_pdf_urls).length > 0

/**
Gets the default language for a multilanguage pdf viewer (document, bill, billtexttab)
 * @param {Object} languages - { language_enum: pdf }
 * @returns the default language from the dict
 */
export const getDefaultLanguage = (languages) => {
    const english = String(DjangIO.app.document.types.Language.english.value)
    const defaultLanguage = Object.keys(languages).includes(english) ? english : Object.keys(languages).sort()[0]
    return defaultLanguage
}

// formats the language object into the object valid for the discrete select
export const formatLanguages = (languages) =>
    Object.keys(languages).map((language) => ({
        value: language,
        label: DjangIO.app.document.types.Language.by_value(language).label,
    }))

// gets the selected language from the options available in the language dict
export const getLanguageUrl = ({ options, selected }) => options[selected]

// export generateStaticUrl for legacy pages
window.generateStaticUrl = helperFunctions.generateStaticUrl

/**
 * recursively converts DOM nodes into React elements
 * @param   {(DOM) Node} element the DOM Node
 * @param   {number} index the unique key
 * @param   {number} id the ID of the parent Component
 *
 * @returns {JSX} the new JSX element
 */
export const reactify = (element, index, id, disableAnchors) => {
    // get attributes of DOM nodes and add them to a map to pass to the React element
    const domAttrs = element.attributes
    // map to hold attributes of vanilla DOM nodes
    const attributes = {}
    // map to hold the inline style values of vanilla DOM nodes
    const style = {}

    // populate map so we can pass it into our custom React component
    if (domAttrs) {
        // NamedNodeMap does not have a forEach
        for (let i = 0; i < domAttrs.length; i += 1) {
            // React-specific camelCase syntax rules
            // https://reactjs.org/blog/2017/09/08/dom-attributes-in-react-16.html
            // https://github.com/facebook/react/pull/14268
            // https://github.com/facebook/react/blob/master/packages/react-dom/src/shared/possibleStandardNames.js
            switch (domAttrs[i].name) {
                case "bgcolor":
                    style.backgroundColor = domAttrs[i].value
                    break
                case "contenteditable":
                    attributes.contentEditable = domAttrs[i].value
                    // https://github.com/facebook/draft-js/issues/81
                    attributes.suppressContentEditableWarning = true
                    break
                case "style":
                    // This is required since the element.style CSSStyleDeclaration object contains every single possible css key
                    //      (including browser-prefixed elements that will cause React to throw warnings)
                    // The CSSStyleDeclaration object contains a weird mix of array indices and key/value pairs, so we are using a
                    //      vanilla for(;;) loop to be safe
                    for (let j = 0; j < element.style.length; j += 1) {
                        // required since the CSSStyleDeclaration declares its style array values (keys) in snake_case
                        //      but stores its actual key/val pairs in camelCase
                        const camelCaseKey = element.style[j].replace(/-([a-z])/g, (g) => g[1].toUpperCase())
                        // pass the camelCased key to React, since React does not recognize snake_case inline css rules
                        style[camelCaseKey] = element.style.getPropertyValue(element.style[j])
                    }
                    break
                // some press release and dear colleague documents contain <p> and <table> elements with the HTML5 incompatible align attribute
                // some tweet content fields contain <img> elements with the HTML5 incompatible border attribute
                // some constituent email fields contain elements with HTML5 incompatible attributes
                case "align":
                case "border":
                case "face":
                case "vspace":
                case "hspace":
                case "valign":
                    break
                default:
                    // Despite what the html5 spec claims,
                    // html5 element attribute names that contain any unicode character (for example, 'xuoﬁ') cause a
                    // "DOMException: Failed to execute 'setAttribute' on 'Element': '...' is not a valid attribute name."
                    // on all major browsers.

                    // Valid html5 attribute characters, as of this comment,
                    // include A-Z characters (case insensitive) and '-' for custom data attributes
                    // https://html.spec.whatwg.org/multipage/dom.html#attributes
                    // https://html.spec.whatwg.org/multipage/syntax.html#attributes-0
                    // https://html.spec.whatwg.org/multipage/dom.html#custom-data-attribute
                    // https://html.spec.whatwg.org/multipage/custom-elements.html#custom-elements-core-concepts
                    // https://www.w3.org/TR/2011/WD-html5-20110525/elements.html#attributes
                    // https://www.w3.org/TR/2011/WD-html5-20110525/syntax.html#attributes-0
                    // https://www.w3.org/TR/2011/WD-html5-20110525/elements.html#custom-data-attribute
                    // https://www.w3.org/TR/2016/WD-custom-elements-20160830/#custom-elements-core-concepts
                    // https://www.w3.org/TR/html4/index/attributes.html

                    // To fix this, we strip out all invalid characters.
                    // https://stackoverflow.com/questions/925994/926136#comment33673269_926136
                    const attributeNameRegex = /[^A-Za-z-]/
                    const attributeName = attributeNameRegex.test(domAttrs[i].name)
                        ? domAttrs[i].name.replace(attributeNameRegex, "")
                        : domAttrs[i].name
                    attributes[attributeName] = domAttrs[i].value
                    break
            }
        }
    }

    // prevent warning about modifying a function parameter
    let uniqueKey = index

    // reactify all child nodes of a given parent
    const childHelper = (parent) => {
        const children = []
        parent.childNodes.forEach((childNode) => {
            children.push(reactify(childNode, (uniqueKey += 1), id, disableAnchors))
        })
        return children
    }

    // get the element type
    // variable name must be PascalCase in order for React to correctly parse it into a built-in element
    // https://stackoverflow.com/a/33471928
    const TagName = element.tagName
    const Tag = TagName && TagName.toLowerCase()

    // use unique keys when rendering dynamic React elements
    const key = `${id}${uniqueKey}`

    // handle internal linking with our custom Segue middleware
    if (TagName === "A") {
        if (!disableAnchors) {
            // internal quorum Segue links are structured as relative URLs, but the parsed DOM node replaces the
            //      relative href (i.e., /project_profile/190/) with the
            //      absolute URL (i.e., http://localhost:8000/project_profile/190/)
            // element.href is any external absolute URL
            let href = element.getAttribute("href")
            let link = {}
            let quorumSegue = false

            // we check to see if the attribute exists as some anchor elements may not declare an href value
            //      (which happens in some Document Inlines)
            if (href) {
                if (
                    // the only semantic difference between internal quorum segues and external URLs is the leading '/'
                    href.charAt(0) === "/" &&
                    // this href syntax is deprecated and breaks when invoking any element properties in Edge 17+
                    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Authentication#Access_using_credentials_in_the_URL
                    !isLoginUrl.test(href)
                ) {
                    // we do not want to segue to downloads since they will 404
                    if (!href.includes("/api/")) {
                        quorumSegue = true
                    }

                    // pathname returns only the content that follows the anchor host (only the Segue link)
                    if (!href.includes("?")) {
                        href = element.pathname
                    }
                }
            }

            const Tag = quorumSegue ? SegueLink : "a"
            link = (
                <Tag
                    {...attributes}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    // if we are dealing with a Quorum segue, set middleware attribute appropriately
                    to={quorumSegue ? href : undefined}
                    // if we are dealing with a plain URL, do not create a Segue object (instead set the href attr)
                    href={href}
                    style={style}
                    // if we are dealing with a plain (external) URL, make sure it opens in a new tab onClick
                    target={!quorumSegue ? "_blank" : undefined}
                    rel={!quorumSegue ? "noopener noreferrer" : undefined}
                >
                    {childHelper(element)}
                </Tag>
            )

            // height=0 is necessary because, on the first render frame,
            // the <object> uses an enormous default height which breaks the
            // app/static/frontend/widgets/components/ListWidget.jsx react-virtaulized AutoSizer/CellMeasurer
            return (
                <object height="0" width="0" key={key}>
                    {link}
                </object>
            )
        } else {
            return element.textContent
        }
    }
    // all html elements have some base text content (or nothing)
    // DOM Core level 2 properties, so they should work in IE6+, Firefox 2+, Chrome 1+ etc
    // https://caniuse.com/#search=nodeName
    else if (element.nodeType === Node.TEXT_NODE) {
        // only return the textContent if the string is non-empty
        // https://stackoverflow.com/a/10262019
        if (element.textContent.replace(/\s/g, "").length) {
            return element.textContent
        }
        return undefined
    }
    // void elements are not allowed to have any content nor children
    // https://html.spec.whatwg.org/multipage/syntax.html#void-elements
    else if (
        [
            "AREA",
            "BASE",
            "BR",
            "COL",
            "EMBED",
            "HR",
            "IMG",
            "INPUT",
            "LINK",
            "META",
            "PARAM",
            "SOURCE",
            "TRACK",
            "WBR",
        ].includes(TagName)
    ) {
        return <Tag {...attributes} key={key} style={style} />
    }
    // generalize creation of built-in html elements that allow nesting
    // https://stackoverflow.com/a/26287085
    else if (element.nodeType === Node.ELEMENT_NODE) {
        // the DOMParser() can return elements with a malformed TagName if the html is incorrect...
        if (!isValidTagName(TagName)) {
            return childHelper(element)
        }

        return (
            <Tag {...attributes} key={key} style={style}>
                {childHelper(element)}
            </Tag>
        )
    }
}

// convert backend-generated html to React JSX (hrefs to our SegueLink Component, etc.)
// we use this for both the aforementoned reason and instead of:
//     React's dangerouslySetInnerHTML because it is dangerous to pass raw HTML (XSS) and we want to whitelist specific elements
//     our old querySelectorAll hack which grabbed and modified DOM nodes after we had already rendered them to the page because
//          it is a React anti-pattern to modify DOM nodes in a stateful React component (since React keeps track of the VDOM)

// this performs around the same amount of work as rendering React elements to the page and grabbing the DOM nodes, but instead
//      of waiting until React renders and populates the VDOM and then grabbing the vanilla DOM nodes with a querySelector,
//      we are instead creating the vanilla JS DOM and parsing the elements into React nodes with their Quorum-specific
//      functionality (before React renders them to the page). This avoids directly modifying the document DOM
//      (instead of React's VDOM), avoiding what is generally considered to be a React anti-pattern

// this is currently being used to convert:
//      the Note Inline's firstLineData and thirdLineData
//      the Document Inline's thirdLinedata (in certain cases)
export const parseHtmlToReact = (html, inlineId, disableAnchors, useFullDocument) => {
    // parse a raw html string into DOM nodes
    const doc = new DOMParser().parseFromString(html, "text/html")
    // grab the nodes from the DOM tree
    // some callers need to be able to parse the full document instead of just the body
    const elements = useFullDocument ? doc.childNodes : doc.body.childNodes
    const react = []

    // IE-compatible NodeList iteration
    // https://developer.mozilla.org/en-US/docs/Web/API/NodeList
    // https://gist.github.com/bendc/4090e383865d81b4b684
    Array.prototype.forEach.call(elements, (element, index) => {
        // convert each DOM node to a react element
        react.push(reactify(element, index, inlineId, disableAnchors))
    })

    // return an array of React elements that we can pass to a React component
    return react
}

const isValidTagName = (tagName) => {
    return /^[A-Z]+\d?$/.test(tagName)
}

// https://stackoverflow.com/a/15458968
export const stringContainsHTMLMarkup = (str) => {
    const doc = new DOMParser().parseFromString(str, "text/html")
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1)
}

/**
 * Downloads a file of a certain type, with items from a specific project and/or action center
 * @param {Object} qdt - The QuorumDataType enum of the targeted items
 * @param {String} type - The type of download, (e.g. "csv", "docx")
 * @param {Integer or String} projectId - The id of the project
 * @param {Integer or String} actionCenterId - The id of the action center that the items should be related to
 * @param {Integer or String} customEventId - The id of the custom event that the items should be related to
 * @returns {Promise}
 */
export const downloadItemsFromProject = ({
    qdt,
    type,
    projectId = undefined,
    actionCenterId = undefined,
    customEventId = undefined,
}) =>
    DjangIO.resourceFromDataType(qdt.value)
        .objects.filter({
            format: type,
            ...(projectId && { projects__in: projectId }),
            ...(actionCenterId && { action_center: actionCenterId }),
            ...(customEventId && { event: customEventId }),
        })
        .download()

/**
 * Formats named react router urls with the url arguments and params
 * If there are optional arguments (indicated by parantheses), we remove them from url
 * If there are url params defined, append them to end of url with a '?'
 * @param {String} urlPattern - React router url
 * @param {Object} urlArguments - The arguments that need to be replaced
 * @param {Object} urlParams - Any params that need to be appended to url
 * @param {Boolean} stripUndefinedParams - Indicates if undefined params should be exlcluded from url
 * @returns {String} - Final url with replaced url arguments and added url params
 */
export const formatUrlFromPath = (urlPattern, urlArguments, urlParams, stripUndefinedParams = true) => {
    const url = reverse(urlPattern, urlArguments).replace("(", "").replace(")", "")

    const params = param(urlParams, stripUndefinedParams)

    return `${url}${params && "?"}${params}`
}

/*
 * A function to download PDFs from a url with a different domain than quorum.us
 * This was necessary because jquery-file-download did not function for cross-site downloads
 *
 * This function creates a new XMLHttpRequest with a 'GET' request to the PDF url
 * On successfully fetching the PDF data, it creates a Blob (a file-like object of immutable, raw data) with the data
 * Then an 'a' tag is created with the href pointing to the Blob
 * The download attribute is set so the filename is specified and a download will initiate on click
 * We simulate a click on the 'a' tag, and the PDF is finally downloaded
 *
 * @name downloadPDFCrossDomain
 * @function
 * @param {string} url - The URL of the PDF. Usually a link to our S3 bucket
 * @param {string} filename - The desired filename. Usually will be the slug of the current blog post. If undefined, will be 'Quorum-Content'
 */
export const downloadPDFCrossDomain = (url, fileName = "Quorum-Content") =>
    new Promise((resolve) => {
        const xhr = new XMLHttpRequest()

        xhr.open("GET", url, true)
        xhr.responseType = "arraybuffer"

        xhr.onload = () => {
            if (xhr.status === 200) {
                const blob = new Blob([xhr.response], { type: "application/pdf" })
                const link = document.createElement("a")
                link.href = window.URL.createObjectURL(blob)
                link.download = `${fileName}.pdf`
                link.click()
                resolve(xhr.response)
            }
        }

        xhr.send()
    })

/**
 * Checks whether the browser and any element is currently fullscreen. Meant to work on all supported (non-mobile) browsers.
 *
 * @name isFullscreen
 * @returns {Boolean}: whether the browser is currently fullscreen
 */
export const isFullscreen = () =>
    ["fullscreenElement", "mozFullScreen", "webkitFullscreenElement", "msFullscreenElement"].some((e) => !!document[e])

/**
 * A function to enter (or exit, if currently in) fullscreen mode, and is meant to work with all supported (non-mobile) browsers. Full screen mode
 * can be used in the entire document (page) or to make just one element fullscreen. Important note: if using this, css changes might need to be
 * made to the page/element to properly support fullscreen.
 *
 * @name toggleFullscreen
 * @param {Element} - (optional) the element to expand into fullscreen. If none is passed, uses entire document
 */
export const toggleFullscreen = (element) => {
    element = element || document.documentElement
    if (isFullscreen()) {
        const cancelFullscreen = ["exitFullscreen", "mozCancelFullScreen", "webkitCancelFullScreen", "msExitFullscreen"]
        cancelFullscreen.map((func) => document[func] && document[func]())
    } else {
        const enterFullscreen = [
            "requestFullscreen",
            "mozRequestFullScreen",
            "webkitRequestFullScreen",
            "msRequestFullscreen",
        ]
        enterFullscreen.map((func) => element[func] && element[func]())
    }
}

/**
 * A function to flatten an object to a single-level
 *
 * @param {Object} object - The object to flatten, e.g. * { 'a':{ 'b':{ 'b2':2 }, 'c':{ 'c2':2, 'c3':3 } } }
 * @param {String} prefix - (optional) a prefix for each key
 * @returns {Object}: The flattened object, e.g. {a.b.b2: 2, a.c.c2: 2, a.c.c3: 3}
 */
export const flattenObject = (object, prefix = "") => {
    return Object.keys(object).reduce((prev, element) => {
        return object[element] && typeof object[element] == "object" && !Array.isArray(element)
            ? { ...prev, ...flattenObject(object[element], `${prefix}${element}.`) }
            : { ...prev, ...{ [`${prefix}${element}`]: object[element] } }
    }, {})
}

/**
 * A function to lighten or darken a hex color code
 * Stolen from Stack Overflow https://stackoverflow.com/a/13532993
 *
 * @param {String} colorHexCode - The base color hex code. Should include the '#', ex: "#503ABD"
 * @param {Integer} percent - How much the color should be lightened or darkened. Positive percentage for lighter colors, negative percentage for darker
 * @returns {String} - The new color hex code
 */
export const shadeColor = (colorHexCode, percent) => {
    let r = parseInt(colorHexCode.substring(1, 3), 16)
    let g = parseInt(colorHexCode.substring(3, 5), 16)
    let b = parseInt(colorHexCode.substring(5, 7), 16)

    r = parseInt((r * (100 + percent)) / 100)
    g = parseInt((g * (100 + percent)) / 100)
    b = parseInt((b * (100 + percent)) / 100)

    r = r < 255 ? r : 255
    g = g < 255 ? g : 255
    b = b < 255 ? b : 255

    const rr = r.toString(16).length === 1 ? `0${r.toString(16)}` : r.toString(16)
    const gg = g.toString(16).length === 1 ? `0${g.toString(16)}` : g.toString(16)
    const bb = b.toString(16).length === 1 ? `0${b.toString(16)}` : b.toString(16)

    return `#${rr}${gg}${bb}`
}

/**
 * A function to convert a hex code to a string representation of a RGBA function
 *
 * @param {String} colorHexCode - The base color hex code.  Including or not including the '#', ex: "#503ABD"
 * @param {Integer} alpha - The alpha value, ex: 0.4
 * @returns {String} - The rgba function
 */
export const hexCodetoRGBA = (colorHexCode, alpha = 1) => {
    let parsedColorHexCode = colorHexCode.replace("#", "")

    if (parsedColorHexCode.length === 3) {
        // If passed in a hex shortcut like '#fff', convert to full hexcode
        parsedColorHexCode = `${colorHexCode[0]}${colorHexCode[0]}${colorHexCode[1]}${colorHexCode[1]}${colorHexCode[2]}${colorHexCode[2]}`
    }

    const [r, g, b] = parsedColorHexCode.match(/\w\w/g).map((x) => parseInt(x, 16))
    return `rgba(${r},${g},${b},${alpha})`
}

/**
 * https://stackoverflow.com/questions/49986720/#comment93030939_49986758
 * Checks whether or not the current browser is internet explorer
 *
 * @returns {bool} - Whether or not the current browser is Internet Explorer
 */
export const isIE = () => window.navigator.userAgent.match(/(MSIE|Trident)/)

export const saveElementAsImage = async ({ element, format, title }) => {
    const titleFormat = `${title}.${format}`

    // if we are downloading an intermediary svg,
    // we want to format it to the exact width of an A4 Letter
    // since it will eventually be converted and saved as a pdf
    if (format === "pdf") {
        element.style.maxWidth = "800px"
        window.dispatchEvent(new Event("resize"))

        // Even though window.dispatchEvent(new Event("resize")) is a blocking call,
        // the app/static/frontend/dashboards/components/Dashboard.jsx { WidthProvider as provideWidth }
        // has a slight delay in its repaint event.

        // Similarly, app/static/frontend/widgets/components/visualization/hooks/visualizationHooks.jsx onResize
        // is debounced by 250ms.

        // So to ensure that both the ReactGridLayout WidthProvider and each individual has resized correctly
        // before we convert the entire Dashboard to png/jpg/svg,
        // we wait for at least the amount of time it will take for the Visualization Widgets to repaint after the RGL resize event.
        await new Promise((r) => setTimeout(r, 300))
    }

    // optional filter function for dom-to-image-more
    // https://github.com/1904labs/dom-to-image-more#usage
    // TODO: temporarily disabled because it slightly misplaces some icons
    const filter = (node) => {
        // id blacklist
        if (
            [
                // app/static/frontend/dashboards/components/DashboardHeader.jsx
                // "dashboard-download-button",
                // app/static/frontend/dashboards/selectors.js getDasboardWidgetDefaultHeaderIcons
                "widget-header-download-icon",
                // app/static/frontend/widgets/selectors/listWidgetSelectors.js getListWidgetHeaderIcons
                "widget-header-search-icon",
            ].includes(node.id)
        ) {
            return false
        }

        return node
    }

    const getConvertedImage = () => {
        switch (format) {
            case "png":
                return domtoimage.toPng(element, {
                    filter,
                    width: element.scrollWidth,
                    height: element.scrollHeight,
                    style: { overflow: "hidden" },
                })
            case "jpg":
            case "jpeg":
                return domtoimage.toJpeg(element, {
                    filter,
                    quality: 1.0,
                })
            case "svg":
            case "pdf":
                // we use an svg as a portable intermediary representation of the Dashboard content
                // (it is converted to pdf below using chrome/chromium on update/production instances)
                return domtoimage.toSvg(element, { filter })
            default:
                return domtoimage.toPng(element, { width: element.scrollWidth, height: element.scrollHeight })
        }
    }

    await getConvertedImage().then(async (dataUrl) => {
        const anchor = document.createElement("a")
        anchor.href = dataUrl

        // this works, but I have only enabled png downloads in
        // app/static/frontend/dashboards/components/DashboardHeader.jsx
        // app/static/frontend/dashboards/selectors.js getDasboardWidgetDefaultHeaderIcons
        // app/static/frontend/widgets/selectors/listWidgetSelectors.js getListWidgetHeaderIcons
        // since that is the immediate product requirement
        if (format === "pdf") {
            await axios
                .post(
                    // app/generatepdf/views.py svg_to_pdf
                    "/generatepdf/svg_to_pdf/",
                    {
                        data: dataUrl.replace(/data:image\/svg\+xml;charset=utf-8,/, ""),
                        title,
                    },
                    {
                        headers: {
                            Accept: "application/pdf",
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                        // to avoid the default browser UTF-8 to DOMstring (UTF-16) conversion,
                        // (which breaks binary pdf content)
                        // set the config to responseType: "blob"
                        // https://stackoverflow.com/a/42992484/6201291
                        // https://github.com/axios/axios/issues/1392#issuecomment-430898057
                        responseType: "blob",
                    },
                )
                .then((response) => {
                    anchor.href = window.URL.createObjectURL(new Blob([response], { type: "application/pdf" }))
                })
        }

        anchor.download = titleFormat
        anchor.click()
    })

    // if we are downloading an intermediary svg,
    // we want to remove the A4 Letter
    // formatting now that we have converted and saved the svg/pdf
    if (format === "pdf") {
        element.style.removeProperty("max-width")
        window.dispatchEvent(new Event("resize"))
    }
}

/**
 * Ater mounting the application, check for the session_expires cookie and verify that the current session is still valid.
 * If it is, create a timeout to check again close to the expiration date. Repeat until the session has expired, or is about to,
 * and then fire a swal forcing the user to refresh the page and log back in.
 *
 * Notes:
 * Firefox does not start timeouts until the initial page load is complete. This shouldn't matter,
 * but it's worth mentioning.
 *
 * Because this function is recursive, it is technically possible - though unlikely - for us to blow the call stack with it.
 * Maybe there is a better way for us to accomplish this effect with clearing and creating new intervals,
 * or storing the expiration in Redux and using a simpler interval that fires every minute?
 */
export const pollSessionExpiration = () =>
    window.setTimeout(function poll() {
        const now = new Date()

        // Grab the session_expires cookie
        const cookie = helperFunctions.readCookieByKey("session_expires")
        // If there is no session_expires cookie, don't do anything
        if (!cookie) {
            return
        }

        // Parse the session_expiration cookie into a date, stripping \" from the start and end of the string
        const expiresOnStr = cookie.replace(/"/g, "")
        const expiresOnDate = new Date(expiresOnStr)

        // Milliseconds until the current session expires
        const ttl = expiresOnDate - now

        // Check if we're past, or nearly past expiration, and force the user to refresh if we are.
        // Otherwise, reset the timeout to check again after ttl ms.
        if (ttl <= 1000) {
            swal(swalContents.SESSION_EXPIRED).then(() => window.location.reload())
        } else {
            /**
             * Maximum size of a signed 32bit integer - equal to about 25 days in milliseconds.
             * Timeouts greater than this number overflow and fire immediately,
             * so we'll clamp the timeout at this maximum value.
             */
            const MAX_INT32 = Math.pow(2, 31) - 1
            setTimeout(poll, Math.min(ttl, MAX_INT32))
        }
    })

export const hasOverflow = (element) =>
    element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth

/**
 * Replaces regex characters in a string with escaped versions of those characters. This ensures
 * the special characters are treated as literal characters rather than regex metacharacters.
 *
 * The function takes a string as input and returns a new string with all regular expression special
 * characters escaped. This means that any characters that have a special meaning in regular expressions
 * are preceded by a backslash (\) in the returned string.
 *
 * @example
 * // returns "\\[\\]\\^\\$\\.\\|\\?\\*\\+\\("
 * escapeRegExpCharacters("[]^$.|?*+(");
 *
 * @example
 * // returns "\\\\PAC\\ Snack\\!"
 * escapeRegExpCharacters("\\PAC Snack!");
 *
 * @param {string} string - The string to escape.
 * @returns {string} The modified string with regex characters escaped.
 */
export const escapeRegExp = (string) => string.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&")

module.exports = {
    handle205,
    openChatWindow,
    chatIsAvailable,
    parseHtmlToReact,
    reactify,
    stringContainsHTMLMarkup,
    focusOnLastItem,
    decodeHtmlEntities,
    isMultiLanguagePDF,
    getDefaultLanguage,
    formatLanguages,
    getLanguageUrl,
    downloadItemsFromProject,
    formatUrlFromPath,
    downloadPDFCrossDomain,
    isFullscreen,
    toggleFullscreen,
    flattenObject,
    shadeColor,
    hexCodetoRGBA,
    isIE,
    saveElementAsImage,
    pollSessionExpiration,
    hasOverflow,
    escapeRegExp,
    ...helperFunctions,
}
