import React from "react"
import PropTypes from "prop-types"
import { centsToCurrencyStr } from "shared/pac/helperFunctions"

import {
    StyledDonationHistoryContainer,
    StyledDonationHistoryFirstSection,
    StyledDonationHistorySecondSection,
    StyledDonationHistorySectionContent,
    StyledDonationHistorySectionContentHeading,
    StyledDonationHistorySectionContentItem,
    StyledDonationHistorySectionContentItemLabel,
    StyledDonationHistorySectionTitle,
    StyledDonationHistoryTitle,
    StyledDonationHistoryWrapper,
} from "./style"

export const DonationHistoryWidget = ({
    lastTransactionAmount,
    lastTransactionDate,
    lastTransactionMethod,
    title,
    transactionTotalsByYear,
}) => {
    const lastDate = lastTransactionDate
    const lastMethod = lastTransactionMethod
    const lastValue = lastTransactionAmount === 0 ? 0 : lastTransactionAmount
    const lastValueStr = centsToCurrencyStr(lastValue)
    const totalsByYear = transactionTotalsByYear || []

    return (
        <StyledDonationHistoryContainer>
            {title && (
                <StyledDonationHistoryTitle>
                    <div data-cy="title">{title}</div>
                </StyledDonationHistoryTitle>
            )}
            <StyledDonationHistoryWrapper>
                <StyledDonationHistoryFirstSection>
                    <StyledDonationHistorySectionTitle>Last contribution</StyledDonationHistorySectionTitle>
                    <StyledDonationHistorySectionContent>
                        <StyledDonationHistorySectionContentItem>
                            {lastDate === "Never" ? (
                                <StyledDonationHistorySectionContentHeading>
                                    No prior contributions
                                </StyledDonationHistorySectionContentHeading>
                            ) : (
                                <>
                                    <StyledDonationHistorySectionContentHeading>
                                        {lastDate}
                                    </StyledDonationHistorySectionContentHeading>
                                    <StyledDonationHistorySectionContentItemLabel>
                                        Contributed {lastValueStr} {lastValue > 0 && `via ${lastMethod}`}
                                    </StyledDonationHistorySectionContentItemLabel>
                                </>
                            )}
                        </StyledDonationHistorySectionContentItem>
                    </StyledDonationHistorySectionContent>
                </StyledDonationHistoryFirstSection>
                {totalsByYear.length > 0 && (
                    <StyledDonationHistorySecondSection>
                        <StyledDonationHistorySectionTitle>Totals per year</StyledDonationHistorySectionTitle>
                        <StyledDonationHistorySectionContent>
                            {totalsByYear.map((total) => {
                                let value = centsToCurrencyStr(total.value)
                                return (
                                    <StyledDonationHistorySectionContentItem>
                                        <StyledDonationHistorySectionContentHeading>
                                            {total.year}
                                        </StyledDonationHistorySectionContentHeading>
                                        <StyledDonationHistorySectionContentItemLabel>
                                            {value}
                                        </StyledDonationHistorySectionContentItemLabel>
                                    </StyledDonationHistorySectionContentItem>
                                )
                            })}
                        </StyledDonationHistorySectionContent>
                    </StyledDonationHistorySecondSection>
                )}
            </StyledDonationHistoryWrapper>
        </StyledDonationHistoryContainer>
    )
}

DonationHistoryWidget.propTypes = {
    lastContributionDate: PropTypes.string,
    lastContributionMethod: PropTypes.string,
    lastContributionValue: PropTypes.number,
    lastTransactionAmount: PropTypes.number,
    lastTransactionDate: PropTypes.string,
    lastTransactionMethod: PropTypes.string,
    title: PropTypes.string,
    transactionTotalsByYear: PropTypes.array,
}

export default DonationHistoryWidget
