// constants
import { isInternational, internationalRegions } from "shared/imports/regionConstants"
import {
    BLANK_AVATAR_PATH,
    FEDERAL_SEAL_AVATAR_PATH,
    SEAL_AVATAR_PATH,
    PARTY_COLORS,
    QUORUM_BLUE,
} from "shared/imports/sharedConstants"
import { mobileBreakpoint, QuorumTheme } from "app/static/frontend/design-constants"

// helpers
import {
    dataObjectToProfileLink,
    deduplicatedObjectArrayToString,
    editorialPrecedenceSorter,
    formatPhoneNumber,
    generateStaticUrl,
    mrtPrecedenceSorter,
    stringContainsHTMLMarkup,
} from "shared/imports/sharedHelperFunctions"
import { checkRegionsWithinRegions } from "shared/imports/permissionFunctions"

import { stripHtml } from "quorum/static/frontend/marketing-website/constants/functions"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"

// selectors
import { getSecondLineIcon } from "shared/search/inline-selectors/inline-selectors"
import { shouldHideLocalOfficialsContact } from "shared/userdata/userdata-selectors"

export const getDefinedFieldsChildren = (datum, fields) =>
    fields.map((field) => datum.getIn(field)).filter((field) => Boolean(field))

// only needed for mobile — on desktop, we can pass the value of the enum as a className
// i.e., "republican" or "democrat"
export const getPartyBorderColor = (most_recent_party) => {
    switch (most_recent_party) {
        case DjangIO.app.person.types.Party.Republican.value:
            return PARTY_COLORS.REPUBLICAN
        case DjangIO.app.person.types.Party.Democrat.value:
            return PARTY_COLORS.DEMOCRAT
        case DjangIO.app.person.types.Party.Independent.value:
            return PARTY_COLORS.INDEPENDENT
        default:
            return isFeatureEnabled("ff_new_card_square") ? QuorumTheme.colors.gray[3] : "grey"
    }
}

export const getImage = (datum, federalCase, nullCase) => {
    // if there's a URL linking to an image, link to it!
    if (datum.get("image_url")) {
        // if it's a custom official we don't want to generate a static URL
        if (datum.get("organization") || datum.get("user")) {
            return datum.get("image_url")
        }
        return generateStaticUrl(datum.get("image_url"))
    }

    // otherwise, determine the default image in the cases:
    // 1) there is no region
    // 2) region is federal
    // 3) region is states
    let defaultImagePath = nullCase

    const region = datum.get("most_recent_region")
    if (region && region === DjangIO.app.models.Region.federal.value) {
        defaultImagePath = federalCase
    } else if (!isInternational && region) {
        defaultImagePath = `${SEAL_AVATAR_PATH}${DjangIO.app.models.Region.by_value(region)?.region_name?.replace(
            / /g,
            "",
        )}.png`
    }

    // if nullCase is undefined, then don't return any string
    if (defaultImagePath) {
        return generateStaticUrl(defaultImagePath)
    }

    return undefined
}

export const getCurrentMajorRoleTypes = (currentMajorRoleTypes) => {
    if (currentMajorRoleTypes && currentMajorRoleTypes.size) {
        return deduplicatedObjectArrayToString({
            enumeration: DjangIO.app.person.newtypes.MajorRoleType.region_supported_items(),
            values: currentMajorRoleTypes.toJS(),
            attribute: "title",
        })
    }

    return undefined
}

export const isInternationalOfficial = (mostRecentRegions) =>
    Boolean(mostRecentRegions) && checkRegionsWithinRegions(mostRecentRegions, internationalRegions)

const isPerson = true

export const memberInlineSelectorGetSecondLineDS = (secondLine, isCommittees, mostRecentRegion, mostRecentRegions) => {
    const icon = isCommittees ? getSecondLineIcon("gavel") : getSecondLineIcon("address-card")

    if (mostRecentRegion === DjangIO.app.models.Region.federal.value) {
        return {
            ...icon,
            isHtml: stringContainsHTMLMarkup(secondLine),
            value: secondLine,
        }
    }

    if (isInternationalOfficial(mostRecentRegions) && !isCommittees) {
        // Parse an international member's Biography current role values for display
        // on the search card's preview. Only show 1 role and its corresponding organization
        // on the card's preview. Any others will be shown in the toggletip, which
        // displays all current roles and corresponding orgs, as seen on the international
        // member's profile page Current Roles section.
        const splitRoles = secondLine.split(" ^^^ ")
        const numRoles = splitRoles.length

        const getSinglePreviewLineValue = () => {
            if (numRoles === 1) {
                return stripHtml(secondLine).replace(" *** ", " - ")
            }

            if (numRoles >= 2) {
                const singleRoleValue = splitRoles[0]

                const replacedSingleRoleValue = singleRoleValue.replace(" *** ", " - ")

                return `${stripHtml(replacedSingleRoleValue)}... `
            }

            return ""
        }

        const getToggletipAllRolesValue = () => {
            const listItems = splitRoles.map((role) => role.replace(" *** ", " - ")).join(" ")
            return `<ul>${listItems}</ul>`
        }

        const structuredSecondLine = {
            ...icon,
            isHtml: stringContainsHTMLMarkup(secondLine),
            value: getSinglePreviewLineValue(),
            valueTooltip: numRoles >= 2 && getToggletipAllRolesValue(),
        }

        return structuredSecondLine
    }

    // Default rendering format, for non-federal members and non-international members, etc.
    // Displays a preview with the first one to two committees. there are 3 or more
    // A toggletip will render and will display all committees.
    // replaceAll is NOT supported by all of our users' browsers
    const splitCommittees = secondLine.replace(" | ", ", ").split(", ")
    const parsedSecondLine = splitCommittees.join(" - ")
    const numCommittees = parsedSecondLine.split(" - ").length
    const getSinglePreviewLineValueDefault = () => {
        if (numCommittees > 2) {
            const value = parsedSecondLine.split(" - ").slice(0, 2).join(" - ")
            return `${value}... `
        }

        return parsedSecondLine
    }

    const getToggletipValueDefault = () => {
        const listItems = splitCommittees.map(
            (committee, index) => `<li key={${stripHtml(committee)}-${index}}>${committee}</li>`,
        )

        const joinedListItems = listItems.join(" ")
        return `<ul>${joinedListItems}</ul>`
    }

    return {
        ...icon,
        isHtml: stringContainsHTMLMarkup(secondLine),
        value: getSinglePreviewLineValueDefault(),
        valueTooltip: numCommittees > 2 && getToggletipValueDefault(),
    }
}

const memberInlineSelector = (datum) => {
    const isCustom = !!(datum.get("organization") || datum.get("user"))
    const firstLine = datum.get("name")
    const roleType = datum.get("most_recent_role_type")
    // second line logic -- taken and modified from inlines.jsx. We'll need to pare down later.
    const getSecondLine = () => {
        if (datum.get("committee_string")) {
            return datum.get("committee_string")
        } else {
            const secondLine = []
            const regionEnum = DjangIO.app.models.Region.by_value(datum.get("most_recent_region"))

            const origBioRoleData = datum
                ?.getIn(["_extra", "person_biography_role_data"])
                ?.get("biography_ph_role_data")

            if (isInternationalOfficial(datum.get("most_recent_regions")) && origBioRoleData) {
                const sortedOrigBioRoleData = origBioRoleData.sort(
                    isFeatureEnabled("ff_intntl_eu_editorial_precedence")
                        ? editorialPrecedenceSorter
                        : mrtPrecedenceSorter,
                )
                const currentRoles = sortedOrigBioRoleData
                    .toJS()
                    .map((role) => `<li key={${role.role_id}}><b>${role.title}</b> *** ${role.org_name}</li>`)

                if (currentRoles) {
                    return currentRoles.join(" ^^^ ")
                }
            }

            if (regionEnum && regionEnum.value === DjangIO.app.models.Region.federal.value) {
                secondLine.unshift(`${datum.get("title")}`)
                return secondLine.join(" | ")
            }

            // state senator
            if (roleType === DjangIO.app.person.types.RoleType.state_senator.value) {
                secondLine.unshift(`${regionEnum.upper_member_title} from ${regionEnum?.region_name}`)
                return secondLine.join(" | ")
                // state rep
            } else if (roleType === DjangIO.app.person.types.RoleType.state_representative.value) {
                secondLine.unshift(`${regionEnum.lower_member_title} from ${regionEnum?.region_name}`)
                return secondLine.join(" | ")
            }

            if (datum.get("title")) {
                secondLine.unshift(`${datum.get("title")} from ${regionEnum?.region_name?.replace(" Local", "")}`)
                return secondLine.join(" | ")
            }

            if (roleType) {
                secondLine.unshift(
                    `${DjangIO.app.person.types.RoleType.by_value(roleType).label} from ${regionEnum?.region_name}`,
                )
            }

            return secondLine.join(" | ")
        }
    }

    const secondLine = getSecondLine()

    let additionalFields
    let hideContactData = false

    if (
        shouldHideLocalOfficialsContact() &&
        DjangIO.app.models.SupportedRegion.local.child_regions.includes(datum.get("most_recent_region"))
    ) {
        hideContactData = true
    }

    if (!hideContactData) {
        additionalFields = [
            ["_extra", "most_recent_role", "most_recent_role__address"],
            ["_extra", "most_recent_role", "most_recent_role__phone"],
        ]
    }

    // if they're a senator or representative, don't show their email.
    if (
        [
            DjangIO.app.person.types.RoleType.senator.value,
            DjangIO.app.person.types.RoleType.representative.value,
        ].includes(roleType)
    ) {
        additionalFields = additionalFields.filter((key) => key !== "email")
    }

    // filters out all the undefined fields from additionalFields.
    const definedFields = additionalFields ? getDefinedFieldsChildren(datum, additionalFields) : []

    const address = datum.get("address")
    if (
        address &&
        !hideContactData &&
        DjangIO.app.person.types.RoleType.by_value(roleType) === DjangIO.app.person.types.RoleType.municipal_legislator
    ) {
        definedFields.unshift(address)
    }

    const website = datum.getIn(["_extra", "most_recent_role", "most_recent_role__website"])
    if (website && !hideContactData) {
        const websiteHtml = `<a href='${website}'>Visit Website</a>`
        definedFields.push(websiteHtml)
    }

    const email = datum.get("email")
    if (
        email &&
        !hideContactData &&
        DjangIO.app.person.types.RoleType.by_value(roleType) !== DjangIO.app.person.types.RoleType.senator &&
        DjangIO.app.person.types.RoleType.by_value(roleType) !== DjangIO.app.person.types.RoleType.representative
    ) {
        const emailHtml = `<a href='mailto:${email}'>${email}</a>`
        const arrPosition = 1
        if (definedFields.length > arrPosition) {
            definedFields.splice(arrPosition, 0, emailHtml)
        } else {
            definedFields.unshift(emailHtml)
        }
    }

    const publicOrgDescriptor = datum.get("public_organization_descriptor")
    if (publicOrgDescriptor) {
        definedFields.unshift(publicOrgDescriptor)
    }

    let firstLineIsHtml = false
    let secondLineIsHtml = false
    let thirdLineIsHtml = false
    let fourthLineIsHtml = false
    let fifthLineIsHtml = false
    let sixthLineIsHtml = false

    if (firstLine && stringContainsHTMLMarkup(firstLine)) {
        firstLineIsHtml = true
    }
    if (secondLine && stringContainsHTMLMarkup(secondLine)) {
        secondLineIsHtml = true
    }
    if (definedFields[0] && stringContainsHTMLMarkup(definedFields[0])) {
        thirdLineIsHtml = true
    }
    if (definedFields[1] && stringContainsHTMLMarkup(definedFields[1])) {
        fourthLineIsHtml = true
    }
    if (definedFields[2] && stringContainsHTMLMarkup(definedFields[2])) {
        fifthLineIsHtml = true
    }
    if (definedFields[3] && stringContainsHTMLMarkup(definedFields[3])) {
        sixthLineIsHtml = true
    }

    const definedFieldsDS = []

    if (publicOrgDescriptor && !isFeatureEnabled("ff_new_card_square")) {
        definedFieldsDS.push({
            ...getSecondLineIcon("building"),
            value: publicOrgDescriptor,
        })
    }

    const fullAddress =
        datum.getIn(["_extra", "most_recent_role", "most_recent_role__address"]) ||
        datum.get("most_recent_role_address")
    if (fullAddress && !hideContactData) {
        definedFieldsDS.push({
            ...(isFeatureEnabled("ff_new_card_square")
                ? getSecondLineIcon("map-marker")
                : getSecondLineIcon("map-marker-alt")),
            value: fullAddress,
        })
    }
    if (
        address &&
        !fullAddress &&
        DjangIO.app.person.types.RoleType.by_value(roleType) === DjangIO.app.person.types.RoleType.municipal_legislator
    ) {
        definedFieldsDS.push({
            ...(isFeatureEnabled("ff_new_card_square")
                ? getSecondLineIcon("map-marker")
                : getSecondLineIcon("map-marker-alt")),
            value: address,
        })
    }

    const phone = datum.getIn(["_extra", "most_recent_role", "most_recent_role__phone"])
    if (phone && !hideContactData) {
        definedFieldsDS.push({
            isLink: true,
            isHtml: true,
            ...getSecondLineIcon("phone"),
            value: `<a href='tel:${phone}'>${phone}</a>`,
        })
    }

    if (
        email &&
        !hideContactData &&
        ![
            DjangIO.app.person.types.RoleType.senator.value,
            DjangIO.app.person.types.RoleType.representative.value,
        ].includes(roleType)
    ) {
        definedFieldsDS.push({
            ...getSecondLineIcon("envelope"),
            isHtml: true,
            value: `<a href="mailto:${email}">${email}</a>`,
        })
    }

    if (website && !hideContactData) {
        definedFieldsDS.push({
            ...(isFeatureEnabled("ff_new_card_square") ? getSecondLineIcon("globe") : {}),
            isLink: true,
            isHtml: true,
            value: `<a href="${website}">Visit Website <i class="fa fa-external-link" aria-hidden="true" /></a>`,
        })
    }

    const isMobile = window.innerWidth <= mobileBreakpoint

    if (isMobile) {
        // Extra string formatting for International Official roles for mobile display
        // Slightly different from desktop as seen in memberInlineSelectorGetSecondLineDS
        const isIntOfficial = isInternationalOfficial(datum.get("most_recent_regions"))
        const cleaned = stripHtml(secondLine)
        const parsed = cleaned.split(" ^^^ ").map((role) => role.replace(" *** ", " - "))
        const parsedSecondLineValue = isIntOfficial ? parsed.join(" • ") : secondLine

        const secondLineDS = [
            { fa5Icon: "gavel", fa5IconFamily: "far", value: parsedSecondLineValue },
            ...definedFieldsDS,
        ]
        return {
            iconName: DjangIO.app.models.QuorumDataType.person.icon.replace("fa-", ""),
            imagePath: getImage(datum, BLANK_AVATAR_PATH, BLANK_AVATAR_PATH),
            firstLineData: firstLine,
            secondLineDS,
        }
    }

    return {
        href: `${DjangIO.app.models.QuorumDataType.person.profile}${datum.get("id")}/`,
        iconName: DjangIO.app.models.QuorumDataType.person.icon.replace("fa-", ""),
        imageBorderColor: { borderColor: getPartyBorderColor(datum.get("most_recent_party")) },
        imageBorderRule:
            datum.get("most_recent_party") &&
            DjangIO.app.person.types.Party.by_value(datum.get("most_recent_party")).class_name.toLowerCase(),
        imagePath: getImage(datum, BLANK_AVATAR_PATH, BLANK_AVATAR_PATH),
        isPerson,
        isCustom,
        model: DjangIO.app.models.QuorumDataType.person.value,
        personType:
            datum.get("most_recent_person_type") &&
            `${DjangIO.app.person.types.PersonType.by_value(datum.get("most_recent_person_type")).key}-card`,
        firstLineData: firstLine,
        secondLineData: secondLine,
        thirdLineData: definedFields[0],
        fourthLineData: definedFields[1],
        fifthLineData: definedFields[2],
        sixthLineData: definedFields[3],
        swapThirdAndFourthLines: true,
        firstLineIsHtml,
        secondLineIsHtml,
        thirdLineIsHtml,
        fourthLineIsHtml,
        fifthLineIsHtml,
        sixthLineIsHtml,

        secondLine:
            secondLine &&
            memberInlineSelectorGetSecondLineDS(
                secondLine,
                Boolean(datum.get("committee_string")),
                datum.get("most_recent_region"),
                datum.get("most_recent_regions"),
            ),
        thirdLine: definedFieldsDS[0],
        fourthLine: definedFieldsDS[1],
        fifthLine: definedFieldsDS[2],
        sixthLine: definedFieldsDS[3],

        name: datum.get("name"),
        publicOrgDescriptor: datum.get("public_organization_descriptor"),
    }
}

const executiveInlineSelector = (datum) => {
    const isCustom = !!(datum.get("organization") || datum.get("user"))
    const firstLine = datum.get("name")

    const getTitle = () => {
        const title = []

        if (isInternationalOfficial(datum.get("most_recent_regions"))) {
            const currentMajorRoleTypes = getCurrentMajorRoleTypes(datum.get("_ph_dn_current_major_role_types"))
            if (currentMajorRoleTypes) {
                title.push(currentMajorRoleTypes)
            }
        }

        if (datum.get("title")) {
            title.unshift(datum.get("title"))
            return title.join(" | ")
        } else if (datum.get("most_recent_role")) {
            title.unshift(datum.get("most_recent_role").title)
            return title.join(" | ")
        } else {
            title.unshift(DjangIO.app.person.types.RoleType.by_value(datum.get("most_recent_role_type")).label)
            return title.join(" | ")
        }
    }

    const definedFields = []

    const title = datum.get("public_organization_descriptor")
    if (title) {
        definedFields.push(title)
    }

    const email = datum.get("email")
    if (email) {
        const emailHtml = `<a href='mailto:${email}'>${email}</a>`
        definedFields.push(emailHtml)
    }

    const phone = datum.getIn(["_extra", "most_recent_role", "most_recent_role__phone"])
    if (phone) {
        definedFields.push(phone)
    }

    const website = datum.getIn(["_extra", "most_recent_role", "most_recent_role__website"])
    if (website) {
        const websiteHtml = `<a href='${website}'>Visit Website</a>`
        definedFields.push(websiteHtml)
    }

    const address = datum.getIn(["_extra", "most_recent_role", "most_recent_role__address"])
    if (address) {
        definedFields.push(address)
    }

    let firstLineIsHtml = false
    let thirdLineIsHtml = false
    let fourthLineIsHtml = false
    let fifthLineIsHtml = false
    let sixthLineIsHtml = false

    if (firstLine && stringContainsHTMLMarkup(firstLine)) {
        firstLineIsHtml = true
    }
    if (definedFields[0] && stringContainsHTMLMarkup(definedFields[0])) {
        thirdLineIsHtml = true
    }
    if (definedFields[1] && stringContainsHTMLMarkup(definedFields[1])) {
        fourthLineIsHtml = true
    }
    if (definedFields[2] && stringContainsHTMLMarkup(definedFields[2])) {
        fifthLineIsHtml = true
    }
    if (definedFields[3] && stringContainsHTMLMarkup(definedFields[3])) {
        sixthLineIsHtml = true
    }

    const secondLine = getTitle()
    const definedFieldsDS = []

    if (title && !isFeatureEnabled("ff_new_card_square")) {
        definedFieldsDS.push({
            ...getSecondLineIcon("building"),
            value: title,
        })
    }

    if (email) {
        definedFieldsDS.push({
            ...getSecondLineIcon("envelope"),
            isHtml: true,
            value: `<a href='mailto:${email}'>${email}</a>`,
        })
    }

    if (phone) {
        definedFieldsDS.push({
            isLink: true,
            isHtml: true,
            ...getSecondLineIcon("phone"),
            value: `<a href='tel:${phone}'>${phone}</a>`,
        })
    }

    if (address) {
        definedFieldsDS.push({
            ...(isFeatureEnabled("ff_new_card_square")
                ? getSecondLineIcon("map-marker")
                : getSecondLineIcon("map-marker-alt")),
            value: address,
        })
    }

    if (website) {
        definedFieldsDS.push({
            ...(isFeatureEnabled("ff_new_card_square") ? getSecondLineIcon("globe") : {}),
            isLink: true,
            isHtml: true,
            value: `<a href="${website}">Visit Website <i class="fa fa-external-link" aria-hidden="true" /></a>`,
        })
    }

    const isMobile = window.innerWidth <= mobileBreakpoint

    if (isMobile) {
        const secondLineDS = [{ fa5Icon: "gavel", fa5IconFamily: "far", value: secondLine }, ...definedFieldsDS]
        return {
            iconName: DjangIO.app.models.QuorumDataType.person.icon.replace("fa-", ""),
            imagePath: getImage(datum, FEDERAL_SEAL_AVATAR_PATH, BLANK_AVATAR_PATH),
            firstLineData: firstLine,
            secondLineDS,
        }
    }

    return {
        expandTitle: true,
        href: `${DjangIO.app.models.QuorumDataType.person.profile}${datum.get("id")}/`,
        iconName: DjangIO.app.models.QuorumDataType.person.icon.replace("fa-", ""),
        imageBorderColor: { borderColor: getPartyBorderColor(datum.get("most_recent_party")) },
        imageBorderRule: Userdata.has_international_region ? undefined : "federal-executive-img",
        imagePath: getImage(datum, FEDERAL_SEAL_AVATAR_PATH, BLANK_AVATAR_PATH),
        isCustom,
        isPerson,
        listModel: DjangIO.app.models.QuorumDataType.person.value,
        model: DjangIO.app.models.QuorumDataType.executive.value,
        firstLineData: firstLine,
        secondLineData: secondLine,
        thirdLineData: definedFields[0],
        fourthLineData: definedFields[1],
        fifthLineData: definedFields[2],
        sixthLineData: definedFields[3],
        firstLineIsHtml,
        thirdLineIsHtml,
        fourthLineIsHtml,
        fifthLineIsHtml,
        sixthLineIsHtml,

        secondLine: isFeatureEnabled("ff_new_card_square")
            ? null
            : secondLine && {
                  ...getSecondLineIcon("address-card"),
                  value: secondLine,
              },
        thirdLine: definedFieldsDS[0],
        fourthLine: definedFieldsDS[1],
        fifthLine: definedFieldsDS[2],
        sixthLine: definedFieldsDS[3],

        name: datum.get("name"),
        publicOrgDescriptor: datum.get("public_organization_descriptor"),
    }
}

const stafferInlineSelector = (datum) => {
    const getFirstLine = () => {
        // gets only the name (datum.name returns name + title + boss)
        const name = datum.get("staffer_name")
        if (name) {
            if (Userdata.isSingleRegionMode()) {
                return name
            } else {
                return `${name} (${DjangIO.app.models.Region.by_value(datum.get("most_recent_region"))?.region_name})`
            }
        }

        return undefined
    }

    const getSecondLine = () => {
        const secondLine = []

        const title = datum.get("title")
        if (title) {
            secondLine.push(title)
        }

        if (isInternationalOfficial(datum.get("most_recent_regions"))) {
            const currentMajorRoleTypes = getCurrentMajorRoleTypes(datum.get("_ph_dn_current_major_role_types"))
            if (currentMajorRoleTypes) {
                secondLine.push(currentMajorRoleTypes)
            }
        }

        return secondLine.join(" | ")
    }

    const getOffice = () => {
        const { OfficeType } = DjangIO.app.person.types

        if (datum.getIn(["_extra", "location", "location__office_type"])) {
            return OfficeType.by_value(datum.getIn(["_extra", "location", "location__office_type"])).value ===
                OfficeType.Capitol.value
                ? OfficeType.Capitol.label
                : `${datum.getIn(["_extra", "location", "location__city"])} Office`
        } else if (datum.get("most_recent_region") === DjangIO.app.models.Region.federal.value) {
            return OfficeType.Capitol.label
        }

        return undefined
    }

    const getStafferCommittee = () => {
        const commStringArr = []
        const committeeName = datum.getIn(["_extra", "committee", "committee__name"])
        if (committeeName) {
            commStringArr.push(committeeName)
        }
        if (
            datum.get("comstaffertype") &&
            datum.get("comstaffertype") !== DjangIO.app.person.types.ComStafferType.Other.value
        ) {
            commStringArr.push(DjangIO.app.person.types.ComStafferType.by_value(datum.get("comstaffertype")).label)
        }
        return commStringArr.join(", ")
    }

    const getLegislatorLine = () => {
        const office = getOffice()
        return [datum.get("member_name"), office].filter(Boolean).join(", ")
    }

    const stafferCommittee = getStafferCommittee()
    const legislatorInfo = getLegislatorLine()
    const definedFields = [stafferCommittee, legislatorInfo].filter(Boolean)

    const email = datum.get("email")
    if (email) {
        const emailHtml = `<a href='mailto:${email}'>${email}</a>`
        definedFields.push(emailHtml)
    }

    const phone = datum.getIn(["_extra", "most_recent_role", "most_recent_role__phone"])
    if (phone) {
        definedFields.push(phone)
    }

    const responsibilitiesString = datum.get("responsibilities_string")
    if (responsibilitiesString) {
        definedFields.push(responsibilitiesString)
    }

    const address = datum.getIn(["_extra", "most_recent_role", "most_recent_role__address"])
    if (address) {
        definedFields.push(address)
    }

    let firstLineIsHtml = false
    let thirdLineIsHtml = false
    let fourthLineIsHtml = false
    let fifthLineIsHtml = false
    let sixthLineIsHtml = false

    const firstLine = getFirstLine()
    if (firstLine && stringContainsHTMLMarkup(firstLine)) {
        firstLineIsHtml = true
    }
    if (definedFields[0] && stringContainsHTMLMarkup(definedFields[0])) {
        thirdLineIsHtml = true
    }
    if (definedFields[1] && stringContainsHTMLMarkup(definedFields[1])) {
        fourthLineIsHtml = true
    }
    if (definedFields[2] && stringContainsHTMLMarkup(definedFields[2])) {
        fifthLineIsHtml = true
    }
    if (definedFields[3] && stringContainsHTMLMarkup(definedFields[3])) {
        sixthLineIsHtml = true
    }

    const getMostRecentParty = () => {
        const mostRecentParty = datum.get("most_recent_party")
        const personMostRecentParty = datum.getIn(["_extra", "person", "person__most_recent_party"])

        if (mostRecentParty && mostRecentParty !== DjangIO.app.person.types.Party.Other.value) {
            return mostRecentParty
        } else if (personMostRecentParty) {
            return personMostRecentParty
        }

        // mostRecentParty could theoretically be undefined,
        // so fallback to other just in case
        return mostRecentParty || DjangIO.app.person.types.Party.Other.value
    }

    const secondLine = getSecondLine()
    const definedFieldsDS = []

    if (stafferCommittee) {
        definedFieldsDS.push({
            ...getSecondLineIcon("gavel"),
            value: stafferCommittee,
        })
    }

    if (legislatorInfo && !isFeatureEnabled("ff_new_card_square")) {
        definedFieldsDS.push({
            ...getSecondLineIcon("building"),
            value: legislatorInfo,
        })
    }

    if (email) {
        definedFieldsDS.push({
            ...getSecondLineIcon("envelope"),
            isLink: true,
            isHtml: true,
            value: `<a href='mailto:${email}'>${email}</a>`,
        })
    }

    if (phone) {
        definedFieldsDS.push({
            ...getSecondLineIcon("phone"),
            isLink: true,
            isHtml: true,
            value: `<a href='tel:${phone}'>${phone}</a>`,
        })
    }

    if (responsibilitiesString) {
        definedFieldsDS.push({
            ...getSecondLineIcon("info"),
            value: responsibilitiesString,
        })
    }

    if (address) {
        definedFieldsDS.push({
            ...(isFeatureEnabled("ff_new_card_square")
                ? getSecondLineIcon("map-marker")
                : getSecondLineIcon("map-marker-alt")),
            value: address,
        })
    }

    const isMobile = window.innerWidth <= mobileBreakpoint

    if (isMobile) {
        const secondLineDS = [{ fa5Icon: "address-card", fa5IconFamily: "far", value: secondLine }, ...definedFieldsDS]
        return {
            iconName: DjangIO.app.models.QuorumDataType.person.icon.replace("fa-", ""),
            imagePath: getImage(datum, FEDERAL_SEAL_AVATAR_PATH, BLANK_AVATAR_PATH),
            firstLineData: firstLine,
            secondLineDS,
        }
    }

    return {
        expandTitle: true,
        href: `${DjangIO.app.models.QuorumDataType.person.profile}${datum.get("id")}/`,
        iconName: DjangIO.app.models.QuorumDataType.person.icon.replace("fa-", ""),
        imageBorderColor: { borderColor: getPartyBorderColor(getMostRecentParty()) },
        imageBorderRule: DjangIO.app.person.types.Party.by_value(getMostRecentParty()).label.toLowerCase(),
        imagePath: getImage(datum, FEDERAL_SEAL_AVATAR_PATH, BLANK_AVATAR_PATH),
        isPerson,
        model: DjangIO.app.models.QuorumDataType.staffer.value,
        firstLineData: firstLine,
        secondLineData: secondLine,
        thirdLineData: definedFields[0],
        fourthLineData: definedFields[1],
        fifthLineData: definedFields[2],
        sixthLineData: definedFields[3],
        firstLineIsHtml,
        thirdLineIsHtml,
        fourthLineIsHtml,
        fifthLineIsHtml,
        sixthLineIsHtml,

        secondLine: isFeatureEnabled("ff_new_card_square")
            ? null
            : {
                  ...getSecondLineIcon("address-card"),
                  isHtml: stringContainsHTMLMarkup(secondLine),
                  value: secondLine,
              },
        thirdLine: definedFieldsDS[0],
        fourthLine: definedFieldsDS[1],
        fifthLine: definedFieldsDS[2],
        sixthLine: definedFieldsDS[3],

        name: datum.get("name"),
        publicOrgDescriptor: datum.get("public_organization_descriptor"),
    }
}

const pressContactInlineSelector = (datum) => {
    const firstLine = datum.get("name")
    const secondLine = datum.get("title")

    const getOrganizations = () => {
        const organizations = datum.getIn(["_extra", "media_organizations"]) || []

        return organizations
            .map((org) =>
                dataObjectToProfileLink({
                    qdt: DjangIO.app.models.QuorumDataType.public_organization,
                    id: org.get("id"),
                    label: org.get("name"),
                }),
            )
            .join(", ")
    }
    const thirdLine = getOrganizations()

    const getEmail = () => {
        const email = datum.get("email")
        if (email) {
            return `<a href='mailto:${email}'>${email}</a>`
        }
    }
    const getContactData = () => {
        return [getEmail(), datum.get("most_recent_role_phone")].filter((datum) => !!datum).join(" | ")
    }
    const fourthLine = getContactData()

    const getBeats = () => {
        const beats = datum.getIn(["_extra", "beats"])
        return beats && beats.map((beat) => beat.get("beat__name")).join(", ")
    }

    // const secondLine = getSecondLine()
    // const definedFieldsDS = []
    const getFifthLineDS = () => {
        const phone = datum.get("most_recent_role_phone")
        if (phone) {
            return {
                ...getSecondLineIcon("phone"),
                value: formatPhoneNumber(phone),
            }
        }

        return undefined
    }

    const getSixthLineDS = () => {
        const value = getBeats()
        return value
            ? {
                  ...getSecondLineIcon("info"),
                  value,
              }
            : undefined
    }

    const isMobile = window.innerWidth <= mobileBreakpoint

    if (isMobile) {
        const secondLineDS = [
            {
                ...getSecondLineIcon("address-card"),
                value: secondLine,
            },
            {
                ...getSecondLineIcon("building"),
                isHtml: true,
                value: thirdLine,
            },
            {
                ...getSecondLineIcon("envelope"),
                isHtml: true,
                value: getEmail(),
            },
        ]
        return {
            iconName: DjangIO.app.models.QuorumDataType.person.icon.replace("fa-", ""),
            imagePath: getImage(datum, FEDERAL_SEAL_AVATAR_PATH, BLANK_AVATAR_PATH),
            firstLineData: firstLine,
            secondLineDS,
        }
    }

    return {
        expandTitle: true,
        href: `${DjangIO.app.models.QuorumDataType.press_contact.profile}${datum.get("id")}/`,
        iconName: DjangIO.app.models.QuorumDataType.press_contact.icon.replace("fa-", ""),
        imageStyle: {
            borderColor: QUORUM_BLUE,
            borderWidth: 2,
            borderStyle: "solid",
        },
        imagePath: datum.get("image_url"),
        isPerson,
        model: DjangIO.app.models.QuorumDataType.press_contact.value,
        firstLineData: firstLine,
        firstLineIsHtml: firstLine && stringContainsHTMLMarkup(firstLine),
        secondLineData: secondLine,
        thirdLineData: thirdLine,
        fourthLineData: fourthLine,
        thirdLineIsHtml: true,
        fourthLineIsHtml: true,

        secondLine: {
            ...getSecondLineIcon("address-card"),
            value: secondLine,
        },
        thirdLine: {
            ...getSecondLineIcon("building"),
            isHtml: true,
            value: thirdLine,
        },
        fourthLine: {
            ...getSecondLineIcon("envelope"),
            isHtml: true,
            value: getEmail(),
        },
        fifthLine: getFifthLineDS(),
        sixthLine: getSixthLineDS(),

        name: datum.get("name"),
    }
}

const euCouncilMemberInlineSelect = (datum) => {
    let firstLineIsHtml = false
    let secondLineIsHtml = false

    const getSecondLine = () => {
        const secondLine = []

        const mostRecentCountry = datum.getIn(["_extra", "most_recent_country", "most_recent_country__name_common"])
        if (mostRecentCountry) {
            secondLine.push(`${mostRecentCountry}`)
        }

        if (isInternationalOfficial(datum.get("most_recent_regions"))) {
            const currentMajorRoleTypes = getCurrentMajorRoleTypes(datum.get("_ph_dn_current_major_role_types"))
            if (currentMajorRoleTypes) {
                secondLine.push(currentMajorRoleTypes)
            }
        }

        return secondLine.join(" | ")
    }

    const firstLine = datum.get("name")
    if (firstLine && stringContainsHTMLMarkup(firstLine)) {
        firstLineIsHtml = true
    }

    const secondLine = getSecondLine()
    if (secondLine && stringContainsHTMLMarkup(secondLine)) {
        secondLineIsHtml = true
    }

    const getSecondLineDS = () => {
        if (secondLine) {
            return {
                ...getSecondLineIcon("address-card"),
                isHtml: stringContainsHTMLMarkup(secondLine),
                value: secondLine,
            }
        }

        return undefined
    }

    const isMobile = window.innerWidth <= mobileBreakpoint

    if (isMobile) {
        const secondLineDS = [
            {
                ...getSecondLineIcon("address-card"),
                value: secondLine,
            },
        ]
        return {
            ...executiveInlineSelector(datum),
            firstLineData: firstLine,
            secondLineDS,
        }
    }

    return {
        ...executiveInlineSelector(datum),
        firstLineData: firstLine,
        secondLineData: getSecondLine(),
        firstLineIsHtml,
        secondLineIsHtml,

        secondLine: getSecondLineDS(),

        name: datum.get("name"),
    }
}

const personInlineSelector = (datum) => {
    switch (datum.get("most_recent_person_type")) {
        case DjangIO.app.person.types.PersonType.staffer.value:
            return stafferInlineSelector(datum)
        case DjangIO.app.person.types.PersonType.press_contact.value:
            return pressContactInlineSelector(datum)
        case DjangIO.app.person.types.PersonType.executive.value:
            if (DjangIO.app.models.Region.by_value(datum.get("most_recent_region"))?.label.indexOf("Local") >= 0) {
                // want local executive selector here
                return memberInlineSelector(datum)
            } else {
                switch (datum.get("most_recent_role_type")) {
                    case DjangIO.app.person.types.RoleType.eu_councilmember.value:
                        return euCouncilMemberInlineSelect(datum)
                    default:
                        return executiveInlineSelector(datum)
                }
            }
        default:
            return memberInlineSelector(datum)
    }
}

export default personInlineSelector
