import React, { useState, useCallback, useRef } from "react"
import PropTypes from "prop-types"
import Select from "react-select"

import Button from "Components/Molecules/Button"

import * as S from "Components/Molecules/SearchableDropdownButton/style"

import { useOnClickOutside } from "utils/hooks"

export const SearchableDropdownButton = ({
    dataCy,
    icon,
    iconFamily,
    isTiny,
    onClick,
    options,
    placeholder,
    text,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const toggleOpen = () => setIsOpen(!isOpen)

    const ref = useRef()
    // Not necessary, but prevents a small amount of work during re-renders
    const closeDropdown = useCallback(() => setIsOpen(false), [])
    useOnClickOutside(ref, closeDropdown)

    const onSelectChange = (option) => {
        toggleOpen()
        onClick(option)
    }
    const buttonClick = (event) => {
        event.preventDefault()
        toggleOpen()
    }

    return (
        <S.SearchableDropdownButton data-auto-cy="MoleculeSearchableDropdownButton">
            <Button
                leftIcon={icon}
                leftIconFamily={iconFamily}
                rightIcon={isOpen ? "chevron-up" : "chevron-down"}
                rightIconFamily="far"
                onClick={buttonClick}
                text={text}
                type="tertiary"
                dataCy={dataCy}
                isTiny={isTiny}
            />
            {isOpen && (
                <S.SelectWrapper ref={ref}>
                    <Select
                        autoFocus
                        backspaceRemovesValue={false}
                        components={{
                            DropdownIndicator: null,
                            IndicatorSeparator: null,
                        }}
                        controlShouldRenderValue={false}
                        isClearable={false}
                        hideSelectedOptions={false}
                        menuIsOpen
                        onChange={onSelectChange}
                        options={options}
                        placeholder={placeholder}
                        styles={S.selectStyles}
                        tabSelectsValue={false}
                    />
                </S.SelectWrapper>
            )}
        </S.SearchableDropdownButton>
    )
}

SearchableDropdownButton.propTypes = {
    dataCy: PropTypes.string,
    icon: PropTypes.string.isRequired,
    iconFamily: PropTypes.string.isRequired,
    isTiny: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        }))
    })),
    placeholder: PropTypes.string,
    text: PropTypes.string,
}

export default SearchableDropdownButton
