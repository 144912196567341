import { useEffect } from "react"
import * as d3v6 from "d3v6"

const ramp = (color, width = 256) => {
    // https://github.com/observablehq/stdlib#DOM_canvas
    // const canvas = DOM.canvas(n, 1)
    const canvas = document.createElement("CANVAS")
    canvas.width = width
    canvas.height = 1
    const context = canvas.getContext("2d")

    for (let i = 0; i < width; i += 1) {
        context.fillStyle = color(i / (width - 1))
        context.fillRect(i, 0, 1, 1)
    }

    return canvas
}

function validateType(value, type) {
    return typeof value === type ? value : undefined
}

const renderContinuous = (svg, color, { width, height }, { marginTop, marginLeft, marginBottom, marginRight }) => {
    const n = Math.min(color.domain().length, color.range().length)
    const x = color.copy().rangeRound(d3v6.quantize(d3v6.interpolate(marginLeft, width - marginRight), n))

    svg.append("image")
        .attr("x", marginLeft)
        .attr("y", marginTop)
        .attr("width", width - marginLeft - marginRight)
        .attr("height", height - marginTop - marginBottom)
        .attr("preserveAspectRatio", "none")
        .attr("xlink:href", ramp(color.copy().domain(d3v6.quantize(d3v6.interpolate(0, 1), n))).toDataURL())

    return x
}

const renderSequential = (
    svg,
    color,
    { width, height },
    { marginTop, marginLeft, marginBottom, marginRight },
    ticks,
    tickFormat,
    tickValues,
) => {
    const x = Object.assign(color.copy().interpolator(d3v6.interpolateRound(marginLeft, width - marginRight)), {
        range() {
            return [marginLeft, width - marginRight]
        },
    })

    svg.append("image")
        .attr("x", marginLeft)
        .attr("y", marginTop)
        .attr("width", width - marginLeft - marginRight)
        .attr("height", height - marginTop - marginBottom)
        .attr("preserveAspectRatio", "none")
        .attr("xlink:href", ramp(color.interpolator()).toDataURL())

    // scaleSequentialQuantile doesn’t implement ticks or tickFormat.
    if (!x.ticks) {
        if (tickValues === undefined) {
            const n = Math.round(ticks + 1)
            tickValues = d3v6.range(n).map((i) => d3v6.quantile(color.domain(), i / (n - 1)))
        }

        if (typeof tickFormat !== "function") {
            tickFormat = d3v6.format(tickFormat === undefined ? ",f" : tickFormat)
        }
    }

    return [x, tickFormat, tickValues]
}

const renderThreshold = (
    svg,
    color,
    { width, height },
    { marginTop, marginLeft, marginBottom, marginRight },
    tickFormat,
    tickValues,
) => {
    const quantiles = color.quantiles
        ? // scaleQuantile
          color.quantiles()
        : // scaleThreshold
          color.domain()
    const thresholds = color.thresholds
        ? // scaleQuantize
          color.thresholds()
        : quantiles
    const tickFormatString = typeof tickFormat === "string" ? d3v6.format(tickFormat) : tickFormat
    const thresholdFormat = tickFormat === undefined ? (d) => d : tickFormatString

    const x = d3v6
        .scaleLinear()
        .domain([-1, color.range().length - 1])
        .rangeRound([marginLeft, width - marginRight])

    svg.append("g")
        .selectAll("rect")
        .data(color.range())
        .join("rect")
        .attr("x", (d, i) => x(i - 1))
        .attr("y", marginTop)
        .attr("width", (d, i) => x(i) - x(i - 1))
        .attr("height", height - marginTop - marginBottom)
        .attr("fill", (d) => d)

    tickValues = d3v6.range(thresholds.length)
    tickFormat = (i) => thresholdFormat(thresholds[i], i)

    return [x, tickFormat, tickValues]
}

const renderOrdinal = (
    svg,
    color,
    { width, height },
    { marginTop, marginLeft, marginBottom, marginRight },
    tickFormat,
    tickValues,
) => {
    const x = d3v6
        .scaleBand()
        .domain(color.domain())
        .rangeRound([marginLeft, width - marginRight])

    svg.append("g")
        .selectAll("rect")
        .data(color.domain())
        .join("rect")
        .attr("x", x)
        .attr("y", marginTop)
        .attr("width", Math.max(0, x.bandwidth() - 1))
        .attr("height", height - marginTop - marginBottom)
        .attr("fill", color)

    const tickAdjust = () => undefined

    return [x, tickFormat, tickValues, tickAdjust]
}

export const useRenderLegend = ({
    color,
    colorTimestamp,
    tickSize = 6,
    width = 320,
    height = 44 + tickSize,
    marginBottom = 16 + tickSize,
    marginLeft = 0,
    marginRight = 0,
    marginTop = 18,
    referenceLegend,
    tickFormat,
    ticks = width / 64,
    tickValues,
    title,
}) => {
    const render = () => {
        if (!colorTimestamp) {
            return
        }

        d3v6.select(referenceLegend.current).select(".legend").remove()

        const svg = d3v6
            .select(referenceLegend.current)
            .attr("height", height)
            .attr("preserveAspectRatio", "xMidYMid meet")
            .attr("viewBox", [0, 0, width, height])
            .attr("width", "100%")
            .append("g")
            .attr("class", "legend")
            .attr("data-cy", "legend")

        let tickAdjust = (g) => g.selectAll(".tick line").attr("y1", marginTop + marginBottom - height)
        let x

        // Continuous
        if (color.interpolate) {
            x = renderContinuous(svg, color, { width, height }, { marginTop, marginLeft, marginBottom, marginRight })
        }

        // Sequential
        else if (color.interpolator) {
            ;[x, tickFormat, tickValues] = renderSequential(
                svg,
                color,
                { width, height },
                { marginTop, marginLeft, marginBottom, marginRight },
                ticks,
                tickFormat,
                tickValues,
            )
        }

        // Threshold
        else if (color.invertExtent) {
            ;[x, tickFormat, tickValues] = renderThreshold(
                svg,
                color,
                { width, height },
                { marginTop, marginLeft, marginBottom, marginRight },
                tickFormat,
                tickValues,
            )
        }

        // Ordinal
        else {
            ;[x, tickFormat, tickValues, tickAdjust] = renderOrdinal(
                svg,
                color,
                { width, height },
                { marginTop, marginLeft, marginBottom, marginRight },
                tickFormat,
                tickValues,
            )
        }

        svg.append("g")
            .attr("transform", `translate(0,${height - marginBottom})`)
            .call(
                d3v6
                    .axisBottom(x)
                    .ticks(ticks, validateType(tickFormat, "string"))
                    .tickFormat(validateType(tickFormat, "function"))
                    .tickSize(tickSize)
                    .tickValues(tickValues),
            )
            .call(tickAdjust)
            .call((g) => g.select(".domain").remove())
            .call((g) =>
                g
                    .append("text")
                    .attr("x", marginLeft)
                    .attr("y", marginTop + (marginBottom - height - 6))
                    .attr("fill", "currentColor")
                    .attr("text-anchor", "start")
                    .attr("font-weight", "bold")
                    .attr("class", "title")
                    .text(title),
            )
    }

    useEffect(() => {
        render()
    }, [colorTimestamp, width, render])
}
