import React from 'react'
import PropTypes from 'prop-types'

import GridToggleField from "app/static/frontend/forms/components/GridToggleField" // eslint-disable-line import/no-named-as-default
import SelectField from "app/static/frontend/forms/components/SelectField" // eslint-disable-line import/no-named-as-default

const GRID_TOGGLE_SELECT_OPTIONS_LIMIT = 9

/**
 * This field is a GridToggleField when the number of options is less than or equal to props.numOptionsLimit
 * (which defaults to 9) and is a SelectField otherwise.
 *
 * NOTE: DO NOT USE THIS COMPONENT IF NUMBER OF 'props.choices' WILL CHANGE DYNAMICALLY
 *      Redux-form does some magic when you create a <Field component={GridToggleSelectField} {...otherStuff} />
 *      For example, if you pass 'parse' as a prop to Field, you won't be able to use it in this component - it gets plucked out (Sad!)
 *      For that reason, I'd advise using this component when the number of choices isn't changing dynamically.
 *      If it is changing dynamically, then pass the 'parse' prop below to cover your bases.
 */
export const GridToggleSelectField = (props) => {
    const optionsLimit = props.shouldRenderNewComponents ? 6 : props.numOptionsLimit
    return (props.choices.length <= optionsLimit)
        ? <GridToggleField {...props} />
        : (
            <SelectField
                {...{
                    dataCy: "grid-toggle-select-field-select",
                    ...props,
                    shouldRenderNewSelect: props.shouldRenderNewComponents,
                    input: {
                        ...props.input,
                        // edit input.onChange passed by redux-form to instead
                        // map any objects to the values to match how GridToggleField's
                        // onChange works
                        onChange: (fullObject) => {
                            if (props.multi) {
                                props.input.onChange(fullObject ? fullObject.map(selection => selection.value) : null)
                            } else {
                                props.input.onChange(fullObject ? fullObject.value : null)
                            }
                        }
                    }
                }}
            />
        )
}

GridToggleSelectField.propTypes = {
    label: PropTypes.string,
    tooltipText: PropTypes.string,
    choices: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.number.isRequired,
            PropTypes.string.isRequired,
            PropTypes.bool,
        ]),
        label: PropTypes.string.isRequired,
        // only shown when in GridToggle mode
        disabled: PropTypes.bool,
        tip: PropTypes.string,
        icon: PropTypes.string
    })).isRequired,
    multi: PropTypes.bool,
    numOptionsLimit: PropTypes.number,
    shouldRenderNewComponents: PropTypes.bool,

    // only GridToggle
    isIndentedToggle: PropTypes.bool,
    pillWidth: PropTypes.number,

    // only Select
    defaultLabel: PropTypes.string,
    className: PropTypes.string,
    noResultsText: PropTypes.string,
    dropUp: PropTypes.bool,
    onMenuOpen: PropTypes.func,

    // from redux-form (not passed in by parent)
    input: PropTypes.object,
    meta: PropTypes.object,
}

GridToggleSelectField.defaultProps = {
    numOptionsLimit: GRID_TOGGLE_SELECT_OPTIONS_LIMIT,
}

export default GridToggleSelectField
