import {
    StyledDateTimeCol,
    StyledGridToggleSelectField,
    StyledPeopleSelectorWrapper,
} from "QuorumGrassroots/campaign-forms/components/ExternalLogForm/style"
import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import { StyledHorizontalRule } from "QuorumGrassroots/styled-components/components/StyledHorizontalRule"
import CustomFieldFormSection from "app/static/frontend/custom-fields/containers/CustomFieldFormSection"
import DateTimeField from "app/static/frontend/forms/components/DateTimeField"
import PeopleSelectorField from "app/static/frontend/forms/components/PeopleSelectorField"
import SelectField from "app/static/frontend/forms/components/SelectField"
import PropTypes from "prop-types"
import React from "react"
import { Row } from "react-bootstrap"
import { Fields } from "redux-form"
import { Field } from "redux-form/immutable"

import { normalizeSingleChoiceValue } from "QuorumGrassroots/helperFunctions"
import { InputField } from "app/static/frontend/forms/components/InputField"
import "app/static/stylus/reusable_components/react-datetime.styl"

export const ExternalLogForm = ({ t, ...props }) => (
    <div className="external-log-form">
        {props.externalFormDescription && (
            <div>
                <div dangerouslySetInnerHTML={{ __html: props.externalFormDescription }} data-cy="description" />
                <br />
            </div>
        )}

        <StyledPeopleSelectorWrapper className="people-selector-wrapper" isCampaignPage activateNewNGGTheme>
            <Fields
                names={["officials", "supporters"]}
                component={PeopleSelectorField}
                pageId="externalLogForm"
                label={props.officialLabel}
                parse={(people) => people.map((person) => person.resource_uri)}
                displayErrorWithoutTouch={false}
                initialOfficialsValue={props.initialOfficialsValue}
            />
        </StyledPeopleSelectorWrapper>

        <Field
            name={DjangIO.app.grassroots.campaign.types.CampaignType.by_value(props.campaignType).note_field_key}
            component={StyledGridToggleSelectField}
            label={t("campaign.log_interaction.type.label")}
            choices={props.externalTypes}
            clearable={false}
            allowNull={false}
            numOptionsLimit={6}
        />
        {props.campaignType === DjangIO.app.grassroots.campaign.types.CampaignType.log_interaction.value && (
            <Row className="log-interaction-fields">
                <StyledDateTimeCol md={4} xs={12}>
                    <Field
                        name="date"
                        component={DateTimeField}
                        label={t("campaign.log_interaction.date.label")}
                        timeFormat={false}
                    />
                </StyledDateTimeCol>
                <StyledDateTimeCol md={4} xs={12}>
                    <Field
                        name="time"
                        component={DateTimeField}
                        label={t("campaign.log_interaction.time.label")}
                        dateFormat={false}
                    />
                </StyledDateTimeCol>
                <StyledDateTimeCol md={4} xs={12}>
                    <Field
                        name="duration"
                        component={SelectField}
                        label={t("campaign.log_interaction.duration.label")}
                        choices={DjangIO.app.userdata.types.PossibleDurations.items().sort((a, b) => a.order - b.order)}
                        clearable={false}
                        parse={normalizeSingleChoiceValue}
                    />
                </StyledDateTimeCol>
            </Row>
        )}

        <Field
            name="text"
            component={InputField}
            label={t("campaign.log_interaction.text.label")}
            placeholder={props.textPlaceholder}
            dataCy="external-log-form-notes"
            isTextarea
            isCampaignPage
        />
        {Boolean(props.issueList.length) && (
            <Field
                name="projects"
                component={StyledGridToggleSelectField}
                label={t("campaign.log_interaction.projects.label")}
                choices={props.issueList}
                numOptionsLimit={6}
                multi
            />
        )}
        <CustomFieldFormSection form={props.form} customFieldNameDict={props.customFieldNameDict} externalFacing />
        <StyledHorizontalRule />
        <StyledButton
            onClick={props.handleSubmit}
            disabled={props.submitting}
            data-cy="external-log-form-submit-button"
            isCampaignPage
            activateNGGTheme
        >
            {props.submitting ? t("form.submitting") : t("form.submit_form")}
        </StyledButton>
    </div>
)

ExternalLogForm.propTypes = {
    customFieldNameDict: PropTypes.object.isRequired,
    externalTypes: PropTypes.array.isRequired,
    form: PropTypes.string.isRequired,
    campaignType: PropTypes.number,
    initialOfficialsValue: PropTypes.object.isRequired,
    issueList: PropTypes.array.isRequired,
    externalFormDescription: PropTypes.string,
    officialLabel: PropTypes.string,
    textPlaceholder: PropTypes.string.isRequired,
    // From redux form
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    // i18n
    t: PropTypes.func.isRequired,
    // ngg theme
    isCampaignPage: PropTypes.bool,
}
