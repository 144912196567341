import styled from "styled-components"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { getFontColorForBackgroundContrast } from "QuorumGrassroots/styled-components/helperFunctions"

export const StyledContrastText = styleWithOrgDesign(styled.span<{ isSingleColumnLayout?: boolean }>`
    ${(props) =>
        props.isCampaignPage && `color: ${getFontColorForBackgroundContrast(props.organizationDesign.primary_color)};`}

    ${(props) =>
        props.isSingleColumnLayout &&
        `
        color: red;
        margin-top: -20px;
        font-size: 14px;
        `}
`) as React.FC<any>
