import React from "react"
import PropTypes from "prop-types"

import UserInfoFormSection from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/index"
import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"

import { StyledButton } from "QuorumGrassroots/styled-components/components/StyledButton"
import { StyledHorizontalRule } from "QuorumGrassroots/styled-components/components/StyledHorizontalRule"
import { StyledAlert } from "QuorumGrassroots/styled-components/components/StyledAlert"
import { PacUserInfoSection } from "QuorumGrassroots/widgets/UpdateInformation/components/PacUserInfoSection"

// we're keeping the form tag in order to easily be able to submit on pressing
// Enter.
const UpdateInformationWidget = ({ t, ...props }) => (
    <WidgetStyleWrapper
        className="update-information-widet"
        md={props.colWidth}
        useWidgetStyling={props.useWidgetStyling}
        title={!props.isEmbedded ? t("registration.update_header") : null}
    >
        {props.updateInfoText && !props.useWidgetStyling && (
            <StyledAlert className="update-info-text-alert" isCampaignPage={props.isCampaignPage} activateNGGTheme>
                {props.isCampaignPage && <i className="fa fa-info-circle" />}
                {props.updateInfoText}
            </StyledAlert>
        )}
        <form onSubmit={props.handleSubmit}>
            <UserInfoFormSection
                isCampaignPage={props.isCampaignPage}
                registrationPageIds={props.registrationPageIds}
                additionalSimpleFields={props.additionalSimpleFields}
                // This prop was not being used in the original code
                // Set it as false because we want to show all fields
                showOnlyFilledCustomFields={false}
                showOnlyUnfilledFields={props.showOnlyUnfilledFields}
                requireAllFields={true}
                defaultRegistrationPage={props.defaultRegistrationPage}
                tooltipText={props.tooltipText}
                readOnlyFields={props.readOnlyFields}
                t={t}
            />

            {props.isUserPacEligible && props.hasPacCharitableMatch && !props.isEmbedded && (
                <PacUserInfoSection
                    change={props.change}
                    initialValues={props.initialValues}
                    charitableMatchInfo={props.pacCharitableMatchInfo}
                />
            )}

            <StyledHorizontalRule isCampaignPage={props.isCampaignPage} />
            <StyledButton
                isCampaignPage={props.isCampaignPage}
                activateNGGTheme
                onClick={props.handleSubmit}
                disabled={props.submitting}
                type="submit"
            >
                {props.submitting ? t("form.submitting") : props.styledButtonText || t("form.submit_form")}
            </StyledButton>
        </form>
    </WidgetStyleWrapper>
)

UpdateInformationWidget.propTypes = {
    showOnlyFilledFields: PropTypes.bool,
    showOnlyUnfilledFields: PropTypes.bool,
    registrationPageIds: PropTypes.array,
    widgetStyling: PropTypes.bool,
    colWidth: PropTypes.number,
    useWidgetStyling: PropTypes.bool,
    updateInfoText: PropTypes.string,
    additionalSimpleFields: PropTypes.array,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    isEmbedded: PropTypes.bool,
    isUserPacEligible: PropTypes.bool,
    hasPacCharitableMatch: PropTypes.bool,
    initialCharity: PropTypes.object,
    defaultRegistrationPage: PropTypes.object,
    tooltipText: PropTypes.string,
    readOnlyFields: PropTypes.array,
    // value from this Campaign's "Custom Call to Action Button Text" field
    styledButtonText: PropTypes.string,
}

UpdateInformationWidget.defaultProps = {
    showOnlyFilledCustomFields: true,
    useWidgetStyling: true,
    colWidth: undefined,
}

export default UpdateInformationWidget
