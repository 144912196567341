import React, { Dispatch, useState } from "react"
import { connect } from "react-redux"
import { formValueSelector } from "redux-form/immutable"
import regexes from "shared/imports/regex"

import { EmailField } from "shared-web/forms/fields/EmailField"
import { fetchOfficialsByAddress } from "QuorumGrassroots/services"
import {
    fieldWithOfficialActions,
    replaceTargetedMessageSupporterNamePlaceholders,
    setOfficialsPreview,
    setTargetedMessages,
    storeSupporterPlaceholderValues,
    updateCanParticipate,
} from "QuorumGrassroots/campaign-forms/action-creators"

import {
    Address,
    Campaign,
    Official,
    SetOfficialsPreviewAction,
    SetTargetedMessagesAction,
    StoreSupporterPlaceholderValuesAction,
    SupporterRegisterFields,
    TargetedMessage,
} from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/interfaces"
import { parseAddress } from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/helper"

interface Props {
    address: string | Address
    campaign: Campaign
    uniqueWidgetId: string
    updateCanParticipate: (uniqueWidgetId: string, canParticipate: boolean) => void
    setOfficialsPreview: (uniqueWidgetId: string, officials: Official[]) => void
    setTargetedMessages: (uniqueWidgetId: string, messages: Record<string, TargetedMessage>) => void
    storeSupporterPlaceholderValues: (uniqueWidgetId: string, supporter: SupporterRegisterFields) => void
    replaceTargetedMessageSupporterNamePlaceholders: (
        uniqueWidgetId: string,
        supporter: SupporterRegisterFields,
    ) => void
    name: string
    dataCy?: string
    label?: string
    placeholder?: string
    displayErrorWithoutTouch?: boolean
    validate?: (formValue: unknown) => string
    accessibilityId?: string
    showAsterisk?: boolean
    isCampaignPage?: boolean
    disabled?: boolean
    tooltipText?: string | null
    firstname: string
    lastname: string
}

const UnconnectedEmailFieldWithOfficials = (props: Props) => {
    const [email, setEmail] = useState<string>("")

    const handleOfficialsByEmail = (email: string) => {
        const { address } = props

        const isEmailValid = regexes.emailValidationRegex.test(email)
        if (!isEmailValid) return

        const { addressDict, addressText, isInvalidAddress } = parseAddress(address)
        if (isInvalidAddress) return

        props.setOfficialsPreview(props.uniqueWidgetId, null)
        fetchOfficialsByAddress(props.campaign.id, addressText, email, addressDict).then((response) => {
            const { targets, targeted_messages } = response
            const supporter = {
                firstname: props.firstname,
                lastname: props.lastname,
                address: addressText,
                email,
            }
            props.updateCanParticipate(props.uniqueWidgetId, true)
            props.setTargetedMessages(props.uniqueWidgetId, targeted_messages)
            props.replaceTargetedMessageSupporterNamePlaceholders(props.uniqueWidgetId, supporter)
            props.storeSupporterPlaceholderValues(props.uniqueWidgetId, supporter)
            props.setOfficialsPreview(
                props.uniqueWidgetId,
                targets.map((official: Official) => ({
                    value: String(official.id),
                    label: official.name,
                    imageUrl: official.image_url,
                    targeted: true,
                    message_group_id: String(official.message_group_id),
                })),
            )
        })
    }

    return (
        <EmailField
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                const currentEmail = e.target.value
                if (currentEmail !== email) {
                    setEmail(currentEmail)
                    handleOfficialsByEmail(currentEmail)
                }
            }}
            name={props.name}
            dataCy={props.dataCy}
            label={props.label}
            placeholder={props.placeholder}
            displayErrorWithoutTouch={props.displayErrorWithoutTouch}
            validate={props.validate}
            accessibilityId={props.accessibilityId}
            showAsterisk={props.showAsterisk}
            isCampaignPage={props.isCampaignPage}
            disabled={props.disabled}
            tooltipText={props.tooltipText}
        />
    )
}

const selector = formValueSelector("registration")

const mapStateToProps = (state) => {
    return {
        address: selector(state, "input_address"),
        firstname: selector(state, "firstname"),
        lastname: selector(state, "lastname"),
    }
}

export const EmailFieldWithOfficials = connect(
    mapStateToProps,
    fieldWithOfficialActions,
)(UnconnectedEmailFieldWithOfficials)
