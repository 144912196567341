import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import SyncSelect, { components } from "react-select"
import AsyncSelect from "react-select/async"

import Icon from "Components/Atoms/Icon"
import Label from "Components/Atoms/Label"

import * as S from "Components/Molecules/SelectDropdown/style"
import * as colors from "Components/colors"

import { useWindowSize } from "utils/hooks"

/**
 * Replace the Option component to render a checkbox next to the label when isMulti is true
 */
const Option = ({ children, innerProps, innerRef, isFocused, isSelected, selectProps, ...rest }) => (
    <S.MultiSelectOption ref={innerRef} isFocused={isFocused} isSelected={isSelected} {...innerProps} {...rest}>
        {selectProps.isMulti && !selectProps.shouldSuppressCheckbox && (
            <Icon icon={isSelected ? "check-square" : "square"} iconFamily={isSelected ? "fad" : "far"} />
        )}
        {children}
    </S.MultiSelectOption>
)

/**
 * Modify the MultiValueLabel to allow onClick behavior
 */
const MultiValueLabel = (props) => (
    <components.MultiValueLabel {...props}>
        <S.MultiValueLabel
            onClick={() => props.data.onClick && props.data.onClick(props.data)}
            style={{ cursor: props.data.onClick ? "pointer" : "default" }}
        >
            {props.children}
            {props.data.icon && <Icon icon={props.data.icon} iconFamily="fas" />}
        </S.MultiValueLabel>
    </components.MultiValueLabel>
)

/**
 * Modify the MultiValueRemove component to use a larger X icon
 */
const MultiValueRemove = (props) => (
    <components.MultiValueRemove {...props}>
        <Icon icon="times" iconFamily="far" color={colors.QuorumBlue} />
    </components.MultiValueRemove>
)

const DropdownIndicator = ({ selectProps, ...props }) =>
    !selectProps.shouldSuppressDropdownIndicator ? (
        selectProps.icon ? (
            <Icon icon={selectProps.icon} iconFamily={selectProps.iconFamily} color={selectProps.iconColor} size="lg" />
        ) : (
            <components.DropdownIndicator {...props} selectProps={selectProps} />
        )
    ) : null

const SelectDropdown = ({
    cacheOptions,
    dataCy,
    components,
    description,
    disabled,
    errorMessage,
    fitContent,
    icon,
    iconColor,
    iconFamily,
    id,
    isAsync,
    isMulti,
    isSearchable,
    isSmall,
    isClearable,
    label,
    tooltipElement,
    loadOptions,
    onChange,
    options,
    placeholder,
    shouldSuppressCheckbox,
    shouldSuppressDropdownIndicator,
    subLabel,
    value,
    warningMessage,
}) => {
    // Allow local state management during development
    const [values, setValues] = useState(value)
    const [selectTop, setSelectTop] = useState(null)
    const { windowWidth, windowHeight } = useWindowSize()

    const selectRef = React.createRef()

    useEffect(() => {
        const dropdownThreshold = 2 * (windowHeight / 3)
        if (selectRef.current && selectRef.current.offsetTop !== selectTop) {
            // if selectTop is already 0, no need to change anything
            if (selectRef.current.offsetTop >= dropdownThreshold && selectTop) {
                setSelectTop(0)
            } else if (selectRef.current.offsetTop < dropdownThreshold) {
                // selectRef is the selector input bar, so the top of menu box should include the height input bar
                // 76 is the redux-form footer, add up to 85 to show a little gap between the footer and the bottom border of the menu box
                setSelectTop(selectRef.current.offsetTop + selectRef.current.offsetHeight + 85)
            }
        }
    }, [windowWidth, windowHeight, selectRef])

    const onSelectChange = (newValue, action) => {
        setValues(newValue)
        onChange(newValue, action)
    }

    const renderNoOptionsMessage = ({ inputValue }) => {
        if (isAsync) {
            return inputValue && inputValue.length ? "No Results" : null
        }

        return "No Results"
    }

    const Select = isAsync ? AsyncSelect : SyncSelect

    const selectComponents = {
            DropdownIndicator,
            IndicatorSeparator: null,
            MultiValueLabel,
            MultiValueRemove,
            Option,
        }

    return (
        <div data-auto-cy="MoleculeSelectDropdown" data-cy={dataCy}>
            {(label || subLabel) && (
                <S.LabelContainer>
                    <S.LabelWrapper>
                        <S.Label
                            id={`${id}-aria-label`}
                            htmlFor={id}
                            disabled={disabled}
                        >
                            {label}
                        </S.Label>
                        {tooltipElement &&
                            <S.TooltipIcon>
                                {tooltipElement}
                            </S.TooltipIcon>
                        }
                    </S.LabelWrapper>
                    {subLabel && (
                        <S.SubLabel>
                            {subLabel.toLowerCase().indexOf("counting") < 0 && (
                                <Icon icon="paper-plane" iconFamily="far" />
                            )}
                            {subLabel}
                        </S.SubLabel>
                    )}
                </S.LabelContainer>
            )}
            <S.SelectWrapper fitContent={fitContent} ref={selectRef}>
                <Select
                    aria-labelledby={`${id}-aria-label`}
                    backspaceRemovesValue={false}
                    components={components || selectComponents}
                    hasError={errorMessage}
                    hasWarning={warningMessage}
                    hideSelectedOptions={false}
                    icon={icon}
                    iconColor={iconColor}
                    iconFamily={iconFamily}
                    id={id}
                    isClearable={isClearable || false}
                    isDisabled={disabled}
                    isMulti={isMulti}
                    isSearchable={isSearchable}
                    onChange={onSelectChange}
                    cacheOptions={cacheOptions}
                    options={options}
                    defaultOptions={options}
                    loadOptions={loadOptions}
                    menuPlacement={selectTop ? "bottom" : "top"}
                    placeholder={placeholder}
                    styles={S.selectStyles}
                    tabSelectsValue={false}
                    top={selectTop}
                    value={onChange ? value : values}
                    noOptionsMessage={renderNoOptionsMessage}
                    shouldSuppressCheckbox={shouldSuppressCheckbox}
                    shouldSuppressDropdownIndicator={shouldSuppressDropdownIndicator}
                    isSmall={isSmall}
                />
            </S.SelectWrapper>
            {(description || errorMessage || warningMessage) && (
                <S.Description hasError={errorMessage} hasWarning={warningMessage}>
                    {errorMessage && <Icon icon="exclamation-triangle" />}
                    {!errorMessage && warningMessage && <Icon icon="exclamation-square" />}
                    {errorMessage || warningMessage || description}
                </S.Description>
            )}
        </div>
    )
}

SelectDropdown.defaultProps = {
    id: "SelectDropdown",
    isSearchable: true,
}

SelectDropdown.propTypes = {
    cacheOptions: PropTypes.any,
    components: PropTypes.any,
    dataCy: PropTypes.string,
    description: PropTypes.string,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.string,
    fitContent: PropTypes.bool,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    iconFamily: PropTypes.string,
    id: PropTypes.string,
    isClearable: PropTypes.bool,
    isAsync: PropTypes.bool,
    isMulti: PropTypes.bool,
    isSearchable: PropTypes.bool,
    isSmall: PropTypes.bool,
    label: PropTypes.string,
    tooltipElement: PropTypes.element,
    loadOptions: PropTypes.func,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            label: PropTypes.string,
            onClick: PropTypes.func,
            value: PropTypes.string,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    icon: PropTypes.string,
                    label: PropTypes.string,
                    onClick: PropTypes.func,
                    value: PropTypes.string,
                })
            ),
        })
    ),
    placeholder: PropTypes.string,
    shouldSuppressCheckbox: PropTypes.bool,
    subLabel: PropTypes.string,
    value: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    warningMessage: PropTypes.string,
}

export default SelectDropdown
