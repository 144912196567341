import { constants, themeStyleConstants } from "QuorumGrassroots/constants"
import styleWithOrgDesign from "QuorumGrassroots/styled-components/connect"
import { hexCodetoRGBA } from "app/static/frontend/imports/desktopHelperFunctions"
import React from "react"

/**
 * Wraps the string in a media query denoting styles only to be applied in desktop screen sizes
 *
 * @param  {string} styling - The desired styling to be applied
 * @return {string}
 */
export const styleInDesktopScreenSize = (styling) => `
    @media(min-width: ${constants.mobileWidth + 1}px) {
        ${styling}
    }
`

/**
 * Wraps the string in a media query denoting styles only to be applied in mobile screen sizes
 *
 * @param  {string} styling - The desired styling to be applied
 * @return {string}
 */
export const styleInMobileScreenSize = (styling) => `
    @media(max-width: ${constants.mobileWidth}px) {
        ${styling}
    }
`

/**
 * Helper function for styleHeaderImageOrColor
 * If actionCenterSettings or actionCenterSettings.navigation_bar_style_type are not defined in props,
 * fall back to organizationDesign to set header background.
 * If organizationDesign is undefined, do not override current styling.
 * @param  {object} organizationDesign - The organizationDesign from props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
const styleWithOrganizationDesign = (organizationDesign) => {
    if (organizationDesign) {
        return organizationDesign.should_use_action_center_header_color
            ? `background-color: ${organizationDesign.action_center_header_color};`
            : `
                background-image: url("${organizationDesign.background_image}");
                background-color: ${hexCodetoRGBA(organizationDesign.primary_color, 0.4)};
            `
    }
    return ""
}

/**
 * Depending on organization design settings and the action center settings,
 * either return a styling with background image or solid color for the header.
 * The action center settings override the organization design.
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const styleHeaderImageOrColor = (props) => {
    if (props.isTransparent) {
        return "background-color: transparent;"
    } else if (props.actionCenterSettings) {
        switch (props.actionCenterSettings.navigation_bar_style_type) {
            case DjangIO.app.grassroots.enums.ActionCenterNavigationBarBackgroundStyle.image.value:
                return `
                    background-image: url("${props.actionCenterSettings.navigation_bar_image_override_url}");
                    background-color: ${hexCodetoRGBA(props.organizationDesign.primary_color, 0.4)};
                `
            case DjangIO.app.grassroots.enums.ActionCenterNavigationBarBackgroundStyle.color.value:
                return `background-color: ${props.actionCenterSettings.navigation_bar_color_override};`
            case DjangIO.app.grassroots.enums.ActionCenterNavigationBarBackgroundStyle.transparent.value:
                return ""
            // if navigation_bar_style_type is not one of the defined enum values don't override style
            default:
                return styleWithOrganizationDesign(props.organizationDesign)
        }
    }
    return styleWithOrganizationDesign(props.organizationDesign)
}

/**
 * Set the default background color from the organization design's primary color UNLESS
 * the action center settings have been set to a transparent background on the navigation bar
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const getHeaderBackgroundColorRuleMinusSemiColon = (props) => {
    if (props.actionCenterSettings && props.organizationDesign) {
        switch (props.actionCenterSettings.navigation_bar_style_type) {
            case DjangIO.app.grassroots.enums.ActionCenterNavigationBarBackgroundStyle.image.value:
                return `background-color: ${props.organizationDesign.primary_color}`
            case DjangIO.app.grassroots.enums.ActionCenterNavigationBarBackgroundStyle.color.value:
                return `background-color: ${props.actionCenterSettings.navigation_bar_color_override}`
            case DjangIO.app.grassroots.enums.ActionCenterNavigationBarBackgroundStyle.transparent.value:
                return ""
            // if navigation_bar_style_type is not one of the defined enum values don't override style
            default:
                return props.organizationDesign.should_use_action_center_header_color
                    ? `background-color: ${props.organizationDesign.action_center_header_color}`
                    : `background-color: ${props.organizationDesign.primary_color}`
        }
    }
    // if props.actionCenterSettings or props.actionCenterSettings are not defined then default to light grey background
    return `background-color: ${constants.defaultBackgroundGrey}`
}

/**
 * Check if the action center design has a solid white color for the header.
 * If so, return background-color for organization's secondary color
 * @return {string}
 */
export const getBackgroundColorForWhiteHeader = (props) => {
    if (
        props.organizationDesign.should_use_action_center_header_color &&
        props.organizationDesign.action_center_header_color.toUpperCase() === constants.whiteColor
    ) {
        return `background-color: ${props.organizationDesign.secondary_color} !important;`
    } else {
        return ""
    }
}

/**
 * helper function to be used for dynamic styled overrides based on action center theme
 * @param  {object} actionCenterSettings - settings from props of a styled component connected with styleWithOrgDesign
 * @param  {object} organizationDesign - org design from props of a styled component connected with styleWithOrgDesign
 * @param  {string} defaultThemeStyle - CSS to return if theme_type is default in actionCenterSettings
 * @param  {string} impactThemeStyle - CSS to return if theme_type is impact in actionCenterSettings
 * @param  {string} invalidThemeStyle - CSS to return if theme_type is not a valid enum value from ActionCenterTheme enum
 * @param  {string} disconnectedStyle - CSS to return if function is called from a styled component not using styleWithOrgDesign connection
 * @return {string} - CSS to override styling of styled component calling function
 */
export const overrideStyleBasedOnTheme = ({
    actionCenterSettings,
    organizationDesign,
    defaultThemeStyle,
    impactThemeStyle,
    invalidThemeStyle,
    disconnectedStyle,
}) => {
    if (actionCenterSettings && organizationDesign) {
        switch (actionCenterSettings.theme_type) {
            case DjangIO.app.grassroots.types.ActionCenterTheme.default.value:
                return defaultThemeStyle
            case DjangIO.app.grassroots.types.ActionCenterTheme.impact.value:
                return impactThemeStyle
            default:
                return invalidThemeStyle
        }
    }
    return disconnectedStyle
}

/**
 * return the correct height for the header based on the action center theme
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const overrideHeaderStyle = (props) => {
    const defaultThemeStyle = ""
    const impactThemeStyle = `
        align-items: center;
        height: ${themeStyleConstants[DjangIO.app.grassroots.types.ActionCenterTheme.impact.value].headerHeight}px;
        z-index: 1;
    `

    return overrideStyleBasedOnTheme({
        actionCenterSettings: props.actionCenterSettings,
        organizationDesign: props.organizationDesign,
        defaultThemeStyle: props.isCampaignPage ? impactThemeStyle : defaultThemeStyle,
        impactThemeStyle,
        invalidThemeStyle: "",
        disconnectedStyle: "",
    })
}

/**
 * set the background for the entire grassroots site
 * If actionCenter settings is undefined then do not set a background
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const styleAppBackground = (props) => {
    if (props.backgroundStyleType) {
        switch (props.backgroundStyleType) {
            case DjangIO.app.grassroots.enums.ActionCenterBackgroundStyle.image.value:
                return `
                    background-image: url("${props.backgroundImage}");
                    background-size: cover;
                    background-position: center;
                    background-attachment: fixed;
                `
            case DjangIO.app.grassroots.enums.ActionCenterBackgroundStyle.color.value:
                return `background-color: ${props.backgroundColor};`
            default:
                break
        }
    }
    if (props.actionCenterSettings) {
        switch (props.actionCenterSettings.background_style_type) {
            case DjangIO.app.grassroots.enums.ActionCenterBackgroundStyle.image.value:
                return `
                    background-image: url("${props.actionCenterSettings.background_image_override_url}");
                    background-size: cover;
                    background-position: center;
                    background-attachment: fixed;
                `
            case DjangIO.app.grassroots.enums.ActionCenterBackgroundStyle.color.value:
                return `background-color: ${props.actionCenterSettings.background_color_override};`
            // if background_style_type is not one of the defined enum values don't set background style
            default:
                return ""
        }
    }
    return ""
}

/**
 * dynamically choose CSS styling based on action center theme
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const overridePageStyle = (props) => {
    const defaultThemeStyle = ""
    const impactThemeStyle = `
        border-radius: 4px;
        ${styleInDesktopScreenSize(`
            ${themeStyleConstants[DjangIO.app.grassroots.types.ActionCenterTheme.impact.value].pageDesktopStyle}
            ${
                props.shouldNotShowNavBar
                    ? `
                        margin-top: 0px;
                        padding-top: 0px;
                    `
                    : ""
            }
        `)}
    `
    return overrideStyleBasedOnTheme({
        actionCenterSettings: props.actionCenterSettings,
        organizationDesign: props.organizationDesign,
        defaultThemeStyle: props.isCampaignPage ? impactThemeStyle : defaultThemeStyle,
        impactThemeStyle,
        invalidThemeStyle: "",
        disconnectedStyle: "",
    })
}

/**
 * override the page background color for campaign pages
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const overridePageBackgroundColor = (props) => {
    if (props.actionCenterSettings && props.isCampaignPage) {
        return "background-color: transparent;"
    }
    return ""
}

// trim the whitespace and new lines out of a string because this is ignored in css
// this is a helper function for testing other helper functions
export const ignoreWhitespace = (str) => str.replace(/ /g, "").replace(/(\r\n|\n|\r)/gm, "")

/**
 * helper function to be used for creating unit tests for helper functions
 * which dynamically override styles based on action center theme
 * @param  {string} testName - name of test suite that will be returned, generally the name of the styling helper function being tested
 * @param  {func}   functionToTest - styling helper function to tests
 * @param  {object} initialProps - object which represents the base props that would be passed to the function being tested
 * @param  {string} expecteDdefaultThemeStyle - expected return if theme_type is default in actionCenterSettings
 * @param  {string} expectedImpactThemeStyle - expected return if theme_type is impact in actionCenterSettings
 * @param  {string} expectedInvalidThemeStyle - expected return if theme_type is not a valid enum value from ActionCenterTheme enum
 * @param  {string} expectedDisconnectedStyle - expected return if function is called from a styled component not using styleWithOrgDesign connection
 * @return {jest test suite}
 */
export const createUnitTestsForThemeStylingHelperFunction = ({
    testName,
    functionToTest,
    initialProps,
    expectedDefaultThemeStyle,
    expectedImpactThemeStyle,
    expectedInvalidThemeStyle,
    expectedDisconnectedStyle,
}) => {
    const themeType = DjangIO.app.grassroots.types.ActionCenterTheme
    return describe(testName, () => {
        it("if actionCenterSettings or organizationDesign is undefined", () => {
            const props = {}
            const style = functionToTest(props)
            expect(ignoreWhitespace(style)).toEqual(ignoreWhitespace(expectedDisconnectedStyle))
        })
        it("if theme_type is default", () => {
            const props = {
                ...initialProps,
                actionCenterSettings: {
                    ...initialProps.actionCenterSettings,
                    theme_type: themeType.default.value,
                },
            }
            const style = functionToTest(props)
            expect(ignoreWhitespace(style)).toEqual(ignoreWhitespace(expectedDefaultThemeStyle))
        })
        it("if theme_type is impact", () => {
            const props = {
                ...initialProps,
                actionCenterSettings: {
                    ...initialProps.actionCenterSettings,
                    theme_type: themeType.impact.value,
                },
            }
            const style = functionToTest(props)
            expect(ignoreWhitespace(style)).toEqual(ignoreWhitespace(expectedImpactThemeStyle))
        })
        it("if theme_type is not a defined enum value", () => {
            const props = {
                ...initialProps,
                actionCenterSettings: {
                    ...initialProps.actionCenterSettings,
                    theme_type: null,
                },
            }
            const style = functionToTest(props)
            expect(ignoreWhitespace(style)).toEqual(ignoreWhitespace(expectedInvalidThemeStyle))
        })
    })
}

/**
 * dynamically override Header padding based on action center theme
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const overrideHeaderPadding = (props) => {
    const defaultThemeStyle = `
        padding-top: 3px;
        padding-right: 76px;
    `
    const impactThemeStyle = `
        padding-left: 115px;
        padding-right 110px;
    `
    return overrideStyleBasedOnTheme({
        actionCenterSettings: props.actionCenterSettings,
        organizationDesign: props.organizationDesign,
        defaultThemeStyle: props.isCampaignPage ? impactThemeStyle : defaultThemeStyle,
        impactThemeStyle,
        invalidThemeStyle: "",
        disconnectedStyle: "",
    })
}

/**
 * dynamically override Header padding based on action center theme
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const overrideHeaderPaddingLogoOnly = (props) => {
    const defaultTheme = `
        padding-top: 17px;
        padding-left: 0px;
        padding-right: 0px;
    `
    const impactThemeStyle = `
        padding: 0px;
    `
    return overrideStyleBasedOnTheme({
        actionCenterSettings: props.actionCenterSettings,
        organizationDesign: props.organizationDesign,
        defaultThemeStyle: props.isCampaignPage ? impactThemeStyle : defaultTheme,
        impactThemeStyle,
        invalidThemeStyle: `
            padding-top: 17px;
            padding-left: 0px;
            padding-right: 0px;
        `,
        disconnectedStyle: "",
    })
}

/**
 * dynamically override background color for "Or Register Below" text in registration form
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const overrideRegistrationFormSpanBackground = (props) => {
    const defaultThemeStyle = ""
    const impactThemeStyle = "background-color: white;"
    return overrideStyleBasedOnTheme({
        actionCenterSettings: props.actionCenterSettings,
        organizationDesign: props.organizationDesign,
        defaultThemeStyle: props.isCampaignPage ? impactThemeStyle : defaultThemeStyle,
        // overrideWidgetBackgroundColor always sets background color to white on forms if impactTheme is active
        impactThemeStyle: "background-color: white;",
        invalidThemeStyle: "",
        disconnectedStyle: "",
    })
}

/**
 * dynamically override border for line which separate submit button in forms
 * @param  {object} props - The props of the Styled component (must styleWithOrgDesign connection)
 * @return {string}
 */
export const overrideHorizontalRuleBorder = (props) => {
    const defaultThemeStyle = ""
    const impactThemeStyle = "border: 0px;"
    return overrideStyleBasedOnTheme({
        actionCenterSettings: props.actionCenterSettings,
        organizationDesign: props.organizationDesign,
        defaultThemeStyle: props.isCampaignPage ? impactThemeStyle : defaultThemeStyle,
        impactThemeStyle,
        invalidThemeStyle: "",
        disconnectedStyle: "",
    })
}

// https://github.com/styled-components/styled-components/issues/2266
// https://github.com/styled-components/styled-components/issues/3137
export const styleWithOrgDesignHelper = (component) =>
    styleWithOrgDesign((props) => {
        const ConnectedComponent = component

        return <ConnectedComponent {...props} />
    })

function luminance(r, g, b) {
    // https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
    // https://www.w3.org/TR/WCAG20/#relativeluminancedef
    // https://www.w3.org/TR/WCAG20/#contrast-ratiodef

    const RsRGB = r / 255
    const GsRGB = g / 255
    const BsRGB = b / 255

    const R = RsRGB <= 0.03928 ? RsRGB / 12.92 : ((RsRGB + 0.055) / 1.055) ** 2.4
    const G = GsRGB <= 0.03928 ? GsRGB / 12.92 : ((GsRGB + 0.055) / 1.055) ** 2.4
    const B = BsRGB <= 0.03928 ? BsRGB / 12.92 : ((BsRGB + 0.055) / 1.055) ** 2.4

    return 0.2126 * R + 0.7152 * G + 0.0722 * B
}

const getHexLuminance = (hex) => {
    const rgba = hexCodetoRGBA(hex)
    if (!rgba || !rgba.startsWith("rgba(") || !rgba.endsWith(")") || rgba.split(",").length !== 4) {
        return luminance(0, 0, 0) // default to white
    }
    const [r, g, b] = hexCodetoRGBA(hex, 1).split("rgba(")[1].split(")")[0].split(",")
    return luminance(r, g, b)
}

/**
 * Given a background color, returns black or white, depending on which has the best contrast ratio
 * @param  {string} hex - the hex background color
 * @param  {string} darkFontColor - a hex color to be returned in place of black, defaults to black
 * @return {string} - white or darkFontColor
 */
export const getFontColorForBackgroundContrast = (hex, darkFontColor = "#000000", lightFontColor = "#FFFFFF") => {
    //0.179 is the threshold for the contrast ratio of black > white
    return getHexLuminance(hex) > 0.179 ? darkFontColor : lightFontColor
}

/**
 * Decides if a hex color is light or dark based on its contrast ratio with black or white
 * @param  {string} hex - the hex background color
 * @return {string} - true if contrast with black is greater than contrast with white(ie. color is light)
 */
export const isHexColorLight = (hex) => {
    //0.179 is the threshold for the contrast ratio of black > white
    return getHexLuminance(hex) > 0.179
}

// Generated by GPT-4 from OpenAI
function rgbToHsv(r, g, b) {
    r /= 255
    g /= 255
    b /= 255

    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    const delta = max - min
    let h,
        s,
        v = max

    if (delta === 0) {
        // Achromatic (grey)
        h = 0
    } else {
        if (max === r) {
            h = ((g - b) / delta + (g < b ? 6 : 0)) / 6
        } else if (max === g) {
            h = ((b - r) / delta + 2) / 6
        } else {
            h = ((r - g) / delta + 4) / 6
        }
    }

    s = max === 0 ? 0 : delta / max

    return [h, s, v]
}

// Generated by GPT-4 from OpenAI
function hsvToHex(h, s, v) {
    let r, g, b
    const i = Math.floor(h * 6)
    const f = h * 6 - i
    const p = v * (1 - s)
    const q = v * (1 - f * s)
    const t = v * (1 - (1 - f) * s)

    switch (i % 6) {
        case 0:
            r = v
            g = t
            b = p
            break
        case 1:
            r = q
            g = v
            b = p
            break
        case 2:
            r = p
            g = v
            b = t
            break
        case 3:
            r = p
            g = q
            b = v
            break
        case 4:
            r = t
            g = p
            b = v
            break
        case 5:
            r = v
            g = p
            b = q
            break
        default:
            r = 0
            g = 0
            b = 0
            break
    }

    const toHex = (c) =>
        Math.round(c * 255)
            .toString(16)
            .padStart(2, "0")

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}

export function adjustHsv(h, s, v, isLight, hover = false) {
    const adjustAmount = 0.2
    const hoverAmount = 0.1
    if (isLight) {
        // Light primary colors: Increase saturation
        s += adjustAmount
        if (hover) {
            s = s > 1 ? s + hoverAmount : Math.min(s + hoverAmount, 1) // Only allows hover to loop if background color already looped
        }
    } else {
        // Dark primary colors: Decrease brightness
        v -= adjustAmount
        if (hover) {
            v = v < 0 ? v - hoverAmount : Math.max(v - hoverAmount, 0) // Only allows hover to loop if background color already looped
        }
    }

    // checks if s or v are 1 since 1 % 1 = 0 which is undesired
    // loops over the value if it's out of range. eg. 1.2 -> 0.2, -0.2 -> 0.8
    s = s === 1 ? s : s < 0 ? (s + 1) % 1 : s % 1
    v = v === 1 ? v : v < 0 ? (v + 1) % 1 : v % 1

    return [h, s, v]
}

/**
 * Given a background color, returns a color that has been adjusted to be more visible
 * If white is the best contrast, returns the original color with decreased brightness on HSV scale
 * If black is the best contrast, returns the original color with increased saturation on HSV scale
 * On hover, the adjustment is increased
 * @param  {string} colorHex - the hex background color
 * @param  {boolean} isHover - if true, will make the adjustment increased for hover effect
 * @return {string} - hex resulting color
 */
export function getDynamicColorHex(colorHex, isHover = false) {
    const rgb = colorHex.match(/[A-Za-z0-9]{2}/g).map((hex) => parseInt(hex, 16))
    const [h, s, v] = rgbToHsv(...rgb)
    const isLight = isHexColorLight(colorHex)
    const adjustedHsv = adjustHsv(h, s, v, isLight, isHover)
    return hsvToHex(...adjustedHsv).toUpperCase()
}
