import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import DatePicker from "app/static/frontend/components/DatePicker"
import ReduxFormFieldWrapper from "forms/components/ReduxFormFieldWrapper"

import "app/static/stylus/FormStyles.styl"
import "forms/stylus/DateField.styl"

export const DateField = (props) => {
    const {
        input: { name, value, onChange },
        meta: { error, warning, touched },
        className,
        customInputStyle,
        dataCy,
        label,
        labelStyle,
        shouldBeInline,
        tooltipText,
        clearable,
        style,
        accessibilityId,
        renderErrorBelowField,
        displayErrorWithoutTouch,
        disabled,
    } = props

    const submitDate = (date) => {
        return date ? onChange(date.toAPIString()) : onChange("")
    }

    return (
        <ReduxFormFieldWrapper
            className={classNames("date", className)}
            displayErrorWithoutTouch={displayErrorWithoutTouch}
            error={error}
            label={label}
            labelStyle={labelStyle}
            style={style}
            tooltipText={tooltipText}
            touched={touched}
            warning={warning}
            accessibilityId={accessibilityId}
            renderErrorBelowField={renderErrorBelowField}
            dataCy={dataCy}
        >
            <DatePicker
                customStyle={disabled ? { background: "#ecf0f1" } : customInputStyle}
                defaultDate={value}
                publishDateChanges={submitDate}
                shouldBeInline={shouldBeInline}
                clearable={clearable}
                disabled={disabled}
                useCurrentDateForUndefinedDefaultDate={false}
            />
        </ReduxFormFieldWrapper>
    )
}

DateField.propTypes = {
    className: PropTypes.string,
    customInputStyle: PropTypes.object,
    dataCy: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    meta: PropTypes.object.isRequired,
    shouldBeInline: PropTypes.bool,
    tooltipText: PropTypes.string,
    clearable: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default DateField
