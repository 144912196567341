import React from "react"
import classNames from "classnames"

import ReduxFormFieldWrapper from "app/static/frontend/forms/components/ReduxFormFieldWrapper"
import { AddressAutocompleteInput } from "shared-web/forms/components/AddressAutocompleteInput"

export const AddressFormComponent = (props) => {
    const {
        input: { value, onChange, onBlur },
        meta: { error, warning, touched },
        type,
        // support passing additional classnames from Field
        className,
        dataCy,
        label,
        disabled,
        placeholder,
        tooltipText,
        accessibilityId,
        // Boolean to determine if the error should be shown before the field is 'touched'. Defaulted to true
        displayErrorWithoutTouch,
        labelStyle,
        style,
        showAsterisk,
        // more props specific to address
        shouldGeocode,
        isCampaignPage,
        onClickAddressSuggestion,
    } = props

    const inputProps = {
        className: classNames(
            isCampaignPage ? "ngg-input-field" : "input-field",
            "form-control", // Needs to be specified for Textarea
            {
                "input-error-outline": error && (displayErrorWithoutTouch || touched) && !isCampaignPage,
                "ngg-input-error-outline": error && (displayErrorWithoutTouch || touched) && isCampaignPage,
                "input-warning-outline": warning && (displayErrorWithoutTouch || touched),
                "input-disabled": disabled,
            },
        ),
        "data-cy": dataCy,
        disabled,
        onBlur,
        onChange,
        placeholder,
        style,
        type,
        // otherwise there will be sadness about controlled and uncontrolled components
        value: value || "",
        id: accessibilityId,
    }

    const handleClickAddressSuggestion = (value) => {
        if (onClickAddressSuggestion) {
            onClickAddressSuggestion(value)
        }

        onChange(value)
    }

    return (
        <ReduxFormFieldWrapper
            className={classNames("input", className)}
            displayErrorWithoutTouch={displayErrorWithoutTouch}
            error={error}
            label={label}
            labelStyle={labelStyle}
            style={style}
            tooltipText={tooltipText}
            touched={touched}
            warning={warning}
            accessibilityId={accessibilityId}
            showAsterisk={showAsterisk} //the only frontend validation is if the field is required. If validate is null, then it isn't required
        >
            <AddressAutocompleteInput
                inputProps={inputProps}
                shouldGeocode={shouldGeocode}
                onAddressClick={handleClickAddressSuggestion}
            />
        </ReduxFormFieldWrapper>
    )
}
