import { Map, List, fromJS } from "immutable"
import { createSelector, createStructuredSelector } from "reselect"

import * as frameworkSelectors from "QuorumGrassroots/framework/selectors"
import { selectWidgetsFromProps } from "QuorumGrassroots/widgets/selectors"
import { createCustomFieldNameDict } from "shared/customFields/constants/helperFunctions"

const { GrassrootsRegistrationField } = DjangIO.app.grassroots.types

/*
 * Given a registration page, get the fields from the registration page.
 * We have some conditional behavior -- whether or not we want custom fields
 * or simple fields.
 */
export const getFieldsFromPage = ({ page, custom = false }) => {
    const customFieldValue = DjangIO.app.grassroots.types.GrassrootsRegistrationField.custom_field.value

    const fields = page
        .get("form_fields", List())
        .filter((field) => {
            const isCustomField = field.get("registration_field_type") === customFieldValue
            return isCustomField == custom
        })
        .sort((a, b) => a.get("order", 0) - b.get("order", 0))

    return fields
}

export const supporterHasSimpleField = (supporter, field) => {
    if (!supporter.tag_dict) {
        return false
    }

    const fieldEnum = DjangIO.app.grassroots.types.GrassrootsRegistrationField

    if (field.get("registration_field_type") === GrassrootsRegistrationField.text_opt_in.value) {
        return supporter.unsubscribed_from_texting !== null
    }

    return Boolean(supporter[fieldEnum.by_value(field.get("registration_field_type")).supporter_field])
}

export const supporterHasCustomField = (supporter, slug) => {
    if (!supporter.tag_dict) {
        return false
    }

    // must not be undefined and must not be an empty string. Empty
    // string case is because of some custom field idiosyncrasies where
    // some custom tags have default values of empty strings, even if
    // they actually have no default values.
    //
    // Also cannot be empty array (in the case it's a choice select).

    const value = supporter.tag_dict[slug]

    if (value == null || value === "" || (Array.isArray(value) && !value.length)) {
        return false
    }

    return true
}

/**
 * Function that returns a boolean given the Supporter information and field
 * If the field is an address registration field, see if supporter has validated address
 * For all other fields, call supporterHasSimpleField with userdata and field
 *
 * If advocate is logged in and on registration page, use 'supporterHasSimpleField' function
 * (This is to ensure that if an advocate registers using a link to a zip-code allowed registration form,
 * the advocate will receive the 'Thanks' message, and won't have to resubmit with full address)
 *
 * @name doesNotHaveValidField
 * @function
 * @param {Object} userdata - Supporter information
 * @param {Map} field - Immutable Map of field information
 * @param {Boolean} onRegistrationPageAndLoggedIn - Indicates advocate has logged in and on registration form
 *
 * @returns {Boolean} - value that determines if Supporter has valid value for field
 */
export const doesNotHaveValidField = (userdata, field, onRegistrationPageAndLoggedIn) =>
    field.get("registration_field_type") === DjangIO.app.grassroots.types.GrassrootsRegistrationField.address.value &&
    !onRegistrationPageAndLoggedIn
        ? !userdata.validated_address // Set address field as 'unfilled' if address is not valid
        : !supporterHasSimpleField(userdata, field)

export const selectRegistrationPageIds = createSelector(
    (_, props) => props.registrationPageIds,
    (registrationPageIds) => registrationPageIds,
)

export const selectGrassrootsForms = createSelector(
    [frameworkSelectors.selectRegistrationPages, frameworkSelectors.selectDonationForms],
    (registrationPages, donationForms) => registrationPages.concat(donationForms),
)

export const selectOnPageWithSignUpWidget = createSelector(
    selectWidgetsFromProps,
    // Check that a 'sign_up' widget is included on the page
    (widgets) =>
        Boolean(
            widgets &&
                widgets.find(
                    (widget) =>
                        widget && widget.widgetType === DjangIO.app.grassroots.types.GrassrootsWidgetType.sign_up.value,
                ),
        ),
)

export const selectOnPageWithSignUpWidgetAndLoggedIn = createSelector(
    frameworkSelectors.selectUserLoggedIn,
    selectOnPageWithSignUpWidget,
    (userLoggedIn, onPageWithSignUpWidget) => userLoggedIn && onPageWithSignUpWidget,
)

export const selectRegistrationPages = createSelector(
    [selectRegistrationPageIds, selectGrassrootsForms],
    (ids, grassrootsForms) => (ids ? grassrootsForms.filter((page) => ids.includes(page.get("id"))) : fromJS([])),
)

export const selectAllSimpleFields = createSelector(
    [
        frameworkSelectors.selectDefaultRegistrationPageId,
        selectRegistrationPages,
        (_, props) => props.additionalSimpleFields || [],
    ],
    (defaultRegPageId, regPages, additionalSimpleFields) => {
        // these are going to be fields that are provided from pages. We just append
        // all the required fields onto each other.

        // Get default registration form fields first, so UserInfoFormSection displays labels of default page
        const defaultRegPage = regPages.find((page) => page.get("id") === defaultRegPageId)
        const defaultRegPageFields = defaultRegPage
            ? getFieldsFromPage({ page: defaultRegPage, custom: false })
            : List()

        const pageFields = regPages.reduce((acc, page) => {
            const pageFieldObjs = getFieldsFromPage({ page, custom: false })
            return acc.concat(pageFieldObjs)
        }, List())

        // get the additional fields!
        const additionalSimpleFieldsObjs = additionalSimpleFields.map((simpleFieldEnum) => ({
            registration_field_type: simpleFieldEnum,
        }))
        const additionalFields = fromJS(additionalSimpleFieldsObjs)

        // Combine all these simple fields together
        const combinedFields = defaultRegPageFields.concat(pageFields).concat(additionalFields)

        // Remove duplicates
        const fields = combinedFields
            .map((field) => field.get("registration_field_type"))
            .toOrderedSet()
            .toList()
            .map((registrationFieldType) =>
                combinedFields.find((field) => field.get("registration_field_type") === registrationFieldType),
            )
            .filter((field) => field)

        // if we're mashing multiple reg pages together, people's sorting
        // preferences are not important. Sort them by our enum order.
        // If not, print based on the order that occurred!
        return regPages.size > 1 ? fields.sort() : fields
    },
)

/*
 * Now that we have all the possible simple fields, filter down the fields
 * to show only the ones we need to show.
 */
export const selectSimpleFields = createSelector(
    [
        selectAllSimpleFields,
        frameworkSelectors.selectUserdata,
        (_, props) => props.showOnlyUnfilledFields,
        selectOnPageWithSignUpWidgetAndLoggedIn,
    ],
    (simpleFields, userdata, unfilledOnly, onPageWithSignUpWidgetAndLoggedIn) => {
        let fields = simpleFields

        if (unfilledOnly) {
            fields = fields.filter((field) => doesNotHaveValidField(userdata, field, onPageWithSignUpWidgetAndLoggedIn))
        }

        return fields.toJS()
    },
)

// sorts combined simple and custom field objects
export const getAllSimpleAndCustomFieldsFromPage = ({ page }) => {
    if (!page) {
        return []
    }
    const fields = page.get("form_fields", List()).sort((a, b) => a.get("order", 0) - b.get("order", 0))

    return fields.toJS()
}

export const selectIncludesTextingOptIn = createSelector(
    selectSimpleFields,
    (fields) =>
        fields &&
        Boolean(
            fields.find(
                (field) =>
                    field.registration_field_type ===
                    DjangIO.app.grassroots.types.GrassrootsRegistrationField.text_opt_in.value,
            ),
        ),
)

/**
 * Creates a selector that selects the simple fields that
 * contains a prop of the given name. Optionally filter
 * out fields that have a fasly value for that prop.
 *
 * @name makeSelectSimpleFieldsWithProp
 * @function
 * @param {String} propName - the name of the prop to find fields with
 * @param {Boolean} filterFalsyValues - true if fields that have a falsy value
 *                                      for the given prop should be excluded
 *
 * @returns {Selector}    Selector that returns the fields with the given prop
 */
export const makeSelectSimpleFieldsWithProp = (propName, filterFalsyValues = true) =>
    createSelector(selectRegistrationPages, (regPages) =>
        regPages.reduce((acc, page) => {
            const simpleFields = getFieldsFromPage({ page, custom: false })
            // find the fields with the given prop present
            const fieldsWithProp = simpleFields.filter((field) => {
                return filterFalsyValues
                    ? // if we are filtering fields with falsy values for the given prop,
                      // we should filter on the value of the prop (.get())
                      field.get("props", Map()).get(propName)
                    : // otherwise, just check if we have that prop (.has())
                      field.get("props", Map()).has(propName)
            })

            let newAcc = { ...acc }

            // create a mapping of the field type to the given prop's value for that field
            fieldsWithProp.forEach((field) => {
                newAcc = {
                    ...newAcc,
                    [field.get("registration_field_type")]: field.get("props", Map()).get(propName),
                }
            })
            return newAcc
        }, {}),
    )

/*
 * Return an object for all simple fields with the 'default_value' prop
 * Key is registration_field_type, value is the value of 'default_value'
 */
export const selectSimpleFieldDefaults = makeSelectSimpleFieldsWithProp("default_value", false)

/*
 * Return an object for all simple fieldswith the 'optional_field = true' prop
 * Key is registration_field_type, value is 'true'
 */
export const selectOptionalSimpleFields = makeSelectSimpleFieldsWithProp("optional_field")

export const selectUnfilledSimpleFields = createSelector(
    [selectAllSimpleFields, frameworkSelectors.selectUserdata, selectOnPageWithSignUpWidgetAndLoggedIn],
    (fields, userdata, onPageWithSignUpWidgetAndLoggedIn) =>
        fields.filter((field) => doesNotHaveValidField(userdata, field, onPageWithSignUpWidgetAndLoggedIn)).toJS(),
)

export const selectUnfilledRequiredSimpleFields = createSelector(
    [selectUnfilledSimpleFields, selectOptionalSimpleFields],
    (unfilledFields, optionalFields) =>
        unfilledFields
            ? unfilledFields.filter(
                  (registrationFieldType) => !optionalFields[registrationFieldType.registration_field_type],
              )
            : [],
)

const selectCustomFieldPageObjs = createSelector(selectRegistrationPages, (regPages) =>
    regPages.reduce((acc, page) => {
        const customFieldObjs = getFieldsFromPage({ page, custom: true })
        return acc.concat(customFieldObjs)
    }, List()),
)

const makeCustomFieldsPropsSelector = (fieldName) =>
    createSelector(selectCustomFieldPageObjs, (customFieldObjs) =>
        customFieldObjs
            .filter((field) => field.getIn(["props", fieldName]))
            .reduce((acc, field) => {
                const props = field.get("props")

                return { ...acc, [props.get("id")]: props.get(fieldName) }
            }, {}),
    )

export const selectCustomFieldRequiredValues = makeCustomFieldsPropsSelector("required_value")
export const selectCustomFieldOptionalFields = makeCustomFieldsPropsSelector("optional_field")

export const selectAllCustomFields = createSelector(
    [
        frameworkSelectors.selectDefaultRegistrationPageId,
        selectRegistrationPages,
        selectCustomFieldPageObjs,
        frameworkSelectors.selectCustomFields,
    ],
    (defaultRegPageId, regPages, customFieldObjs, customFields) => {
        const defaultRegPage = regPages.find((page) => page.get("id") === defaultRegPageId)
        const defaultRegPageFields = defaultRegPage ? getFieldsFromPage({ page: defaultRegPage, custom: true }) : List()

        const allCustomFieldObjects = defaultRegPageFields.concat(customFieldObjs)

        const fields = allCustomFieldObjects
            .map((field) => field.getIn(["props", "id"]))
            .toOrderedSet()
            .toList()
            .map((customFieldId) => {
                // On the registration form, admins can set a custom field to have a explicit label only on that form
                const customField = customFields.find((tag) => tag.get("id") === customFieldId)

                const customFormField = allCustomFieldObjects.find(
                    (tag) => tag.getIn(["props", "id"]) === customFieldId,
                )

                const customLabel = customFormField.getIn(["props", "label"])

                // Here, we take the custom label (nested under 'props.label' and set the label as the 'external_name' on the custom field object)
                return customField && customLabel ? customField.set("external_name", customLabel) : customField
            })
            .filter((customField) => customField)

        return fields
    },
)

export const selectAllOptionalCustomFields = createSelector([selectCustomFieldPageObjs], (customFieldObjs) => {
    const optionalFields = {}
    const requiredFields = customFieldObjs.toJSON().filter((item) => !item.props.optional_field)
    customFieldObjs
        .toJSON()
        .filter((item) => item.props.optional_field && !requiredFields.find((r) => r.props.id === item.props.id))
        .reduce((acc, curr) => {
            if (!acc.find((a) => a.props.id === curr.props.id)) {
                acc.push(curr)
            }
            return acc
        }, [])
        .forEach((item) => (optionalFields[item.props.id] = true))
    return optionalFields
})

export const selectCustomFields = createSelector(
    [
        selectAllCustomFields,
        frameworkSelectors.selectUserdata,
        (_, props) => props.showOnlyFilledCustomFields,
        (_, props) => props.showOnlyUnfilledFields,
    ],
    (customFields, userdata, filledOnly, unfilledOnly) => {
        let fields = customFields

        if (filledOnly) {
            fields = fields.filter((customField) => supporterHasCustomField(userdata, customField.get("slug")))
        }

        if (unfilledOnly) {
            fields = fields.filter((customField) => !supporterHasCustomField(userdata, customField.get("slug")))
        }

        return fields.toJS()
    },
)

export const selectUnfilledCustomFields = createSelector(
    [selectAllCustomFields, frameworkSelectors.selectUserdata],
    (fields, userdata) =>
        fields.filter((customField) => !supporterHasCustomField(userdata, customField.get("slug"))).toJS(),
)

export const selectUnfilledRequiredCustomFields = createSelector(
    [selectUnfilledCustomFields, selectCustomFieldOptionalFields],
    (unfilledFields, optionalFields) =>
        unfilledFields ? unfilledFields.filter((customField) => !optionalFields[customField.id]) : [],
)

export const selectHasUnfilledRequiredFields = createSelector(
    [selectUnfilledRequiredSimpleFields, selectUnfilledRequiredCustomFields],
    (unfilledRequiredSimpleFields, unfilledRequiredCustomFields) =>
        Boolean(unfilledRequiredSimpleFields.length + unfilledRequiredCustomFields.length),
)

export const selectCustomFieldNameDict = createSelector(selectCustomFields, (pageCustomFields) =>
    createCustomFieldNameDict(pageCustomFields),
)

export const selectCurrentRegistrationPage = createSelector(
    selectRegistrationPageIds,
    selectGrassrootsForms,
    (currentRegistrationPageIds, grassrootsForms) => {
        // Given the list of current registration page ID, find the registration page corresponding to it
        for (let registrationPageId of currentRegistrationPageIds) {
            const form = grassrootsForms.find((page) => page.get("id") === registrationPageId)
            if (form) {
                return form
            }
        }
        return undefined
    },
)

export const selectAllowPartialAddress = createSelector(selectCurrentRegistrationPage, (page) =>
    page ? page.get("should_allow_partial_address") : undefined,
)

export const selectRegistrationPagePreText = createSelector(selectCurrentRegistrationPage, (registrationPage) =>
    registrationPage ? registrationPage.get("pre_text", "") : "",
)

export const selectRegistrationPagePostText = createSelector(selectCurrentRegistrationPage, (registrationPage) =>
    registrationPage ? registrationPage.get("post_text", "") : "",
)

/**
 * Takes selectors to create a selector that returns a parsed ordered combination
 * of all simple, additional simple, and custom fields.
 *
 * @name selectAllSimpleAndCustomFieldObjects
 * @function
 * @param {Selector} selectSimpleFields - selector that selects all simple fields
 * @param {Selector} selectCustomFields - selector that selects all custom fields
 * @param {Selector} selectCurrentRegistrationPage - selector that selects the form of the current registration page
 *
 * @returns {Selector} - returns a selector that selects all simple and custom fields,
 * additional simple fields, and in certain cases filled-out custom fields as
 * sorted/ordered objects with keys of isSimple and field. Simple fields also get
 * the md property, which will be used to determine its width when rendered. The
 * logic that determines whether a simple field's md will be 6 or 12 (when rendered
 * via a Bootstrap row) lives in the selectAllSimpleAndCustomFieldsWidth selector
 * (and is dependent on screen width and order proximity to other simple or custom fields).
 */
export const selectAllSimpleAndCustomFieldObjects = createSelector(
    selectSimpleFields,
    selectCustomFields,
    selectCurrentRegistrationPage,
    (_, props) => props.additionalSimpleFields || [],
    (simpleFields, customFields, page, additionalSimpleFields) => {
        const allFieldRows = getAllSimpleAndCustomFieldsFromPage({ page })
        let parsedCombinedFields = allFieldRows
            .map((field) => {
                if (field.registration_field_type === GrassrootsRegistrationField.custom_field.value) {
                    const customFieldId = field.props.id
                    const customFieldObj = customFields.find(
                        (customField) => customField && customField.id === customFieldId,
                    )
                    if (customFieldObj) {
                        return {
                            isSimple: false,
                            field: { [customFieldObj.slug]: customFieldObj },
                        }
                    }
                } else {
                    const simpleFieldObj = Object.values(simpleFields).find(
                        (simpleField) => simpleField.registration_field_type === field.registration_field_type,
                    )
                    if (simpleFieldObj) {
                        return {
                            isSimple: true,
                            field: simpleFieldObj,
                            md: "",
                        }
                    }
                }
            })
            .filter(Boolean)

        // crosscheck parsedCombinedFields (from registration page via getAllSimpleAndCustomFieldsFromPage) against all customFields
        // if the custom field is already in parsedCombinedFields, do nothing. (ex. if we're on a campaign page we don't want duplicate custom fields.)
        // if the custom field is NOT in parsedCombinedField, that means it will need to be parsed and added by this check.
        // (ex. a previously filled-out custom field that needs to be rendered on an Update Information page)
        let additionalCustomFieldsObjs = []
        if (customFields.length) {
            additionalCustomFieldsObjs = customFields
                .map((customField) => {
                    const dupe = parsedCombinedFields.find(
                        (combinedField) =>
                            combinedField &&
                            !combinedField.isSimple &&
                            Object.keys(combinedField.field)[0] === customField.slug,
                    )
                    if (!dupe) {
                        return {
                            isSimple: false,
                            field: { [customField.slug]: customField },
                        }
                    }
                })
                .filter(Boolean)
        }
        parsedCombinedFields = parsedCombinedFields.concat(additionalCustomFieldsObjs)

        let additionalSimpleFieldsObjs = []
        if (!parsedCombinedFields.length && additionalSimpleFields) {
            additionalSimpleFieldsObjs = additionalSimpleFields.map((simpleFieldEnum) => ({
                isSimple: true,
                field: {
                    registration_field_type: simpleFieldEnum,
                },
                md: "",
            }))
        }
        return parsedCombinedFields.concat(additionalSimpleFieldsObjs)
    },
)

/**
 * Takes a selector and creates a selector that selects an ordered array of all
 * custom and simple fields to be rendered with the proper md (width) propery
 * necessary for simple fields.
 *
 * @name selectAllSimpleAndCustomFieldsWidth
 * @function
 * @param {Selector} selectAllSimpleAndCustomFieldObjects - selector that selects
 * a parsed ordered combination of all simple, additional simple, custom, and
 * additional custom fields.
 *
 * @returns {Selector} - selector that selects an ordered list of field objects
 * and determines the md property of 6 or 12 for simple fields (to render them
 * with the correct/desired width via a Bootstrap row).
 */
export const selectAllSimpleAndCustomFieldsWidth = createSelector(
    selectAllSimpleAndCustomFieldObjects,
    (allFieldObjects) => {
        const allFieldRows = []
        let simpleFieldRow = []
        if (allFieldObjects) {
            allFieldObjects.forEach((fieldObj) => {
                if (fieldObj.isSimple) {
                    if (simpleFieldRow.length < 2) {
                        simpleFieldRow.push(fieldObj)
                    } else if (simpleFieldRow.length === 2) {
                        simpleFieldRow[0].md = 6
                        simpleFieldRow[1].md = 6
                        allFieldRows.push(...simpleFieldRow)
                        simpleFieldRow = []
                        simpleFieldRow.push(fieldObj)
                    }
                }
                if (!fieldObj.isSimple) {
                    if (simpleFieldRow.length === 1) {
                        simpleFieldRow[0].md = 12
                        allFieldRows.push(...simpleFieldRow)
                        simpleFieldRow = []
                    }
                    if (simpleFieldRow.length === 2) {
                        simpleFieldRow[0].md = 6
                        simpleFieldRow[1].md = 6
                        allFieldRows.push(...simpleFieldRow)
                        simpleFieldRow = []
                    }
                    if (simpleFieldRow.length === 0) {
                        allFieldRows.push(fieldObj)
                    }
                }
            })
            if (simpleFieldRow.length === 1) {
                simpleFieldRow[0].md = 12
                allFieldRows.push(...simpleFieldRow)
                simpleFieldRow = []
            }
            if (simpleFieldRow.length === 2) {
                simpleFieldRow[0].md = 6
                simpleFieldRow[1].md = 6
                allFieldRows.push(...simpleFieldRow)
                simpleFieldRow = []
            }
        }
        return allFieldRows
    },
)

export const selectFormFields = createStructuredSelector({
    allowPartialAddress: selectAllowPartialAddress,
    allSimpleAndCustomFieldsWidth: selectAllSimpleAndCustomFieldsWidth,
    simpleFieldObjs: selectSimpleFields,
    optionalSimpleFields: selectOptionalSimpleFields,
    customFields: selectCustomFields,
    customFieldNameDict: selectCustomFieldNameDict,
    customFieldOptionalFields: selectAllOptionalCustomFields,
    customFieldRequiredValues: selectCustomFieldRequiredValues,
    includesTextingOptIn: selectIncludesTextingOptIn,
    registrationPagePreText: selectRegistrationPagePreText,
    registrationPagePostText: selectRegistrationPagePostText,
})
