import React from "react"
import styled from "styled-components"

const ThankYouContainer = styled.div`
    text-align: center;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
`

const CheckIcon = styled.i`
    color: #7259ef;
    font-size: 10rem;
    margin-bottom: 1rem;
`

const Title = styled.h2`
    color: #000;
    margin-bottom: 1rem;
`

const Message = styled.p`
    font-size: 1.1rem;
    color: #4a4a4a;
`

export const ThankYouMessage = () => {
    return (
        <ThankYouContainer>
            <CheckIcon className="fa fa-check-circle" aria-hidden="true" />
            <Title>Thank you!</Title>
            <Message>Thank you, your contribution has been received.</Message>
        </ThankYouContainer>
    )
}
