import * as Sentry from "@sentry/browser"
import swalContents from "app/static/frontend/swalConfigs"
import { getNestedObjectKey } from "shared/imports/sharedHelperFunctions"
import {
    userActionLog,
    shouldThrowRavenError,
    showQueryIssue,
    showSwal,
} from "app/static/frontend/imports/backendErrorHelperFunctions"
/**
 * Quorum's global error function
 * @param {Object} data - the error response
 * @param {Object} swalConfig - an optional swal configuration object that overrides the default behavior
 * @param {Boolean } skipSwal - whether or not to show a swal. Set to false to show no swal.
 * @return {Promise|undefined} Either a promise, if the we are showing a swal, or undefined, if we determine
 * that a swal is unnecessary
 * of the swal message and swal confirm action
 * This functions handles the following things:
 * Send a message to Sentry to capture the exception
 * Send a message to our user analytics recording
 * Show a swal in some cases
 * -------- Implementation Details ----------
 * This function has async blocking properties - by default, this function will not return
 * until the user has resolved the Promise of the default swal - either by clicking OK, clicking Refresh,
 * or clicking off of the swal
 *
 * If you override  the swal configuration, and a swal should be shown (for example, we don't show a swal
 * for a cancelled request), the value returned will be a Promise that resolves to either null (if the user
 * hits the default Cancel or clicks off of swal) or whatever the return value is specificed for the button
 * in your swal configuration object. This is the behavior of sweetalert swals. However, if a swal should not be
 * shown, the return value of this function will be undefined.
 *
 *
 *
 * ---------- Misc Notes -------------
 * While we still have code that depends on the window, we'll set this to the window, but we'll
 * also begin to import BACKENDERROR in places over time
 */

const BACKENDERROR = async (
    data,
    swalConfig = swalContents.defaultError,
    skipSwal = false,
    shouldLogUserAnalytics = true,
) => {
    // Always log to the console so we don't lose the error
    console.log("The below error was logged via BACKENDERROR")
    console.error(data)

    // if we are aborting the request, there is no need to log any exception details or
    // inform the user
    if ((data && data.statusText && data.statusText === "abort") || (data && data.__CANCEL__)) {
        return
    }
    if (data) {
        if (shouldThrowRavenError(data)) {
            Sentry.captureException(data) // log error to sentry
        }
    }

    if (shouldLogUserAnalytics) {
        try {
            userActionLog(data) // log error to user analytics
        } catch (error) {
            console.warn("unable to log to user analytics")
        }
    }

    if (skipSwal) {
        return
    }

    // for some error codes, we want to change how the swal behavior
    if (data && data.response && data.response.status) {
        const status = data.response.status
        if ([404, 502, 504].includes(status)) {
            return

            // catch a ValidationError from the backend (e.g sending limit reached)
        } else if ([403, 400].includes(status)) {
            // check to see if swalConfig is NOT the default error message. The developer may want to override the default "not allowed" error message
            if (swalConfig !== swalContents.defaultError) {
                showSwal(swalConfig)
                // if the response data contains a message, display that instead of the default error message
            } else if (getNestedObjectKey(data, ["response", "data", "message"])) {
                showSwal({ ...swalContents.notAllowedError, text: data.response.data.message })
                // otherwise, show the default error message with the statusText if it exists AND is short enough
            } else if (getNestedObjectKey(data, ["response", "statusText"]) && data.response.statusText.length < 200) {
                showSwal({ ...swalContents.notAllowedError, text: `Error Message: ${data.response.statusText}` })
                // show the data of the error response, if it exists and is short enough.
            } else {
                showSwal(swalContents.notAllowedError)
            }
        } else if (status === 418) {
            showQueryIssue(data.response.data)
        } else {
            return showSwal(swalConfig)
        }
    } else {
        return showSwal(swalConfig)
    }
    return
}

// to support legacy use
window.BACKENDERROR = BACKENDERROR

export default BACKENDERROR
