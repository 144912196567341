import React from "react"
import PropTypes from "prop-types"
import DateTime from "react-datetime"
import classNames from "classnames"

import "app/static/stylus/FormStyles.styl"
import "../stylus/DateTimeField.styl"
import ReduxFormFieldWrapper from "app/static/frontend/forms/components/ReduxFormFieldWrapper"

export const DateTimeField = (props) => {
    const {
        disabled,
        input: { name, value, onChange },
        meta,
        label,
        dataCy,
        tooltipText,
        placeholder,
        dateFormat,
        timeFormat,
        displayTimeZone,
    } = props

    // show error if user has touched the field and it is invalid
    // default to showing an error before showing a warning, and don't try to show both
    const error = meta.touched && meta.error
    const warning = !error && meta.touched && meta.warning

    return (
        <ReduxFormFieldWrapper
            className="datetime"
            error={error}
            label={label}
            tooltipText={tooltipText}
            touched={meta.touched}
            warning={warning}
        >
            <div
                className={classNames({
                    "select-error-outline": error,
                    "select-warning-outline": warning,
                })}
            >
                <DateTime
                    name={name}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    inputProps={{ disabled, placeholder, "data-cy": dataCy || "date-time-field" }}
                    dateFormat={Userdata.isOnlyEUMode && Userdata.isOnlyEUMode() ? "DD/MM/YYYY" : dateFormat}
                    timeFormat={timeFormat}
                    displayTimeZone={displayTimeZone}
                />
            </div>
        </ReduxFormFieldWrapper>
    )
}

DateTimeField.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    tooltipText: PropTypes.string,
    placeholder: PropTypes.string,
    dateFormat: PropTypes.bool,
    timeFormat: PropTypes.bool,
    displayTimeZone: PropTypes.string,
}

DateTimeField.defaultProps = {
    disabled: false,
    placeholder: "",
    dateFormat: undefined,
    timeFormat: undefined,
    displayTimeZone: undefined,
}

export default DateTimeField
