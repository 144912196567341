import { Campaign, CustomField } from "@/types/djangio"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Contents } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/PersonalizedMessages"
import { PromptAndResponseCampaignForm } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/PersonalizedMessages.helper"
import { useFormReturn } from "QuorumGrassroots/campaign-forms/hooks/useForm"
import { GenericWriteForm } from "QuorumGrassroots/campaign-forms/components/ReusableForms/GenericWriteForm"
import {
    patchGrassrootsAction,
    updateSupporterHasParticipatedInCampaigns,
    usePersonalizedCampaignGetCampaignMessages,
    useReworkMessageWithAI,
} from "QuorumGrassroots/services"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"
import {
    adaptTargetedMessages,
    adaptTargetedMessagesToActionsGenerator,
    getPointsForCampaign,
} from "QuorumGrassroots/campaign-forms/helpers"
import { generatePersonalizedMessagesActions } from "QuorumGrassroots/campaign-forms/grassrootsActionGenerators"
import { useMutation } from "@tanstack/react-query"
import BACKENDERROR from "app/static/frontend/imports/backenderror"
import { swalConfigs } from "QuorumGrassroots/swalConfigs"
import { getPathObject, runUserJavascript } from "QuorumGrassroots/helperFunctions"
import { useNavigate } from "react-router-dom"
import { ThanksDisplay } from "QuorumGrassroots/widgets/ReusableComponents/ThanksDisplay"
import { getReduxlessThanksDisplayProps } from "QuorumGrassroots/widgets/ReusableComponents/WidgetWrappers/ThankableWrapper"
import { useGrassrootsActionCenterSettings } from "QuorumGrassroots/services/grassrootsActionCenterSettings"
import { withGamificationModal } from "QuorumGrassroots/campaign-forms/wrappers/withGamificationModal"
import { Container } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/components/QuestionsContainer.styles"
import { Heading } from "QuorumGrassroots/campaign-forms/components/PersonalizedMessages/PersonalizedMessages.styles"

interface Props {
    form: useFormReturn<PromptAndResponseCampaignForm>
    questions: CustomField[]
    handleChangeContent: (contentName: Contents) => void
    campaign: Campaign
    isEmbedded: boolean
    setIsFinished: (value: boolean) => void
    showGamificationLevelUpModalIfEnabled: (pointsEarned?: number) => void
    pointsEarned?: number
}

const { CampaignType } = DjangIO.app.grassroots.campaign.types

const addRequiredValuesToActions = (actions: object[], campaign: Campaign) => {
    return actions.map((action) => ({
        ...action,
        supporter: DjangIO.app.grassroots.models.Supporter.resourceUriFromId(window.userdata.id),
        organization: DjangIO.app.userdata.models.Organization.resourceUriFromId(window.organization.id),
        action_center: window.action_center_settings.resource_uri,
        points_earned: getPointsForCampaign(campaign),
        campaign: DjangIO.app.grassroots.campaign.models.Campaign.resourceUriFromId(campaign.id),
    }))
}

export const submitAction = async (campaign: Campaign, messages: object, targets: object, tag_dict) => {
    const { adaptedMessages, messageGroups } = isFeatureEnabled("ff_ngg_message_preview")
        ? adaptTargetedMessages(messages, targets)
        : adaptTargetedMessagesToActionsGenerator(messages, targets)

    const actions = generatePersonalizedMessagesActions(adaptedMessages, {}, { campaign, messageGroups, tag_dict })
    const actionsWithRequiredValues = addRequiredValuesToActions(actions, campaign)
    await patchGrassrootsAction({ objects: actionsWithRequiredValues })
}

export const SendMessageContainer = withGamificationModal((props: Props) => {
    const placeholders = props.form.values
    const navigate = useNavigate()
    const actionCenterSettings = useGrassrootsActionCenterSettings(props.campaign.action_center_id)
    const messagesQuery = usePersonalizedCampaignGetCampaignMessages(String(props.campaign.id))

    const { data: parsedMessages } = useReworkMessageWithAI(
        messagesQuery.data,
        placeholders,
        props.questions,
        props.campaign,
    )

    const messageData = useMemo(() => messagesQuery.data, [messagesQuery.data]) as { messages: object; targets: object }

    const [messages, setMessages] = useState({ [CampaignType.personalized_messages.value]: parsedMessages })

    const [targets, setTargets] = useState({ [CampaignType.personalized_messages.value]: messageData.targets })
    const [isLoading, setIsLoading] = useState(false)
    const [isFinished, setIsFinished] = useState(false)
    const tag_dict = props.form.values

    const isSingleColumnLayout = isFeatureEnabled("ff_ngg_personalized_messages_design")

    const submitMutation = useMutation<void, unknown, Campaign>({
        mutationFn: async (values) => {
            setIsLoading(true)
            await submitAction(
                values,
                messages[CampaignType.personalized_messages.value],
                targets[CampaignType.personalized_messages.value],
                tag_dict,
            ).finally(() => setIsLoading(false))
        },
        onError: (error) => {
            BACKENDERROR(error, swalConfigs.postGrassrootsActionError, true, false)
            throw error
        },
        onSuccess: () => handleSuccess(),
    })

    const handleSuccess = () => {
        props.showGamificationLevelUpModalIfEnabled()
        runUserJavascript(props.campaign.custom_thank_you_javascript)
        const redirectDetails =
            props.campaign.success_redirection_url && getPathObject(props.campaign.success_redirection_url)
        if (!props.isEmbedded && redirectDetails) {
            if (redirectDetails.isInternal) {
                navigate(redirectDetails.url)
            } else {
                window.swal({ icon: "success", title: "Thank you! You are now being redirected..." })
                window.location.assign(redirectDetails.url)
            }
        } else {
            setIsFinished(true)
        }
        updateSupporterHasParticipatedInCampaigns()
    }

    if (isFinished) {
        const pointsEarned = getPointsForCampaign(props.campaign)
        const thankableProps = getReduxlessThanksDisplayProps(
            actionCenterSettings,
            props.campaign,
            props.isEmbedded,
            pointsEarned,
        )

        return <ThanksDisplay {...thankableProps} />
    }

    return (
        <Container isSingleColumnLayout={isSingleColumnLayout}>
            {isFeatureEnabled("ff_ngg_personalized_messages_design") && (
                <Heading>
                    <h3>Act now!</h3>
                    <h5>Fill out the form below to support us!</h5>
                </Heading>
            )}
            <GenericWriteForm
                {...props}
                campaign={{ ...props.campaign, messages: messages[CampaignType.personalized_messages.value] }}
                queriedMessages={messages}
                setQueriedMessages={setMessages}
                queriedTargets={targets}
                customSubmit={submitMutation.mutate}
                setQueriedTargets={setTargets}
                isQueryMode
                isSingleColumnLayout={isSingleColumnLayout}
                isLoading={isLoading}
                submitButtonTextKey="campaign.personalized_messages.submit_verb"
                submitButtonClassName="custom-submit-button-class"
            />
        </Container>
    )
})
