import React from "react"
import PropTypes from "prop-types"

import * as S from "Components/Molecules/CircleImage/style"

import Icon from "Components/Atoms/Icon"
import * as colors from "Components/colors"
import * as helperFunctions from "utils/helperFunctions"

const EXPANDED_CIRCLE_PADDING = 30

class CircleImage extends React.Component {
    state = {
        dynamicText: this.props.text
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.text !== prevProps.text){
            this.setState((state, props) => ({dynamicText: this.props.text}))
        }
    }

    handleMouseEnter = () => {
        if (this.props.dynamicHover) {
            this.setState((state, props) => ({dynamicText: this.props.hoverText}))
        }
    }

    handleMouseLeave = () => {
        if (this.props.dynamicHover) {
            this.setState((state, props) => ({dynamicText: this.props.text}))
        }
    }

    handleHoverWidth = () => {
        if (this.props.dynamicHover) {
            const font = `normal normal 400 ${this.props.fontSize} Helvetica`
            const textWidth = helperFunctions.calculateTextWidth(font, this.props.hoverText) + EXPANDED_CIRCLE_PADDING
            return `${textWidth}px`
        }
        // else return the standard Counter diameter
        return this.props.diameter
    }

    render() {
        return (
            <S.ImageArea
                data-auto-cy="MoleculeCircleImage"
                id="ds-image-area"
                boldText={this.props.boldText}
                circleColor={this.props.circleColor}
                fillCircle={this.props.fill}
                fillColor={this.props.fillColor}
                fontColor={this.props.fontColor}
                fontSize={this.props.fontSize}
                diameter={this.props.diameter}
                hoverWidth={this.handleHoverWidth()}
                isV2={this.props.isV2}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                dynamicHover={this.props.dynamicHover}
                cursor={this.props.cursor}
                opacity={this.props.opacity}
            >
                {!this.props.imgUrl && this.state.dynamicText}
                {!this.props.imgUrl && this.props.icon && <Icon icon={this.props.icon} iconFamily={this.props.iconFamily}/>}
                {this.props.imgUrl && <S.Img isV2={this.props.isV2} src={this.props.imgUrl}></S.Img>}
            </S.ImageArea>
        )
    }
}

CircleImage.defaultProps = {
    boldText: false,
    circleColor: colors.DarkPurple,
    diameter: "66px",
    fontColor: colors.White,
    fontSize: "42px",
    isV2: false,
}

CircleImage.propTypes = {
    // if the circle text should be bold
    boldText: PropTypes.bool,
    // color of the circlurlar border
    circleColor: PropTypes.string,
    // size of the circle
    diameter: PropTypes.string,
    // whether or not the circle should be hollow
    // if no fill color is provided, the circle color will be used
    fill: PropTypes.bool,
    // color to fill the circle with
    fillColor: PropTypes.string,
    // color of the icon / text displayed in the circle
    fontColor: PropTypes.string,
    // size of the icon / text displayed in the circle
    fontSize: PropTypes.string,
    // icon to display in the circle
    icon: PropTypes.string,
    // family of the shown icon
    iconFamily: PropTypes.string,
    // URL of an image to display in the circle
    imgUrl: PropTypes.string,
    // v2 image change to rounded square
    isV2: PropTypes.bool,
    // text to show in the circle
    text: PropTypes.string,
}

export default CircleImage
