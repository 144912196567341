import React, { useEffect, useRef, useState } from "react"
import styled, { keyframes, css } from "styled-components"

const slideOutLeft = keyframes`
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
`

const slideInRight = keyframes`
    0% { transform: translateX(100%); }
    75% { transform: translateX(-5%); }
    100% { transform: translateX(0); }
`

export const ANIMATION_OUT_DURATION = 100
const ANIMATION_IN_DURATION = 500

const AnimatedComponent = styled.div`
    width: 100%;
    height: 100%;
    ${(props) => {
        if (props.disableFirstAnimation && props.isFirstAnimation) {
            return ""
        }
        return props.transitioning
            ? css`
                  animation: ${slideOutLeft} ${ANIMATION_OUT_DURATION}ms forwards;
              `
            : css`
                  animation: ${slideInRight} ${ANIMATION_IN_DURATION}ms forwards;
              `
    }}
`

export const AnimationContainer = ({ children, shouldAnimate, disableFirstAnimation = false, ...props }) => {
    const isFirstAnimationRef = useRef(true)

    useEffect(() => {
        if (shouldAnimate && isFirstAnimationRef.current) {
            isFirstAnimationRef.current = false
        }
    }, [shouldAnimate])

    return (
        <>
            <AnimatedComponent
                transitioning={shouldAnimate}
                disableFirstAnimation={disableFirstAnimation}
                isFirstAnimation={isFirstAnimationRef.current}
            >
                {children}
            </AnimatedComponent>
        </>
    )
}
export const useAnimation = () => {
    const [shouldAnimate, setShouldAnimate] = useState(false)

    const animate = () => {
        setShouldAnimate(true)
        setTimeout(() => {
            setShouldAnimate(false)
        }, ANIMATION_OUT_DURATION)
    }

    return { animate, shouldAnimate }
}
