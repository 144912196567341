import { getPathObject } from "QuorumGrassroots/helperFunctions"

/**
 * Given the url to sanitize by removing any script tags or any other malicious code
 *
 * @param  {string} url url to redirect to
 * Returns the sanitized url
 */
function sanitizeUrl(url) {
    const parsedUrl = new URL(url)
    const cleanSearch = parsedUrl.search
        .replace(/<script.*?>.*?<\/script>/gi, "")
        .replace(/javascript:.*/gi, "")
        .replace(/data:.*/gi, "")
        .replace(/vbscript:.*/gi, "")

    const cleanHash = parsedUrl.hash
        .replace(/<script.*?>.*?<\/script>/gi, "")
        .replace(/javascript:.*/gi, "")
        .replace(/data:.*/gi, "")
        .replace(/vbscript:.*/gi, "")

    return `${parsedUrl.origin}${parsedUrl.pathname}${cleanSearch}${cleanHash}`
}

/**
 * Given the props and the url to redirect to, redirect to the url
 * Calls 'getPathObject' to get the formatted url and if the url is internal or not
 *
 * @param  {object} props props with navigate
 * @param  {string} url url to redirect to
 */
export const redirectToUrl = (props, url) => {
    const pathObject = getPathObject(url, { addRedirectParam: false })

    if (pathObject.isInternal) {
        props.navigate(pathObject.url)
    } else {
        const sanitizedUrl = sanitizeUrl(pathObject.url)
        swal({ icon: "success", title: "Thank you! You are now being redirected..." })
        window.open(decodeURIComponent(sanitizedUrl), "_blank")
    }
}
