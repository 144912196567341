import React from "react"
import { Field } from "redux-form/immutable"

import GridToggleField from "app/static/frontend/forms/components/GridToggleField"
import ReduxFormFieldWrapper from "app/static/frontend/forms/components/ReduxFormFieldWrapper"

// This component is used to render the label, but prevent the user
// from changing the value of the toggle from the initial value
// Used for the texting opt-in field to allow orgs to force supporters
// to agree to texting when registering, or give supporters the option to
// choose if they want to receive texts or not
const renderForcedTrueField = (props) => {
    const {
        meta: { error, warning, touched } = {},
        accessibilityId,
        banner,
        className,
        dataCy,
        displayErrorWithoutTouch,
        label,
        labelStyle,
        style,
        tooltipText,
        showAsterisk,
    } = props

    return (
        <ReduxFormFieldWrapper
            additionalClassNames={className}
            className="gridtoggle"
            dataCy={dataCy}
            displayErrorWithoutTouch={displayErrorWithoutTouch}
            error={error}
            label={label}
            labelStyle={labelStyle}
            style={style}
            tooltipText={tooltipText}
            touched={touched}
            warning={warning}
            accessibilityId={accessibilityId}
            banner={banner}
            showAsterisk={showAsterisk}
        />
    )
}

export const ToggleField = (props) => {
    return props.allowChoices ? (
        <Field
            {...props}
            component={GridToggleField}
            choices={
                props.choices || [
                    {
                        label: "Yes",
                        value: true,
                    },
                    {
                        label: "No",
                        value: false,
                    },
                ]
            }
            pillWidth={6}
        />
    ) : (
        <Field {...props} component={renderForcedTrueField} />
    )
}

export default ToggleField
