import isFeatureEnabled from "shared/featureflags/helperFunctions"

export default {
    LINK_COPY_SUCCESS_MSG: "This link has been copied to your clipboard.",
    LINK_COPY_FAIL_MSG: "Something went wrong. Please reload the page and try again.",
}

export const MAX_BILL_SEARCHIFY_SELECTIONS = 20000
export const MAX_AI_SEARCH_CHARACTERS = 250

// because of prefiling, get the next year when it enters June of the current year
// this works for both Bill and Regulation
// cannot import DEFAULT_TRACKING_YEAR() from app/static/frontend/projects-new/tracking_dashboards/constants.js
// because it will raise TypeError for mobile compile
const current_year_for_bills = new Date().getMonth() >= 5 ? new Date().getFullYear() + 1 : new Date().getFullYear()

export const getBillYearOptions = () => {
    const billYearOptions = [
        {
            key: -1,
            value: -1,
            display_text: "Current Year",
        },
    ]
    for (let i = 0; i < 3; i++) {
        billYearOptions.push({
            key: current_year_for_bills - i,
            value: current_year_for_bills - i,
            display_text: (current_year_for_bills - i).toString(),
        })
    }
    return billYearOptions
}

export const getBillYearSelectOptions = () => {
    const billYearSelectOptions = []
    for (let i = current_year_for_bills - 3; i >= 2014; i--) {
        billYearSelectOptions.push({
            key: i,
            value: i,
            display_text: i.toString(),
        })
    }
    return billYearSelectOptions
}

export const getElectionYearSelectOptions = () => {
    const electionYearSelectOptions = []
    const current_year = new Date().getFullYear()
    for (let i = current_year; i <= current_year + 7; i++) {
        electionYearSelectOptions.push({
            key: i,
            value: i,
            display_text: i.toString(),
        })
    }
    return electionYearSelectOptions
}

export const NOTE_BILL_TRUNCATION_LIMIT = 2
export const NOTE_CUSTOM_FIELDS_TRUNCATION_LIMIT = 4

export const aiSearchAllowedRegions = DjangIO.app.models.SupportedRegion.states_and_local.child_regions.concat(
    [
        DjangIO.app.models.SupportedRegion.all_regions.value,
        DjangIO.app.models.SupportedRegion.federal.value,
        DjangIO.app.models.SupportedRegion.states.value,
        DjangIO.app.models.SupportedRegion.local.value,
        DjangIO.app.models.SupportedRegion.states_and_local.value,
    ],
    isFeatureEnabled("ff_ai_eu_search") ? [DjangIO.app.models.SupportedRegion.eu.value] : [],
)

export const AI_SEARCH_ERROR_IMG_URL = "https://static.quorum.us/global/common/images/alert-illustration.svg"
export const AI_SEARCH_NOT_FOUND_IMG_URL = "https://static.quorum.us/global/common/images/search-illustration.svg"

// NOTE: we're hardcoding the url and filter keys to avoid additional calls to the OpenAI API, cost incurs on each search
export const aiSearchExamples = {
    Legislation: [
        ...(Userdata.current_regions.includes(DjangIO.app.models.Region.eu.abbrev)
            ? [
                  {
                      initialFilter: {
                          ai_search: "Initiatives that are in preparation this year",
                          bill_type__in: [14],
                          current_general_status__in: [111],
                          for_years: [-1],
                      },
                      qdt: 2,
                  },
                  {
                      initialFilter: {
                          ai_search: "This year's Initiatives that are in the public consultation stage",
                          current_status__in: 1302,
                      },
                      qdt: 2,
                  },
              ]
            : [{}]),
    ],
    Bills: [
        ...(Userdata.current_regions.includes(DjangIO.app.models.Region.eu.abbrev)
            ? [
                  {
                      initialFilter: {
                          ai_search: "Initiatives that are in preparation this year",
                          bill_type__in: [14],
                          current_general_status__in: [111],
                          for_years: [-1],
                      },
                      qdt: 2,
                  },
                  {
                      initialFilter: {
                          ai_search: "This year's Initiatives that are in the public consultation stage",
                          current_status__in: [2],
                      },
                      qdt: 2,
                  },
              ]
            : [
                  {
                      initialFilter: {
                          ai_search: "Bills introduced yesterday",
                          introduced_date_range: [10],
                      },
                      qdt: 2,
                  },
                  {
                      initialFilter: {
                          ai_search: "Prefiled bills for 2024",
                          current_general_status__in: [1],
                          for_introduced_years: [2024],
                      },
                      qdt: 2,
                  },
                  {
                      initialFilter: {
                          ai_search: "House bills that are High Priority",
                          bill_type__in: [3],
                          my_priority: [1],
                      },
                      qdt: 2,
                  },
                  {
                      initialFilter: {
                          ai_search: "Bills with a stance of Support that are enacted",
                          current_general_status__in: [6],
                          my_stance: [1],
                      },
                      qdt: 2,
                  },
                  {
                      initialFilter: {
                          advanced_search: "'data security' OR 'data privacy'",
                          ai_search: "Bills about Data Security from 2023",
                          for_years: [2023],
                      },
                      qdt: 2,
                  },
                  {
                      initialFilter: {
                          advanced_search:
                              "tax reform AND (simplification OR modernization OR incentives OR credits OR deductions OR exemptions OR progressive OR regressive OR flat OR graduated OR proportional OR loopholes OR evasion OR avoidance OR shelters OR compliance OR enforcement OR audit OR small businesses OR entrepreneurs OR middle class OR working class OR low-income OR revenue OR deficit OR budget OR spending OR international OR global OR cross-border OR offshore OR tax codes OR tax brackets OR tax incentives)",
                          ai_search: "Bills about Tax Reform in 2023",
                          for_years: [2023],
                      },
                      qdt: 2,
                  },
                  {
                      initialFilter: {
                          ai_search: "Bills of medium priority that have had movement in the past 3 months",
                          most_recent_action_date_range: [7],
                          my_priority: [2],
                      },
                      qdt: 2,
                  },
              ]),
    ],
    Committees: [],
    Documents: [
        ...(Userdata?.current_regions.includes(DjangIO.app.models.Region.eu.abbrev)
            ? [
                  {
                      initialFilter: {
                          ai_search: "Tweets on the Green Deal from S&D MEPs",
                          date_range: [8],
                          source_members: {
                              addedIds: [],
                              deletedIds: [],
                              querysetList: [
                                  {
                                      show_in_quicksearch: true,
                                      most_recent_person_type__in: [6, 3, 8, 1, 7],
                                      dehydrate_extra: ["alignment", "most_recent_role", "priority"],
                                      ph_minor_role_type: [37],
                                      most_recent_party__in: [8],
                                      ph_current: true,
                                  },
                              ],
                              searchifyCount: 142,
                          },
                          document_type_is: 6,
                      },
                      qdt: 25,
                  },
              ]
            : [
                  {
                      initialFilter: {
                          advanced_search:
                              "('presidential campaign' OR 'presidential election') AND (candidate OR campaign strategy OR fundraising OR debates OR polling OR endorsements OR swing states OR voter turnout OR political ads OR grassroots mobilization)",
                          ai_search: "Tweets about the presidential campaign from legislators",
                          document_type_is: [6],
                          source_type__in: [1],
                      },
                      qdt: 25,
                  },
                  {
                      initialFilter: {
                          advanced_search:
                              "('student loans' OR 'education loans' OR 'college loans' OR 'loan forgiveness' OR 'loan repayment' OR 'student debt' OR 'financial aid' OR 'higher education financing' OR 'loan interest rates' OR 'loan refinancing' OR 'loan default' OR 'loan servicers' OR 'loan repayment plans' OR 'loan consolidation' OR 'loan deferment' OR 'loan forbearance' OR 'loan cancellation' OR 'loan discharge' OR 'loan rehabilitation' OR 'loan eligibility' OR 'loan affordability' OR 'loan affordability programs' OR 'loan repayment assistance programs' OR 'loan forgiveness programs' OR 'loan repayment options' OR 'loan repayment assistance' OR 'loan repayment calculators' OR 'loan repayment terms' OR 'loan repayment strategies' OR 'loan repayment resources' OR 'loan repayment tips' OR 'loan repayment advice' OR 'loan repayment guidance' OR 'loan repayment support' OR 'loan repayment information' OR 'loan repayment guidance' OR 'loan repayment resources' OR 'loan repayment tips' OR 'loan repayment advice' OR 'loan repayment guidance' OR 'loan repayment support' OR 'loan repayment information' OR 'loan repayment assistance' OR 'loan repayment calculators' OR 'loan repayment terms' OR 'loan repayment strategies' OR 'loan repayment options' OR 'loan repayment assistance programs' OR 'loan forgiveness programs' OR 'loan cancellation' OR 'loan discharge' OR 'loan rehabilitation' OR 'loan deferment' OR 'loan forbearance' OR 'loan consolidation' OR 'loan interest rates' OR 'loan refinancing' OR 'loan default' OR 'loan servicers' OR 'loan repayment plans' OR 'loan eligibility' OR 'loan affordability' OR 'loan affordability programs' OR 'loan repayment assistance programs' OR 'loan forgiveness' OR 'loan repayment' OR 'student debt' OR 'financial aid' OR 'higher education financing')",
                          ai_search: "Press releases mentioning student loans",
                          document_type_is: [5],
                      },
                      qdt: 25,
                  },
                  {
                      initialFilter: {
                          ai_search: "Tweets and Facebook posts from the last week",
                          date_range: 11,
                          document_type_is: [6, 9],
                      },
                      qdt: 25,
                  },
                  {
                      initialFilter: {
                          advanced_search:
                              "(income OR property OR sales OR corporate) AND (taxes OR taxation OR tax policy OR tax reform OR tax rates OR tax brackets OR tax incentives OR tax deductions OR tax credits OR tax loopholes OR tax evasion OR tax avoidance OR tax shelters OR tax compliance OR tax enforcement OR tax burden OR tax fairness OR tax revenue OR tax system OR tax codes OR tax brackets OR tax incentives)",
                          ai_search: "Statements made in committee hearings around Taxes",
                          document_type_is: [45],
                      },
                      qdt: 25,
                  },
                  {
                      initialFilter: {
                          advanced_search:
                              "international trade OR global trade OR international commerce OR global commerce OR cross-border trade OR global economic exchange",
                          ai_search: "White House documents mentioning international trade",
                          document_type_is: [64, 65, 67, 71, 72, 73, 74, 1002],
                      },
                      qdt: 25,
                  },
                  {
                      initialFilter: {
                          ai_search: "State executive orders from the last month",
                          date_range: [6],
                          document_type_is: [25],
                      },
                      qdt: 25,
                  },
                  {
                      initialFilter: {
                          advanced_search:
                              "short term rentals OR vacation rentals OR Airbnb OR VRBO OR home-sharing OR temporary accommodations",
                          ai_search: "Local agendas and minutes mentioning short term rentals",
                          document_type_is: [1014, 1018, 1015, 1019],
                      },
                      qdt: 25,
                  },
                  {
                      initialFilter: {
                          advanced_search: "books OR novels OR literature",
                          ai_search: "School board meeting documents about books",
                          document_type_is: [1021, 1022],
                      },
                      qdt: 25,
                  },
              ]),
    ],
    Notes: [
        ...(Userdata.current_regions.includes(DjangIO.app.models.Region.eu.abbrev)
            ? []
            : [
                  {
                      initialFilter: {
                          ai_search: "Notes logged in the past week",
                          date_range: [11],
                      },
                      qdt: 20,
                  },
                  {
                      initialFilter: {
                          ai_search: "New relationships logged this month",
                          date_range: [6],
                          note_type__in: [3],
                      },
                      qdt: 20,
                  },
              ]),
    ],
    Officials: [
        ...(Userdata.current_regions.includes(DjangIO.app.models.Region.eu.abbrev)
            ? [
                  {
                      initialFilter: {
                          ai_search: "Members of Parliament from France",
                          ph_current: true,
                          ph_minor_role_type: [37],
                          representing_city: {
                              addedIds: [75],
                              deletedIds: [],
                              querysetList: [],
                              searchifyCount: 1,
                          },
                      },
                      qdt: 1,
                  },
                  {
                      initialFilter: {
                          ai_search: "EU Officials part of the European People’s Party",
                          most_recent_party__in: [7],
                          ph_current: true,
                      },
                      qdt: 1,
                  },
              ]
            : [
                  {
                      initialFilter: {
                          ai_search: "MOC who have cosponsored bills we support from this year",
                          cosponsored_bills: {
                              addedIds: [],
                              deletedIds: [],
                              querysetList: [
                                  {
                                      dehydrate_extra: ["priority", "stance", "issues"],
                                      my_stance: [1],
                                      for_years: [2023],
                                  },
                              ],
                              searchifyCount: 17,
                          },
                          ph_current: true,
                          ph_minor_role_type: [5, 6],
                      },
                      qdt: 1,
                  },
                  {
                      initialFilter: {
                          ai_search: "Mayors and city council members",
                          ph_current: true,
                          ph_minor_role_type: [198, 199],
                      },
                      qdt: 1,
                  },
                  {
                      initialFilter: {
                          ai_search: "Officials who have sponsored bills about data security",
                          ph_current: true,
                          sponsored_bills: {
                              addedIds: [],
                              deletedIds: [],
                              querysetList: [
                                  {
                                      dehydrate_extra: ["priority", "stance", "issues"],
                                      advanced_search: "data+security",
                                  },
                              ],
                              searchifyCount: 2350,
                          },
                      },
                      qdt: 1,
                  },
                  {
                      initialFilter: {
                          ai_search: "Show me all the House Speakers",
                          leadership_type: [1],
                      },
                      qdt: 1,
                  },
                  {
                      initialFilter: {
                          ai_search: "Legislators who are members of agriculture type committees",
                          committee_keywords: [3],
                      },
                      qdt: 1,
                  },
                  {
                      initialFilter: {
                          ai_search: "City Legislators in Virginia",
                          ph_minor_role_type: [199],
                          representing_state: { addedIds: [47] },
                      },
                      qdt: 1,
                  },
                  {
                      initialFilter: {
                          ai_search: "City Council Members and the Mayor in New York City",
                          ph_current: true,
                          ph_minor_role_type: [199, 198],
                          representing_municipality: {
                              addedIds: [17544],
                              deletedIds: [],
                              querysetList: [],
                              searchifyCount: 1,
                          },
                      },
                      qdt: 1,
                  },
              ]),
    ],
    Staffers: [
        {
            initialFilter: {
                ai_search: "Staffers who work for the House Ways and Means committee",
                ph_current: true,
                staffer_works_for_committee: { addedIds: [13562], deletedIds: [], querysetList: [], searchifyCount: 1 },
            },
            qdt: 8,
        },
        {
            initialFilter: {
                ai_search: "Staffers who work for members who sit on the Senate Foreign Relations Committee",
                members: {
                    addedIds: [],
                    deletedIds: [],
                    querysetList: [
                        {
                            show_in_quicksearch: true,
                            most_recent_person_type__in: [1, 6],
                            dehydrate_extra: ["most_recent_role"],
                            on_committees: { addedIds: [13675], deletedIds: [], querysetList: [], searchifyCount: 1 },
                            ph_current: true,
                        },
                    ],
                    searchifyCount: 21,
                },
                ph_current: true,
                ph_minor_role_type: [30],
            },
            qdt: 8,
        },
        {
            initialFilter: {
                ai_search: "Staffers who work on technology issues",
                staffer_issues: { addedIds: [63] },
            },
            qdt: 8,
        },
        {
            initialFilter: {
                ai_search: "Chief of Staff in the Senate",
                staffer_chamber: [1],
                staffer_titles: { addedIds: [18196, 11525] },
            },
            qdt: 8,
        },
        {
            initialFilter: {
                ai_search: "Staffers who work for officials assigned to me",
                members: {
                    addedIds: [],
                    deletedIds: [],
                    querysetList: [
                        {
                            show_in_quicksearch: true,
                            dehydrate_extra: ["most_recent_role"],
                            most_recent_person_type__in: [1, 6],
                            assigned_to_me: true,
                            ph_current: true,
                        },
                    ],
                    searchifyCount: 0,
                },
                ph_current: true,
            },
            qdt: 8,
        },
        {
            initialFilter: {
                ai_search: "Staffers who work on finance issues",
                staffer_issues: { addedIds: [25], deletedIds: [], querysetList: [], searchifyCount: 1 },
            },
            qdt: 8,
        },
    ],
}

export const COUNT_NOT_AVAILABLE = "N/A"
