/* eslint-disable import/no-named-as-default */
import PropTypes from "prop-types"
import React, { Component } from "react"

import {
    runUserJavascript,
    simpleReplacePlaceholders,
    updateOrCreateStyleTagWithId,
} from "QuorumGrassroots/helperFunctions"
import { Helmet } from "react-helmet"

import { formMap } from "QuorumGrassroots/campaign-forms/CampaignFormMap"
import CampaignWarning from "QuorumGrassroots/widgets/Campaign/components/CampaignWarning"
import LoginUpdateOrRender from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/LoginUpdateOrRender"

import CampaignMessagePreview from "QuorumGrassroots/campaign-forms/containers/CampaignMessagePreview"
import ProgressBar from "QuorumGrassroots/framework/components/ProgressBar/index"
import { StyledContrastText } from "QuorumGrassroots/styled-components/components/StyledContrastText"
import RegulationsFooter from "QuorumGrassroots/widgets/Campaign/components/RegulationsFooter"
import {
    StyledCampaignDescription,
    StyledCampaignWrapper,
    StyledShowMoreButton,
} from "QuorumGrassroots/widgets/Campaign/components/style"
import FullBleedImage from "QuorumGrassroots/widgets/FullBleedImage/components"
import { FullBleedVideo, VideoWrapper } from "QuorumGrassroots/widgets/Video/components/style"
import WidgetStyleWrapper from "QuorumGrassroots/widgets/WidgetStyleWrapper/index"
import { StyledColumnWidget, StyledWidgetHeader } from "QuorumGrassroots/widgets/styled-components/StyledWidget"
import { getVimeoEmbedUrl, getYoutubeEmbedUrl } from "QuorumGrassroots/widgets/styled-components/helperFunctions"
import i18n from "i18n"
import { getIsThankPage } from "QuorumGrassroots/widgets/Campaign/components/helper"
import isFeatureEnabled from "shared/featureflags/helperFunctions"

export class CampaignWrapper extends Component {
    static propTypes = {
        // from page
        id: PropTypes.number,
        uniqueWidgetId: PropTypes.string.isRequired,
        isEmbedded: PropTypes.bool,
        // from initializer
        loadWidgetContent: PropTypes.func.isRequired,
        hasLoaded: PropTypes.bool.isRequired,
        hasLoadedContent: PropTypes.bool.isRequired,
        content: PropTypes.object,
        // from connect
        campaignDescriptionColumnWidth: PropTypes.number,
        campaignPassThroughProps: PropTypes.object.isRequired,
        campaignWidgetHeader: PropTypes.string.isRequired,
        t: PropTypes.func.isRequired,
        widgetWidthSize: PropTypes.number,
        shouldDisplayProgressBar: PropTypes.bool,
        shouldTopAlignDescription: PropTypes.bool,
        shouldDisplayRegulationsFooter: PropTypes.bool.isRequired,
        numberActionsNeeded: PropTypes.number,
        currentPercent: PropTypes.number,
        percentLeft: PropTypes.number,
        userdata: PropTypes.object.isRequired,
        pageTitle: PropTypes.string.isRequired,
        // from connect, logic to get instantaneous patching after
        // registration for campaigns.
        postGrassrootsSupporterAction: PropTypes.func.isRequired,
        resetSubmittedWidget: PropTypes.func.isRequired,
        requiredFields: PropTypes.array.isRequired,
        shouldThank: PropTypes.bool.isRequired,
        isCampaignPage: PropTypes.bool.isRequired,
    }

    state = { showFullDescriptionOnMobile: true, showSeeMoreButton: false }

    /**
     * Should run once after the campaign is loaded to initialize it. Specifically, we need to run the campaign's
     * Javascript and load in the campaign's css
     *
     * @param campaign an object of the full campaign
     */
    initializeContent() {
        this.updateCampaignSpecificCustomCss(this.props.content.custom_css || "")
        if (this.props.content.custom_javascript) {
            runUserJavascript(this.props.content.custom_javascript)
        }
        const descriptionElement = document.querySelector(".campaign-description")
        const descriptionChildrenHeight = Array.from(descriptionElement?.children || []).reduce(
            (acc, child) => acc + child.offsetHeight,
            0,
        )
        if (descriptionChildrenHeight > 72) {
            this.setState({ showFullDescriptionOnMobile: false, showSeeMoreButton: true })
        }
    }
    /**
     * Alter the campaign-specific custom CSS, overwriting what was previously there. Will create or refrain
     * from creating the style tag if necessary.
     *
     * @param {string} css CSS to load
     */
    updateCampaignSpecificCustomCss(css) {
        updateOrCreateStyleTagWithId("CampaignCustomCss", css)
    }

    loadContent() {
        // if it's a draft, load only from the draft types. If an active or completed
        // campaign, load amongst active or completed campaigns. Draft and non-draft
        // campaigns use the same component, it's just that they're treated
        // a little differently and we'd like to demarcate.
        return this.props.loadWidgetContent({
            id: this.props.id,
            resource: DjangIO.app.grassroots.campaign.models.GrassrootsCampaign,
            uniqueWidgetId: this.props.uniqueWidgetId,
            action: "get_grassroots_campaign_detail",
            kwargs: {
                slug: this.props.match.params.slug,
                widget_type: this.props.widgetType,
                dehydrate_extra: ["can_participate_in_campaign", "message_groups", "personalized_campaign_pages"],
            },
        })
    }

    componentDidMount() {
        if (this.props.isCampaignPage) {
            //temporary solution for changing font size for new NGG theme so that mantine works without increasing the css root font size.
            //this can be removed after communicating the change to clients on launch (so we change the global css)
            //or when we update mantine (v7 allows scaling the components with createTheme)
            document.documentElement.style.fontSize = "16px"
        }
        if (!this.props.hasLoadedContent) {
            // Load content on mount
            this.loadContent().then(() => {
                // In cases where a campaign description includes a twitter embed
                // This accounts for cases where the twitter embed script is loaded and run before the description has loaded
                // After the campaign content is loaded, rerun the twitter widget's load function once more
                // (load function can be run multiple times)
                if (window.twttr && window.twttr.widgets && window.twttr.widgets.load) {
                    window.twttr.widgets.load()
                }
            })
        } else {
            if (this.props.shouldThank) {
                // if shouldThank is true when we mount the component,
                // we know that the user has previously submitted an action without performing a hard reload
                // which means that we need to clear that section of the store so that they can submit another action
                this.props.resetSubmittedWidget(this.props.uniqueWidgetId)
                //we still need the custom css when mounting the thank you page though
                this.updateCampaignSpecificCustomCss(this.props.content.custom_css || "")
            } else {
                this.initializeContent()
            }
        }
    }

    componentDidUpdate(prevProps) {
        // Initialize content if the content becomes loaded that wasn't before,
        // or if the campaign id changes but the content has already loaded
        if (
            (!prevProps.hasLoadedContent && this.props.hasLoadedContent) ||
            (this.props.hasLoadedContent && prevProps.content.id !== this.props.content.id)
        ) {
            this.initializeContent()
        }
    }

    componentWillUnmount() {
        this.updateCampaignSpecificCustomCss("")
        this.loadedUniqueWidgetId = null
        if (this.props.isCampaignPage) {
            //removes inline style so that the rem size returns to the original if not on the campaign page/theme
            document.documentElement.style.fontSize = ""
        }
    }

    renderCampaignDescriptionColumn() {
        return (
            <StyledColumnWidget
                className="campaign-description-col"
                xs={12}
                sm={12}
                md={this.props.campaignDescriptionColumnWidth}
                lg={this.props.campaignDescriptionColumnWidth}
                backgroundImage={this.props.content.background_image}
                isCampaignDescription
                layout={this.props.content.page_layout_type}
                isCampaignPage
                activateNGGTheme
                campaignBackgroundColor={this.props.content.background_color_override}
                backgroundStyle={this.props.content.background_style_type}
            >
                {this.props.content.summary_display_option_type ===
                    DjangIO.app.grassroots.campaign.types.CampaignNameDisplayOptionType.include_in_main_text.value && (
                    <StyledWidgetHeader
                        className="campaign-header"
                        isCampaignPage
                        activateNGGTheme
                        isCampaignTitle
                        backgroundStyle={this.props.content.background_style_type}
                    >
                        {this.props.content.name || (this.props.hasLoaded ? "" : this.props.t("loading"))}
                    </StyledWidgetHeader>
                )}
                {this.props.shouldDisplayProgressBar && (
                    <ProgressBar
                        numberActions={this.props.content.number_actions_count}
                        numberActionsNeeded={this.props.numberActionsNeeded}
                        currentPercent={this.props.currentPercent}
                        percentLeft={this.props.percentLeft}
                        isCampaignPage
                    />
                )}
                <StyledCampaignDescription
                    className="campaign-description"
                    data-cy="long-description"
                    dangerouslySetInnerHTML={{
                        __html:
                            simpleReplacePlaceholders(this.props.content.long_description, this.props.userdata) ||
                            (this.props.hasLoaded ? "" : this.props.t("loading")),
                    }}
                    shouldThank={this.props.shouldThank}
                    backgroundStyle={this.props.content.background_style_type}
                    campaignBackgroundColor={this.props.content.background_color_override}
                    showFullDescriptionOnMobile={this.state.showFullDescriptionOnMobile}
                    isCampaignPage
                    activateNGGTheme
                />
                {this.props.isCampaignPage && this.props.hasLoadedContent && this.state.showSeeMoreButton && (
                    <StyledShowMoreButton
                        className="show-more-button"
                        backgroundStyle={this.props.content.background_style_type}
                        onClick={() =>
                            this.setState({ showFullDescriptionOnMobile: !this.state.showFullDescriptionOnMobile })
                        }
                    >
                        {this.state.showFullDescriptionOnMobile
                            ? `${this.props.t("campaign.write.see_less")}`
                            : `${this.props.t("campaign.write.see_more")}`}
                        <i className={`fa fa-w fa-chevron-${this.state.showFullDescriptionOnMobile ? "up" : "down"}`} />
                    </StyledShowMoreButton>
                )}
                {
                    // Comment on Regulations campaigns now require compliance language to be displayed for use of regulations.gov API
                    this.props.shouldDisplayRegulationsFooter && (
                        <RegulationsFooter regulationId={this.props.content.regulation_id} />
                    )
                }
            </StyledColumnWidget>
        )
    }

    renderCampaignFormColumn() {
        const campaignType = this.props.content.campaign_type

        const completed =
            this.props.content.status === DjangIO.app.grassroots.campaign.types.CampaignStatus.complete.value

        if (!this.props.content.id) {
            return <StyledContrastText isCampaignPage>{this.props.t("loading")}</StyledContrastText>
        }
        if (completed) {
            return (
                <CampaignWarning
                    text={this.props.t("campaign.completed")}
                    dataCy="completed-campaign"
                    t={this.props.t}
                    isCampaignPage
                />
            )
        }
        return (
            <>
                <LoginUpdateOrRender
                    uniqueWidgetId={this.props.uniqueWidgetId}
                    // for the component that renders if we get past Login and Update.
                    // The component is conditional on whether or not all campaign data
                    // has been loaded.
                    renderedComponent={formMap[campaignType]}
                    renderedProps={this.props.campaignPassThroughProps}
                    // for the registration forms
                    registrationPageIds={this.props.registrationPageIds}
                    additionalSimpleFields={this.props.requiredFields}
                    // update information fun stuff
                    showOnlyUnfilledFields
                    updateInfoText={this.props.t("campaign.need_more_information")}
                    // internationalization
                    t={this.props.t}
                    // whether or not loginupdateorrender + thankable wrapper should have box styling
                    useWidgetStyling={false}
                    campaign={this.props.content}
                    campaignId={this.props.campaignPassThroughProps.campaignId}
                    campaignIsOneClickRegistrationEnabled={
                        this.props.campaignPassThroughProps.campaignIsOneClickRegistrationEnabled
                    }
                    shouldCompleteOnMount={false}
                    isCampaignPage
                />
                {this.props.campaignPassThroughProps.shouldDisplayCampaignMessagePreview && (
                    <CampaignMessagePreview
                        campaign={this.props.content}
                        t={this.props.t}
                        uniqueWidgetId={this.props.uniqueWidgetId}
                        isCampaignPage
                        registrationSubmitButtonText={this.props.registrationSubmitButtonText}
                    />
                )}
            </>
        )
    }

    render() {
        const isPersonalizedCampaign =
            this.props.content?.campaign_type ===
            DjangIO.app.grassroots.campaign.types.CampaignType.personalized_messages.value
        const shouldShowCampaignWidgetHeader = !this.props.shouldThank && !getIsThankPage() && !isPersonalizedCampaign

        if (this.props.hasLoaded && !this.props.hasLoadedContent) {
            // if the campaign doesn't exist, nothing we can do.
            return (
                <WidgetStyleWrapper title={this.props.t("does_not_exist")} useWidgetStyling>
                    <CampaignWarning text={this.props.t("does_not_exist")} dataCy="does-not-exist" t={this.props.t} />
                </WidgetStyleWrapper>
            )
        }

        return (
            <div>
                {/* Add Helmet to change browser title of page to campaign name */}
                <Helmet>
                    <title>{this.props.pageTitle}</title>
                </Helmet>
                {this.props.content.full_bleed_header_type ===
                    DjangIO.app.grassroots.campaign.types.CampaignFullBleedHeaderType.image.value && (
                    <FullBleedImage
                        imageContain={this.props.content.full_bleed_header_image_contain}
                        imageUrl={this.props.content.full_bleed_header_image_url}
                        includeText={
                            this.props.content.summary_display_option_type ===
                            DjangIO.app.grassroots.campaign.types.CampaignNameDisplayOptionType.overlay_header.value
                        }
                        text={this.props.content.name}
                        isLeftAligned={this.props.content.full_bleed_header_text_is_left_aligned}
                        isFirst
                        navBarStyle={this.props.content.navigation_bar_type}
                        navBarIsTransparent={this.props.content.navigation_bar_is_transparent}
                        isCampaignPage
                    />
                )}
                {!this.props.isCampaignPage &&
                    this.props.content.full_bleed_header_type ===
                        DjangIO.app.grassroots.campaign.types.CampaignFullBleedHeaderType.video.value && (
                        <FullBleedVideo
                            src={
                                getYoutubeEmbedUrl(this.props.content.full_bleed_header_video_url) ||
                                getVimeoEmbedUrl(this.props.content.full_bleed_header_video_url)
                            }
                            allowFullScreen
                            isFirst
                            isLast={false}
                            isVideo
                            navBarStyle={this.props.content.navigation_bar_type}
                            navBarIsTransparent={this.props.content.navigation_bar_is_transparent}
                            isCampaignPage
                        />
                    )}
                {this.props.isCampaignPage &&
                    this.props.content.full_bleed_header_type ===
                        DjangIO.app.grassroots.campaign.types.CampaignFullBleedHeaderType.video.value && (
                        <VideoWrapper>
                            <FullBleedVideo
                                src={
                                    getYoutubeEmbedUrl(this.props.content.full_bleed_header_video_url) ||
                                    getVimeoEmbedUrl(this.props.content.full_bleed_header_video_url)
                                }
                                allowFullScreen
                                isFirst
                                isLast={false}
                                isVideo
                                navBarStyle={this.props.content.navigation_bar_type}
                                navBarIsTransparent={this.props.content.navigation_bar_is_transparent}
                                isCampaignPage
                            />
                        </VideoWrapper>
                    )}
                <StyledCampaignWrapper
                    className="campaign-wrapper"
                    backgroundStyle={this.props.content.background_style_type}
                    isStandalone={this.props.isStandalone}
                    layout={this.props.content.page_layout_type}
                    isSingleColumnLayout={this.isSingleColumnLayout()}
                >
                    {this.props.shouldTopAlignDescription &&
                        this.shouldShowDescriptionBox() &&
                        this.renderCampaignDescriptionColumn()}
                    <StyledColumnWidget
                        className="column-widget"
                        xs={12}
                        sm={12}
                        md={this.props.widgetWidthSize}
                        lg={this.props.widgetWidthSize}
                        layout={this.props.content.page_layout_type}
                        isSingleColumnLayout={this.isSingleColumnLayout()}
                        isForm
                        isCampaignPage
                        activateNGGTheme
                    >
                        <>
                            {shouldShowCampaignWidgetHeader && (
                                <StyledWidgetHeader
                                    className="column-widget-header"
                                    isCampaignPage
                                    activateNGGTheme
                                    // only capitalizing when campaignWidgetHeader is default "Act now!"
                                    // this prop only works for temp theme
                                    shouldCapitalize={
                                        this.props.campaignWidgetHeader === i18n.t("campaign.action_text")
                                    }
                                >
                                    {this.props.campaignWidgetHeader}
                                </StyledWidgetHeader>
                            )}
                            {this.renderCampaignFormColumn()}
                        </>
                    </StyledColumnWidget>
                    {!this.props.shouldTopAlignDescription &&
                        this.shouldShowDescriptionBox() &&
                        this.renderCampaignDescriptionColumn()}
                </StyledCampaignWrapper>
            </div>
        )
    }

    isSingleColumnLayout() {
        const isPersonalizedMessages =
            this.props.content.campaign_type ===
            DjangIO.app.grassroots.campaign.types.CampaignType.personalized_messages.value
        const hasPendencies = !this.props.isAuthenticated || this.props.hasUnfilledRequiredFields
        return isPersonalizedMessages && !hasPendencies && isFeatureEnabled("ff_ngg_personalized_messages_design")
    }

    shouldShowDescriptionBox() {
        if (this.isSingleColumnLayout()) return false

        return this.props.content.long_description !== "" || this.props.shouldDisplayProgressBar
    }
}

CampaignWrapper.defaultProps = {
    shouldTopAlignDescription: true,
}

export default CampaignWrapper
