/* eslint-disable quote-props */

const compliance = 0
const notification = 1

const { PacFileFormStatusType } = DjangIO.app.pac.types

export const ENCRYPTED_DATA_PLACEHOLDER = "**********"

export const COMMITTEE_ENTITY_TYPE_KEY = "COMMITTEE_ENTITY_TYPE"
export const ORGANIZATION_ENTITY_TYPE_KEY = "ORGANIZATION_ENTITY_TYPE"
export const FILING_CREATE_FORM_KEY = "FILING_CREATE_FORM"
export const PRINT_CHECKS_FORM_KEY = "PRINT_CHECKS_FORM_KEY"
export const RECONCILIATION_DETAIL_TABLE = "pacReconciliationDetailViewTable"
export const RECONCILIATION_DETAIL_BATCH_TABLE = "pacReconciliationDetailViewBatchTable"
export const RECONCILIATION_SUMMARY_TABLE = "pacReconciliationSummaryTable"
export const RECONCILIATION_TRANSACTION_SET_NAME = "matchedTransactionSet"
export const RECONCILIATION_TRANSACTION_BATCH_SET_NAME = "matchedTransactionBatchSet"

export const TRANSACTION_SUMMARY_TABLE_ID = `transactionListTable-${DjangIO.app.ledger.types.TransactionTableFilterType.no_filter.value}`

export const FINANCIAL_LARGE_GATEWAY_WIDTH = 79
export const MAX_LARGE_GATEWAY_PER_ROW = 3

export const FEC_REPORT_EMPTY_STATE_PARAGRAPH = `
    Generate a PDF Representation of your FEC Report.
    Click File FEC Report only when you are ready to file.
    Once you have submitted your report, you'll be able to view your status
    under the filings list view.
`
export const FEC_REPORT_EMPTY_STATE_TITLE = "Generate and E-File Your FEC Report!"

export const FEC_REPORT_CONFIRMATION_PARAGRAPH = ""
export const FEC_REPORT_CONFIRMATION_TITLE = "Are you sure you want to file?"

export const RECONCILIATION_DISABLE_FINISH_TOOLTIP =
    "The balance in Quorum should equal the last statement ending balance."
export const RECONCILIATION_RECENT_DESCRIPTION =
    "The date and amount are from the last time you reconciled in Quorum. The initial amount was entered in your settings upon first set up."

export const TRANSACTION_FORM_TRANSACTION_TYPE_DESCRIPTION_TEXT = (typeName, entityRole) =>
    `${typeName} types are used to determine where a transaction will appear on a compliance report. The available types will change based on which ${entityRole} you've selected above. If you need to refund or void a transaction, please go to the Transaction table to create those transactions.`
export const TRANSACTION_FORM_ENTITY_AWAITING_FURTHER_SELECTION_MESSAGE = (typeName) =>
    `Please select from the dropdown above to find relevant ${typeName} types. Without a selection, there are no ${typeName} type choices available yet.`

export const VALIDATION_MESSAGE_FOR_EMPTY_END_BALANCE = "Please include an ending balance."
export const VALIDATION_MESSAGE_FOR_EMPTY_END_DATE = "Please include an ending date."

export const VALIDATION_MESSAGE_FOR_EMPTY_DESCRIPTION =
    "For each refund add a Purpose of disbursement (a brief but specific description of why the disbursement was made)."

export const MAX_PAC_CHECKS_TO_LOAD = 500

export const FORM_ONE_REQUIRED_FIELDS_TEXT =
    "Enter the next amendment number and the FEC Filing ID# of the previous amended filing (if applicable)"
export const FORM_ONE_PAC_SETTINGS_TEXT =
    "The information below is populated from PAC Settings. Please review and confirm if there were changes to information previous filed. Return to PAC Settings if you want to edit the information listed."

export const defaultDisclaimerText = (pacName = "a PAC") =>
    `Contributions to ${pacName} are not deductible for federal tax purposes. All contributions to ${pacName} are voluntary and you have a right to refuse without reprisal. The proposed contribution amounts are only suggestions and more or less may be given. The amount given by the contributor, or the refusal to give, will not benefit or disadvantage the person being solicited. Federal law requires ${pacName} to report the full name, address, occupation and employer of individuals whose contributions exceed $200 per year. Federal law prohibits corporate contributions.`

// FEC max lengths. Unless a form number is included in the constant name,
// it applies to any field of its type across all forms.
export const FEC_COMMITTEE_ID_MAX_LENGTH = 9 // C plus eight digits. If possible we should enforce this starts with C.
export const FEC_CANDIDATE_ID_MAX_LENGTH = 9 // H, S, or P plus eight digits.
export const FEC_COMMITTEE_NAME_MAX_LENGTH = 200 // applies to any political committee name, on any form
export const FEC_ORGANIZATION_NAME_MAX_LENGTH = 200 // affiliated org name, transaction org name, etc.
export const FEC_COMMITTEE_STREET1_MAX_LENGTH = 34
export const FEC_COMMITTEE_STREET2_MAX_LENGTH = 34
export const FEC_COMMITTEE_CITY_MAX_LENGTH = 30
export const FEC_COMMITTEE_STATE_MAX_LENGTH = 2
export const FEC_COMMITTEE_ZIP_MAX_LENGTH = 9

export const FEC_COMMITTEE_EMAIL_MAX_LENGTH = 90
export const FEC_COMMITTEE_URL_MAX_LENGTH = 90

export const FEC_FIRST_NAME_MAX_LENGTH = 20
export const FEC_LAST_NAME_MAX_LENGTH = 30
export const FEC_MIDDLE_NAME_MAX_LENGTH = 20
export const FEC_PREFIX_MAX_LENGTH = 10
export const FEC_SUFFIX_MAX_LENGTH = 10

export const FEC_DOLLAR_AMOUNT_MAX_LENGTH = 12 // includes - or . DOES NOT include $
export const FEC_TRANSACTION_MEMO_OR_DESCRIPTION_MAX_LENGTH = 100
export const FEC_TRANSACTION_EXPENDITURE_PURPOSE_DESCRIPTION_MAX_LENGTH = 100

export const FEC_ID_PREFIX = "FEC-"

// used for a TEXT annotation of a single transaction in Form 3X
export const FEC_FORM_3X_TEXT_ANNOTATION_MAX_LENGTH = 4000

// used for a designated Form 99 text report
export const FEC_FORM_99_TEXT_MAX_LENGTH = 20000

// used in grassroots action filters
// NOTE: because we are using 2 different enum classes here
// if you add more options to this please make sure they are not overlapping in value
export const GRASSROOTS_ACTION_FILTERS_PAYMENT_TYPES = [
    DjangIO.app.ledger.types.TransactionMethodType.credit_card,
    DjangIO.app.ledger.types.TransactionMethodType.payroll,
    DjangIO.app.ledger.types.TransactionMethodType.check,
    DjangIO.app.grassroots.types.PayrollDonationType.percentage,
]
